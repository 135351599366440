import React from 'react';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';

export default function RiderOrderFilter(props) {
    const { setAppliedRiderFilter, setAppliedOrderFilter, appliedOrderFilter, appliedRiderFilter } = props;

    const riderFilterStatus = [
        { label: 'All', value: null },
        { label: 'Good', value: 'good' },
        { label: 'Average', value: 'average' },
        { label: 'Bad', value: 'bad' },
    ];

    const orderFilterStatus = [
        { label: 'All', value: null },
        { label: 'Good', value: 'good' },
        { label: 'Average', value: 'average' },
        { label: 'Bad', value: 'bad' },
    ];

    const handleOrderRatingSelection = (selection) => {
        setAppliedOrderFilter(selection);
    };

    const handleRiderRatingSelection = (selection) => {
        setAppliedRiderFilter(selection);
    };

    return (
        <section className='flex flex-row items-center justify-end mb-6 md:mb-4'>
            <div className='w-[200px] mr-2'>
                <DropdownWithValueLabel
                    menuItems={orderFilterStatus}
                    label='Order Rating'
                    placeholder='Select order rating'
                    selectedItem={appliedOrderFilter ?? orderFilterStatus[0]}
                    setSelected={(selection) => handleOrderRatingSelection(selection)}
                />
            </div>
            <div className='w-[200px] ml-2'>
                <DropdownWithValueLabel
                    menuItems={riderFilterStatus}
                    label='Rider Rating'
                    selectedItem={appliedRiderFilter?.value ? appliedRiderFilter : riderFilterStatus[0]}
                    setSelected={(selection) => handleRiderRatingSelection(selection)}
                />
            </div>
        </section>
    );
}
