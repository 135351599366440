import React, { useState } from 'react';
import DownArrow from './DownArrow';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as ExportIcon } from '../../../../src/Assets/export.svg';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import APIV3 from '../../../api/axios/APIV3';

const PaymentReports = ({ tabName, reportType }) => {
    const [isReportGenerated, setIsReportGenerated] = useState(false);
    const [reportData, setReportData] = useState(null);
    const today = new Date();
    const [selectedDates, setSelectedDates] = useState([today, today]);

    const [reportDetails, setReportDetails] = useState([]);
    const [isDisable, setIsDisable] = useState(false);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    // Function to simulate report generation
    // const generateReport = () => {
    //     const report = {
    //         title: 'Payment Report',
    //         duration: `${format(selectedDates[0])} - ${format(selectedDates[1])}`, // Use formatted date range
    //         status: 'Completed',
    //         vendor: 'Vendor 1',
    //     };
    //     // Simulating report generation delay
    //     setTimeout(() => {
    //         setReportData(report);
    //         setIsReportGenerated(true);
    //     }, 1000); // Simulate 1-second delay for report generation
    // };

    const formatDate = (date) => format(date, 'yyyy-MM-dd');
    const getParams = () => {
        if (!selectedDates.length) {
            const params = {
                filters: {
                    date_range: {
                        from_date: format(new Date(), 'yyyy-MM-dd'),
                        to_date: format(new Date(), 'yyyy-MM-dd'),
                    },
                },
            };

            return params;
        }

        const start_date = format(new Date(Math.min(...selectedDates)), 'yyyy-MM-dd');

        const end_date = format(new Date(Math.max(...selectedDates)), 'yyyy-MM-dd');

        const params = {
            start_date,
            end_date,
        };

        return params;
    };

    const handleClickExportData = async () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        const reportFilterData = {
            type: reportType,
            start_date: formatDate(from_date),
            end_date: formatDate(to_date),
            outlet_id: selectedOutletId,
        };

        try {
            setIsDisable(true);
            const response = await APIV3.post(`export-report`, reportFilterData);
            if (response.success === true) {
                setIsDisable(false);
            } else {
                setIsDisable(false);
            }
        } catch (error) {
            console.log(error);
            setIsDisable(false);
        }
    };
    return (
        <div className='lg:mx-[0rem] mx-[1rem] md:mx-0'>
            <div className='p-4 lg:p-6 h-full overflow-auto'>
                <span className='text-2xl font-bold mb-4'>{tabName} Reports</span>
                <div className='mb-4'>
                    <span className='text-orange-500 font-semibold text-lg'>Please note:</span>
                    <span className='text-sm text-gray-600 ml-4'>
                        • Data Available for {format(selectedDates[0], 'dd MMM yyyy')} -{' '}
                        {format(selectedDates[1], 'dd MMM yyyy')} , Max Duration in a Single Report: 1 month
                    </span>
                </div>
                <DownArrow
                    isReportGenerated={isReportGenerated}
                    reportData={reportData}
                    selectedDates={selectedDates}
                    setSelectedDates={setSelectedDates}
                />
                <div className='flex justify-between items-center md:p-2 lg:p-3 xl:p-3 my-4 p-4'>
                    <button className='text-blue-500 hover:text-blue-700 text-lg'>+ Add Multiple Filters</button>
                </div>

                <div className='flex flex-row h-[5rem] gap-6 items-center justify-start lg:mx-2 mx-4'>
                    <div className='sm:w-[8rem] sm:h-[3rem]'>
                        <div
                            className='md:w-1/2 md:ml-[8.5px] mobile:ml-1'
                            onClick={handleClickExportData}>
                            <LargePrimaryButton
                                label='Export data'
                                leftIconDefault={<ExportIcon stroke='#FFFFFF' />}
                                leftIconClick={<ExportIcon stroke='#C4BEED' />}
                                hideLabel='lg:hidden md:block'
                            />
                        </div>
                    </div>

                    <button className='w-[14rem] h-[3rem] sm:w-[9rem] sm:h-[3rem] rounded-md group border bg-shades-50 border-neutral-300 flex items-center justify-center hover:bg-primary-50 hover:text-primary-500 hover:border-primary-500'>
                        Send to Email
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PaymentReports;
