import * as types from '../types/pos';

const initialState = {
    isOnline: true,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_INTERNET_STATUS:
            return {
                ...state,
                isOnline: action.payload,
            };

        default:
            return state;
    }
};
