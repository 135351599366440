import React from 'react';
import { ReactComponent as EditIcon } from '../../../../../../Assets/edit.svg';
import { CheckBoxWithoutLabels } from '../../../../../../Components/FormControl/FormControls';
import { useFormContext } from 'react-hook-form';

export default function Table(props) {
    const { isEditable, togglePopup, isSelection, onSelection } = props;

    const { watch } = useFormContext();

    const handleClickEdit = (element) => {
        const title = element?.label;

        const color = element?.value?.color;

        isEditable && togglePopup({ title: title, color: color, isEdit: true, badge: element });
    };

    return (
        <>
            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border mb-4'>
                <table className='w-full break-words tableMediaLibrary'>
                    <thead>
                        <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                            <th className='px-6 min-w-[200px] paragraph-overline-small text-neutral-700 md:px-0 md:pl-3 md:min-w-[112px]'>
                                BADGE TITLE
                            </th>
                            <th className='px-6 min-w-[196px] paragraph-overline-small text-neutral-700 md:px-0 md:pl-3 md:min-w-[150px]'>
                                BADGE COLOR
                            </th>
                            <th className='px-6 min-w-[202px] paragraph-overline-small text-neutral-700 md:px-0 md:pl-3 md:min-w-[150px]'>
                                BADGE PREVIEW
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {watch('availableBadge')?.map((el, index) => (
                            <tr
                                className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                key={index}>
                                <td className='px-6'>
                                    <div
                                        className={`flex flex-row items-center ${isEditable && 'cursor-pointer'}`}
                                        onClick={() => handleClickEdit(el)}>
                                        {isEditable && (
                                            <span className='mr-3'>
                                                <EditIcon />
                                            </span>
                                        )}
                                        {isSelection && (
                                            <div className='mr-3'>
                                                <CheckBoxWithoutLabels
                                                    onChange={(isSelected) => onSelection?.(el?.value?.id, isSelected)}
                                                    isChecked={el?.value?.isSelected}
                                                />
                                            </div>
                                        )}
                                        <span>{el.label}</span>
                                    </div>
                                </td>

                                <td className='px-6'>
                                    <div className='flex flex-row items-center'>
                                        <div
                                            style={{
                                                ...(el?.style
                                                    ? {
                                                          ...el.style,
                                                          border: `${
                                                              el.style.background === '#FFFFFF'
                                                                  ? '1px solid #d3d2d8'
                                                                  : 'none'
                                                          }`,
                                                      }
                                                    : {}),
                                            }}
                                            className={`h-6 w-6 rounded mr-3`}
                                        />
                                        <span className='paragraph-small-regular'>{el?.value?.color}</span>
                                    </div>
                                </td>

                                <td className='px-6'>
                                    <div
                                        style={{ ...(el?.style ? el.style : {}) }}
                                        className={`px-2 py-1 rounded w-fit ${
                                            el.style.background === '#FFFFFF'
                                                ? 'border border-neutral-300 text-neutral-900'
                                                : ' text-white'
                                        }`}>
                                        {el.label}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}
