import React, { useEffect, useState } from 'react';

export default function DeliveryEstimation({ selectedDeliveryPartner, selectedRider, serviceDetail, pidgeData }) {
    const [riderDetail, setRiderDetail] = useState({});
    const [riderName, setRiderName] = useState('');

    useEffect(() => {
        if (pidgeData) {
            setRiderDetail(pidgeData);
            setRiderName(selectedDeliveryPartner?.value || '');
        } else {
            const partnerValue = selectedDeliveryPartner?.value;
            const riderService =
                partnerValue === 'dunzo'
                    ? serviceDetail?.dunzo_order_estimation
                    : partnerValue === 'shadowfax'
                      ? serviceDetail?.shadowfex_order_estimation
                      : partnerValue === 'porter'
                        ? serviceDetail?.porter_order_estimation
                        : partnerValue === 'wefast'
                          ? serviceDetail?.wefastResponse?.order
                          : {};

            setRiderDetail(riderService);
            setRiderName(partnerValue || '');
        }
    }, [pidgeData, selectedDeliveryPartner, serviceDetail]);

    const estimatedPrice = riderDetail?.vehicles
        ? parseFloat(
              Object.values(riderDetail.vehicles).reduce(
                  (t, { fare }) => Math.min(t, fare?.minor_amount || t),
                  Infinity
              ) / 100
          ).toFixed(2)
        : '-';
    return (
        <div className='mt-4'>
            {riderName !== 'bolt' && pidgeData ? (
                <>
                    <div className='flex flex-row items-center mb-1.5'>
                        <span className='paragraph-medium-medium text-neutral-500'>Base Delivery Amount: </span>
                        <span className='paragraph-medium-regular ml-2'>{riderDetail?.base_delivery_charge}</span>
                    </div>
                    <div className='flex flex-row items-center mb-1.5'>
                        <span className='paragraph-medium-medium text-neutral-500'>GST Charges: </span>
                        <span className='paragraph-medium-regular ml-2'>{riderDetail?.total_gst_amount}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <span className='paragraph-medium-medium text-neutral-500'>Estimate Pickup Time: </span>
                        <span className='paragraph-medium-regular ml-2'>{riderDetail?.pickup_min}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <span className='paragraph-medium-medium text-neutral-500'>Surge Amount: </span>
                        <span className='paragraph-medium-regular ml-2'>{riderDetail?.surge}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <span className='paragraph-medium-medium text-neutral-500'>Total Amount: </span>
                        <span className='paragraph-medium-regular ml-2'>{riderDetail?.price}</span>
                    </div>
                </>
            ) : riderName === 'porter' ? (
                <>
                    <div className='flex flex-row items-center mb-1.5'>
                        <span className='paragraph-medium-medium text-neutral-500'>Estimated Delivery Cost: </span>
                        <span className='paragraph-medium-regular ml-2'>{`₹${estimatedPrice}`}</span>
                    </div>
                </>
            ) : riderName !== 'bolt' ? (
                <>
                    <div className='flex flex-row items-center mb-1.5'>
                        <span className='paragraph-medium-medium text-neutral-500'>Base Delivery Amount: </span>
                        <span className='paragraph-medium-regular ml-2'>{riderDetail?.delivery_fee_amount}</span>
                    </div>
                    <div className='flex flex-row items-center mb-1.5'>
                        <span className='paragraph-medium-medium text-neutral-500'>Start time: </span>
                        <span className='paragraph-medium-regular ml-2'>
                            {riderDetail?.points?.[0]?.required_start_datetime}
                        </span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <span className='paragraph-medium-medium text-neutral-500'>Finish Time: </span>
                        <span className='paragraph-medium-regular ml-2'>
                            {riderDetail?.points?.[0]?.required_finish_datetime}
                        </span>
                    </div>
                </>
            ) : (
                <>
                    <div className='flex flex-row items-center mb-1.5'>
                        <span className='paragraph-medium-medium text-neutral-500'>Rider Name: </span>
                        <span className='paragraph-medium-regular ml-2'>{selectedRider?.riderName || 'N/A'}</span>
                    </div>
                    <div className='flex flex-row items-center mb-1.5'>
                        <span className='paragraph-medium-medium text-neutral-500'>Mobile Number: </span>
                        <span className='paragraph-medium-regular ml-2'>{selectedRider?.mobileNumber || 'N/A'}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <span className='paragraph-medium-medium text-neutral-500'>Normal Charge: </span>
                        <span className='paragraph-medium-regular ml-2'>{selectedRider?.normalCharge || 'N/A'}</span>
                    </div>
                    <div className='flex flex-row items-center mb-1.5'>
                        <span className='paragraph-medium-medium text-neutral-500'>Extra Charge: </span>
                        <span className='paragraph-medium-regular ml-2'>
                            {selectedRider?.extraDeliveryRate || 'N/A'}
                        </span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <span className='paragraph-medium-medium text-neutral-500'>Total Amount: </span>
                        <span className='paragraph-medium-regular ml-2'>{selectedRider?.deliveryRate || 'N/A'}</span>
                    </div>
                </>
            )}
        </div>
    );
}
