import React, { useState } from 'react';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';

export default function Filters(props) {
    const { setAppliedFilter } = props;

    const riderFilterStatus = [
        { label: 'All', value: null },
        { label: 'Good', value: 'good' },
        { label: 'Average', value: 'average' },
        { label: 'Bad', value: 'bad' },
    ];
    const [selectedItem, setSelectedItem] = useState(riderFilterStatus[0]);

    const handleSelection = (selection) => {
        setSelectedItem(selection);
        setAppliedFilter(selection.value);
    };

    return (
        <section className='flex md:block items-center justify-end mb-6 md:mb-4 mt-4'>
            <div className='flex sm:block'>
                <div className='w-[200px] md:w-1/2 sm:w-full mr-2 sm:mr-0 sm:mb-3 md:mr-1'>
                    <DropdownWithValueLabel
                        menuItems={riderFilterStatus}
                        label='Mode'
                        placeholder='Rider Rating'
                        selectedItem={selectedItem}
                        setSelected={(selection) => {
                            handleSelection(selection);
                        }}
                    />
                </div>
            </div>
        </section>
    );
}
