import React, { useEffect, useState } from 'react';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { DefaultInputField } from '../../../Components/InputField/InputField';

import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import APIV5 from '../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SetAlertLoader from '../Components/SetAlertLoader';
import { ErrorMessage } from '../../../Components/Error/ErrorMessage';

export default function SetAlertPopup(props) {
    const { handleClickClose, fetchTransactionList, fetchServiceCreditList } = props;

    const { creditType } = useParams();

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [isPopupLoading, setIsPopupLoading] = useState(false);

    const [alertAmount, setAlertAmount] = useState(props.alertAmount);
    const [error, setError] = useState(null);
    useEffect(() => {
        setError(null);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsPopupLoading(true);
                if (creditType === 'infrastructure') {
                    const response = await APIV5.get(
                        `restaurants/${selectedRestaurantId}/low-infrastructure-credit-alert`
                    );
                    setAlertAmount(
                        response?.low_infrastructure_credit_alert_amount?.low_infrastructure_credit_alert_amount
                    );
                } else if (creditType === 'service') {
                    const response = await APIV5.get(
                        `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/low-service-credit-alert`
                    );

                    setAlertAmount(response?.low_service_credit_alert_amount);
                }
                setIsPopupLoading(false);
            } catch (error) {
                console.error('Error fetching alert data:', error);
            }
        };

        fetchData();
    }, [creditType, selectedRestaurantId, selectedOutletId]);

    const updateInfraCreditAlert = async () => {
        try {
            if (alertAmount <= 0) {
                setError({ message: 'The low service credit alert amount must be between 1 and 8999999999999999999.' });
                return;
            }
            setError(null);

            const response = await APIV5.put(`/restaurants/${selectedRestaurantId}/credit-settings`, {
                low_infrastructure_credit_alert_amount: alertAmount,
            });
            if (response.success) {
                handleClickClose();
                fetchTransactionList();
            } else {
                setError({
                    message: response?.errors?.low_infrastructure_credit_alert_amount ?? 'something went wrong',
                });
            }
        } catch (error) {
            setError({ message: error?.response?.data?.errors?.low_infrastructure_credit_alert_amount });

            console.log(error);
        }
    };

    const updateServiceCreditAlert = async () => {
        try {
            if (alertAmount <= 0) {
                setError({ message: 'The low service credit alert amount must be between 1 and 8999999999999999999.' });
                return;
            }
            setError(null);
            const response = await APIV5.put(`/restaurants/${32}/outlets/${selectedOutletId}/service-credits-alert`, {
                low_service_credit_alert_amount: alertAmount,
            });
            if (response.success) {
                handleClickClose();
                fetchServiceCreditList();
            } else {
                setError({ message: response?.errors?.low_service_credit_alert_amount ?? 'something went wrong' });
            }
        } catch (error) {
            setError({ message: error?.response?.data?.errors?.low_service_credit_alert_amount });
            console.log(error);
        }
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex px-4'>
                <div className='w-[475px] rounded-xl bg-white px-8 py-6 md:p-4 m-auto'>
                    {isPopupLoading ? (
                        <SetAlertLoader />
                    ) : (
                        <>
                            <div className='flex justify-between items-center'>
                                <div>
                                    <h3 className='paragraph-large-medium'>Set alert</h3>
                                    <p className='paragraph-medium-italic text-neutral-500'>
                                        Select minimum amount to trigger alert
                                    </p>
                                </div>
                                <span
                                    className='cursor-pointer'
                                    onClick={() => handleClickClose()}>
                                    <CloseIcon />
                                </span>
                            </div>

                            <div className='pt-6 pb-12 md:py-3'>
                                <DefaultInputField
                                    inputType='number'
                                    value={alertAmount}
                                    enteredValue={setAlertAmount}
                                    label='Alert amount'
                                    placeholder='Enter amount'
                                    boxHeight='11'
                                />
                                {error && <ErrorMessage errors={error} />}
                            </div>

                            <div
                                onClick={() =>
                                    creditType === 'infrastructure'
                                        ? updateInfraCreditAlert()
                                        : updateServiceCreditAlert()
                                }>
                                <LargePrimaryButton label='Save' />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
