import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export default function BasicDetails(props) {
    const { dishDetails, selectedCategoryDish } = props;

    const [showDetails, setShowDetails] = useState(false);

    const selectedLanguage = useSelector((state) => state.langauge.languageId);

    const handleCardClick = () => {
        if (!showDetails) {
            setShowDetails(true);
        }
    };

    const toggleShowDetails = (e) => {
        e.stopPropagation();
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };

    return (
        <>
            <div
                className={`border border-neutral-300 px-4 py-[15px] rounded-lg w-full ${
                    !showDetails && 'cursor-pointer'
                }`}
                onClick={handleCardClick}>
                <div className={`flex flex-row justify-between`}>
                    <span className='paragraph-medium-medium'>Basic details</span>
                    <span
                        className='paragraph-medium-regular text-primary-500 cursor-pointer'
                        onClick={toggleShowDetails}>
                        {!showDetails ? 'Show' : 'Hide'}
                    </span>
                </div>

                {showDetails && (
                    <div className={`mt-2`}>
                        <div className='flex flex-row items-start mb-2'>
                            <img
                                src={dishDetails?.image?.image_url}
                                alt={dishDetails?.image?.image_title}
                                className='w-[144px] h-[96px] mr-3 rounded'
                            />
                            <div className='flex flex-col'>
                                <div className='flex flex-row mb-4'>
                                    <span className='paragraph-small-regular text-neutral-500 mr-2'>Category:</span>
                                    <span className='paragraph-small-regular'>
                                        {selectedCategoryDish.category.internal_name}
                                    </span>
                                </div>
                                <div className='flex flex-row mr-4'>
                                    <span className='paragraph-small-regular text-neutral-500 mr-2 min-w-[94px]'>
                                        Internal name:
                                    </span>
                                    <span className='paragraph-small-regular break-words'>
                                        {dishDetails.internal_name}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className=''>
                            <div className='flex flex-row items-start mb-4'>
                                <span className='paragraph-small-regular text-neutral-500 mr-2'>Dish title:</span>
                                <div className='flex flex-col items-start'>
                                    <span className='paragraph-small-regular mb-2 last:mb-0'>
                                        {dishDetails.translations.title[selectedLanguage]}
                                    </span>
                                </div>
                            </div>
                            <div className='flex flex-row items-start'>
                                <span className='paragraph-small-regular text-neutral-500 mr-2'>Description:</span>
                                <div className='flex flex-col items-start'>
                                    <span className='paragraph-small-regular mb-2 last:mb-0'>
                                        {dishDetails.translations.description[selectedLanguage]}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
