import * as types from '../types/outlet'; // importing action types

export const setRedirectoryLink = (redirectLink) => {
    return {
        type: types.SET_REDIRECTORY_LINK,
        payload: { redirectLink },
    };
};
export const toggleActionSwitch = (switchName, isEnabled) => ({
    type: types.TOGGLE_ACTION_SWITCH,
    payload: { switchName, isEnabled },
});
export const savePOSSettings = (settings) => ({
    type: types.SAVE_POS_SETTINGS,
    payload: settings,
});
