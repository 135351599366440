import React, { useState } from 'react';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../Components/Buttons/Button';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { ReactComponent as SelectIcon } from '../../../Assets/select.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { ReactComponent as ListViewIcon } from '../../../Assets/view.svg';
import { ReactComponent as GridViewIcon } from '../../../Assets/grid-view.svg';
import { IsMobileScreen } from '../../../Constants/Constants';
import { Tab } from '../../../Components/Tabs/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmDeletePopup from '../../../Components/ConfirmDeletePopup/ConfirmDeletePopup';
import APIV2 from '../../../api/axios/APIV2';
import { useSelector } from 'react-redux';

export default function ListControllerSection(props) {
    const { setIsSelectionEnable, isSelectionEnable, selectedMediaList, fetchMedia, setSelectedMediaList } = props;

    const { view } = useParams();

    const navigate = useNavigate();

    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);

    const isMobileScreen = IsMobileScreen();

    const handleTaNavigation = (view) => {
        navigate(`/media-library/${view}`);
    };

    const dropDownTabMenuItem = [
        {
            item: 'List view',
            onClick: () => handleTaNavigation('list'),
            icon: <ListViewIcon className='mobile:hidden' />,
            iconClick: <ListViewIcon stroke='#6C5DD3' />,
        },
        {
            item: 'Grid view',
            onClick: () => handleTaNavigation('grid'),
            icon: <GridViewIcon className='mobile:hidden' />,
            iconClick: <GridViewIcon stroke='#6C5DD3' />,
        },
    ];

    const handleClickCancel = () => {
        setIsSelectionEnable(false);
        setSelectedMediaList([]);
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [errorWhileDelete, setErrorWhileDelete] = useState();

    const handleDelete = async () => {
        const params = { image_ids: selectedMediaList };

        try {
            const response = await APIV2.delete(`/restaurants/${selectedRestaurantId}/uploads`, { params });

            if (response.success) {
                setShowConfirmDeletePopup(false);
                fetchMedia();
                setSelectedMediaList([]);
            }
        } catch (error) {
            setErrorWhileDelete(error.response.data);
            console.log(error);
        }
    };

    return (
        <>
            <div className='flex flex-row justify-between mb-6 md:mb-3'>
                {!isSelectionEnable ? (
                    <div
                        className='max-w-[156px] w-full md:max-w-full md:w-1/2 md:pr-2 cursor-pointer mobile:pr-1'
                        onClick={() => setIsSelectionEnable(true)}>
                        <LargePrimaryButton
                            isDefault={false}
                            label='Bulk select'
                            leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                            leftIconClick={<SelectIcon stroke='#C4BEED' />}
                        />
                    </div>
                ) : (
                    <div className='flex flex-row items-center md:w-1/2 justify-between md:pr-2'>
                        <div
                            className='max-w-[125px] mr-4 mobile:mr-2 md:w-1/2 md:max-w-full cursor-pointer'
                            onClick={handleClickCancel}>
                            <LargePrimaryButton
                                isDefault={false}
                                label='Cancel'
                                hideLabel='lg:hidden'
                                leftIconDefault={<CloseIcon stroke='#FFFFFF' />}
                                leftIconClick={<CloseIcon stroke='#C4BEED' />}
                            />
                        </div>

                        <div
                            className='max-w-[192px] md:w-1/2 md:max-w-full'
                            onClick={() => setShowConfirmDeletePopup(true)}>
                            <LargeDestructiveButton
                                label='Delete selected'
                                leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                                leftIconClick={<SelectIcon stroke='#D7EDEB' />}
                                hideLabel='lg:hidden'
                                isDefault={false}
                                disabled={!selectedMediaList.length}
                            />
                        </div>

                        <span className='paragraph-large-italic text-neutral-500 mx-4 md:hidden'>
                            (Selected images - {selectedMediaList.length})
                        </span>
                    </div>
                )}

                {!isMobileScreen ? (
                    <div className='flex flex-row'>
                        <div
                            className='mr-4 cursor-pointer'
                            onClick={() => handleTaNavigation('grid')}>
                            <Tab
                                label='Grid view'
                                IconDefault={<GridViewIcon />}
                                IconClick={<GridViewIcon stroke='#6C5DD3' />}
                                isActive={view === 'grid'}
                            />
                        </div>

                        <div
                            className='cursor-pointer'
                            onClick={() => handleTaNavigation('list')}>
                            <Tab
                                label='List view'
                                IconDefault={<ListViewIcon />}
                                IconClick={<ListViewIcon stroke='#6C5DD3' />}
                                isActive={view === 'list'}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='w-1/2 pl-2 mobile:pl-1'>
                        <DropDownTabs
                            tabStyle='paragraph-small-medium'
                            menuItems={dropDownTabMenuItem}
                            selectedItem={view === 'grid' ? dropDownTabMenuItem[1] : dropDownTabMenuItem[0]}
                        />
                    </div>
                )}
            </div>

            {showConfirmDeletePopup && (
                <ConfirmDeletePopup
                    handleClickClose={() => {
                        setShowConfirmDeletePopup(false);
                        setErrorWhileDelete();
                    }}
                    header={<h2 className='paragraph-large-medium ml-2'>Delete the media?</h2>}
                    description={
                        <p className='paragraph-medium-italic text-neutral-500 pb-3 md:pb-3'>
                            Are you sure to delete selected media
                        </p>
                    }
                    errorWhileDelete={errorWhileDelete}
                    handleDelete={handleDelete}
                />
            )}
        </>
    );
}
