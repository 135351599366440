import React, { useState } from 'react';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { ReactComponent as LeftArrow } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import DropdownWithValueLabel from '../../../../Components/DropDown/DropdownWithValueLabel';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';

export default function ReservationDetailsPopup(props) {
    const { setShowPopup, type } = props;

    const isMobileScreen = IsMobileScreen();

    const [showRefundDetails, setShowRefundDetails] = useState(false);

    const tableInformation = [
        { item: 'Name', value: 'Mr. Bahubali Sing' },
        { item: 'Mobile number', value: '+91 12345 67890' },
        { item: 'Date', value: '12 Nov 2033' },
        { item: 'Time', value: '08:30 PM' },
        { item: 'Pax', value: '12 guests' },
        { item: 'Occasion', value: 'Birth day' },
        { item: 'Section', value: 'Ground floor' },
        { item: 'Table', value: 'Table - T1' },
        { item: 'Reservation status', value: 'Reserved' },
    ];

    const firstColumnTable = tableInformation?.slice(0, 5);
    const secondColumnTable = tableInformation?.slice(5);

    const confirmationStatus = [
        { label: 'Seated', value: 'Seated' },
        { label: 'Not confirmed', value: 'Not confirmed' },
        { label: 'Confirmed', value: 'Confirmed' },
        { label: 'Cancelled', value: 'Cancelled' },
        { label: 'Late', value: 'Late' },
    ];

    const refundDetails = [
        { item: 'Amount', value: '1,234/-' },
        { item: 'Failed at', value: '12 Nov 2034' },
        { item: 'Initiated at', value: '12 Nov 2033' },
        { item: 'Received at', value: '08:30 PM' },
        { item: 'RRN number', value: '4587fj4587fj45' },
        { item: 'Payment gateway', value: 'Paytm' },
    ];

    const firstColumnRefund = refundDetails?.slice(0, 3);
    const secondColumnRefund = refundDetails?.slice(3);

    const isReservation = type !== 'waitList';

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center overflow-auto md:bg-white md:relative p-4'>
                <div className='bg-white w-[652px] md:w-full rounded-xl px-8 py-6 m-auto md:p-0'>
                    {isMobileScreen && (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={() => setShowPopup(null)}>
                            <LeftArrow className='rotate-90' />
                            <span className='ml-1'>Back to tables</span>
                        </div>
                    )}
                    <div className='flex flex-row justify-between items-center mb-2.5 pb-4 border-b border-neutral-300'>
                        <div>
                            <div className='paragraph-large-medium md:paragraph-medium-medium'>
                                {isReservation ? 'Reservation' : 'Waiting'} details
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='paragraph-medium-italic text-neutral-500'>
                                    {isReservation ? 'Reservation' : 'Waiting'} details for table T2
                                </div>
                            </div>
                        </div>

                        <span
                            className='cursor-pointer md:hidden'
                            onClick={() => setShowPopup(false)}>
                            <CloseIcon
                                height={24}
                                width={24}
                            />
                        </span>
                    </div>

                    <div className={`flex flex-row justify-between md:block`}>
                        <div className='flex flex-col'>
                            {firstColumnTable.map((el, index) => (
                                <Item
                                    {...el}
                                    key={index}
                                />
                            ))}
                        </div>

                        <div className='flex flex-col'>
                            {secondColumnTable.map((el, index) => (
                                <Item
                                    {...el}
                                    key={index}
                                />
                            ))}
                        </div>
                    </div>

                    {isReservation && (
                        <div className='flex flex-row justify-between mt-4 pt-4 border-t border-neutral-300'>
                            <div className='flex flex-row items-center gap-2'>
                                <span className='paragraph-medium-medium'>Reservation amount</span>
                                <div className='flex flex-row items-center'>
                                    <span
                                        className={`font-medium text-[10px] leading-3 px-2 py-1 rounded border mr-2 ${
                                            !false
                                                ? 'border-success-500 text-success-500 bg-success-50'
                                                : 'border-destructive-500 text-destructive-500 bg-destructive-50'
                                        }`}>
                                        {!false ? 'Paid' : 'Unpaid'}
                                    </span>
                                </div>
                            </div>

                            <div className='flex flex-row'>
                                <span
                                    className='text-primary-500 paragraph-small-medium underline cursor-pointer'
                                    onClick={() => setShowPopup(type !== 'waitList' ? 'refund' : 'reserveTable')}>
                                    {type !== 'waitList' ? 'Refund' : 'Resend'}
                                </span>
                                <span className='paragraph-medium-medium pl-3'>₹5,325.00/-</span>
                            </div>
                        </div>
                    )}

                    <div className='flex flex-row justify-between mt-4 pb-4 border-t border-neutral-300 pt-4'>
                        <span className='paragraph-medium-medium'>Refund details</span>
                        <span
                            className='paragraph-medium-regular text-primary-500 cursor-pointer'
                            onClick={() => setShowRefundDetails((prevState) => !prevState)}>
                            {showRefundDetails ? 'Hide' : 'Show'}
                        </span>
                    </div>

                    <div
                        className={`transition-all duration-500 flex flex-row justify-between overflow-hidden ${
                            showRefundDetails ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                        }`}>
                        <div className='flex flex-col'>
                            {firstColumnRefund.map((el, index) => (
                                <Item
                                    {...el}
                                    key={index}
                                />
                            ))}
                        </div>

                        <div className='flex flex-col'>
                            {secondColumnRefund.map((el, index) => (
                                <Item
                                    {...el}
                                    key={index}
                                />
                            ))}
                        </div>
                    </div>

                    <div className='flex flex-row gap-4 items-end pt-4 mt-12 '>
                        <div className='w-1/2'>
                            <DropdownWithValueLabel
                                menuItems={confirmationStatus}
                                selectedItem={confirmationStatus[1]}
                            />
                        </div>

                        {type === 'waitList' ? (
                            <div className='w-1/2 flex flex-row gap-3'>
                                <div className='w-1/2'>
                                    <LargePrimaryButton label='Print' />
                                </div>
                                <div
                                    className='w-1/2'
                                    onClick={() => setShowPopup('reserveTable')}>
                                    <LargePrimaryButton label='Edit' />
                                </div>
                            </div>
                        ) : (
                            <div
                                className='w-1/2 flex justify-end'
                                onClick={() => setShowPopup('reserveTable')}>
                                <div className='w-1/2'>
                                    <LargePrimaryButton label='Edit' />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

const Item = ({ item, value, actionComponent }) => {
    return (
        <div className='my-1.5 flex flex-row'>
            <span className='paragraph-medium-medium text-neutral-500'>{item}:</span>

            <div className='flex flex-row items-center ml-2'>
                <span className='paragraph-medium-regular'>{value}</span>

                {actionComponent}
            </div>
        </div>
    );
};

//  refund remove bottom
//  after paid remove dont edit
