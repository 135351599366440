import React from 'react';

export const CardDescription = (props) => {
    const { description, focusContentColor, focusContent } = props;

    const segments = description.split(':focusContent:');

    return (
        <>
            <p className='paragraph-x-small-regular text-neutral-500'>
                {segments[0]}
                {focusContent && (
                    <span className={`paragraph-x-small-medium ${focusContentColor}`}>{Math.abs(focusContent)}</span>
                )}
                {segments[1]}
            </p>
        </>
    );
};

export const AboutDot = () => {
    return (
        <>
            <div className='flex flex-row ml-6'>
                <div className='h-1 w-1 rounded-full bg-neutral-500' />
                <div className='h-1 w-1 rounded-full bg-neutral-500 mx-0.5' />
                <div className='h-1 w-1 rounded-full bg-neutral-500' />
            </div>
        </>
    );
};
