import React, { useEffect, useState } from 'react';
import GridSection from '../Sections/GridSection';
import TableSection from '../Sections/TableSection';
import ChangeStatusPopup from '../Popups/ChangeStatusPopup';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { initializeRestaurant } from '../../../reduxStore/actions/initilizationActionCreator';
import { SET_SELECTED_OUTLET, SET_SELECTED_OUTLET_ID } from '../../../reduxStore/types/outlet';

export default function RestaurantList(props) {
    const {
        name,
        showGrid,
        id,
        image_url,
        outlets,
        setRestaurantsList,
        appliedFilter,
        index,
        setOpenOutletsRestaurantIndex,
        openOutletsRestaurantIndex,
    } = props;

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const initialStatusPopupData = { showPopup: false, data: null, statusOf: null };

    const [statusPopupData, setStatusPopupData] = useState(initialStatusPopupData);

    const [showOutlets, setShowOutlets] = useState(false);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape' && statusPopupData.showPopup) {
                setStatusPopupData(initialStatusPopupData);
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };

        // eslint-disable-next-line
    }, [statusPopupData.showPopup]);

    useEffect(() => {
        if (index === 0) {
            setShowOutlets(true);
            return;
        }

        if (openOutletsRestaurantIndex.includes(id)) {
            setShowOutlets(true);
            return;
        }

        const hasNonNullProperty = Object.values(appliedFilter).some((value) => value !== null);

        const hasNonNullModes = appliedFilter.modes.some((mode) => mode.status !== null);

        const isNonNullOrTrue = hasNonNullProperty && hasNonNullModes;

        setShowOutlets(isNonNullOrTrue);

        // eslint-disable-next-line
    }, [appliedFilter]);

    const handleRestaurantSelection = async (item) => {
        localStorage.setItem('selectedOutletId', item.id);

        dispatch({
            type: SET_SELECTED_OUTLET,
            payload: {
                selectedOutlet: { displayName: item?.legal_name, outletData: item, outletId: item.id },
            },
        });
        dispatch({
            type: SET_SELECTED_OUTLET_ID,
            payload: {
                selectedOutletId: item.id,
            },
        });

        localStorage.setItem('selectedRestaurantID', item.restaurant_id);

        dispatch(initializeRestaurant(item.restaurant_id, item.id, navigate, '/'));
    };

    const toggleShowOutlet = () => {
        setShowOutlets((prevState) => !prevState);
        !showOutlets
            ? setOpenOutletsRestaurantIndex((prevState) => [...prevState, id])
            : setOpenOutletsRestaurantIndex((prevState) => prevState.filter((restaurantId) => restaurantId !== id));
    };

    return (
        <>
            <div className='flex flex-row items-center justify-between'>
                <div className='flex flex-row items-center'>
                    <h3 className='heading-h3-medium-desktop md:heading-h6-medium-desktop md:tracking-normal mobile:text-base sm:max-w-[200px] md:truncate mobile:max-w-[150px]'>
                        {name}
                    </h3>
                    <span className='ml-2 paragraph-large-italic md:paragraph-small-italic'>
                        ({outlets.length} outlets)
                    </span>
                </div>

                <span
                    className='paragraph-medium-regular text-primary-500 md:paragraph-small-regular md:text-primary-500 cursor-pointer'
                    onClick={toggleShowOutlet}>
                    {showOutlets ? 'Hide all' : 'Show all'}
                </span>
            </div>

            {showOutlets && (
                <div className='mt-2 mobile:mt-1 md:mt-1'>
                    {showGrid ? (
                        <>
                            <GridSection
                                outlets={outlets}
                                image_url={image_url}
                                setStatusPopupData={setStatusPopupData}
                                handleRestaurantSelection={handleRestaurantSelection}
                            />
                        </>
                    ) : (
                        outlets.length !== 0 && (
                            <TableSection
                                outlets={outlets}
                                setStatusPopupData={setStatusPopupData}
                                handleRestaurantSelection={handleRestaurantSelection}
                                name={name}
                            />
                        )
                    )}
                </div>
            )}

            {statusPopupData.showPopup && (
                <ChangeStatusPopup
                    handleClickClose={() => setStatusPopupData(initialStatusPopupData)}
                    statusPopupData={statusPopupData}
                    setRestaurantsList={setRestaurantsList}
                />
            )}
        </>
    );
}
