import React, { useEffect, useState } from 'react';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import DropdownSection from '../../Components/DropdownSection';
import { useFormContext } from 'react-hook-form';

import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as LinearIcon } from '../../../../../../Assets/linear-menu.svg';
import { LargePrimaryButton } from '../../../../../../Components/Buttons/Button';

const paymentType = [
    { label: 'Paytm', value: 'paytm' },
    { label: 'Stripe', value: 'stripe' },
];

export default function MethodSection(props) {
    const { methods, setMethod, setShowFooter } = props;

    const {
        watch,
        setValue,
        formState: { isDirty },
    } = useFormContext();

    useEffect(() => {
        if (!watch('payment_methods')) return;

        methods.forEach((el) => {
            const name = el.id.replace('payment_methods.', '');

            if (watch('payment_methods.paytm')?.includes(name)) {
                setValue(el.id, paymentType[0]);
                setValue(`defaultValue.${el.id}`, paymentType[0]);
            }

            if (watch('payment_methods')?.stripe?.includes(name)) {
                setValue(el.id, paymentType[1]);
                setValue(`defaultValue.${el.id}`, paymentType[1]);
            }
        });

        // eslint-disable-next-line
    }, [watch('payment_methods.paytm'), watch('payment_methods.stripe')]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [isRearrange, setIsRearrange] = useState(false);

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active?.id && over?.id && active?.id !== over?.id) {
            const modes = methods;

            const oldIndex = modes.findIndex((item) => item.id === active.id);
            const newIndex = modes.findIndex((item) => item.id === over.id);

            const updatedValue = arrayMove(modes, oldIndex, newIndex);

            setMethod(updatedValue);
            setShowFooter(true);
        }
    };

    return (
        <>
            <div className='mb-4 flex flex-row justify-between'>
                <TitleDescription
                    title='Payment methods'
                    description='Select a payment gateway from the list for the respective payment method.'
                />

                {!isDirty && (
                    <div
                        className=''
                        onClick={() => setIsRearrange((prevState) => !prevState)}>
                        <LargePrimaryButton label='Rearrange' />
                    </div>
                )}
            </div>

            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor]}>
                <SortableContext
                    items={methods}
                    strategy={verticalListSortingStrategy}
                    disabled={!isRearrange}>
                    {methods?.map((el, index) => {
                        return (
                            <DragableItem
                                key={el.id}
                                el={el}
                                watch={watch}
                                index={index}
                                isRearrange={isRearrange}
                                {...el}
                            />
                        );
                    })}
                </SortableContext>
            </DndContext>
        </>
    );
}

const DragableItem = (props) => {
    const { id, isRearrange, el, index, watch } = props;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isRearrange ? 'pointer' : 'default',
    };

    return (
        <div
            className='max-w-[312px] w-full relative mb-4 md:max-w-full flex flex-row items-center'
            ref={setNodeRef}
            style={style}
            key={index}
            {...attributes}
            {...listeners}>
            {isRearrange && (
                <span className='pt-4'>
                    <LinearIcon />
                </span>
            )}

            <DropdownSection
                name={el.id}
                {...el}
                hasFixedPlaceholder={id !== 'payment_methods.CREDIT_CARD/DEBIT_CARD'}
                placeholder={
                    id !== 'payment_methods.CREDIT_CARD/DEBIT_CARD' &&
                    (watch('payment_methods.CREDIT_CARD/DEBIT_CARD')?.value === 'stripe' ? 'Unavailable' : 'Paytm')
                }
                disabled={id !== 'payment_methods.CREDIT_CARD/DEBIT_CARD'}
                menuItems={paymentType}
                labelStyle={`paragraph-medium-italic ${
                    id !== 'payment_methods.CREDIT_CARD/DEBIT_CARD' && 'text-neutral-300'
                }`}
            />
        </div>
    );
};
