import React from 'react';
import TableSection from './TableSection/TableSection';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import GridSection from './GridSection/GridSection';
import { useParams } from 'react-router-dom';
import MobileViewListSection from './TableSection/MobileViewListSection';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function ListSection(props) {
    const {
        list,
        handlePageChange,
        handleChangeResultPerPage,
        setSelectedMediaList,
        isSelectionEnable,
        paginationState,
        selectedMediaList,
        setUserDetail,
        isLoading,
        resultPerPage,
        setShowUploadPopup,
    } = props;

    const { view } = useParams();

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {view === 'list' ? (
                !isMobileScreen ? (
                    <TableSection
                        list={list}
                        setSelectedMediaList={setSelectedMediaList}
                        isSelectionEnable={isSelectionEnable}
                        selectedMediaList={selectedMediaList}
                        setUserDetail={setUserDetail}
                        isLoading={isLoading}
                        setShowUploadPopup={setShowUploadPopup}
                    />
                ) : (
                    <MobileViewListSection
                        list={list}
                        setSelectedMediaList={setSelectedMediaList}
                        isSelectionEnable={isSelectionEnable}
                        selectedMediaList={selectedMediaList}
                        setUserDetail={setUserDetail}
                        setShowUploadPopup={setShowUploadPopup}
                    />
                )
            ) : (
                <GridSection
                    list={list}
                    setSelectedMediaList={setSelectedMediaList}
                    isSelectionEnable={isSelectionEnable}
                    selectedMediaList={selectedMediaList}
                    isLoading={isLoading}
                    setShowUploadPopup={setShowUploadPopup}
                    resultPerPage={resultPerPage}
                />
            )}

            <div className='mt-4'>
                <PaginationWithNumber
                    lastPage={paginationState.lastPage}
                    setSelectedPage={handlePageChange}
                    selectedPage={paginationState.selectedPageNumber}
                    setResultPerPage={handleChangeResultPerPage}
                    selectedResultPerpage={paginationState.resultPerPage}
                />
            </div>
        </>
    );
}
