import React, { useEffect, useState } from 'react';
import APIV3 from '../../../api/axios/APIV3';
import APIV2 from '../../../api/axios/APIV2';
import DisableOutletDiningModePopup from './DisableOutletDiningModePopup';
import EnableOutletDiningModePopup from './EnableOutletDiningModePopup';
import '../Styles/datePicker.css';
import APIV5 from '../../../api/axios/APIV5';

export default function ChangeStatusPopup(props) {
    const { handleClickClose, statusPopupData, setRestaurantsList } = props;

    const [renderPopup, setRenderPopup] = useState();

    const isActive =
        statusPopupData.statusOf === 'zomato'
            ? statusPopupData?.data?.isActive
            : statusPopupData.statusOf === 'ordringMode'
              ? statusPopupData.data.is_orderingmode_active
              : statusPopupData.data.is_outlet_open;

    const toggleOrderingMode = async (nextAvailableTime) => {
        try {
            await APIV3.patch(
                `/restaurants/${statusPopupData.data.restaurant_id}/outlets/${statusPopupData.data.outlet_id}/update-mode`,
                {
                    is_available: isActive ? 0 : 1,
                    restaurant_ordering_mode_id: statusPopupData.data.restaurant_ordering_mode_id,
                    next_available_time: nextAvailableTime,
                }
            );

            handleClickClose();

            try {
                let response = await APIV5.get(`/users/restaurants/${statusPopupData.data.restaurant_id}`);
                const updateOutlets = (restaurant) => {
                    if (restaurant.id === statusPopupData.data.restaurant_id) {
                        return { ...restaurant, outlets: response.outlets };
                    }
                    return restaurant;
                };

                setRestaurantsList((prevState) => prevState.map(updateOutlets));
            } catch (error) {
                console.log(error);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const toggleOutletAvailibility = async (nextAvailableTime) => {
        try {
            await APIV2.patch(
                `restaurants/${statusPopupData.data.restaurant_id}/outlets/${statusPopupData.data.outlet_id}/update/status`,
                {
                    is_open: isActive ? 0 : 1,
                    next_available_time: nextAvailableTime,
                }
            );

            handleClickClose();

            try {
                let response = await APIV5.get(`/users/restaurants/${statusPopupData.data.restaurant_id}`);
                const updateOutlets = (restaurant) => {
                    if (restaurant.id === statusPopupData.data.restaurant_id) {
                        return { ...restaurant, outlets: response.outlets };
                    }
                    return restaurant;
                };

                setRestaurantsList((prevState) => prevState.map(updateOutlets));
            } catch (error) {
                console.log(error);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleClickChange = (nextAvailableTime) => {
        try {
            statusPopupData.statusOf === 'ordringMode'
                ? toggleOrderingMode(nextAvailableTime)
                : toggleOutletAvailibility(nextAvailableTime);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        isActive
            ? setRenderPopup(
                  <DisableOutletDiningModePopup
                      handleClickClose={handleClickClose}
                      handleClickChange={handleClickChange}
                  />
              )
            : setRenderPopup(
                  <EnableOutletDiningModePopup
                      handleClickClose={handleClickClose}
                      handleClickChange={handleClickChange}
                  />
              );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return renderPopup;
}
