import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Tables from './Components/Tables';
import Layout from './NewComponents/Layout';
import AddTablePopup from './NewComponents/AddTablePopup';
import EditTablePopup from './NewComponents/EditTablePopup';
import SectionPopup from './NewComponents/SectionPopupComponent/SectionPopup';
import TabSection from './NewComponents/TabSection/TabSection';
import LeftFlyOut from '../../Components/FlyOut/LeftFlyOut';
import { fetchSections, fetchTable } from './HelperFunction/ApiFunctions';
import { useNavigate, useParams } from 'react-router-dom';

const TableManagement = () => {
    let navigate = useNavigate();
    let { section } = useParams();
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [sectionList, setSectionList] = useState([]);
    const [errorOfApi, seterrorOfApi] = useState('');
    const [tableList, setTableList] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [totalTable, setTotalTable] = useState([]);
    const [showTableContent, setShowTableContent] = useState(true);

    const [popupStatus, setPopupStatus] = useState({
        addSection: false,
        addTable: false,
        editSection: false,
        editTable: false,
        tableArrangeMent: false,
        confirmDeletePopup: false,
    });

    const [loaderStatus, setloaderStatus] = useState({
        isLoading: false,
        isResponseSuccess: true,
        isClicked: false,
        isSectionLoading: false,
    });

    const {
        control,
        watch,
        reset,
        handleSubmit,
        formState: { errors },
        setValue,
        register,
        formState: { isDirty },
        getValues,
    } = useForm({
        defaultValues: {
            subSection: [{ name: null }],
        },
    });

    const resetPopupStatus = () => {
        //reset popup status
        setShowTableContent(true);
        reset();
        setPopupStatus({
            addSection: false,
            addTable: false,
            editSection: false,
            editTable: false,
            tableArrangeMent: false,
            confirmDeletePopup: false,
        });
        setloaderStatus({ isLoading: false, isSectionLoading: false, isResponseSuccess: true, isClicked: false });
        seterrorOfApi('');
    };

    useEffect(() => {
        //this useeffect used for fetch section and initially navigate to first section and fetch navigated section table
        //it used when page reload
        const fetchAndNavigate = async () => {
            setloaderStatus({ isLoading: true, isResponseSuccess: true, isClicked: false, isSectionLoading: true });
            const sections = await fetchSections({ setSectionList, setloaderStatus, selectedOutletId });
            console.log('sections <====>', sections);
            if (!sections || sections.length === 0) return;

            let selectedSection;
            if (section === ':section' || section === 'undefined') {
                selectedSection = sections[0].internal_name;
                navigate(`/table-management/${selectedSection}`, { replace: true });
            } else {
                // selectedSection = section;
                selectedSection = sections[0].internal_name;
                navigate(`/table-management/${selectedSection}`, { replace: true });
            }
            fetchTable({
                setloaderStatus,
                setTableList,
                setTotalTable,
                section: selectedSection,
                sectionList: sections,
            });
            setloaderStatus({ isLoading: false, isSectionLoading: false, isResponseSuccess: true, isClicked: false });
        };

        fetchAndNavigate();
        //eslint-disable-next-line
    }, [selectedOutletId, selectedRestaurantId]);
    return (
        <>
            {showTableContent && (
                <Layout
                    showMainLayout={true}
                    setPopupStatus={setPopupStatus}
                    setIsEditMode={setIsEditMode}
                    isEditMode={isEditMode}
                    popupStatus={popupStatus}
                    setShowTableContent={setShowTableContent}>
                    <TabSection
                        isEditMode={isEditMode}
                        sectionList={sectionList}
                        setPopupStatus={setPopupStatus}
                        setValue={setValue}
                        fetchSections={fetchSections}
                        setloaderStatus={setloaderStatus}
                        setTableList={setTableList}
                        setTotalTable={setTotalTable}
                        setShowTableContent={setShowTableContent}
                        loaderStatus={loaderStatus}
                    />
                    <Tables
                        isEditMode={isEditMode}
                        sectionList={sectionList}
                        setPopupStatus={setPopupStatus}
                        setValue={setValue}
                        loaderStatus={loaderStatus}
                        setloaderStatus={setloaderStatus}
                        setTableList={setTableList}
                        tableList={tableList}
                        showTableContent={showTableContent}
                        setShowTableContent={setShowTableContent}
                        popupStatus={popupStatus}
                    />
                </Layout>
            )}

            {popupStatus.addTable && (
                <AddTablePopup
                    control={control}
                    handleClickClose={resetPopupStatus}
                    sectionList={sectionList}
                    watch={watch}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    setloaderStatus={setloaderStatus}
                    loaderStatus={loaderStatus}
                    seterrorOfApi={seterrorOfApi}
                    errorOfApi={errorOfApi}
                    setTotalTable={setTotalTable}
                    setTableList={setTableList}
                    setValue={setValue}
                    getValues={getValues}
                />
            )}

            {popupStatus.editTable && (
                <EditTablePopup
                    control={control}
                    watch={watch}
                    handleClickClose={resetPopupStatus}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    isEditMode={isEditMode}
                    sectionList={sectionList}
                    setloaderStatus={setloaderStatus}
                    loaderStatus={loaderStatus}
                    setTotalTable={setTotalTable}
                    setTableList={setTableList}
                    errorOfApi={errorOfApi}
                    seterrorOfApi={seterrorOfApi}
                    isDirty={isDirty}
                    setValue={setValue}
                    getValues={getValues}
                />
            )}

            <div className='font-normal text-sm text-neutral-900 leading-5'></div>

            {(popupStatus.addSection || popupStatus.editSection) && (
                <SectionPopup
                    control={control}
                    handleClickClose={resetPopupStatus}
                    isEditSection={popupStatus.editSection}
                    sectionList={sectionList}
                    watch={watch}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    isEditMode={isEditMode}
                    register={register}
                    setloaderStatus={setloaderStatus}
                    loaderStatus={loaderStatus}
                    setSectionList={setSectionList}
                    errorOfApi={errorOfApi}
                    seterrorOfApi={seterrorOfApi}
                    setTableList={setTableList}
                    setTotalTable={setTotalTable}
                    isDirty={isDirty}
                    getValues={getValues}
                    popupStatus={popupStatus}
                    setPopupStatus={setPopupStatus}
                />
            )}
            {popupStatus.tableArrangeMent && (
                <LeftFlyOut
                    handleClickClose={resetPopupStatus}
                    sectionList={sectionList}
                    totalTable={totalTable}
                    setTotalTable={setTotalTable}
                    setloaderStatus={setloaderStatus}
                    loaderStatus={loaderStatus}
                    errorOfApi={errorOfApi}
                    seterrorOfApi={seterrorOfApi}
                    setTableLists={setTableList}
                />
            )}
        </>
    );
};

export default TableManagement;
