import React, { useEffect, useState } from 'react';
import { IsMobileScreen } from '../../../Constants/Constants';
import CashbackCards from './Components/CashbackCards';
import OrderDetailsPopup from '../../../Components/CustomerOrderDetails/OrderDetailsPopup';
import Layout from './Components/Layout';
import ListSection from './Components/ListSection';
import APIV2 from '../../../api/axios/APIV2';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import APIV5 from '../../../api/axios/APIV5';

export default function LoyaltyOrderedCashback() {
    const today = new Date();

    const [showOrderDetailPopup, setShowOrderDetailPopup] = useState(false);

    const [selectedDates, setSelectedDates] = useState([today, today]);

    const isMobileScreen = IsMobileScreen();

    const selectedLangaugeId = useSelector((state) => state.langauge.languageId);
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutlet);

    const [orderDetails, setOrderDetails] = useState([]);

    const [updatedpaginationStatus, setUpdatedpaginationStatus] = useState({});
    const [isPopupLoading, setIsPopupLoading] = useState(false);

    const [popupOrderId, setPopupOrderId] = useState(null);

    useEffect(() => {
        setShowOrderDetailPopup(true);
    }, [popupOrderId]);

    const showMainLayout = !isMobileScreen || !popupOrderId;

    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [isLoading, setIsLoading] = useState(false);

    const formattedDate = (dateString) => {
        return dateString ? format(new Date(dateString), 'dd MMM yyyy') : 'N/A';
    };

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const [cashbackTypePagination, setCashbackTypePagination] = useState(null);

    const [filters, setFilters] = useState({ selectedType: null });

    const [popupDetails, setPopupDetails] = useState(null);

    const [cashbacks, setCashbacks] = useState({});

    const defaultParams = (cashbackType) => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);

        return {
            cashback_type: cashbackType !== undefined ? cashbackType : null,
            filters: {
                from_date: formatDate(from_date),
                to_date: formatDate(to_date),
            },
        };
    };
    //Below function is used to fetch data to be displayed in cashback table
    const fetchOrderDetail = async (params) => {
        try {
            const { page, per_page, cashback_type, ...otherParams } = params;
            const defaultParamsData = defaultParams(cashback_type);
            const response = await APIV2.get(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/cashback-logs`,
                {
                    params: {
                        ...otherParams,
                        ...defaultParamsData,
                        per_page: per_page ?? updatedpaginationStatus.resultPerPage,
                        page: page ?? updatedpaginationStatus.selectedPage,
                    },
                }
            );

            // setOrderDetails(response.reward_point_logs.data);

            setOrderDetails(response.reward_point_logs.data);

            setPaginationState((prevState) => {
                return {
                    ...prevState,
                    lastPage: response.reward_point_logs.last_page,
                    selectedPage: response.reward_point_logs.current_page,
                };
            });
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    //This function updates the pagination number only when internet is available and fetchOrderDetail is executed and data is received
    const updatePaginationState = (updates) => {
        setUpdatedpaginationStatus(updates);
        const newSelectedPage =
            updates.selectedPage !== undefined ? updates.selectedPage : paginationState.selectedPage;

        const params = {
            page: newSelectedPage,
            per_page: updates.resultPerPage ?? updatedpaginationStatus.resultPerPage,
            cashback_type: cashbackTypePagination?.cashback_type,
        };

        fetchOrderDetail(params, () => {
            if (newSelectedPage === paginationState.selectedPage) {
                setPaginationState((prevState) => ({
                    ...prevState,
                    selectedPage: 1,
                    per_page: updates.resultPerPage ?? paginationState.resultPerPage,
                    ...updates,
                }));
            }
        });
    };

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            updatePaginationState(data);
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }

        updatePaginationState({ ...data, selectedPage: 1 });
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    const fetchOrderDetailPopup = async () => {
        setIsPopupLoading(true);
        try {
            setIsPopupLoading(true);
            const response = await APIV5.get(`orders/${popupOrderId}`);

            setPopupDetails(response.order_details);
            const matchedOrder = orderDetails.find((order) => order.order_id === popupOrderId);
            if (matchedOrder) {
                setCashbacks({
                    ...cashbacks,
                    cashback_earned: matchedOrder.cashback_earned,
                    cashback_redeemed: matchedOrder.cashback_redeemed,
                });
            }
            setIsPopupLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        if (popupOrderId) {
            fetchOrderDetailPopup(popupOrderId);
        }
    }, [popupOrderId]);

    const formatPlacedAt = (dateStr) => {
        // Create a Date object from the date string
        const date = new Date(dateStr);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }

        // Format the date
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });

        return formattedDate;
    };

    const extractTime = (placedAt) => {
        try {
            return format(parseISO(placedAt), 'hh:mm a');
        } catch (error) {
            return '';
        }
    };

    const calculateTotalQuantity = (cartItems) =>
        cartItems?.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0) || 0;

    const orderingMode = {
        'dine_in': 'Dine-In',
        'delivery': 'Delivery',
        'takeaway': 'Takeaway',
        'CASH_ON_DELIVERY': 'Cash',
        'CREDIT_CARD/DEBIT_CARD': 'Card',
        'CREDIT_CARD': 'Credit card',
        'DEBIT_CARD': 'Debit card',
        'UPI': 'UPI',
        'UPI_INTENT': 'UPI',
        'BALANCE': 'Balance',
    };

    function formatTime(time) {
        const [hours, minutes] = time.split(':');
        const hour = parseInt(hours, 10);
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 24 || 24;
        const formattedTime = `${formattedHour}:${minutes} ${period}`;

        return formattedTime;
    }

    const popupData = [
        {
            item: 'Order bill name',
            value: popupDetails && popupDetails?.order_bill_name ? popupDetails?.order_bill_name : 'NA',
        },
        {
            item: 'Order date',
            value: popupDetails && popupDetails?.order_date ? formatPlacedAt(popupDetails?.order_date) : 'NA',
        },
        {
            item: 'Order type',
            value: popupDetails && popupDetails?.order_type ? orderingMode[popupDetails?.order_type] : 'NA',
        },
        {
            item: 'Order taken by',
            value:
                popupDetails && popupDetails?.rider
                    ? `${popupDetails.rider.first_name} ${popupDetails.rider.last_name}`
                    : 'NA',
        },
        {
            item: "Customer's order",
            value: popupDetails && popupDetails?.customers_order ? popupDetails?.customers_order : 'NA',
        },
        {
            item: 'Order time',
            value: popupDetails && popupDetails?.order_time ? formatTime(popupDetails?.order_time) : 'NA',
        },
        {
            item: 'Payment method',
            value: popupDetails && popupDetails?.payment_method ? orderingMode[popupDetails?.payment_method] : 'NA',
        },
        {
            item: 'Dishes ordered',
            value:
                popupDetails && popupDetails?.cart?.cart_items
                    ? calculateTotalQuantity(popupDetails?.cart?.cart_items)
                    : 'NA',
        },
    ];

    const feedbacks = {
        orderFeedback: popupDetails?.order_feedback,
        riderFeedback: popupDetails?.rider_feedback,
        date: formattedDate(popupDetails?.order_date),
    };

    const otherAmount = [
        { type: 'Gross total', value: popupDetails?.gross_total?.toFixed(2) },
        {
            type: 'Other charges & taxes',
            value: (
                popupDetails?.cart?.gst_amount +
                popupDetails?.cart?.packaging_charge +
                popupDetails?.cart?.convenience_charge
            )?.toFixed(2),
        },
        {
            type: 'Delivery charge',
            value: popupDetails?.cart?.delivery_charge?.toFixed(2),
        },
    ];

    const extraCharges = [
        {
            gst_amount: popupDetails?.cart?.gst_amount,
            packaging_charge: popupDetails?.cart?.packaging_charge,
            convenience_charge: popupDetails?.cart?.convenience_charge,
        },
    ];

    useEffect(() => {
        setIsLoading(true);
        fetchOrderDetail({ cashback_type: cashbackTypePagination?.cashback_type });

        // eslint-disable-next-line
    }, [selectedDates, selectedOutlet]);

    return (
        <>
            <Layout
                showMainLayout={showMainLayout}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                fetchOrderDetail={fetchOrderDetail}
                setFilters={setFilters}
                setIsLoading={setIsLoading}
                setCashbackTypePagination={setCashbackTypePagination}
                orderDetails={orderDetails}>
                <CashbackCards selectedDates={selectedDates} />

                <ListSection
                    handleClickCustomerName={() => {
                        setShowOrderDetailPopup((prevState) => !prevState);
                    }}
                    selectedDates={selectedDates}
                    updatePaginationState={updatePaginationState}
                    paginationState={paginationState}
                    isLoading={isLoading}
                    orderDetails={orderDetails}
                    setPopupOrderId={setPopupOrderId}
                    handlePagination={handlePagination}
                    fetchOrderDetail={fetchOrderDetail}
                />
            </Layout>

            {showOrderDetailPopup && popupOrderId && (
                <OrderDetailsPopup
                    isDelivered={popupDetails?.delivered}
                    code={popupDetails?.last_four_character_order_label}
                    isPopupLoading={isPopupLoading}
                    orderedDishes={popupDetails?.cart?.cart_items}
                    isRiderFeedback={popupData[2]?.value}
                    orderDetails={popupData}
                    extraCharges={extraCharges}
                    otherAmount={otherAmount}
                    netTotal={popupDetails?.total_bill_amount}
                    feedbacks={feedbacks}
                    isPaid={popupDetails?.paid}
                    cashbacks={cashbacks}
                    // orderDetails={orderDetails}
                    popupOrderId={popupOrderId}
                    hasRefundOption={true}
                    page='loyaltyCashback'
                    handleClickClose={() => {
                        setShowOrderDetailPopup(false);
                        setPopupOrderId(null);
                    }}
                    selectedLangaugeId={selectedLangaugeId}
                    popupDetails={popupDetails}
                />
            )}
        </>
    );
}
