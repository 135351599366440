export const offerTypes = [
    { label: 'All', value: null },
    { label: 'Flat discount', value: 'flat_discount' },
    { label: 'Percentage discount', value: 'percentage_discount' },
    { label: 'Free delivery', value: 'free_delivery' },
    { label: 'Get an item', value: 'free_items' },
    { label: 'Buy X get Y free', value: 'buy_x_get_y' },
    { label: 'Menu discount', value: 'menu_discount' },
    { label: 'BOGO', value: 'buy_one_get_one_free' },
    { label: 'Coupled offer', value: 'coupled_offer' },
    { label: 'Value deal', value: 'combo_item_price_deal' },
];

export const setOtherStateDefault = (setState, newStateObject) => {
    setState((prevState) => ({
        ...Object?.fromEntries(Object?.keys(prevState)?.map((k) => [k, false])),
        ...newStateObject,
    }));
};
