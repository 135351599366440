import React from 'react';
import { Tab } from '../../../../../../../Components/Tabs/Tabs';

export default function CategoryDishTab(props) {
    const { activeTab, setActiveTab } = props;

    const tabs = [
        { item: 'Category', value: 'category' },
        { item: 'Dish', value: 'dish' },
    ];

    return (
        <>
            <div className='flex flex-row items-center'>
                {tabs.map((tab, index) => (
                    <div
                        className='mr-4 lg:mr-2 cursor-pointer'
                        onClick={() => setActiveTab(tab.value)}
                        key={index}>
                        <Tab
                            label={tab.item}
                            isActive={activeTab === tab.value}
                        />
                    </div>
                ))}
            </div>
        </>
    );
}
