import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Catalogue from './Components/Catalogue/Catalogue';
import CataloguePreset from './Components/CataloguePreset/CataloguePreset';
import OutletMenu from './Components/OutletMenu/OutletMenu';
import Layout from './Components/Layout';
import AddCategory from './AddCategory/AddCategory';
import MenuManagementOutletMenu from './OutletMenu/MenuManagementOutletMenu';
import MenuManagementDishDetails from './MenuManagementDishDetails';
import AddSubCategory from './AddSubCategory';
import MenuManagementDish from './AddDish/MenuManagementDish';
import AddonDetails from './AddonDetails/AddonDetails';
import CreateAddonGroup from './CreateAddonGroup/CreateAddonGroup';
import CreateAddonChoice from './CreateAddonChoice/CreateAddonChoice';

function MenuLayoutWrapper({ Component }) {
    return (
        <Layout>
            <Component />
        </Layout>
    );
}

export default function MenuManagement() {
    return (
        <Routes>
            <Route
                path='catalogue'
                element={<MenuLayoutWrapper Component={Catalogue} />}
            />

            <Route
                path='catalogue-presets'
                element={<MenuLayoutWrapper Component={CataloguePreset} />}
            />

            <Route
                path='outlet-menu'
                element={<MenuLayoutWrapper Component={OutletMenu} />}
            />

            <Route
                path='add-category/:categoryTab'
                element={<AddCategory />}
            />

            <Route
                path='edit-category/:editId/:categoryTab'
                element={<AddCategory />}
            />

            <Route
                path='add-sub-category'
                element={<AddSubCategory />}
            />

            <Route
                path='dish/:categoryId/:dishTab'
                element={<MenuManagementDish />}
            />

            <Route
                path='/:categoryId/edit-dish/:dishId/:dishTab'
                element={<MenuManagementDish />}
            />

            <Route
                path='outlet-menu-details'
                element={<MenuManagementOutletMenu />}
            />
            <Route
                path='dish-details'
                element={<MenuManagementDishDetails />}
            />

            <Route
                path='addon-details'
                element={<AddonDetails />}
            />

            <Route
                path='addon/choice/:choiceTab'
                element={<CreateAddonChoice />}
            />

            <Route
                path='addon/choice/:choiceId/:choiceTab'
                element={<CreateAddonChoice />}
            />

            <Route
                path='addon/:addonTab'
                element={<CreateAddonGroup />}
            />

            <Route
                path='*'
                element={<Navigate to='catalogue' />}
            />
        </Routes>
    );
}
