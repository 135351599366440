import React from 'react';
import { ReactComponent as CloseIcon } from '../../../../../Assets/close.svg';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage.js';
import DropdownSection from '../../../../../Components/DropDown/DropdownSection';
import DishSelectionDropdown from './DishSelectionDropdown';

export default function SingleItemSection(props) {
    const { fields, handleClickAppend, remove } = props;

    const preferenceMenuItem = [
        { label: 'And', value: 'and' },
        { label: 'Or', value: 'or' },
    ];

    const {
        formState: { errors },
    } = useFormContext();

    return (
        <>
            {fields.map((item, index) => {
                return (
                    <div
                        className='mb-4'
                        key={item.id}>
                        {handleClickAppend && (
                            <React.Fragment>
                                <div
                                    className={`flex flex-row w-full flex-end items-center max-w-[712px] mb-2 ${
                                        index === 0 ? 'justify-end' : 'justify-between'
                                    }`}>
                                    {index !== 0 && (
                                        <div className='max-w-[100px] w-full'>
                                            <DropdownSection
                                                name={`selected_item[${index}].option`}
                                                menuItems={preferenceMenuItem}
                                                placeholder='and/or'
                                                maxWidth='max-w-[100px]'
                                                rules={{ required: 'Please select preference' }}
                                            />
                                        </div>
                                    )}
                                    {index === fields?.length - 1 && (
                                        <span
                                            className='paragraph-small-medium text-primary-500 cursor-pointer'
                                            onClick={handleClickAppend}>
                                            + Add dish
                                        </span>
                                    )}
                                </div>
                                {errors?.selected_item?.[index]?.option && (
                                    <ErrorMessage
                                        className='mb-2'
                                        errors={errors.selected_item[index].option}
                                    />
                                )}
                            </React.Fragment>
                        )}

                        <div className='flex flex-row justify-between items-center relative max-w-[712px]'>
                            <div className='w-full mobile:max-w-[128px]'>
                                <DishSelectionDropdown filedName={`selected_item[${index}].item`} />

                                {errors?.selected_item?.[index]?.item && (
                                    <ErrorMessage errors={errors?.selected_item?.[index]?.item} />
                                )}
                            </div>

                            <div
                                className='min-w-[24px] ml-4 sm:ml-1.5'
                                onClick={() => fields.length !== 1 && remove(index)}>
                                <CloseIcon
                                    stroke={fields.length !== 1 ? '#EF4444' : '#D3D2D8'}
                                    className={fields.length !== 1 ? 'cursor-pointer' : null}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}
