import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PaginationWithNumber from '../../Components/Pagination/PaginationWithNumber';
import CreateGroupPopup from './Popups/CreateGroupPopup';
import { useWindowSize } from '@uidotdev/usehooks';
import Header from './Components/Header';
import Tabs from './Components/Tabs';
import { TableIndividual, TableGroup } from './Components/TableList';
import { IsMobileScreen } from '../../Constants/Constants';
// import LinkOutletPopup from '../Offers/Components/LinkOutletPopup';
import UserDetailsPopup from '../../Components/UserDetailsPopup/UserDetailsPopup';
import AddCutomerPopup from './Popups/AddCutomerPopup';
import { useSelector } from 'react-redux';
import ConfirmDeletePopup from '../../Components/ConfirmDeletePopup/ConfirmDeletePopup';
import APIV5 from '../../api/axios/APIV5';
import _ from 'lodash';

export default function CustomerInformation() {
    const navigate = useNavigate();

    const handleClickGroupName = () => {
        navigate('/customer-information/customer-group-details');
    };

    const [isShowBulkSelectButton, setShowBulkSelectButton] = useState(false);

    const [IsShowCreateGroupPopUp, SetIsShowCreateGroupPopUp] = useState(false);

    const [showLinkOutletPopup, setShowLinkOutletPopup] = useState(false);

    const [showUserDetailsPopup, setShowUserDetailsPopup] = useState(false);

    const [searchedCustomer, setSearchedCustomer] = useState(null);

    const [showAddCutomerPopup, setShowAddCutomerPopup] = useState(false);

    const [filters, setFilters] = useState({ primaryfilter: {}, secondaryFilter: {} });

    const [isListLoading, setIsListLoading] = useState(false);

    const [checkedItems, setCheckedItems] = useState([]);

    const [customerList, setCustomerList] = useState([]);

    const [deleted, setDeleted] = useState(false);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });
    const [isInputtingSearch, setIsInputtingSearch] = useState(false);
    const [isDisable, setIsDisable] = useState(checkedItems?.length > 0 ? false : true);
    const [activeTab, setActiveTab] = useState(0);
    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);

    const handleClickBulkButton = () => {
        setShowBulkSelectButton(!isShowBulkSelectButton);
    };

    const handleClickCreateGroup = () => {
        SetIsShowCreateGroupPopUp(!IsShowCreateGroupPopUp);
    };

    const handleClickCustomerName = () => {
        if (isShowBulkSelectButton) {
            return;
        }
        navigate('/customer-information/customer-order-details');
    };

    const screenHeight = useWindowSize().height;
    const isMobileScreen = IsMobileScreen();

    const toggleActiveTabs = (index) => {
        setActiveTab(index);
    };

    const fetchCustomersList = async (params) => {
        try {
            setIsListLoading(true);
            let params = {
                page: paginationState.selectedPage,
                per_page: paginationState.resultPerPage,
            };

            if (filters.primaryfilter?.value?.length && filters.secondaryFilter?.value?.length) {
                params.filter = { column: filters.primaryfilter?.value, direction: filters.secondaryFilter?.value };
            }

            if (searchedCustomer) {
                params.search = searchedCustomer;
            }

            const response = await APIV5.get(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/customers`,
                {
                    params: params,
                }
            );

            setCustomerList(response.customers.data);

            setPaginationState((prevState) => ({
                ...prevState,
                lastPage: response.customers.last_page,
                selectedPage: response.customers.current_page,
            }));
            setIsListLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setIsInputtingSearch(true);

        const timeout = setTimeout(() => {
            setIsInputtingSearch(false);
        }, 1000);

        return () => clearTimeout(timeout);
    }, [searchedCustomer]);

    useEffect(() => {
        setIsListLoading(isInputtingSearch);
    }, [isInputtingSearch]);

    useEffect(() => {
        let params = {
            page: paginationState.selectedPage,
            per_page: paginationState.resultPerPage,
        };

        if (filters.primaryfilter?.value?.length && filters.secondaryFilter?.value?.length) {
            params.filter = { column: filters.primaryfilter?.value, direction: filters.secondaryFilter?.value };
        }

        if (searchedCustomer) {
            params.search = searchedCustomer;
        }

        fetchCustomersList(params);
    }, [
        paginationState.resultPerPage,
        paginationState.selectedPage,
        searchedCustomer,
        filters.secondaryFilter?.value,
        deleted,
        selectedOutletId,
    ]);

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    const handleClickName = async (value) => {
        navigate(`/customer-information/customer-order-details/${value}`);
    };

    const handleCheckboxChange = (id, isChecked) => {
        setCheckedItems((prevCheckedItems) => {
            const isIdChecked = prevCheckedItems.includes(id);

            if (isChecked && !isIdChecked) {
                return [...prevCheckedItems, id];
            } else if (!isChecked && isIdChecked) {
                return prevCheckedItems.filter((item) => item !== id);
            }
            return prevCheckedItems;
        });
        if (checkedItems?.length >= 0) {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
    };

    const handleConfirmDeletePopup = () => {
        setShowConfirmDeletePopup(!showConfirmDeletePopup);
    };

    const handleDeteteReview = async () => {
        const response = await APIV5.delete(`restaurants/${selectedRestaurantId}/customers`, {
            params: { customer_ids: checkedItems },
        });
        if (response.success === true) {
            setShowConfirmDeletePopup(false);
            setDeleted(!deleted);
            setCheckedItems([]);
            setShowBulkSelectButton(false);
        }
    };
    const handleCloseDeletePopup = () => {
        setShowConfirmDeletePopup(false);
    };

    return (
        <>
            <div className='bg-[#fafafa]'>
                {((!showLinkOutletPopup && !showUserDetailsPopup) || !isMobileScreen) && (
                    <div
                        className='px-8 pb-[70px] lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'
                        style={{ minHeight: screenHeight - 50 }}>
                        <Header
                            isDisable={isDisable}
                            setCheckedItems={setCheckedItems}
                            setSearchedCustomer={setSearchedCustomer}
                            isShowBulkSelectButton={isShowBulkSelectButton}
                            handleClickBulkButton={handleClickBulkButton}
                            activeTab={activeTab}
                            handleClickAddCustomer={() => setShowAddCutomerPopup((prevState) => !prevState)}
                            handleConfirmDeletePopup={handleConfirmDeletePopup}
                            setFilters={setFilters}
                            filters={filters}
                            searchedCustomer={searchedCustomer}
                            setPaginationState={setPaginationState}
                        />

                        {/* <Tabs
                            handleClickCreateGroup={handleClickCreateGroup}
                            toggleActiveTabs={toggleActiveTabs}
                            activeTab={activeTab}
                        /> */}

                        {/* {activeTab === 0 ? ( */}
                        <TableIndividual
                            searchedCustomer={searchedCustomer}
                            customerList={customerList}
                            isShowBulkSelectButton={isShowBulkSelectButton}
                            handleClickCustomerName={handleClickCustomerName}
                            checkedItems={checkedItems}
                            handleCheckboxChange={handleCheckboxChange}
                            handleClickName={handleClickName}
                            setCheckedItems={setCheckedItems}
                            fetchCustomersList={fetchCustomersList}
                            paginationState={paginationState}
                            isListLoading={isListLoading}
                        />
                        {/* ) : (
                            <TableGroup
                                handleClickGroupName={handleClickGroupName}
                                handleClickLinkOutlet={() => setShowLinkOutletPopup((prevState) => !prevState)}
                                handleClickCreatedby={() => setShowUserDetailsPopup((prevState) => !prevState)}
                            />
                        )} */}

                        {!_.isEmpty(customerList) && (
                            <div className='pt-4'>
                                <PaginationWithNumber
                                    lastPage={paginationState.lastPage}
                                    selectedPage={paginationState.selectedPage}
                                    setSelectedPage={(value) => {
                                        handlePagination({ selectedPage: value }, 'selectedPage');
                                    }}
                                    setResultPerPage={(value) => {
                                        handlePagination({ resultPerPage: value }, 'resultPerPage');
                                    }}
                                    selectedResultPerpage={paginationState.resultPerPage}
                                    isLoading={isListLoading}
                                />
                            </div>
                        )}

                        {/* {IsShowCreateGroupPopUp && <CreateGroupPopup handleClickCreateGroup={handleClickCreateGroup} />} */}
                    </div>
                )}
            </div>
            {/* {showAddCutomerPopup && (
                <AddCutomerPopup handleClickClose={() => setShowAddCutomerPopup((prevState) => !prevState)} />
            )} */}
            {/* {showLinkOutletPopup && (
                <LinkOutletPopup
                    page='customerInformation'
                    handleClickClose={() => setShowLinkOutletPopup((prevState) => !prevState)}
                />
            )} */}
            {/* {showUserDetailsPopup && (
                <UserDetailsPopup handleClickClose={() => setShowUserDetailsPopup((prevState) => !prevState)} />
            )} */}
            {showConfirmDeletePopup && (
                <ConfirmDeletePopup
                    header={<h2 className='paragraph-large-medium ml-2'>Delete the review?</h2>}
                    description={
                        <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3'>
                            Are you sure you want to delete this review?
                        </p>
                    }
                    handleDelete={handleDeteteReview}
                    handleClickClose={handleCloseDeletePopup}
                />
            )}
        </>
    );
}
