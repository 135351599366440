import React, { useEffect, useState } from 'react';
import SubCategoryDropDown from './SubCategoryDropDown';
import { DefaultInputField } from '../InputField/InputField';
import InputSection from '../InputField/InputSection';
import DropdownSection from '../DropDown/DropdownSection';
import Tag from '../Tag/Tag';
import { useFormContext } from 'react-hook-form';

export default function AddDetails(props) {
    const {
        typeSubCategory,
        fetchImageSubCategory,
        subCategoryMenuItem,
        setSubCategoryMenuItem,
        isRiderPage,
        menuTypes,
        setMenuTypes,
    } = props;

    const { setValue, watch } = useFormContext();

    useEffect(() => {
        setValue('selectedCategory', subCategoryMenuItem);
    }, [subCategoryMenuItem]);

    const handleRemoveSubCategory = (label) => {
        setSubCategoryMenuItem((prevState) => prevState.filter((el) => el.label !== label));
    };

    const [isCopied, setIsCopied] = useState(false);

    const handleCopyUrl = () => {
        navigator.clipboard.writeText(watch('imageUrlToDisplay'));
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    };

    return (
        <>
            <div className='mb-6 lg:w-full lg:border-r lg:border-neutral-300 lg:pr-5 lg:mr-5 md:mb-0 md:pr-0 md:border-0 lg:mb-0'>
                <div className='pb-6 border-b border-neutral-300 lg:border-0 md:border-b'>
                    <InputSection
                        label='Image title'
                        shadow='shadow-Xsmall'
                        boxHeight='[44px]'
                        placeholder='Enter image name'
                        name='image_title'
                        maxWidth='max-w-[375px]'
                        className='mb-4'
                        rules={{ required: 'Please enter image title' }}
                    />

                    <InputSection
                        label='Alt title'
                        shadow='shadow-Xsmall'
                        boxHeight='[44px]'
                        placeholder="Enter image's alt name"
                        name='alternative_title'
                        maxWidth='max-w-[375px]'
                        className='mb-4'
                        rules={{ required: 'Please enter image alt title' }}
                    />
                    {!isRiderPage && (
                        <DefaultInputField
                            value={watch('image_category')?.label}
                            disabled
                            label='Image type'
                            shadow='shadow-Xsmall'
                            boxHeight='[44px]'
                            placeholder='Image type'
                        />
                    )}
                    {!isRiderPage && (
                        <div className='w-full my-4'>
                            <SubCategoryDropDown
                                fetchImageSubCategory={fetchImageSubCategory}
                                menuItems={typeSubCategory}
                                setSubCategoryMenuItem={setSubCategoryMenuItem}
                                subCategoryMenuItem={subCategoryMenuItem}
                                fixedHeight='h-[200px]'
                                // disabled={watch('isDisableCategory')}
                            />

                            <div className='align-top'>
                                {subCategoryMenuItem?.map((el, index) => (
                                    <div
                                        className='mr-2 inline-block'
                                        key={index}>
                                        <Tag
                                            tag={el.label}
                                            onClose={() => handleRemoveSubCategory(el.label)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className=''>
                        <DefaultInputField
                            placeholderTextColor='text-neutral-700'
                            label='Image URL'
                            action={isCopied ? 'URL Copied' : 'Copy URL'}
                            boxHeight='[44px]'
                            value={watch('imageUrlToDisplay')}
                            disabled
                            handleClickAction={handleCopyUrl}
                            removeSectionStyle={!watch('imageUrlToDisplay') && 'hidden'}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
