import React, { useState } from 'react';
import CalenderField from '../../../Components/Calender/CalenderField';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as FilterIcon } from '../../../Assets/filter.svg';
import { ReactComponent as ExportIcon } from '../../../Assets/export.svg';
import Filters from './Filters';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import APIV3 from '../../../api/axios/APIV3';

export default function Header(props) {
    const {
        setAppliedOrderFilter,
        setAppliedRiderFilter,
        setSelectedDates,
        selectedDates,
        appliedOrderFilter,
        appliedRiderFilter,
    } = props;
    const [isFilterActive, setIsFilterActive] = useState(false);

    const handleFilterEnablement = () => {
        setIsFilterActive((prevState) => !prevState);
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [isDisable, setIsDisable] = useState(false);

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    let data;

    const handleClickExportData = async () => {
        setIsDisable(true);
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        data = {
            type: 'order_rating_list_report',
            start_date: formatDate(from_date),
            end_date: formatDate(to_date),
            outlet_id: selectedOutletId,
        };
        try {
            const response = await APIV3.post(`export-report`, data);
            if (response.success === true) {
                setIsDisable(false);
            } else {
                setIsDisable(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='flex flex-col'>
            <div className='flex flex-row justify-between md:block lg:mb-2 md:mb-0'>
                <div className='flex flex-row md:justify-between'>
                    <CalenderField
                        setSelectedDates={setSelectedDates}
                        selectedDates={selectedDates}
                    />
                </div>
                <div className='flex flex-row md:justify-between md:mt-4'>
                    <div
                        className='mx-4 md:w-1/2 md:ml-0 md:mr-2 mobile:mr-1 lg:mx-2'
                        onClick={handleFilterEnablement}>
                        <LargePrimaryButton
                            label='Filters'
                            leftIconDefault={<FilterIcon fill='#FFFFFF' />}
                            leftIconClick={<FilterIcon fill='#C4BEED' />}
                        />
                    </div>
                    {/* <div
                        className='md:w-1/2 md:ml-2 mobile:ml-1'
                        onClick={handleClickExportData}>
                        <LargePrimaryButton
                            isDefault={false}
                            label='Export data'
                            leftIconDefault={<ExportIcon stroke='#FFFFFF' />}
                            leftIconClick={<ExportIcon stroke='#C4BEED' />}
                            hideLabel='lg:hidden md:block'
                            disabled={isDisable}
                        />
                    </div> */}
                </div>
            </div>
            {isFilterActive && (
                <div className='flex flex-row justify-between mt-4'>
                    <div className='md:w-1/2'></div>
                    <div className='md:w-1/2'>
                        <Filters
                            setAppliedOrderFilter={setAppliedOrderFilter}
                            setAppliedRiderFilter={setAppliedRiderFilter}
                            appliedOrderFilter={appliedOrderFilter}
                            appliedRiderFilter={appliedRiderFilter}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
