import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import PlatformSalesDetails from './PlatformSalesDetails';
import APIV5 from '../../../api/axios/APIV5';
import { useSelector } from 'react-redux';

export default function PlatformSales({ selectedDates, outletId, defaultParams }) {
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const [platformSalesData, setPlatformSalesData] = useState([]);

    useEffect(() => {
        const fetchPlatformSalesData = async () => {
            const defaultParamsData = defaultParams();
            try {
                const response = await APIV5.get(`restaurants/${selectedRestaurantId}/platform-sales`, {
                    params: {
                        ...defaultParamsData,
                        outlet_ids: [outletId],
                    },
                });
                setPlatformSalesData(response?.platform_sales);
            } catch (error) {
                console.error('Error fetching platform sales data:', error);
            }
        };
        fetchPlatformSalesData();
    }, [selectedDates]);

    const platformNames = platformSalesData?.platform_stats?.map((item) => item.platform_name) || [];
    const salesPercentages = platformSalesData?.platform_stats?.map((item) => item.percentage) || [];

    const chartOptions = {
        plotOptions: {
            pie: {
                donut: {
                    size: '62%',
                },
            },
        },
        labels: platformNames,
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
                fontSize: 16,
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
            },
            formatter: (val) => `${parseInt(val)}%`,
        },
        colors: ['#FFBB3C', '#4B39C9', '#DC2626', '#74C2B9', '#504F5A'],
        stroke: {
            colors: 'transparent',
        },
    };

    return (
        <div>
            <span className='paragraph-large-medium text-black'>Platform Sales</span>
            <div className='rounded-md border border-neutral-300 mt-4 py-2 min-h-[321px] xl:px-0.5'>
                <div className='xl:overflow-auto scrollbar-style py-[19px]'>
                    {platformSalesData?.platform_stats?.length > 0 ? (
                        <div className='flex justify-between items-center w-full xl:w-[550px] lg:w-full lg:block'>
                            <div className='w-[280px] lg:w-full lg:flex lg:mb-5'>
                                <Chart
                                    className='m-auto'
                                    type='donut'
                                    width={280}
                                    height={280}
                                    series={salesPercentages}
                                    options={chartOptions}
                                />
                            </div>
                            <div className='w-full pl-[22px] pr-6 pageContentSmall:pl-0 pageContentSmall:pr-3 lg:pl-5 lg:pr-6 mobile:px-3'>
                                <PlatformSalesDetails
                                    platformSalesData={platformSalesData}
                                    selectedDates={selectedDates}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='w-full h-64 border-neutral-300 rounded-lg overflow-auto [&::-webkit-scrollbar]:hidden'>
                            <table className='w-full break-words'>
                                <div className='w-full pl-6 flex flex-row items-center'>
                                    <span className='text-lg font-bold'>No Content Available</span>
                                </div>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
