import { useEffect, useState } from 'react';
import IncomingOrderSound from '../../Assets/sound/incoming-order-sound.wav';

const SoundComponent = () => {
    const count = 0;

    // const notificationSoundLoop = false;
    // const notificationSoundLoop = useSelector((state) => state.device.notificationSoundLoop);
    const notificationSoundLoop = false;
    const [userInteracted, setUserInteracted] = useState(false);

    useEffect(() => {
        const playSound = () => {
            if (notificationSoundLoop) {
                const audio = new Audio(IncomingOrderSound);
                audio.loop = true;
                audio.play();
            } else {
                for (let i = 0; i < count; i++) {
                    setTimeout(() => {
                        const audio = new Audio(IncomingOrderSound);
                        audio.play();
                    }, i * 1000); // Delay each sound by 1 second
                }
            }
        };

        if (userInteracted) {
            playSound();
        }

        const handleUserInteraction = () => {
            if (!userInteracted) {
                setUserInteracted(true);
            }
        };

        window.addEventListener('click', handleUserInteraction);

        return () => {
            window.removeEventListener('click', handleUserInteraction);
        };
    }, [notificationSoundLoop, count, userInteracted]);

    return null; // or render something if needed
};

export default SoundComponent;
