import React, { useEffect } from 'react';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import InputSection from '../../../../../../Components/InputField/InputSection';
import DropdownSection from '../../../../../../Components/DropDown/DropdownSection';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';
import _ from 'lodash';
import DropdownWithCheckbox from '../../../../../../Components/DropDown/DropdownWithCheckbox';
import { vegNonVegMenuItem } from '../../../../../../Constants/Constants';

export const getNestedProperty = (obj, path) => {
    return path
        .split(/[\.\[\]]+/)
        .filter(Boolean)
        .reduce((acc, part) => {
            return acc && acc[part];
        }, obj);
};

export default function PriceInputForm(props) {
    const { variantNamePrefix, id, isEditing } = props;

    const {
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    const orderingModes = watch('exposeDish');

    const handleChangeInput = (value, type) => {
        !isEditing && orderingModes?.map((el) => setValue(`${id}.prices[${el.id}].${type}`, value));
    };

    const handleSelectAllergens = (isSelected, selection) => {
        if (isSelected) {
            !isEditing
                ? orderingModes?.map((el) => {
                      setValue(`${id}.prices[${el.id}].allergens`, [
                          ...(watch(`${id}.prices[${el.id}].allergens`)?.length
                              ? watch(`${id}.prices[${el.id}].allergens`)
                              : []),
                          selection?.value?.id,
                      ]);
                  })
                : setValue(`${variantNamePrefix}.allergens`, [
                      ...(watch(`${variantNamePrefix}.allergens`)?.length
                          ? watch(`${variantNamePrefix}.allergens`)
                          : []),
                      selection?.value?.id,
                  ]);
        } else {
            !isEditing
                ? orderingModes?.map((el) =>
                      setValue(`${id}.prices[${el.id}].allergens`, [
                          ...watch(`${id}.prices[${el.id}].allergens`)?.filter((el) => el !== selection?.value?.id),
                      ])
                  )
                : setValue(
                      `${variantNamePrefix}.allergens`,
                      watch(`${variantNamePrefix}.allergens`).filter((el) => el !== selection?.value?.id)
                  );
        }
    };

    const allergensMenuItems = watch('availableAllergens')?.map((item) => {
        let isSelected = false;

        if (!isEditing) {
            for (const key in watch(`${id}.prices`)) {
                if (key !== 'pricing' && watch(`${id}.prices`)?.[key]?.allergens?.includes(item?.value?.id)) {
                    isSelected = true;
                    break;
                }
            }
        } else {
            if (watch(`${variantNamePrefix}.allergens`)?.includes(item.value.id)) {
                isSelected = true;
            }
        }

        return { ...item, isSelected };
    });

    const getError = (name) => !_.isEmpty(errors) && getNestedProperty(errors, name);

    useEffect(() => {
        watch('do_add_variant') && !watch(`${id}.food_type`) && setValue(`${id}.food_type`, vegNonVegMenuItem[1]);
    }, []);

    return (
        <>
            {watch('do_add_variant') && (
                <div className='mb-4'>
                    <DropdownSection
                        menuItems={vegNonVegMenuItem}
                        name={`${id}.food_type`}
                        title='Food type'
                        marginBetween='mb-2'
                        placeholder='Please select a food type'
                        rules={{ required: 'Please select a food type' }}
                    />

                    {!_.isEmpty(getError(`${id}.food_type`)) && <ErrorMessage errors={getError(`${id}.food_type`)} />}
                </div>
            )}

            <TitleDescription
                className='mb-4'
                title='Dish price'
                description='Set the dish price. Current price defines the amount that customer needs to pay whereas compare price defines the price that was earlier.'
            />

            <div className='flex flex-row items-start mb-6 lg:block'>
                <div className='mr-1.5 w-1/2 lg:w-full lg:mb-2 lg:max-w-[312px] md:max-w-full lg:mr-0'>
                    <InputSection
                        inputType='number'
                        label='(Current price)'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        placeholder='Enter current price in rupees'
                        shadow='shadow-small-drop-down-shadow'
                        name={`${variantNamePrefix}.price`}
                        handleInputChange={(value) => handleChangeInput(value, 'price')}
                        rules={{ required: 'Please enter price' }}
                    />

                    {!_.isEmpty(getError(`${variantNamePrefix}.price`)) && (
                        <ErrorMessage errors={getError(`${variantNamePrefix}.price`)} />
                    )}
                </div>

                <div className='ml-1.5 w-1/2 lg:w-full lg:max-w-[312px] md:max-w-full lg:ml-0'>
                    <InputSection
                        label='(Compare/strike-through price)'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        placeholder='Enter compare price in rupees'
                        shadow='shadow-small-drop-down-shadow'
                        name={`${variantNamePrefix}.compare_price`}
                        inputType='number'
                        handleInputChange={(value) => handleChangeInput(value, 'compare_price')}
                        rules={{ required: 'Please enter compare price' }}
                    />
                    {!_.isEmpty(getError(`${variantNamePrefix}.compare_price`)) && (
                        <ErrorMessage errors={getError(`${variantNamePrefix}.compare_price`)} />
                    )}
                </div>
            </div>
            <TitleDescription
                className='mb-4'
                title='Quantity'
                description='Set the quantity and select the unit to define the overall dish size.'
            />
            <div className='flex flex-row items-center mb-6 lg:block'>
                <div className='mr-1.5 w-1/2 lg:w-full lg:mb-2 lg:max-w-[312px] md:max-w-full lg:mr-0'>
                    <InputSection
                        label='(Quantity)'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        placeholder='Enter quantity'
                        shadow='shadow-small-drop-down-shadow'
                        name={`${variantNamePrefix}.quantity`}
                        inputType='number'
                        handleInputChange={(value) => handleChangeInput(value, 'quantity')}
                    />
                </div>
                <div className='ml-1.5 w-1/2 lg:w-full lg:max-w-[312px] md:max-w-full lg:ml-0'>
                    <DropdownSection
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        boxHeight='h-[52px]'
                        labelPaddingB='pb-2'
                        label='(Quantity unit)'
                        placeholder='Select a quantity unit'
                        shadow='shadow-small-drop-down-shadow'
                        menuItems={watch('availableQuantityUnits')}
                        name={`${variantNamePrefix}.quantityUnit`}
                        handleSelection={(value) => handleChangeInput(value, 'quantityUnit')}
                    />
                </div>
            </div>
            <TitleDescription
                className='mb-4'
                title='Servings'
                description="Set the number of people that the dish serves along with it's unit."
            />
            <div className='flex flex-row items-center mb-6 lg:block'>
                <div className='mr-1.5 w-1/2 lg:w-full lg:mb-2 lg:max-w-[312px] md:max-w-full lg:mr-0'>
                    <InputSection
                        label='(Serves)'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        placeholder='Enter dish servings number'
                        shadow='shadow-small-drop-down-shadow'
                        name={`${variantNamePrefix}.serves`}
                        inputType='number'
                        handleInputChange={(value) => handleChangeInput(value, 'serves')}
                    />
                </div>
                <div className='ml-1.5 w-1/2 lg:w-full lg:max-w-[312px] md:max-w-full lg:ml-0'>
                    <InputSection
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        label='(Serving unit)'
                        placeholder='Select the desired unit'
                        shadow='shadow-small-drop-down-shadow'
                        name={`${variantNamePrefix}.servesUnit`}
                        handleInputChange={(value) => handleChangeInput(value, 'servesUnit')}
                    />
                </div>
            </div>
            <TitleDescription
                className='mb-4'
                title='Calories and allergens'
                description='Set the amount of calories that the dish contains along with the allergens.'
            />
            <div className='flex flex-row items-center mb-6 lg:block'>
                <div className='mr-1.5 w-1/2 lg:w-full lg:mb-2 lg:max-w-[312px] md:max-w-full lg:mr-0'>
                    <InputSection
                        label='(Calories)'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        placeholder='Enter calories in Kcal'
                        shadow='shadow-small-drop-down-shadow'
                        name={`${variantNamePrefix}.calories`}
                        inputType='number'
                        handleInputChange={(value) => handleChangeInput(value, 'calories')}
                    />
                </div>
                <div className='ml-1.5 w-1/2 lg:w-full lg:max-w-[312px] md:max-w-full lg:ml-0'>
                    <DropdownWithCheckbox
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        boxHeight='h-[52px]'
                        labelPaddingB='pb-2'
                        label='(Allergens)'
                        placeholder='Select the allergens'
                        shadow='shadow-small-drop-down-shadow'
                        menuItems={allergensMenuItems}
                        handleChangeCheck={(isSelected, menuItem) => handleSelectAllergens(isSelected, menuItem)}
                        openFromBottom
                    />
                </div>
            </div>
        </>
    );
}
