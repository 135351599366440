import React, { useEffect, useState } from 'react';
import { Route, createRoutesFromElements, RouterProvider, createBrowserRouter } from 'react-router-dom';
import isElectron from 'is-electron';
import {
    SidebarMenu,
    DashBoard,
    MediaLibrary,
    NoPage,
    Rider,
    RiderDetails,
    AbandonedCarts,
    OrderRating,
    CustomerInformation,
    CustomerGroupDetails,
    SelectRestaurant,
    CustomerOrderDetails,
    Notifications,
    ItemAvailability,
    Login,
    Banners,
    Offers,
    BillPayment,
    KitchenDisplaySystem,
    Setting,
    TableManagement,
    MenuManagement,
    POS,
    Orders,
    Credits,
    DeviceSetting,
    LoyaltyCashback,
    POSSetting,
    PosTableView,
    CreateOffer,
    EditOffer,
    Reports,
} from './Screen/index.js';
import PrivateRoute from './Components/PrivateRoute/PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import { requestNotificationPermission } from './Helper/getDeviceToken';
import { initializeRestaurant } from './reduxStore/actions/initilizationActionCreator.js';
import HeaderProgressLoader from './Components/Loaders/HeaderProgressLoader.js';
import { getPresetList, getUsers } from './Screen/Setting/UserSetting/Components/ApiFunctions.js';
import AppLoader from './Components/Loaders/AppLoader.js';
import SoundComponent from './Components/SoundComponent/SoundComponent.js';
import { SET_OUTLET_MENU } from './reduxStore/types/outlet.js';
import Waiter from './Screen/Waiter/Waiter.js';
import CreateWaiter from './Screen/AddWaiter/CreateWaiter.js';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<SidebarMenu />}>
            <Route
                path='/login'
                element={<Login />}
            />

            <Route
                path='/select-restaurant'
                element={<SelectRestaurant />}
            />

            <Route element={<PrivateRoute />}>
                <Route
                    path='/'
                    element={<DashBoard />}
                />
                <Route
                    path='/orders/:orderType'
                    element={<Orders />}
                />
                <Route
                    path='/table-view'
                    element={<PosTableView />}
                />
                <Route
                    path='/pos'
                    element={<POS />}
                />
                <Route
                    path='/POSSettings'
                    element={<POSSetting />}
                />
                <Route
                    path='/Reports'
                    element={<Reports />}
                />
                <Route
                    path='/kitchen-display-system'
                    element={<KitchenDisplaySystem />}
                />

                <Route
                    path='/menu/*'
                    element={<MenuManagement />}
                />

                <Route
                    path='/media-library/:view'
                    element={<MediaLibrary />}
                />

                <Route
                    path='/availability/:tab'
                    element={<ItemAvailability />}
                />

                <Route
                    path='/order-rating/:tab'
                    element={<OrderRating />}
                />

                <Route
                    path='/order-rating/:tab/:complaint'
                    element={<OrderRating />}
                />

                <Route
                    path='/customer-information'
                    element={<CustomerInformation />}
                />

                <Route
                    path='/customer-information/customer-group-details'
                    element={<CustomerGroupDetails />}
                />
                <Route
                    path='/customer-information/customer-order-details'
                    element={<CustomerOrderDetails />}
                />
                <Route
                    path='/customer-information/:section/:id'
                    element={<CustomerOrderDetails />}
                />
                <Route
                    path='/notifications/:tab'
                    element={<Notifications />}
                />
                <Route
                    path='/banners/:tab/:bannerType'
                    element={<Banners />}
                />
                <Route
                    path='/credits/:creditType'
                    element={<Credits />}
                />

                <Route
                    path='/offers/:tab/:section'
                    element={<Offers />}
                />

                <Route
                    path='/offers/:tab/:id/:section'
                    element={<Offers />}
                />

                <Route
                    path='/offers/edit/:id/:section'
                    element={<EditOffer />}
                />

                <Route
                    path='/offers/create-offer/:section'
                    element={<CreateOffer />}
                />

                <Route
                    path='/offers/:tab'
                    element={<Offers />}
                />

                <Route
                    path='/table-management/:section'
                    element={<TableManagement />}
                />
                <Route
                    path='/abandoned-carts'
                    element={<AbandonedCarts />}
                />
                <Route
                    path='/waiter'
                    element={<Waiter />}
                />

                <Route
                    path='/add-waiter'
                    element={<CreateWaiter />}
                />

                <Route
                    path='/settings/:settingType/:tab'
                    element={<Setting />}
                    loader={({ params }) => {
                        if (params.tab === 'user-list') {
                            return getUsers({
                                handleArrayState: () => {},
                                selectedRestaurantId: localStorage.getItem('selectedRestaurantID'),
                            });
                        }
                        if (params.tab === 'preset-list') {
                            return getPresetList({
                                handleArrayState: () => {},
                                selectedRestaurantId: localStorage.getItem('selectedRestaurantID'),
                            });
                        }
                        return [];
                    }}
                />

                <Route
                    path='/settings/:settingType/:tab/:subTab'
                    element={<Setting />}
                />

                <Route
                    path='/rider'
                    element={<Rider />}
                />
                <Route
                    path='/rider/:section/:id'
                    element={<RiderDetails />}
                />
                <Route
                    path='/bill-payment'
                    element={<BillPayment />}
                />

                <Route
                    path='/loyalty-cashback/:section/*'
                    element={<LoyaltyCashback />}
                />
                <Route
                    path='/device-setting'
                    element={<DeviceSetting />}
                />

                <Route
                    path='/*'
                    element={<NoPage />}
                />
            </Route>
        </Route>
    )
);
function App() {
    const dispatch = useDispatch();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const firebaseToken = useSelector((state) => state.auth.firebaseToken);

    const [isApplicationLoading, setIsApplicationLoading] = useState(false);
    if (isElectron()) {
        window.electron.ipcRenderer.send('getmenu');
        // Listen for the ‘menu-data’ response from the main process
        window.electron.ipcRenderer.on('menu-data', (data) => {
            dispatch({
                type: SET_OUTLET_MENU,
                payload: {
                    categories: data.categories,
                    items: data.items,
                },
            });
        });
    }
    useEffect(() => {
        if (!firebaseToken) {
            navigator.serviceWorker?.register('/firebase-messaging-sw.js', { scope: '/' });
            getDeviceToken();
        }
        let isInitializeRestaurant =
            window.location.pathname !== '/login' &&
            window.location.pathname !== '/select-restaurant' &&
            selectedRestaurantId;

        if (isElectron()) {
            const authToken = localStorage.getItem('authToken');
            window.electron.ipcRenderer.send('startServer', {
                type: 'master',
                syncCode: '181185',
                authToken: authToken,
            });
            window.electron.ipcRenderer.on('isOnline', (data) => {});
        }

        setIsApplicationLoading(
            window.location.pathname !== '/login' && window.location.pathname !== '/select-restaurant'
        );

        isInitializeRestaurant &&
            dispatch(initializeRestaurant(selectedRestaurantId, null, null, null, setIsApplicationLoading));

        // eslint-disable-next-line
    }, []);

    const getDeviceToken = async () => {
        try {
            await requestNotificationPermission(dispatch);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {isApplicationLoading ? (
                <AppLoader />
            ) : (
                <>
                    <SoundComponent />
                    <HeaderProgressLoader />
                    <RouterProvider router={router} />
                </>
            )}
        </>
    );
}

export default App;
