import React, { useState } from 'react';
import { IsMobileScreen } from '../../../../Constants/Constants';
import TitleDescription from '../../../../Components/TitleDescription/TitleDescription';
import UploadImagePopup from '../../../../Components/UploadImagePopup/UploadImagePopup';
import { useFormContext } from 'react-hook-form';
import SelectImageFromLibraryPopup from '../../../Banners/Component/SelectImagePopupComponent/SelectImageFromLibraryPopup';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../../Components/Buttons/Button';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';
import InputSection from '../../../../Components/InputField/InputSection';
import { useSelector } from 'react-redux';

export default function BasicDetails() {
    const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);

    const [showSelectFromLibraryPopup, setShowSelectFromLibraryPopup] = useState(false);

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const {
        setValue,
        formState: { errors },
        watch,
    } = useFormContext();

    const isMobileScreen = IsMobileScreen();

    const toggleUploadImagePopup = () => {
        setShowUploadImagePopup((prevState) => !prevState);
    };

    const formateImageDetails = (imageDetails) => {
        const img = new Image();

        img.src = imageDetails.image_url;

        img.onload = () => {
            const aspectRatio = img.naturalWidth / img.naturalHeight;

            const calculatedWidth = 150 * aspectRatio;

            setValue('details.imageDetail', imageDetails);

            setValue('details.imageDetail.size', { width: calculatedWidth, height: 150 });
        };
    };

    return (
        <>
            {(!isMobileScreen || !showUploadImagePopup) && (
                <div className='max-w-[661px] pb-8 w-full pl-1 mt-4 -ml-1'>
                    <TitleDescription
                        className='mb-4'
                        title='Basic details'
                        description='Enter the basic details of the choice details that you would like to create.'
                    />

                    <TitleDescription
                        className='mb-4'
                        title='Choice image'
                        description='Select a choice image that will be displayed within the category in the mobile application and the POS.'
                    />

                    {watch('details.imageDetail')?.image_url ? (
                        <div className='flex mb-6 gap-4 items-center'>
                            <img
                                src={watch('details.imageDetail.image_url')}
                                alt=''
                                height={watch('details.imageDetail.size.height')}
                                width={watch('details.imageDetail.size.width')}
                            />
                            <div className='justify-center items-center'>
                                <div
                                    className='mb-2'
                                    onClick={() => setShowUploadImagePopup((prevState) => !prevState)}>
                                    <LargeTertiaryButton
                                        label='Replace image'
                                        isDefault={false}
                                    />
                                </div>

                                <div
                                    className='mb-2'
                                    onClick={() => setShowSelectFromLibraryPopup((prevState) => !prevState)}>
                                    <LargePrimaryButton
                                        label='Select from library'
                                        isDefault={false}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='flex flex-row paragraph-medium-regular mb-4 md:block'>
                            <button
                                className='justify-center h-12 border-neutral-300 rounded-md border max-w-[197px] w-full mr-2 md:mr-0 md:mb-2 md:block'
                                onClick={toggleUploadImagePopup}>
                                Upload an image
                            </button>
                            <button
                                className='justify-center h-12 border-neutral-300 rounded-md border max-w-[197px] w-full ml-2 md:ml-0 md:block'
                                onClick={() => setShowSelectFromLibraryPopup(true)}>
                                Select from library
                            </button>
                        </div>
                    )}

                    <TitleDescription
                        className='mb-4'
                        title='Choice title'
                        description='Select a choice title will be used to display information across the mobile application and POS.'
                    />

                    <div className='flex flex-row items-start mb-6 lg:block gap-3'>
                        {availableLanguages.map((el, index) => (
                            <div
                                className='w-1/2 lg:w-full lg:mb-2 lg:max-w-[312px] md:max-w-full md:mr-0'
                                key={index}>
                                <InputSection
                                    label={`(${el.language_label})`}
                                    labelStyle='paragraph-medium-italic text-neutral-500'
                                    placeholder={`Enter display name in ${el.language_label}`}
                                    shadow='shadow-smallDropDownShadow'
                                    name={`details.choiceTitle.${el.id}`}
                                    rules={{ required: `Please enter choice title in ${el.language_label}` }}
                                />
                                {errors?.details?.choiceTitle?.[el.id] && (
                                    <ErrorMessage errors={errors?.details?.choiceTitle?.[el.id]} />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {showUploadImagePopup && (
                <UploadImagePopup
                    handleClickClose={toggleUploadImagePopup}
                    backToPage='add dish'
                    aspectRatio={1}
                    setUploadedImageDetails={(imageDetails) => formateImageDetails(imageDetails)}
                    selectedType={{ label: 'Addon group', value: 'addon-group' }}
                />
            )}

            {showSelectFromLibraryPopup && (
                <SelectImageFromLibraryPopup
                    handleClickClose={() => setShowSelectFromLibraryPopup(false)}
                    handleSelectImage={(imageDetails) => formateImageDetails(imageDetails)}
                />
            )}
        </>
    );
}
