export const styles = () => {
    return `
                            
        .rs-picker-toggle .rs-stack-item svg {
              display: none;
        }
        
        .rs-picker-daterange-header {
              display: none;
        }
        
        .rs-picker-daterange-predefined .rs-stack-item .rs-btn-link {
              font-family: "Inter", sans-serif !important;
              color: #131126 !important;
              font-weight: 400;
        }
        .rs-picker-daterange-predefined {
              width: 149px !important;
              height: 424px !important;
              padding : 14px 0 14px 0 !important;
        }
        
        .rs-stack-item button {
              text-decoration: none !important;
        }
        
        .rs-stack-item button.removeFocusCancel:focus {
              background-color: white !important;
        }
        
        .rs-stack-item button:focus {
              background-color: #e2dff6;
        }
        button.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs:focus,
        button.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs:focus {
              background-color: transparent !important;
        }
        
        .rs-stack-item button.removeFocusToApplyChanges:focus {
              background-color: #6c5dd3 !important;
        }
        .rs-stack-item .rs-btn-link {
              width: 100%;
        }
        
        .rs-picker-daterange-predefined .rs-btn {
              padding-top: 12px !important;
              padding-bottom: 12px !important;
              padding-left: 24px !important;
              width: 100% !important;
              text-align: left !important;
        }
        
        .rs-btn-sm {
              padding: 0px !important;
        }
        
        .rs-picker-daterange-predefined .rs-stack-item {
              width: 100% !important;
        }
        
        .rs-stack-item .rs-btn {
              border-radius: 0px !important;
              padding-right: 6px !important;
        }
        
        .rs-picker-toggle.rs-btn.rs-btn-default {
              border: 1px solid #d3d2d8 !important;
              border-radius: 6px !important;
              height: 48px !important;
              display: flex !important;
              padding-left: 48px !important;
        }
        
        .rs-btn:focus-visible {
              outline: 0px !important;
        }
        
        .rs-calendar-header-title {
              font-family: "Inter", sans-serif !important;
              color: #131126 !important;
              font-weight: 600;
              font-size: 20px;
              line-height: 28px;
              top: 10px;
              position: absolute;
              left: 24px;
        }
        
        .rs-calendar-header-month-toolbar {
              position: relative;
              height: 55px;
              border-bottom: #e4e4e8 solid 1px;
        }
        
        .rs-calendar-header-month-toolbar .rs-calendar-header-backward {
              position: absolute;
              top: 10px;
              right: 43px;
        }
        
        .rs-calendar-header-month-toolbar .rs-calendar-header-forward {
              top: 10px;
              position: absolute;
              right: 12px;
        }
        
        .rs-calendar-header-backward svg {
              width: 20px !important ;
              height: 20px !important;
        }
        
        .rs-calendar-header-forward svg {
              width: 20px !important ;
              height: 20px !important;
        }
        
        .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
              padding-left: 0px !important;
              padding-right: 0px !important;
        }
        
        .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
              color: #fafafa !important;
              background-color: #6c5dd3 !important;
        }
        
        .rs-calendar-table-cell-in-range::before {
              background-color: #e2dff6 !important;
              height: 40px !important;
              top: -4px;
        }
        
        .rs-calendar-table-cell-content:hover {
              background-color: #e2dff6 !important;
        }
        
        .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
              background-color: #e2dff6 !important;
        }
        
        .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
              box-shadow: none !important;
        }
        
        .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
              height: 40px !important;
              width: 40px !important;
              display: flex !important;
        }
        .rs-calendar-table-cell-content {
              border-radius: 0px !important;
        }
        span.rs-calendar-table-cell-day {
              margin: auto !important;
        }
        
        .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
              color: #131126 !important;
        }
        
        .rs-calendar-table-cell,
        .rs-calendar-table-header-cell {
              padding: 0px !important;
        }
        
        .rs-calendar-table-cell {
              height: 40px !important;
              width: fit-content !important;
              min-width: 40px !important;
        }
        
        .rs-picker-daterange-menu .rs-calendar {
              height: 352px !important;
        }
        .rs-calendar {
              @apply pt-0;
        }
        
        .rs-picker-toolbar {
              border: 0px !important;
              padding: 0px !important;
              margin-top: 16px !important;
              margin-bottom: 16px !important;
              height: 40px !important;
              padding-left: 24px !important;
              padding-right: 24px !important;
        }
        
        .rs-btn-primary {
              background-color: #6c5dd3;
        }
        
        .rs-btn-primary:disabled,
        .rs-btn-primary.rs-btn-disabled {
              background-color: #6c5dd3;
        }
        
        .rs-btn-primary:disabled,
        .rs-btn-primary.rs-btn-disabled::before {
              content: "xxxxxx";
        }
        
        .rs-calendar-body {
              padding-top: 12px !important;
              padding-left: 24px !important;
              padding-right: 24px !important;
        }
        
        span.rs-calendar-table-header-cell-content {
              font-family: "Inter", sans-serif !important;
              color: #131126 !important;
              font-weight: 400;
              font-size: 14px !important;
              line-height: 20px !important;
              padding-bottom: 11px !important;
        }
        
        .rs-calendar {
              min-height: 317px !important;
        }
        
        .rs-calendar-header-title {
              left: 10px !important;
        }
        
        .rs-calendar-table-row.rs-calendar-table-header-row {
              min-height: 12px !important;
        }
        
        .rs-picker-daterange-calendar-group {
              border-bottom: 1px solid #e4e4e8;
              height: 352px !important;
        }
        
        .show-new-content {
              content: "";
        }
        
        .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
        .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
        .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
              border: 1px solid #d3d2d8 !important;
              border-radius: 6px !important;
              transition: none !important;
        }
        
        .rs-picker-toggle-active,
        .rs-picker.rs-picker-focused {
              box-shadow: none !important;
        }
        
        .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
        .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
              font-family: "Inter", sans-serif !important;
              font-size: 16px !important;
              font-weight: 400 !important;
              line-height: 24px !important;
              letter-spacing: 0em !important;
              text-align: left !important;
              color: #131126 !important;
        }
        
        .rs-picker-toggle-textbox {
              font-family: "Inter", sans-serif !important;
              font-size: 16px !important;
              font-weight: 400 !important;
              line-height: 24px !important;
              letter-spacing: 0em !important;
              text-align: left !important;
              color: #131126 !important;
              padding-right: 0px !important;
              padding-left: 47px !important;
              cursor: pointer !important;
        }
        
        .rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-has-value.rs-picker-cleanable {
              width: 280px !important;
              position: relative;
        }
          
        .rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-has-value.rs-picker-cleanable::after {
              background-image: url("../../Assets/calendar.svg");
              background-position: left center;
              background-repeat: no-repeat;
              content: "";
              height: 24px;
              width: 24px;
              position: absolute;
              top: 11px;
              left: 18px;
              z-index: 5;
        }
        
        .rs-picker-daterange-panel {
              border: 1px solid #e4e4e8;
              border-radius: 6px;
              box-shadow: "0px 10px 15px -3px rgba(16, 24, 40, 0.01), 0px 4px 6px -4px rgba(16, 24, 40, 0.01);";
        }
        
        .rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-daterange-menu.rs-picker-menu {
             margin-top : 8px !important
        }
        
        .rs-stack {
              margin: auto 0;
        }
        
        .calender-stack .rs-stack-item:first-child {
          width: 100%;
  
        }
    
        .rs-calendar-month-dropdown.show{
          top : 66px !important;
        }
    
        .rs-calendar-month-dropdown-row-wrapper{
          height: 285px !important
        }
    
        .rs-calendar-month-dropdown-scroll{
          height: 285px !important
        }
    
        .rs-calendar-header-title.rs-calendar-header-title-date.rs-btn.rs-btn-subtle.rs-btn-xs {
          pointer-events: none;
       }
    
        
      
       @media only screen and (max-width: 767px) {
            .rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-has-value.rs-picker-cleanable {
                width: 100% !important;
                } 
          }
    
      @media screen and (max-width: 895px) {
          .rs-picker-daterange-predefined {
              height: 790px !important;
                }
          
          .rs-picker-daterange-panel > .rs-stack > :nth-child(2) {
                      width: 328px;
             }
                   
          .rs-picker-toolbar {
                bottom: 16px !important;
                position: absolute !important;
                left : 0 !important;
                background : white !important;
                margin-top : 0 !important;
                margin-bottom : 0 !important;
          }
    }
    
          @media screen and (max-width:768px){
                .rs-picker-daterange-panel > .rs-stack {
                      display : block !important;
                }
    
                .rs-picker-daterange-predefined {
                      height: 120px !important;
                      display: grid;
                      grid-template-columns: repeat(2, 1fr);
                      gap: 10px;
                      border-right : 0px
    
                }
    
                .rs-picker-daterange-predefined .rs-stack-item {
                      width: 142px !important;
                }
               
                .rs-picker-daterange-calendar-group {
                height : 800px !important
                }
    
                .rs-picker-toolbar {
                      bottom: 55px !important;
           }
    
          }
          
          `;
};
