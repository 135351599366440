import React from 'react';
import { useFormContext } from 'react-hook-form';

export default function FileDetails({ setUserDetail, imageDetails }) {
    const { watch } = useFormContext();

    const fileInfo = [
        { label: 'Uploaded on:', value: watch('uploaded_on') ?? '-' },
        {
            label: 'Uploaded by:',
            value: watch('uploader') ?? '-',
            isLink: true,
            onClick: () =>
                setUserDetail &&
                setUserDetail({
                    name: imageDetails?.uploaded_by ?? '-',
                    role: imageDetails?.uploader_role ?? '--',
                    email: imageDetails?.email ?? '--',
                    mobile_number: imageDetails?.mobile_number ?? '--',
                }),
        },
        { label: 'File type:', value: watch('imageType') ?? '-' },
        { label: 'File size:', value: watch('imageSize') ? `${watch('imageSize')} MB` : '-' },
        {
            label: 'Original dimensions:',
            value: watch('originalDimensions')?.width
                ? `${watch('originalDimensions')?.width}px x ${watch('originalDimensions')?.height}px`
                : '-',
        },
        {
            label: 'Cropped dimensions:',
            value: watch('croppedDimensions')?.width
                ? `${watch('croppedDimensions')?.width}px x ${watch('croppedDimensions')?.height}px`
                : '-',
        },
    ];

    return (
        <>
            <div className='lg:w-fit mb-[22px] lg:pb-0 md:mb-0 md:pb-12 md:w-full lg:border-0'>
                {fileInfo.map(({ label, value, isLink, onClick }, index) => (
                    <div
                        className='mb-4 paragraph-medium-regular'
                        key={index}>
                        <span className='text-neutral-400'>{label}</span>
                        <span className='ml-2'>
                            {isLink ? (
                                <span
                                    onClick={() => (onClick ? onClick() : {})}
                                    className='text-primary-500 cursor-pointer'>
                                    {value}
                                </span>
                            ) : (
                                value
                            )}
                        </span>
                    </div>
                ))}
            </div>
        </>
    );
}
