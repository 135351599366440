import React, { useEffect, useState } from 'react';
import { DefaultInputField } from '../../../../Components/InputField/InputField';
import Layout from './Layout';
import DropdownWithValueLabel from '../../../../Components/DropDown/DropdownWithValueLabel';
import { Controller, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { createSection, deleteSection, editSection } from '../../HelperFunction/ApiFunctions';
import ConfirmDeletePopup from '../../../../Components/ConfirmDeletePopup/ConfirmDeletePopup';

export default function SectionPopup(props) {
    const {
        handleClickClose,
        control,
        sectionList,
        watch,
        handleSubmit,
        errors,
        isEditMode,
        loaderStatus,
        setloaderStatus,
        setSectionList,
        errorOfApi,
        seterrorOfApi,
        setTableList,
        setTotalTable,
        isDirty,
        getValues,
        popupStatus,
        setPopupStatus,
    } = props;
    const sections = sectionList.map((item) => ({ label: item.internal_name, value: item.internal_name }));
    const { section } = useParams();
    let selectedSection = sectionList.find((sections) => sections.internal_name == section);

    const outletOrderingModes = useSelector((state) => state.outlet.outletOrderingModes);
    const [isSubSectionAdd, setisSubSectionAdd] = useState(false);
    const [isDelete, setisDelete] = useState(false);
    const navigate = useNavigate();

    const orderingModesList = outletOrderingModes
        .filter((orderingMode) => orderingMode.restaurant_ordering_mode.tab === 'dine_in')
        .map((orderingMode) => ({
            label: orderingMode.restaurant_ordering_mode_name,
            value: orderingMode.id,
        }));
    const handleClickAddSection = () => {
        setisSubSectionAdd(false);
        append({ name: null });
    };

    const { fields, append, remove } = useFieldArray({
        control,
        name: `subSection`,
    });
    useEffect(() => {
        if (fields.length < 1) {
            setisSubSectionAdd(false);
        }
    }, [fields]);

    const subSections = watch('subSection');

    const onSubmit = (sectionData) => {
        createSection({
            sectionData,
            setloaderStatus,
            setisDelete,
            handleClickClose,
            setSectionList,
            seterrorOfApi,
        });
    };
    const onDelete = () => {
        const sectionId = getValues('sectionId');
        deleteSection({
            sectionId,
            setloaderStatus,
            setisDelete,
            navigate,
            handleClickClose,
            setSectionList,
            seterrorOfApi,
            selectedSection,
            setTableList,
            setTotalTable,
            setPopupStatus,
        });
    };
    const onEdit = (sectionData) => {
        editSection({
            sectionData,
            sectionList,
            setloaderStatus,
            setisDelete,
            handleClickClose,
            watch,
            setSectionList,
            seterrorOfApi,
            setTableList,
            setTotalTable,
            section,
            navigate,
        });
    };

    return (
        <>
            <Layout
                loaderStatus={loaderStatus}
                handleClickClose={handleClickClose}
                onSubmit={handleSubmit(onSubmit)}
                onDelete={() => {
                    setPopupStatus((prevValue) => ({
                        ...prevValue,
                        confirmDeletePopup: true,
                    }));
                    setisDelete(true);
                }}
                onEdit={handleSubmit(onEdit)}
                isDelete={isDelete}
                errorOfApi={errorOfApi}
                isEditSection={isEditMode}
                isDirty={isDirty}>
                {!isSubSectionAdd ? (
                    <div className='mb-4'>
                        <Controller
                            render={({ field }) => (
                                <DefaultInputField
                                    label='Section name'
                                    placeholder='Enter section name'
                                    enteredValue={field.onChange}
                                    action='+ Add sub-section'
                                    handleClickAction={handleClickAddSection}
                                    shadow='shadow-xSmall'
                                    value={watch('internal_name') ?? ''}
                                />
                            )}
                            name='internal_name'
                            control={control}
                            rules={{ required: { message: 'Section name require', value: true } }}
                        />
                        {errors?.['internal_name'] && <ErrorMessage errors={errors?.['internal_name']} />}
                    </div>
                ) : (
                    <div className='mb-4'>
                        <Controller
                            render={({ field }) => (
                                <DropdownWithValueLabel
                                    label='Select Section'
                                    menuItems={sections}
                                    placeholder='Select sub-section'
                                    setSelected={field.onChange}
                                    action='+ Add sub-section'
                                    handleClickAction={handleClickAddSection}
                                    shadow='shadow-xSmall'
                                />
                            )}
                            name='outlet_sub_section_id'
                            control={control}
                            rules={{ required: 'Section require', value: true }}
                        />
                        {errors?.['outlet_sub_section_id'] && (
                            <ErrorMessage errors={errors?.['outlet_sub_section_id']} />
                        )}
                    </div>
                )}

                <div className='max-h-[43.1vh] overflow-auto scrollbar-style -ml-1 pl-1 -mr-8 pr-8'>
                    {fields.map((item, index) => {
                        return (
                            <div
                                className='mb-4 last:mb-1'
                                key={item.id}>
                                <Controller
                                    render={({ field }) => (
                                        <DefaultInputField
                                            placeholder='Enter sub-section name'
                                            label='Sub-section name'
                                            action={fields.length > 1 ? '- Remove sub-section' : ''}
                                            actionTextColor='text-destructive-500'
                                            handleClickAction={() => remove(index)}
                                            enteredValue={field.onChange}
                                            removeSectionStyle='mobile:text-xs'
                                            shadow='shadow-xSmall'
                                            value={subSections?.[index].name ?? ''}
                                        />
                                    )}
                                    name={`subSection[${index}].name`}
                                    control={control}
                                    rules={{ required: { message: 'sub Section name require', value: true } }}
                                />
                                {errors?.subSection && errors?.subSection[index]?.name && (
                                    <ErrorMessage errors={errors?.subSection[index]?.name} />
                                )}
                            </div>
                        );
                    })}
                </div>

                <div className='mb-12 relative mt-3'>
                    <Controller
                        render={({ field }) => (
                            <DropdownWithValueLabel
                                label='Select ordering types'
                                placeholder='Select ordering type'
                                menuItems={orderingModesList}
                                setSelected={field.onChange}
                                selectedItem={watch('ordering_mode') ?? {}}
                                fixedHeight='max-h-[100px]'
                            />
                        )}
                        name='ordering_mode'
                        control={control}
                        rules={{ required: 'Ordring mode require', value: true }}
                    />

                    {errors?.['ordering_mode'] && <ErrorMessage errors={errors?.['ordering_mode']} />}
                </div>

                {popupStatus.confirmDeletePopup && (
                    <ConfirmDeletePopup
                        header={<h2 className='paragraph-large-medium ml-2'>Delete the Section</h2>}
                        description={
                            <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3'>
                                {`Are you sure to delete selected section?`}
                            </p>
                        }
                        handleClickClose={() => {
                            setPopupStatus((prevValue) => ({
                                ...prevValue,
                                confirmDeletePopup: false,
                            }));
                            setisDelete(!isDelete);
                        }}
                        handleDelete={onDelete}
                        showLoadingButton={true}
                        isDelete={isDelete}
                        loaderStatus={loaderStatus}
                    />
                )}
            </Layout>
        </>
    );
}
