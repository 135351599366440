import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import APIV2 from '../../../../api/axios/APIV2';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { format } from 'date-fns';
import PopupLoader from './PopupLoader';

export default function OfferDetailsPopup(props) {
    const { selectedOfferId, handleClickClose } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [offerDetails, setOfferDetails] = useState({});

    const fetchOfferDetails = async () => {
        try {
            const respose = await APIV2.get(`/restaurants/${selectedRestaurantId}/offers/${selectedOfferId}`);
            setOfferDetails(respose.offers);
        } catch (error) {
            console.log(error);
        }
    };

    const outletList = useSelector((state) => state.outlet.outletList);

    useEffect(() => {
        fetchOfferDetails();

        // eslint-disable-next-line
    }, []);

    const menuItemmTiming = [
        { label: 'Full-time', value: 'no_time_slots' },
        { label: 'Specific time for all days', value: 'same_time_slot_for_all_days' },
        { label: 'Specific time for each day', value: 'different_time_slots_for_different_days' },
    ];

    const linkedOutletList =
        !!offerDetails?.linked_outlets?.length &&
        offerDetails?.linked_outlets?.map((el) => outletList.find((outlet) => outlet.outletId === el)?.displayName);

    const offerItems = [
        { label: 'Flat discount', value: 'flat_discount' },
        { label: 'Percentage discount', value: 'percentage_discount' },
        { label: 'Free delivery', value: 'free_delivery' },
        { label: 'Get an item', value: 'get_an_item' },
        { label: 'Buy X get Y free', value: 'buy_x_get_y_free' },
        { label: 'Menu discount', value: 'menu_discount' },
        { label: 'BOGO', value: 'bogo' },
        { label: 'Coupled offer', value: 'coupled_offer' },
        { label: 'Value deal', value: 'value_deal' },
    ];

    return (
        <Layout
            handleClickClose={handleClickClose}
            selectedOfferId={selectedOfferId}>
            {/* {true ? ( */}
            {_.isEmpty(offerDetails) ? (
                <PopupLoader />
            ) : (
                <>
                    <SingleKeyValue
                        label='Offer title'
                        value={offerDetails.translations.title[1]}
                    />
                    <SingleKeyValue
                        label='Offer description'
                        value={offerDetails.translations.description[1]}
                    />
                    <SingleKeyValue
                        label='Offer type'
                        value={offerItems.find((el) => el?.value === offerDetails?.offer_type)?.label}
                    />
                    <SingleKeyValue
                        label='Coupon code'
                        value={offerDetails.coupon_code}
                    />
                    <SingleKeyValue
                        label='Status'
                        value={offerDetails.active ? 'Yes' : 'No'}
                    />
                    {!!offerDetails.min_cart_item && (
                        <SingleKeyValue
                            label='Minimum cart item'
                            value={offerDetails.min_cart_item}
                        />
                    )}
                    {!!offerDetails.min_cart_item_quantity && (
                        <SingleKeyValue
                            label='Minimum cart item quantity'
                            value={offerDetails.min_cart_item_quantity}
                        />
                    )}
                    {!!offerDetails.discount_amount && (
                        <SingleKeyValue
                            label='Discount amount'
                            value={offerDetails.discount_amount}
                        />
                    )}
                    {!!offerDetails.max_discount_amount && (
                        <SingleKeyValue
                            label='Max discount amount'
                            value={offerDetails.max_discount_amount}
                        />
                    )}
                    {!!offerDetails.percentage && (
                        <SingleKeyValue
                            label='Percentage'
                            value={offerDetails.percentage + ' %'}
                        />
                    )}
                    {!!offerDetails.min_cart_amount && (
                        <SingleKeyValue
                            label='Minimum cart amount'
                            value={offerDetails.min_cart_amount}
                        />
                    )}
                    {!!offerDetails.max_usage_count && (
                        <SingleKeyValue
                            label='Maximum usage amount'
                            value={offerDetails.max_usage_count}
                        />
                    )}
                    {!!offerDetails.max_usage_count_per_user && (
                        <SingleKeyValue
                            label='Max usage count per user'
                            value={offerDetails.max_usage_count_per_user}
                        />
                    )}
                    {!!offerDetails.allow_offer_clubbing && (
                        <SingleKeyValue
                            label='Allow offer clubbing'
                            value='Yes'
                        />
                    )}
                    {!!offerDetails.auto_apply && (
                        <SingleKeyValue
                            label='Allow auto apply offer'
                            value='Yes'
                        />
                    )}
                    {!!offerDetails.quantity_of_free_item && (
                        <SingleKeyValue
                            label='Quantity of free item'
                            value={offerDetails.quantity_of_free_item}
                        />
                    )}
                    {!!offerDetails.promo_consolidation && (
                        <SingleKeyValue
                            label='Consolidate offer'
                            value='Yes'
                        />
                    )}
                    <SingleKeyValue
                        label='Valid from'
                        value={
                            offerDetails.validate_from
                                ? format(new Date(offerDetails.validate_from), 'dd MMM yyyy')
                                : '-'
                        }
                    />
                    <SingleKeyValue
                        label='Validate to'
                        value={
                            offerDetails.validate_to ? format(new Date(offerDetails.validate_to), 'dd MMM yyyy') : '-'
                        }
                    />
                    {!!offerDetails.apply_on_addon && (
                        <SingleKeyValue
                            label='Apply on addon'
                            value='Yes'
                        />
                    )}
                    {!!offerDetails.repeat_usage_time && (
                        <SingleKeyValue
                            label='Repeat usage time'
                            value={offerDetails.repeat_usage_time}
                        />
                    )}
                    {!!offerDetails.min_required_order_counts && (
                        <SingleKeyValue
                            label='Minimum required order count'
                            value={offerDetails.min_required_order_counts}
                        />
                    )}

                    <SingleKeyValue
                        label='Hide this offer on exhaustion'
                        value={offerDetails.hide_on_exhaustion ? 'Yes' : 'No'}
                    />

                    {!!offerDetails.offer_display.display_in_cart_tab && (
                        <SingleKeyValue
                            label='Display this offer in cart tab'
                            value='Yes'
                        />
                    )}
                    {!!offerDetails.offer_display.display_in_offers_tab && (
                        <SingleKeyValue
                            label='Display this offer in offer tab'
                            value='Yes'
                        />
                    )}
                    {!!offerDetails.max_required_order_counts && (
                        <SingleKeyValue
                            label='Maximum required order count'
                            value={offerDetails.max_required_order_counts}
                        />
                    )}

                    <div className='mb-5 w-full ml-1 md:flex md:flex-col'>
                        <span className='paragraph-medium-semi-bold text-neutral-400'>
                            Maximum required order count :
                        </span>
                        <span className='paragraph-medium-regular ml-1'>
                            {menuItemmTiming.find((el) => el.value === offerDetails.time_slot_setting).label}
                        </span>
                    </div>

                    <div
                        className={`mb-5 flex flex-row items-start ml-1 ${
                            !offerDetails.linked_outlets.length ? 'items-center' : 'items-start'
                        }`}>
                        <span className='paragraph-medium-semi-bold text-neutral-400'>Linked outlet :</span>

                        <div className='flex flex-col gap-1 ml-2'>
                            {!!offerDetails.linked_outlets.length
                                ? linkedOutletList.map((el, index) => (
                                      <span
                                          className='paragraph-medium-regular ml-1'
                                          key={index}>
                                          {el}
                                      </span>
                                  ))
                                : '-'}
                        </div>
                    </div>

                    <div
                        className={`mb-5 flex flex-row ml-1 ${
                            !offerDetails.ordering_modes.length ? 'items-center' : 'items-start'
                        }`}>
                        <span className='paragraph-medium-semi-bold text-neutral-400'>Ordering mode :</span>

                        <div className='flex flex-col gap-1 ml-2'>
                            {!!offerDetails.ordering_modes.length
                                ? offerDetails.ordering_modes.map((el, index) => (
                                      <span
                                          className='paragraph-medium-regular ml-1'
                                          key={index}>
                                          {el.restaurant_ordering_mode_name}
                                      </span>
                                  ))
                                : '-'}
                        </div>
                    </div>

                    <div
                        className={`mb-3 flex flex-row ml-1 ${
                            !offerDetails.offer_customers.length ? 'items-center' : 'items-start'
                        }`}>
                        <span className='paragraph-medium-semi-bold text-neutral-400'>Linked customer :</span>

                        <div className='flex flex-col gap-1 ml-2'>
                            {!!offerDetails.offer_customers.length
                                ? offerDetails.offer_customers.map((el, index) => (
                                      <span
                                          className='paragraph-medium-regular ml-1'
                                          key={index}>
                                          {el.name}
                                      </span>
                                  ))
                                : '-'}
                        </div>
                    </div>

                    <div className='ml-3 md:ml-0'>
                        <SingleKeyValue
                            label='Order terms and conditions'
                            value={offerDetails.translations.terms_and_conditions[1] ?? '-'}
                        />
                    </div>
                </>
            )}
        </Layout>
    );
}

const SingleKeyValue = (props) => {
    const { label, value } = props;

    return (
        <div className='mb-5 w-1/2 inline-block even:ml-1 odd:mr-1 even:-mr-1 odd:-ml-1 md:block md:odd:mr-0 md:odd:ml-1 md:w-full'>
            <span className='paragraph-medium-semi-bold text-neutral-400'>{label} :</span>
            <span className='paragraph-medium-regular ml-1'>{value}</span>
        </div>
    );
};
