export const SET_OUTLET_LIST = 'set-outlet-list';
export const SET_SELECTED_OUTLET_ID = 'set-selected-outlet-id';
export const SET_SELECTED_OUTLET = 'set-selected-outlet';
export const SET_ORDERING_MODES = 'set-ordering-modes';
export const SET_OUTLET_ORDERING_MODES = 'set-outlet-ordering-modes';
export const SET_OUTLET_MENU = 'set-outlet-menu';
export const SET_LIST_ORDER_MENU = 'set_list_order_menu';
export const SET_CATEGORY_WITH_ITEMS = 'set-category-with-items';
export const SET_REDIRECTORY_LINK = 'set-redirectory-link';
export const TOGGLE_ACTION_SWITCH = 'toggle-action-switch';
export const SAVE_POS_SETTINGS = 'Save_pos_settings';
