import React, { useState } from 'react';
import { ReactComponent as DineInIcons } from '../../../Assets/dine-in.svg';
import DetailLoader from '../../ContentLoader/DetailLoader';

const OrderHeader = (props) => {
    const { orderDetail, listView, setShowPopup, isLoading } = props;
    return (
        <div className='flex flex-row justify-between items-center mb-4 pb-4 border-b border-neutral-300'>
            <div className=' w-1/2 flex flex-row items-center'>
                {orderDetail?.ordering_mode === 'Dine-In order' ? (
                    <div className='p-2 bg-primary-500 rounded-lg'>
                        <DineInIcons
                            height={32}
                            width={32}
                            stroke='#FAFAFA'
                        />
                    </div>
                ) : (
                    <></>
                )}
                {!listView ? (
                    <div className='ml-1.5 flex flex-col items-start'>
                        <span className='paragraph-medium-italic text-neutral-500'>Order number</span>
                        {isLoading ? (
                            <DetailLoader />
                        ) : (
                            <span className='paragraph-large-medium'>
                                Order #{orderDetail?.last_four_character_order_label}
                            </span>
                        )}
                        {orderDetail.ordering_mode == 'dine_in' && orderDetail?.table_name && (
                            <div className='mt-2 flex flex-col'>
                                <span className='paragraph-medium-italic text-neutral-500'>Table</span>
                                {isLoading ? (
                                    <DetailLoader />
                                ) : (
                                    <span className='paragraph-large-medium'>{orderDetail?.table_name}</span>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className='ml-2 flex flex-col items-start'>
                        <span className='paragraph-medium-semi-bold'>{orderDetail?.ordering_mode}</span>
                        <span className='paragraph-medium-italic text-neutral-500'>
                            Order #{orderDetail?.details?.last_four_character_order_label}
                        </span>
                        {orderDetail.ordering_mode == 'dine_in' && (
                            <>
                                <span className='paragraph-medium-italic text-neutral-500'>Table Number</span>
                                {isLoading ? (
                                    <DetailLoader />
                                ) : (
                                    <span className='paragraph-large-medium'>
                                        Order #{orderDetail?.last_four_character_order_label}
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>

            {!listView ? (
                <div className='flex flex-col items-end'>
                    <div className='paragraph-medium-italic text-neutral-500'>Order details</div>
                    <span
                        onClick={() => setShowPopup('customerDetails')}
                        className='paragraph-large-underline text-primary-500 cursor-pointer'>
                        View details
                    </span>
                </div>
            ) : (
                <div className='flex flex-col items-end'>
                    <div className='paragraph-medium-regular'>{orderDetail?.placed_at}</div>
                    <div className='paragraph-medium-italic text-neutral-500'>{orderDetail?.ordering_mode}</div>
                </div>
            )}
        </div>
    );
};

export default OrderHeader;
