import React, { useState } from 'react';
import Layout from './Components/Layout';
import GroupSection from './Components/Section/GroupSection/GroupSection';
import ChoiceSection from './Components/Section/ChoiceSection/ChoiceSection';

export default function AddonDetails() {
    const [selectedAddon, setSelectedAddon] = useState({});

    return (
        <>
            <Layout>
                <div className='flex flex-row w-full gap-8'>
                    <div className='w-1/3 pr-8 border-r border-neutral-300'>
                        <GroupSection
                            setSelectedAddon={setSelectedAddon}
                            selectedAddon={selectedAddon}
                        />
                    </div>

                    <div className='w-1/3 pr-8 border-r border-neutral-300'>
                        <ChoiceSection selectedAddon={selectedAddon} />
                    </div>
                    <div className='w-1/3'></div>
                </div>
            </Layout>
        </>
    );
}
