import { combineReducers } from 'redux';
import auth from './auth';
import permission from './permission';
import loading from './loading';
import outlet from './outlet';
import fullScreen from './fullScreen';
import langauge from './langauge';
import device from './device';
import orderStatus from './orderStatus';
import pos from './pos';

export default combineReducers({
    auth,
    permission,
    outlet,
    loading,
    fullScreen,
    langauge,
    device,
    orderStatus,
    pos,
});
