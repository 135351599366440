import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { useWindowSize } from '@uidotdev/usehooks';
import APIV3 from '../../../../../api/axios/APIV3';
import SearchableDropdownWithWindowing from './SearchableDropdownWithWindowing.js';
import APIV5 from '../../../../../api/axios/APIV5.js';

export default function DishSelectionDropdown(props) {
    const { filedName } = props;

    const { watch, setValue, getValues } = useFormContext();

    const screenSize = useWindowSize();

    const [isLoadingMenuItems, setIsLoadingMenuItems] = useState(false);
    const [isLoadingVariants, setIsLoadingVariants] = useState(false);
    const [quantity, setQuantity] = useState(null);
    const [dishMenuItem, setDishMenuItem] = useState([]);
    const [selectedItemID, setSelectedItemID] = useState(null);
    const [variantOptions, setVariantOptions] = useState([]);

    const [pageState, setPageState] = useState({ page: 1, lastPage: 1 });

    const [filters, setFilters] = useState({ searchQuery: '' });
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const formatList = (list) => {
        const itemIdsToFilter = getValues('selected_dish')
            ? getValues('selected_dish')?.map((item) => item.item_id)
            : [];
        const formattedList = list
            .filter((el) => !itemIdsToFilter.includes(el.item_id))
            .map((el) => ({
                label: el.translations.title[1],
                value: {
                    item_id: el.item_id,
                    label: el.internal_name,
                    quantity: quantity,
                    display_name: el.translations.title[1],
                },
                ...el,
            }));

        setDishMenuItem(formattedList);
    };

    const fetchDishList = async (param) => {
        const params = {
            page: pageState.page,
            per_page: 10,
            keyword: filters.searchQuery,
            ...param,
        };

        try {
            const response = await APIV3.get(`restaurants/${selectedRestaurantId}/items`, {
                params,
            });

            setPageState((prevState) => {
                return { ...prevState, lastPage: response.item.last_page };
            });

            formatList(response.item.data);

            setIsLoadingMenuItems(false);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchVariantOptions = async (itemId) => {
        setIsLoadingVariants(true);
        try {
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/items/${itemId}/variant-options`);

            const formattedVariants = response?.options?.map((option) => ({
                label: option?.translations?.title?.[1],
                value: {
                    variant_id: option?.id,
                    display_name: option?.translations?.title?.[1],
                },
            }));

            setVariantOptions(formattedVariants);
        } catch (error) {
            console.error('Error fetching variants:', error);
            setVariantOptions([]);
        } finally {
            setIsLoadingVariants(false);
        }
    };

    const handleSelect = (selectedItem, fieldOnChange) => {
        const itemWithQuantity = {
            ...selectedItem,
            quantity: quantity,
        };

        const newIdArray = watch('selected_dish')?.length
            ? [...watch('selected_dish'), itemWithQuantity]
            : [itemWithQuantity];

        setValue('selected_dish', newIdArray);
        fieldOnChange(itemWithQuantity);
        setSelectedItemID(selectedItem.item_id);
        fetchVariantOptions(selectedItem.item_id);

        const updateMenuItems = dishMenuItem.filter((item) => item.value.item_id !== selectedItem.item_id);
        setDishMenuItem(updateMenuItems);
    };

    const handleVariantSelect = (selectedVariant, fieldOnChange) => {
        setValue('selected_variant', selectedVariant);
        fieldOnChange(selectedVariant);
    };

    const handleQuantityChange = (e) => {
        const newQuantity = parseInt(e.target.value) || 0;
        setQuantity(newQuantity);

        const selectedDish = watch('selected_dish');
        if (selectedDish?.length) {
            const updatedDish = selectedDish.map((dish) => ({
                ...dish,
                quantity: newQuantity,
            }));
            setValue('selected_dish', updatedDish);
        }
    };

    const onCompleteTyping = (searchQuery) => {
        searchQuery.length ? handleFetchList(searchQuery) : setDishMenuItem([]);
    };

    useEffect(() => {
        fetchDishList({ keyword: ' ', page: 1 });
    }, []);

    const handleFetchList = useCallback(
        _.debounce(async (search_query) => {
            setFilters({ keyword: search_query });
            fetchDishList({ keyword: search_query, page: pageState.page });
        }, 1000)
    );

    return (
        <div className='max-w-full'>
            <div className='flex flex-row gap-2'>
                <Controller
                    render={({ field }) => (
                        <SearchableDropdownWithWindowing
                            onCompleteTyping={onCompleteTyping}
                            menuItems={dishMenuItem}
                            placeholder='Select dish'
                            handleSelect={(selection) => handleSelect(selection, field.onChange)}
                            setIsLoadingMenuItems={setIsLoadingMenuItems}
                            isLoadingMenuItems={isLoadingMenuItems}
                            openFromBottom={screenSize.height < 785}
                            setPageState={setPageState}
                            pageState={pageState}
                            fetchNewData={fetchDishList}
                            showDisplayValue={true}
                        />
                    )}
                    name={filedName}
                    rules={{ required: 'Please select a dish' }}
                />

                {selectedItemID && variantOptions.length > 0 && (
                    <div className='flex flex-row gap-4'>
                        <Controller
                            name='selected_variant'
                            rules={{ required: 'Please select a size' }}
                            render={({ field }) => (
                                <SearchableDropdownWithWindowing
                                    menuItems={variantOptions}
                                    placeholder='Select size'
                                    handleSelect={(selection) => handleVariantSelect(selection, field.onChange)}
                                    setIsLoadingMenuItems={setIsLoadingVariants}
                                    isLoadingMenuItems={isLoadingVariants}
                                    openFromBottom={screenSize.height < 785}
                                    showDisplayValue={true}
                                />
                            )}
                        />
                    </div>
                )}
                <div className='flex flex-row items-center justify-center ml-3'>
                    <label
                        htmlFor='quantity'
                        className='text-base'>
                        Quantity:
                    </label>
                    <input
                        type='number'
                        id='quantity'
                        name='quantity'
                        value={quantity}
                        onChange={handleQuantityChange}
                        placeholder='0'
                        className='w-[4rem] p-1 ml-3 border-2 border-gray-600 items-center rounded-md'
                    />
                </div>
            </div>
        </div>
    );
}
