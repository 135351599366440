import React from 'react';

export const Tab = (props) => {
    const {
        shadow,
        tabStyle,
        disabled,
        iconDefault,
        iconClick,
        labelStyle,
        type,
        bannerTabStyle,
        hideLabel,
        label,
        badgeText,
        isActive,
    } = props;

    return (
        <>
            <button
                className={`w-full rounded-md px-3 mobile:px-1 group border ${
                    isActive ? 'bg-primary-50' : 'bg-shades-50'
                } ${
                    !isActive ? 'border-neutral-300' : 'border-primary-500'
                } disabled:border-neutral-300 disabled:bg-shades-50 h-12 flex justify-center ${shadow} ${tabStyle}`}
                disabled={disabled}>
                <div className='flex flex-row items-center h-full'>
                    {iconDefault && <div className='mr-1'>{isActive ? iconClick : iconDefault}</div>}

                    {label && (
                        <span
                            className={`${labelStyle ?? 'paragraph-medium-regular mx-1'} ${
                                type === 'offer' && 'md:text-[15px] mobile:text-sm'
                            } ${
                                isActive ? 'text-primary-500' : 'text-neutral-700 group-hover:text-neutral-900'
                            } group-disabled:text-neutral-300 ${bannerTabStyle} ${hideLabel}`}>
                            {label}
                        </span>
                    )}

                    {badgeText && (
                        <div className='mx-1'>
                            <div
                                className={`px-2.5 paragraph-x-small-medium m-auto group-disabled:text-neutral-400 ${
                                    isActive ? 'text-shades-50' : 'text-neutral-700 group-hover:text-neutral-900'
                                } h-6 rounded-3xl py-0.5 ${
                                    isActive ? 'bg-primary-500 text-shades-50' : 'bg-neutral-200'
                                } disabled:bg-neutral-200`}>
                                <span className='h-5'>{badgeText}</span>
                            </div>
                        </div>
                    )}
                </div>
            </button>
        </>
    );
};

export const TabWithTwoImage = (props) => {
    const { isActive, disabled, iconClick, iconDefault, label, IconRightActive, IconRight } = props;

    return (
        <>
            <button
                className={`w-full rounded-md px-3 md:px-1 mobile:px-0 group border border-neutral-300 group ${
                    isActive ? 'bg-primary-50' : 'bg-shades-50'
                } ${
                    !isActive ? 'border-neutral-300' : 'border-primary-500'
                } disabled:border-neutral-300 disabled:bg-shades-50 py-3 h-12 justify-center`}
                disabled={disabled}>
                <div className='flex flex-row items-center justify-center'>
                    {iconDefault && (
                        <>
                            <div className='mx-1'>{isActive ? iconClick : iconDefault}</div>
                        </>
                    )}
                    {label && (
                        <span
                            className={`paragraph-small-medium mx-1 mobile:mx-0 ${
                                isActive ? 'text-primary-500' : 'text-neutral-700 group-hover:text-neutral-900'
                            } group-disabled:text-neutral-300`}>
                            {label}
                        </span>
                    )}
                    {IconRight && (
                        <>
                            <div className='px-1'>{isActive ? IconRightActive : IconRight}</div>
                        </>
                    )}
                </div>
            </button>
        </>
    );
};

export const TabBig = (props) => {
    const { bottonStyle, disabled, iconClick, iconDefault, label, isActive, badgeText } = props;

    return (
        <>
            <button
                className={`${bottonStyle} w-full rounded-md px-2.5 group border group ${
                    isActive ? 'bg-primary-50' : 'bg-shades-50'
                } ${
                    !isActive ? 'border-neutral-300' : 'border-primary-500'
                } disabled:border-neutral-300 disabled:bg-shades-50 py-[11px] h-12 flex justify-center`}
                disabled={disabled}>
                <div className='flex flex-row items-center'>
                    {iconDefault && (
                        <>
                            <div className='mx-1'>{isActive ? iconClick : iconDefault}</div>
                        </>
                    )}
                    {label && (
                        <span
                            className={`paragraph-medium-regular mx-1 ${
                                isActive ? 'text-primary-500' : 'text-neutral-700 group-hover:text-neutral-900'
                            } group-disabled:text-neutral-300 `}>
                            {label}
                        </span>
                    )}
                    {badgeText && (
                        <div className='mx-1'>
                            <div
                                className={`w-6 h-5 rounded-3xl ${
                                    isActive ? 'bg-primary-500' : 'bg-neutral-200'
                                } group-disabled:bg-neutral-200`}>
                                <span
                                    className={`leading-none m-auto group-disabled:text-neutral-400 ${
                                        isActive ? 'text-shades-50' : 'text-neutral-700 group-hover:text-neutral-900'
                                    }`}>
                                    {badgeText}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </button>
        </>
    );
};

export const MultipleTab = (props) => {
    const {
        disabled,
        onClick,
        index,
        maxWidth,
        minWidth,
        showIcon,
        icon,
        label,
        isActive,
        badgeText,
        textWrap,
        inIconClick,
        paddingLeftRight,
        hasError,
        maxHeight,
    } = props;

    return (
        <button
            disabled={disabled}
            onClick={() => onClick(index)}
            className={`${maxWidth ?? 'max-w-[173px]'} ${minWidth} w-full border ${
                isActive
                    ? 'border-primary-500 bg-primary-50 text-primary-500'
                    : hasError
                      ? 'border-destructive-500 bg-destructive-50 text-destructive-500'
                      : 'border-neutral-300 hover:text-neutral-900'
            } rounded-lg text-left py-2.5 ${
                maxHeight ?? 'max-h-[72px]'
            } min-h-[48px] paragraph-medium-regular disabled:text-neutral-300 flex flex-row ${
                paddingLeftRight ?? 'px-4'
            }`}>
            <div className='flex flex-row items-center'>
                {icon && showIcon && (
                    <div
                        className='mr-2'
                        onClick={(e) => {
                            inIconClick && e.stopPropagation();
                            inIconClick?.();
                        }}>
                        {icon}
                    </div>
                )}
                <div className={`${textWrap}`}>{label}</div>
            </div>
            {!!badgeText && (
                <div className='ml-2'>
                    <div
                        className={`px-2.5 paragraph-x-small-medium m-auto group-disabled:text-neutral-400 ${
                            isActive ? 'text-shades-50' : 'text-neutral-700 group-hover:text-neutral-900'
                        } h-6 rounded-3xl py-0.5 ${
                            isActive ? 'bg-primary-500 text-shades-50' : 'bg-neutral-200'
                        } disabled:bg-neutral-200`}>
                        <span className='h-5'>{badgeText}</span>
                    </div>
                </div>
            )}
        </button>
    );
};
