import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from '../../../../../Assets/chevron-down.svg';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../../../Components/Buttons/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { IsMobileScreen } from '../../../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import MobileScreenAddCategoryDish from '../../../../../Components/MobileViewScreen/MobileScreenAddCategoryDish';
import { useSelector } from 'react-redux';
import { getCategoryDetails } from '../../../functions';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';
import _ from 'lodash';
import DetailsDropDown from '../../../Components/Catalogue/Components/DetailsDropDown';
import UploadImagePopup from '../../../../../Components/UploadImagePopup/UploadImagePopup';
import SelectImageFromLibraryPopup from '../../../../Banners/Component/SelectImagePopupComponent/SelectImageFromLibraryPopup';
import InputSection from '../../../../../Components/InputField/InputSection';
import InputAreaSection from '../../../../../Components/InputField/InputAreaSection';
import { useFormContext } from 'react-hook-form';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import { advanceSettingMenuItem } from '../../constants';

export default function BasicDetails() {
    const navigate = useNavigate();

    const location = useLocation();

    const editingCategory = location?.state?.category;

    const goToMenuPage = () => {
        navigate('/menu');
    };

    const {
        setValue,
        watch,
        formState: { errors },
        clearErrors,
    } = useFormContext();

    const isMobileScreen = IsMobileScreen();

    const [renderPageOnMobileScreen, setRenderPageOnMobileScreen] = useState('home');

    const [showUploadImage, setShowUploadImage] = useState(false);

    const screenHeight = useWindowSize().height;

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const [internalName, setInternalName] = useState('');

    const [title, setTitle] = useState([]);

    const [description, setDescription] = useState([]);

    const [isLoading, setIsLoading] = useState(!!editingCategory);

    const formateAdvanceSetting = (advanceSetting) => {
        let settings = {
            mask: {},
            hideCategory: {},
            tileDetails: {},
            displayTheme: {},
            tilePosition: {},
        };

        settings.tilePosition = advanceSettingMenuItem.tilePosition.find(
            (el) => el.value === advanceSetting.gradient_position
        );

        settings.mask = advanceSettingMenuItem.mask.find((el) => el.value === advanceSetting.apply_mask);

        settings.hideCategory = advanceSettingMenuItem.hideCategory.find((el) => el.value === advanceSetting.hidden);

        settings.displayTheme = advanceSettingMenuItem.displayTheme.find((el) => el.value === advanceSetting.theme);

        settings.tileDetails = advanceSettingMenuItem.tileDetails.find(
            (el) => el.value === advanceSetting.hide_category_tile_details
        );
    };

    const formateAndSetCategoryDetail = (category) => {
        const title = Object.entries(category.translations.title).map(([id, title]) => ({
            id: parseInt(id),
            title: title,
        }));

        const description = Object.entries(category.translations.description).map(([id, title]) => ({
            id: parseInt(id),
            description: title,
        }));

        setInternalName(category.internal_name);

        setTitle(title);

        setDescription(description);

        formateImageDetails(category.image);

        formateAdvanceSetting(category);
    };

    const getAllCategoryDetails = async (categoryId) => {
        const response = await getCategoryDetails(selectedRestaurantId, categoryId);

        setIsLoading(false);

        formateAndSetCategoryDetail(response);
    };

    useEffect(() => {
        editingCategory && getAllCategoryDetails(editingCategory.category_id);
    }, []);

    const [showSelectFromLibrary, setShowSelectFromLibrary] = useState(false);

    const [imageDetails, setImageDetails] = useState(null);

    const formateImageDetails = (imageDetails) => {
        let imageSize;

        const img = new Image();

        img.src = imageDetails.image_url;

        img.onload = () => {
            const aspectRatio = img.naturalWidth / img.naturalHeight;

            const calculatedWidth = 150 * aspectRatio;

            imageSize = { displaySize: { width: calculatedWidth, height: 150 } };

            setImageDetails({ ...imageSize, ...imageDetails });

            setValue('imageDetails', { ...imageSize, ...imageDetails });

            clearErrors('imageDetails');
        };
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    // const formateBody = () => {
    //     const descriptionTranslations = description.reduce((acc, curr) => {
    //         acc[curr.id] = curr.description;
    //         return acc;
    //     }, {});

    //     const titleTranslations = title.reduce((acc, curr) => {
    //         acc[curr.id] = curr.title;
    //         return acc;
    //     }, {});

    //     const body = {
    //         category_image_id: imageDetails.id,
    //         internal_name: internalName,
    //         translations: {
    //             title: titleTranslations,
    //             description: descriptionTranslations,
    //         },
    //         apply_mask: advanceSettingSelection?.mask?.value,
    //         hidden: advanceSettingSelection.hideCategory.value,
    //         hide_category_tile_details: advanceSettingSelection.tileDetails.value,
    //         theme: advanceSettingSelection.displayTheme.value,
    //         tile_details_position: advanceSettingSelection.tilePosition.value,
    //         gradient_position: advanceSettingSelection.tilePosition.value,
    //     };

    //     return body;
    // };

    // const handleEnterInternalName = (name) => {
    //     setInternalName(name);
    //     setErrors((prevState) => ({ ...prevState, internalName: {} }));
    // };

    // const checkValidation = () => {
    //     let isValid = true;

    //     if (!internalName) {
    //         setErrors((prevState) => ({ ...prevState, internalName: { message: 'Please enter internal name' } }));
    //         isValid = false;
    //     }

    //     const titleErrors = availableLanguages.map((_, index) => {
    //         if (!title?.[index]?.title) {
    //             return { message: 'Please enter title' };
    //         }
    //         return {};
    //     });

    //     if (!_.every(titleErrors, _.isEmpty)) {
    //         isValid = false;
    //     }

    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         title: titleErrors,
    //     }));

    //     if (!imageDetails?.id) {
    //         setErrors((prevState) => ({ ...prevState, image: { message: 'Please select image' } }));
    //         isValid = false;
    //     }

    //     return isValid;
    // };

    // const handleClickSave = async () => {
    //     const isValid = checkValidation();

    //     if (!isValid) return;

    //     const response = !editingCategory
    //         ? await addCategory(selectedRestaurantId, formateBody())
    //         : await editCategory(selectedRestaurantId, formateBody(), editingCategory.category_id);

    //     if (response.success) {
    //         navigate('/menu/catalogue');
    //         return;
    //     }

    //     setErrors((prevState) => ({
    //         ...prevState,
    //         internalName: { message: response?.response?.data?.errors?.internal_name },
    //     }));
    // };

    const languageMenuItem = availableLanguages?.map((el) => ({ label: el.language_label, value: el }));

    const [selectedLanguageForMobile, setSelectedLanguageForMobile] = useState(languageMenuItem[0]);

    return (
        <>
            {(!isMobileScreen || !showUploadImage) && (
                <div className='bg-[#fafafa]'>
                    <div className='w-full max-w-[1336px] mx-auto bg-white relative md:max-w-full overflow-auto'>
                        {isMobileScreen && (
                            <div
                                className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                                onClick={goToMenuPage}>
                                <LeftArrow className='rotate-90' />
                                <span className='ml-1'>Back to catalogue</span>
                            </div>
                        )}

                        <div className='flex flex-row xl:block xl:mb-0'>
                            <div
                                className='min-w-fit w-full pr-[30px] border-r border-neutral-300 xl:pr-0 xl:pb-4 xl:border-r-0 xl:border-b xl:mb-6 xl:min-w-full pb-6 md:pb-2 overflow-auto scrollbar-style -ml-1 pl-2'
                                style={{
                                    height: screenHeight - 173,
                                }}>
                                <div className='mb-6'>
                                    <TitleDescription
                                        title='Category image'
                                        description='Select a category image that will be displayed with the category name in the mobile application.'
                                    />
                                </div>

                                {!_.isEmpty(watch('imageDetails')) ? (
                                    <div className='flex mb-6 gap-4 items-center'>
                                        <img
                                            src={watch('imageDetails')?.image_url}
                                            alt=''
                                            height={watch('imageDetails')?.displaySize.height}
                                            width={watch('imageDetails')?.displaySize.width}
                                        />

                                        <div className='justify-center items-center'>
                                            <div
                                                className='mb-2'
                                                onClick={() => setShowUploadImage((prevState) => !prevState)}>
                                                <LargeTertiaryButton
                                                    label='Replace image'
                                                    isDefault={false}
                                                />
                                            </div>

                                            <div
                                                className='mb-2'
                                                onClick={() => setShowSelectFromLibrary((prevState) => !prevState)}>
                                                <LargePrimaryButton
                                                    label='Select from library'
                                                    isDefault={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className='mb-6'>
                                            <div className='flex flex-row paragraph-medium-regular md:block'>
                                                <button
                                                    className='justify-center h-12 border-neutral-300 rounded-md border max-w-[197px] w-full mr-2 md:mr-0 md:mb-2 md:block'
                                                    onClick={() => setShowUploadImage((prevState) => !prevState)}>
                                                    Upload an image
                                                </button>
                                                <button
                                                    className='justify-center h-12 border-neutral-300 rounded-md border max-w-[197px] w-full ml-2 md:ml-0 md:block'
                                                    onClick={() => setShowSelectFromLibrary(true)}>
                                                    Select from library
                                                </button>
                                            </div>

                                            {errors?.imageDetails?.message && (
                                                <span>
                                                    <ErrorMessage errors={errors?.imageDetails} />
                                                </span>
                                            )}
                                        </div>
                                    </>
                                )}

                                <InputSection
                                    name='internalName'
                                    title='Internal name'
                                    description='Internal name will be used to search the item.'
                                    placeholder='Enter internal name'
                                    shadow='shadow-smallDropDownShadow'
                                    marginBetween='mb-4'
                                    className='mb-6'
                                    rules={{ required: 'Please enter a internal name' }}
                                />

                                <TitleDescription
                                    className='mb-2'
                                    title='Title'
                                    description='Please enter the category titles that will be displayed with the category image in the mobile application.'
                                />

                                <div className='flex flex-row items-start mb-6 md:block gap-3'>
                                    {availableLanguages.map((el, index) => {
                                        return (
                                            <div
                                                className='w-1/2 lg:w-full lg:max-w-[312px] md:max-w-full md:mr-0 md:mb-2 relative'
                                                key={index}>
                                                <InputSection
                                                    boxHeight='[52px]'
                                                    labelMarginB='mb-2'
                                                    label={`(${el.language_label})`}
                                                    labelStyle='paragraph-medium-italic text-neutral-500'
                                                    placeholder={`Enter title in ${el.language_label}`}
                                                    shadow='shadow-smallDropDownShadow'
                                                    onFocus={() => setRenderPageOnMobileScreen('home')}
                                                    name={`title.${el.id}`}
                                                    rules={{ required: `Please enter title in ${el.language_label}` }}
                                                />

                                                {errors?.title?.[el.id]?.message && (
                                                    <ErrorMessage
                                                        errors={errors?.title?.[el.id]}
                                                        className='absolute -bottom-5'
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>

                                <TitleDescription
                                    className='mb-2'
                                    title='Description'
                                    description='Please enter the description of the category that will be displayed in the mobile application.'
                                />

                                <div className='flex flex-row items-start md:block gap-3'>
                                    {availableLanguages.map((el, index) => {
                                        return (
                                            <div
                                                className='w-1/2 md:mb-2 md:w-full'
                                                key={index}>
                                                <InputAreaSection
                                                    name={`description.${el.id}`}
                                                    onFocus={() => setRenderPageOnMobileScreen('category')}
                                                    placeholder={`Enter description in ${el.language_label}`}
                                                    paddingT='pt-4'
                                                    label={`(${el.language_label})`}
                                                    shadow='shadow-smallDropDownShadow'
                                                    labelStyle='paragraph-medium-italic text-neutral-500'
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className='w-full flex justify-center pb-10'>
                                <div className='flex flex-col gap-4 items-center h-full'>
                                    <MobileScreenAddCategoryDish
                                        categoryTitle={watch('title')?.[selectedLanguageForMobile?.value?.id]}
                                        image={watch('imageDetails')?.image_url}
                                        renderPageOnMobileScreen={renderPageOnMobileScreen}
                                        categoryDetails={watch('description')?.[selectedLanguageForMobile?.value?.id]}
                                        hasMask={!!watch('advanceSetting')?.mask?.value}
                                        hideTile={!watch('advanceSetting')?.tileDetails?.value}
                                        maskPosition={watch('advanceSetting')?.tilePosition?.value}
                                    />

                                    <DetailsDropDown
                                        menuItem={languageMenuItem}
                                        handleChangeDropDown={(selection) => setSelectedLanguageForMobile(selection)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showUploadImage && (
                <UploadImagePopup
                    backToPage='add category'
                    handleClickClose={() => setShowUploadImage((prevState) => !prevState)}
                    selectedType={{ label: 'Category', value: 'categories' }}
                    aspectRatio={0.938} // 1000 - width  1066 - height
                    setUploadedImageDetails={(imageDetails) => formateImageDetails(imageDetails)}
                />
            )}

            {showSelectFromLibrary && (
                <SelectImageFromLibraryPopup
                    handleClickClose={() => setShowSelectFromLibrary(false)}
                    handleSelectImage={(imageDetails) => formateImageDetails(imageDetails)}
                />
            )}
        </>
    );
}
