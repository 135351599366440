import React, { useCallback, useState } from 'react';
import { ReactComponent as LeftArrow } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { DefaultInputField } from '../../../../Components/InputField/InputField';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../Components/Buttons/Button';
import '../../../../index';
import 'react-phone-number-input/style.css';
import { ListViewOutletPermission } from './ListViewUserSetting';
import { OutletPermissionTable } from './ListTable';
import PhoneInputDropdown from '../../../../Components/MobileNumberInputFlagDropDown/Dropdown';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { ValidateUserPhoneNumber } from './ValidateUserPhoneNumber';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';
import { InputField } from '../../../Login/Components/Components';
import { addPreset, addUser, editPreset, editUser, linkUserToOutlet } from './ApiFunctions';
import ChecBoxTable from './ChecBoxTable';
import SearchableDropdownWithWindowing from '../../../../Components/SearchableDropdownWithWindowing/SearchableDropdownWithWindowing';
import { ContentLoaderWithCheckbox } from '../../../../Components/ContentLoader/ContentLoaderWithCheckbox';
import CircleLoader from '../../../../Components/CircleLoader/CircleLoader';
import { countryCodes } from '../../../../Components/MobileNumberInputFlagDropDown/CountryData';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { compareValues } from '../HelperFunctions/HelperFunction';
export const UserPopup = (props) => {
    const {
        isEditUser,
        handleClickClose,
        presetList,
        handlePagination,
        selectedPage,
        handleClickAddRole,
        popupStatus,
        handleClickEditPresetList,
        loaderStatus,
        setloaderStatus,
    } = props;

    const methods = useFormContext();
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = methods;
    const [validatedNumber, mobileNumber, otp] = watch(['defaultvalue.validatedNumber', 'mobileNumber', 'otp']);
    const [isShowMobileNumberPopup, setIsShowMobileNumberPopup] = useState(false);
    const [isValidInput, setIsValidInput] = useState(true);
    const [countryData, setCountryData] = useState(
        countryCodes.find((country) => '+' + country.code == (watch('country_code') ?? '+91'))
    );

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {popupStatus.addUserPopup && (
                <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex p-4 md:p-0 md:absolute md:z-[9] overflow-auto md:overflow-visible'>
                    <div className='max-w-[830px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:mt-0 md:w-full md:p-4 lg:max-w-[439px] md:max-w-full md:rounded-none'>
                        {isMobileScreen && (
                            <div
                                className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                                onClick={handleClickClose}>
                                <LeftArrow className='rotate-90' />
                                <span className='pl-1'>Back to user list</span>
                            </div>
                        )}

                        <div className='flex flex-row justify-between items-center border-neutral-300 mb-6 lg:mb-4'>
                            <div>
                                <span className='paragraph-large-medium'>{isEditUser ? 'Edit User' : 'Add user'}</span>
                                <div className='flex flex-row items-center'>
                                    <span className='paragraph-medium-italic text-neutral-500'>
                                        {isEditUser
                                            ? 'Edit existing user profile'
                                            : 'Create a new user profile to add them to the application'}
                                    </span>
                                </div>
                            </div>

                            <div
                                onClick={handleClickClose}
                                className='md:hidden cursor-pointer'>
                                <CloseIcon />
                            </div>
                        </div>

                        <div className='flex flex-row items-center mb-4 lg:block md:mb-2'>
                            <div className='w-1/2 mr-2 lg:w-full lg:mr-0 lg:mb-2 md:mb-4'>
                                <Controller
                                    defaultValue={watch('defaultvalue.user_name')} // Pass default value here
                                    name={`user_name`}
                                    control={control}
                                    rules={{
                                        required: 'User Name is required',
                                    }}
                                    render={({ field }) => (
                                        <DefaultInputField
                                            placeholder='Enter first name'
                                            label='User name'
                                            shadow='shadow-xSmall'
                                            value={field.value}
                                            enteredValue={field.onChange}
                                        />
                                    )}
                                />

                                {errors?.user_name && <ErrorMessage errors={errors?.user_name} />}
                            </div>
                            <div className='w-1/2 ml-2 relative lg:w-full lg:ml-0'>
                                <Controller
                                    render={({ field }) => (
                                        <SearchableDropdownWithWindowing
                                            menuItems={presetList?.data?.map((item) => ({
                                                label: item.name,
                                                value: item.name,
                                            }))}
                                            fetchNewData={handlePagination}
                                            isLoadingMenuItems={false}
                                            pageState={{ lastPage: presetList?.last_page, page: selectedPage }}
                                            handleSelect={field.onChange}
                                            defaultValue={field.value}
                                            showDisplayValue={true}
                                            placeholder={!isEditUser && 'Enter user role'}
                                            label='User Role'
                                            action='+ Add New Role'
                                            handleClickAction={handleClickAddRole}
                                        />
                                    )}
                                    name={`user_role`}
                                    control={control}
                                    rules={{ required: 'User role is required' }}
                                    defaultValue={watch('defaultvalue.user_role')}
                                />
                                {errors?.user_role && <ErrorMessage errors={errors?.user_role} />}
                            </div>
                        </div>

                        <div className='flex flex-row items-center mb-3.5 lg:block md:mb-2'>
                            <div className='w-1/2 mr-2 lg:w-full lg:mr-0 lg:mb-2 md:mb-4'>
                                <Controller
                                    render={({ field }) => (
                                        <DefaultInputField
                                            placeholder='Enter email ID'
                                            label='Email ID'
                                            shadow='shadow-xSmall'
                                            value={field.value}
                                            enteredValue={field.onChange}
                                        />
                                    )}
                                    name='user_email_Id'
                                    rules={{
                                        required: 'Email Id is required',
                                        pattern: {
                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: 'Please enter a valid email',
                                        },
                                    }}
                                    control={control}
                                    defaultValue={watch('defaultvalue.user_email_Id')}
                                />

                                {errors?.user_email_Id && <ErrorMessage errors={errors?.user_email_Id} />}
                            </div>

                            <div className='w-1/2 ml-2 relative lg:w-full lg:ml-0 remove-spin-button'>
                                <Controller
                                    render={({ field }) => (
                                        <InputField
                                            inputValue={field.value}
                                            setValue={setValue}
                                            showMobileIcon={false}
                                            isMobileNumberPage={true}
                                            isValidInput={isValidInput}
                                            setIsValidInput={setIsValidInput}
                                            onInputClick={() => {
                                                setIsShowMobileNumberPopup(true);
                                            }}
                                            paddingLeft='pl-[105px]'
                                            mobileNumberFIeldName={'mobileNumber'}
                                            otpFieldName={'otp'}
                                        />
                                    )}
                                    name={`validatedNumber`}
                                    control={control}
                                    rules={{ required: 'mobile number is required' }}
                                    defaultValue={validatedNumber ?? ''}
                                />
                                <div
                                    className={`phoneinputbox absolute ${
                                        errors?.validatedNumber ? 'bottom-8' : 'bottom-3'
                                    } left-4`}
                                    onClick={() => setIsShowMobileNumberPopup(true)}>
                                    <PhoneInputDropdown
                                        value={countryData?.code}
                                        disabled={true}
                                        countryData={countryData}
                                        setCountryData={setCountryData}
                                    />
                                </div>
                                {errors?.validatedNumber && <ErrorMessage errors={errors?.validatedNumber} />}

                                {isShowMobileNumberPopup && (
                                    <ValidateUserPhoneNumber
                                        setIsShowMobileNumberPopup={setIsShowMobileNumberPopup}
                                        watch={watch}
                                        mobileNumber={mobileNumber}
                                        otp={otp}
                                        isValidInput={isValidInput}
                                        setIsValidInput={setIsValidInput}
                                        countryData={countryData}
                                        setCountryData={setCountryData}
                                        methods={methods}
                                        loaderStatus={loaderStatus}
                                        setloaderStatus={setloaderStatus}
                                    />
                                )}
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-center md:fixed md:bottom-0 md:left-0 md:right-0 md:mb-0 md:shadow-dropShadow md:bg-white md:px-4 md:pt-2 md:pb-1'>
                            <span className='paragraph-large-medium'>Step 1/3</span>
                            <div
                                className='min-w-[164px] cursor-pointer'
                                onClick={handleSubmit(handleClickEditPresetList)}>
                                <LargePrimaryButton
                                    label='Proceed'
                                    isDefault={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export const CreatePreset = (props) => {
    const { handleClickClose, handleArrayState, arrayState, setloaderStatus, loaderStatus } = props;
    const { isLoading, isResponseSuccess, isClicked } = loaderStatus;
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setError,
        clearErrors,
        getValues,
    } = useFormContext();

    const error = { message: 'Atleast one permission required' };

    const isMobileScreen = IsMobileScreen();
    const onSubmit = () => {
        if (arrayState.checkedPresets.length < 1) {
            setError('permission_list', error);
            return;
        }
        const requestedBody = {
            name: getValues('preset_name'),
            permissions: [...arrayState.checkedPresets],
        };
        addPreset({
            requestedBody,
            handleClickClose,
            handleArrayState,
            setloaderStatus,
            setError,
            selectedRestaurantId,
        });
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex overflow-auto p-4 md:p-0 md:absolute md:z-[9] md:overflow-visible'>
                <div className='max-w-[688px] w-full rounded-xl h-7/3 md:h-full bg-shades-50 px-8 py-6 m-auto md:w-full md:p-4 md:max-w-full md:rounded-none'>
                    {isMobileScreen && (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrow className='rotate-90' />
                            <span className='pl-1'>Back to preset list</span>
                        </div>
                    )}

                    <div className='flex flex-row justify-between items-center border-neutral-300 mb-6'>
                        <div>
                            <span className='paragraph-large-medium'>Create preset</span>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-medium-italic text-neutral-500'>
                                    Select a set of permissions you would like to add to the new preset
                                </span>
                            </div>
                        </div>
                        <div
                            onClick={handleClickClose}
                            className='md:hidden cursor-pointer'>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='mb-4'>
                        <Controller
                            render={({ field }) => (
                                <DefaultInputField
                                    placeholder='Enter preset name'
                                    shadow='shadow-xSmall'
                                    value={watch('defaultvalue.preset_name')}
                                    enteredValue={field.onChange}
                                    clearErrors={() => clearErrors('addPresetError')}
                                />
                            )}
                            name={`preset_name`}
                            control={control}
                            rules={{ required: 'Preset Name is required' }}
                        />
                        {errors?.preset_name && <ErrorMessage errors={errors?.preset_name} />}
                    </div>

                    {arrayState.permissionList?.permissions?.length ? (
                        <ChecBoxTable
                            module='Access module'
                            errors={errors}
                            handleArrayState={handleArrayState}
                            clearErrors={clearErrors}
                            arrayState={arrayState}
                            checkedPresets={[]}
                        />
                    ) : (
                        <div className='mb-6'>
                            <ContentLoaderWithCheckbox columns={3} />
                        </div>
                    )}
                    {errors?.addPresetError && <ErrorMessage errors={errors?.addPresetError} />}

                    <div className='flex justify-end md:justify-center md:fixed md:bottom-0 md:left-0 md:right-0 md:mb-0 md:shadow-dropShadow md:bg-white md:px-4 md:pt-2 md:pb-1'>
                        <div
                            className='min-w-[153px] md:max-w-full md:w-full'
                            onClick={handleSubmit(onSubmit)}>
                            <LargePrimaryButton
                                label={
                                    isClicked ? (
                                        <CircleLoader
                                            isLoading={isLoading}
                                            isResponseSuccess={isResponseSuccess}
                                        />
                                    ) : (
                                        'Save as preset'
                                    )
                                }
                                isClicked={isClicked}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const SelectAccessPermission = (props) => {
    const {
        handleClickClose,
        header,
        handleArrayState,
        handlePopStatus,
        arrayState,
        actionState,
        handleClickEditPresetList,
        setloaderStatus,
        loaderStatus,
        handlePagination,
        paginationState,
        handleClickAddRole,
        oldValue,
    } = props;

    const { isLoading, isResponseSuccess, isClicked } = loaderStatus;
    const [isShowOutletPopup, setIsShowOutletPopup] = useState(false);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const handleDiscardChanges = () => {
        handleArrayState({
            array: 'checkedPresets',
            updatedValue: getValues('checkedPresetsOld'),
        });

        setValue('preset_name', getValues('defaultvalue.preset_name'));
    };
    const handleClickProceed = () => {
        setIsShowOutletPopup(!isShowOutletPopup);
    };

    const [showProceedButton, setShowProceedButton] = useState(false);
    const [showPresetDropDown, setShowPresetDropDown] = useState(false);

    const isMobileScreen = IsMobileScreen();

    const [isInputFieldDisable, setIsInputFieldDisable] = useState(true);
    const handleAddonClick = () => {
        setIsInputFieldDisable(false);
        // handleClickEditPresetList();
        setShowPresetDropDown(true);
    };
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setError,
        clearErrors,
        getValues,
        setValue,
    } = useFormContext();

    const error = { message: 'Atleast one permission required' };

    const onSubmit = async () => {
        if (arrayState.checkedPresets?.objects?.length < 1) {
            setError('permission_list', error);
            return;
        }
        const requestedBody = {
            name: getValues('preset_name'),
            permissions: [...arrayState.checkedPresets],
        };
        editPreset({
            requestedBody,
            handleClickClose,
            handleArrayState,
            presetId: getValues('preset_Id'),
            setloaderStatus,
            selectedRestaurantId,
        });
    };
    const CheckBox = useCallback(() => {
        return (
            <ChecBoxTable
                module='Access module'
                errors={errors}
                handleArrayState={handleArrayState}
                clearErrors={clearErrors}
                arrayState={arrayState}
                disabled={actionState.isShowUserList && !actionState.isEditPermiison}
                checkedPresets={arrayState.checkedPresets}
            />
        );
    }, [arrayState.checkedPresets]);

    return (
        <>
            {!isShowOutletPopup && (
                <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex overflow-auto p-4 md:p-0 md:absolute md:z-[9] md:overflow-visible'>
                    <div className='max-w-[688px] w-full rounded-xl h-7/3 md:h-full bg-shades-50 px-8 py-6 m-auto md:w-full md:p-4 md:max-w-full md:rounded-none'>
                        {isMobileScreen && (
                            <div
                                className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                                onClick={() => handlePopStatus('addUserPopup')}>
                                <LeftArrow className='rotate-90' />
                                <span className='pl-1'>Back to {header}</span>
                            </div>
                        )}

                        <div className='flex flex-row justify-between items-center border-neutral-300 mb-6 md:mb-4'>
                            <div>
                                <div className='flex flex-row'>
                                    {actionState.isShowUserList && !actionState.isEditPermiison && (
                                        <div
                                            className='cursor-pointer'
                                            onClick={() => handlePopStatus('addUserPopup')}>
                                            <LeftArrow className='rotate-90' />
                                        </div>
                                    )}
                                    <span className='paragraph-large-medium'>Select access permission</span>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <span className='paragraph-medium-italic text-neutral-500'>
                                        Select a set of permissions you would like to provide to the created user
                                    </span>
                                </div>
                            </div>
                            {(!(actionState.isShowUserList && isInputFieldDisable) ||
                                actionState.isOverWriteChanges) && (
                                <div
                                    onClick={handleClickClose}
                                    className='md:hidden cursor-pointer'>
                                    <CloseIcon />
                                </div>
                            )}
                        </div>

                        {!showPresetDropDown ? (
                            <div className='mb-4'>
                                <Controller
                                    render={({ field }) => (
                                        <DefaultInputField
                                            placeholder='Enter preset name'
                                            shadow='shadow-smallDropDownShadow'
                                            value={watch('preset_name') ?? watch('user_role')}
                                            enteredValue={field.onChange}
                                            disabled={isInputFieldDisable}
                                            handleClickAddon={() =>
                                                actionState.isShowUserList
                                                    ? handleAddonClick()
                                                    : setIsInputFieldDisable(!isInputFieldDisable)
                                            }
                                            diableInputStyle='text-neutral-900'
                                            addon='Change'
                                            boxHeight='[52px]'
                                            addonStyle='text-primary-500 font-normal text-sm leading-4 mt-0.5 border-b border-primary-500'
                                        />
                                    )}
                                    name={`preset_name`}
                                    control={control}
                                    rules={{ required: 'Preset Name is required' }}
                                />
                                {errors?.preset_name && <ErrorMessage errors={errors?.preset_name} />}
                            </div>
                        ) : (
                            <div className='mb-4 relative lg:w-full lg:ml-0'>
                                <Controller
                                    render={({ field }) => (
                                        <SearchableDropdownWithWindowing
                                            menuItems={arrayState.presetList?.data?.map((item) => ({
                                                label: item.name,
                                                value: item.name,
                                            }))}
                                            fetchNewData={handlePagination}
                                            isLoadingMenuItems={false}
                                            pageState={{
                                                lastPage: arrayState.presetList?.last_page,
                                                page: paginationState.selectedPage,
                                            }}
                                            handleSelect={(value) => {
                                                field.onChange(value);
                                                handleClickEditPresetList();
                                                setShowPresetDropDown(false);
                                            }}
                                            defaultValue={watch('user_role')}
                                            showDisplayValue={true}
                                            placeholder={!actionState.isEditUser && 'Enter preset name'}
                                            label='User Role'
                                            action='+ Add New Role'
                                            handleClickAction={handleClickAddRole}
                                        />
                                    )}
                                    name={`user_role`}
                                    control={control}
                                    rules={{ required: 'User role is required' }}
                                />
                                {errors?.user_role && <ErrorMessage errors={errors?.user_role} />}
                            </div>
                        )}
                        {arrayState.permissionList?.permissions?.length ? (
                            <CheckBox />
                        ) : (
                            <div className='mb-9'>
                                <ContentLoaderWithCheckbox columns={3} />
                            </div>
                        )}

                        {actionState.isShowUserList && !actionState.isEditPermiison ? (
                            <div className='flex flex-row justify-between items-center md:fixed md:bottom-0 md:left-0 md:right-0 md:mb-0 md:shadow-dropShadow md:bg-white md:px-4 md:pt-2 md:pb-1'>
                                <span className='paragraph-large-medium'>Step 2/3</span>
                                <div className='flex flex-row'>
                                    <div
                                        className='mr-4'
                                        onClick={() => handlePopStatus('showChangeRolePopup')}>
                                        {/* showChangeRolePopup */}
                                        <LargePrimaryButton label='Overwrite changes' />
                                    </div>
                                    <div onClick={handleClickProceed}>
                                        <LargePrimaryButton
                                            label='Proceed'
                                            isDefault={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='flex justify-end md:fixed md:bottom-0 md:left-0 md:right-0 md:mb-0 md:shadow-dropShadow md:bg-white md:px-4 md:pt-2 md:pb-1 md:justify-center mobile:px-2'>
                                {(!_.isEqual(arrayState.checkedPresets, watch('checkedPresetsOld')) ||
                                    watch('preset_name') != watch('defaultvalue.preset_name')) && (
                                    <div className='flex flex-row md:w-full '>
                                        <div
                                            className='mr-4 min-w-[168px] md:w-1/2 md:min-w-0 mobile:mr-2'
                                            onClick={handleDiscardChanges}>
                                            <LargeDestructiveButton label='Discard changes' />
                                        </div>
                                        <div
                                            className='md:w-1/2'
                                            onClick={handleSubmit(onSubmit)}>
                                            <LargePrimaryButton
                                                label={
                                                    isClicked ? (
                                                        <CircleLoader
                                                            isLoading={isLoading}
                                                            isResponseSuccess={isResponseSuccess}
                                                        />
                                                    ) : (
                                                        'Save changes'
                                                    )
                                                }
                                                isClicked={isClicked}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
            {isShowOutletPopup && (
                <OutletPermission
                    handleClickClose={handleClickClose}
                    showBackButton={true}
                    watch={watch}
                    arrayState={arrayState}
                    handleArrayState={handleArrayState}
                    actionState={actionState}
                    setIsShowOutletPopup={setIsShowOutletPopup}
                    loaderStatus={loaderStatus}
                    setloaderStatus={setloaderStatus}
                    oldValue={oldValue}
                />
            )}
        </>
    );
};

export const OutletPermission = (props) => {
    const {
        handleClickClose,
        hideLabel,
        page,
        showBackButton = false,
        arrayState,
        handleArrayState,
        watch,
        setIsShowOutletPopup,
        actionState,
        loaderStatus,
        oldValue,
        setloaderStatus,
    } = props;
    const { isClicked, isLoading, isResponseSuccess } = loaderStatus;
    const [showError, setShowError] = useState(false);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const handleToggleSwitch = ({ item, checked }) => {
        setShowError(false);
        handleArrayState({
            array: 'selectedOutletForPermission',
            updatedValue: (prevState) => {
                const updatedOutletList = checked
                    ? [...(prevState?.outletList ?? []), { id: item?.outletId }]
                    : (prevState?.outletList?.filter((outlet) => outlet?.id !== item?.outletId) ?? []);

                return { ...(prevState ?? []), outletList: updatedOutletList };
            },
        });
    };

    const handleClickOnSave = (id) => {
        const requestedBody = {
            outlet_ids: arrayState?.selectedOutletForPermission?.outletList?.map((item) => item?.id),
        };
        linkUserToOutlet({
            id: id,
            requestedBody,
            handleClickClose,
            handleArrayState,
            setloaderStatus,
            selectedRestaurantId,
        });
    };

    const handleClickAddEditUser = () => {
        const userData = watch();
        const requestedBody = {
            name: userData?.user_name,
            email: userData?.user_email_Id,
            phone_detail_id: userData?.phone_detail_id ?? watch('phone_detail_id'),
            custom_role_id: arrayState.presetList?.data?.find((item) => item?.name == userData?.user_role)?.id,
        };
        actionState.isEditMode
            ? editUser({
                  requestedBody,
                  handleClickClose,
                  handleArrayState,
                  id: userData?.defaultvalue?.user_id,
                  setloaderStatus,
                  selectedRestaurantId,
              })
            : addUser({
                  requestedBody,
                  handleClickClose,
                  handleArrayState,
                  setloaderStatus,
                  selectedRestaurantId,
              }).then((response) => {
                  if (response?.success) {
                      handleClickOnSave(response?.user?.id);
                  }
              });
    };
    const isMobileScreen = IsMobileScreen();
    const outletlist = useSelector((state) => state.outlet.outletList);

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex overflow-auto p-4 md:p-0 md:absolute md:overflow-visible'>
                <div className='max-w-[1102px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:p-4 lg:max-w-[710px] md:max-w-full md:rounded-none md:m-0'>
                    {isMobileScreen && (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrow className='rotate-90' />
                            <span className='pl-1'>Back to select access permission</span>
                        </div>
                    )}

                    <div className='flex justify-between items-center mb-6 md:mb-4'>
                        <div>
                            <div className='flex flex-row'>
                                {showBackButton && (
                                    <div
                                        className='cursor-pointer'
                                        onClick={() => {
                                            setIsShowOutletPopup(false);
                                        }}>
                                        <LeftArrow className='rotate-90' />
                                    </div>
                                )}
                                <h3 className='paragraph-large-medium'>Outlet permission</h3>
                            </div>
                            <div className='flex flex-row items-center'>
                                <p className='paragraph-medium-italic text-neutral-500'>
                                    Link the user with the desired outlets
                                </p>

                                <div className='w-1 h-1 rounded-full bg-neutral-500 mx-3 md:hidden' />
                                <span className='paragraph-medium-italic text-neutral-500 md:hidden'>
                                    Outlet selected - {arrayState.selectedOutletForPermission?.outletList?.length}
                                </span>
                            </div>
                        </div>
                        {!showBackButton && (
                            <span
                                className='cursor-pointer md:hidden'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </span>
                        )}
                    </div>

                    {!isMobileScreen ? (
                        <OutletPermissionTable
                            linkUserData={arrayState.selectedOutletForPermission?.outletList ?? []}
                            handleToggleSwitch={handleToggleSwitch}
                            outletlist={outletlist}
                        />
                    ) : (
                        <div className='pb-20'>
                            {outletlist.map((el, index) => (
                                <div
                                    className='mt-2'
                                    key={index}>
                                    <ListViewOutletPermission
                                        linkUserData={arrayState.selectedOutletForPermission?.outletList ?? []}
                                        outletName={el?.displayName}
                                        state={el?.outletData?.outlet_address?.translations?.state[1]}
                                        city={el?.outletData?.outlet_address?.translations?.city[1]}
                                        address={
                                            el?.outletData?.outlet_address?.translations?.address_line_1[1] +
                                            el?.outletData?.outlet_address?.translations?.address_line_2[1]
                                        }
                                        handleToggleSwitch={handleToggleSwitch}
                                        object={el}
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                    {showError && <ErrorMessage errors={{ message: 'Please Select atleast One Outlet' }} />}

                    <div className='flex flex-row justify-between items-center md:fixed md:bottom-0 md:left-0 md:right-0 md:mb-0 md:shadow-dropShadow md:bg-white md:px-4 md:pt-2 md:pb-1 md:justify-between'>
                        <span className={`paragraph-large-medium ${hideLabel}`}>Step 3/3</span>
                        <div
                            className='min-w-[164px] cursor-pointer'
                            onClick={() =>
                                page === 'settingsOutletpermission'
                                    ? handleClickOnSave(arrayState.selectedOutletForPermission?.id)
                                    : handleClickAddEditUser()
                            }>
                            <LargePrimaryButton
                                label={
                                    isClicked ? (
                                        <CircleLoader
                                            isLoading={isLoading}
                                            isResponseSuccess={isResponseSuccess}
                                        />
                                    ) : page === 'settingsOutletpermission' ? (
                                        'Save Changes'
                                    ) : actionState.isEditMode ? (
                                        'Edit user'
                                    ) : (
                                        'Add user'
                                    )
                                }
                                isDefault={false}
                                disabled={
                                    arrayState?.selectedOutletForPermission &&
                                    (arrayState?.selectedOutletForPermission?.outletList?.length < 1 ||
                                        arrayState?.selectedOutletForPermission?.outletList ==
                                            arrayState?.selectedOutletForPermission?.oldData) &&
                                    compareValues(watch(), oldValue)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
