import React, { useState } from 'react';
import CategorySelection from './CategorySelection';
import DishSection from './DishSection';
import { useWindowSize } from '@uidotdev/usehooks';

export default function DishesRearrange(props) {
    const { handleClickClose, selectedCategoryDish, setIsOperationLoading } = props;

    const [selectedCategory, setSelectedCategory] = useState(null);

    const screenSize = useWindowSize();

    return (
        <>
            <div className='mt-6 mb-4'>
                <CategorySelection
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                />
            </div>

            {!!selectedCategory ? (
                <DishSection
                    handleClickClose={handleClickClose}
                    selectedCategory={selectedCategory}
                    selectedCategoryDish={selectedCategoryDish}
                    setIsOperationLoading={setIsOperationLoading}
                />
            ) : (
                <div
                    className='m-auto flex justify-center min-w-[411px] items-center text-xl font-bold '
                    style={{ height: screenSize.height - 290 }}>
                    Select category to re-arrange dishes
                </div>
            )}
        </>
    );
}
