import React from 'react';
import { useSelector } from 'react-redux';
import { LargeTertiaryButton } from '../../../../Components/Buttons/Button';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';

export default function SelectImageSection(props) {
    const { handleClickUploadImage, setShowLibraryImagePopupLanguageId } = props;

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const {
        watch,
        formState: { errors },
        clearErrors,
    } = useFormContext();

    const handleClickUpload = (languageId) => {
        handleClickUploadImage(languageId);

        clearErrors(`errors.imageSelection[${languageId}]`);
    };

    return (
        <>
            {availableLanguages.map((el, index) => {
                return (
                    <div
                        className='mb-4'
                        key={index}>
                        <div className='mb-2'>
                            <span className='paragraph-small-medium'>
                                {el.language_label !== 'English' && `${el.language_label}:`} Select image
                            </span>

                            <span className='paragraphSmallItalic text-neutral-500 ml-1 md:hidden'>
                                (1080px x 1080px recommended)
                            </span>
                        </div>

                        {!watch('selectedImage')?.[el?.language_id] ? (
                            <>
                                <div className='flex flex-row paragraph-medium-regular mobile:text-sm'>
                                    <button
                                        className='justify-center h-12 border-neutral-300 rounded-md border w-1/2 mr-[8.5px] mobile:mr-1 cursor-pointer'
                                        onClick={() => {
                                            handleClickUpload(el.language_id);
                                        }}>
                                        Upload an image
                                    </button>

                                    <button
                                        className='justify-center h-12 border-neutral-300 rounded-md border w-1/2 ml-[8.5px] mobile:ml-1 cursor-pointer'
                                        onClick={() => setShowLibraryImagePopupLanguageId(el.language_id)}>
                                        Select from library
                                    </button>
                                </div>

                                {errors?.imageSelection?.[el.language_id] && (
                                    <ErrorMessage errors={errors?.imageSelection?.[el.language_id]} />
                                )}
                            </>
                        ) : (
                            <div className='flex flex-row justify-between items-center'>
                                <img
                                    alt='imageData'
                                    className='w-[197px] h-[109px] rounded-md md:mr-[17px] md:min-w-[163px] mobile:min-w-0'
                                    src={watch('selectedImage')?.[el.language_id]?.image_url}
                                />

                                <div className='max-w-[197px] w-full md:max-w-full'>
                                    <div
                                        className='mb-2'
                                        onClick={() => handleClickUpload(el.language_id)}>
                                        <LargeTertiaryButton label='Upload image' />
                                    </div>
                                    <div
                                        className=''
                                        onClick={() => setShowLibraryImagePopupLanguageId(el.language_id)}>
                                        <LargeTertiaryButton label='Replace image' />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
}
