import React, { useEffect, useState } from 'react';
import { ReactComponent as VegIcon } from '../../../Assets/vegetable-icon.svg';
import { ReactComponent as NonVegIcon } from '../../../Assets/non-veg.svg';
import { ReactComponent as LeftArrowIcon } from '../../../Assets/chevron-down.svg';
import { IsIpadScreen, IsMobileScreen } from '../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';

export default function DishesSummery(props) {
    const { handleClickBack, showFullScreen, dishesSummary } = props;

    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const screenHeight = useWindowSize().height;
    const isIpadScreen = IsIpadScreen();
    const isMobileScreen = IsMobileScreen();

    const initialHeight =
        isIpadScreen && !showFullScreen ? 350 : !isIpadScreen && showFullScreen ? 260 : isIpadScreen ? 310 : 310;

    const additionalHeight = scrollY < 140 ? scrollY : isIpadScreen && !showFullScreen ? 200 : isIpadScreen ? 190 : 140;

    const maxHeightOfDishSummery = screenHeight - initialHeight + additionalHeight;

    const totalQuantity = dishesSummary.reduce((total, dish) => total + dish.quantity, 0);

    return (
        <>
            <div
                className='hidden md:flex paragraph-medium-medium flex-row mb-4 pt-4 cursor-pointer'
                onClick={handleClickBack}>
                <LeftArrowIcon className='rotate-90' />
                <span className='ml-1'>Back to KDS</span>
            </div>

            <div className='pr-[15px] lg:pr-0'>
                <div className='pb-4 border-b border-neutral-300 my-4 pl-4 lg:pl-7 md:pl-0'>
                    <span className='paragraph-large-semi-bold mr-2'>Dishes summary</span>
                    <span className='paragraph-medium-italic text-neutral-500'>({totalQuantity} dish preparing)</span>
                </div>
            </div>

            <div className='flex flex-row justify-between mb-4 max-w-[315px] mx-0 px-0 pr-0 w-full ml-4 lg:ml-7 md:ml-0 md:max-w-full md:hidden lg:max-w-full lg:pr-12'>
                <span className='text-base leading-4 border-b border-neutral-900'>Ordered dishes</span>
                <span className='text-base leading-4 border-b border-neutral-900'>Quantity</span>
            </div>

            <div
                className='scrollbar-style overflow-auto pl-4 lg:pl-7 md:pl-0 md:overflow-hidden md:h-full h-full -mr-[18px] md:mr-0'
                style={{ maxHeight: !isMobileScreen && maxHeightOfDishSummery }}>
                <div className='mr-6 lg:mr-[20px] md:mr-0'>
                    {dishesSummary.map((el, index) => {
                        return (
                            <div
                                className='w-full flex flex-row justify-between items-start mb-4 pb-4 border-b border-neutral-300 min-w-[297px] last:border-b-0 last:mb-0 lg:min-w-0'
                                key={index}>
                                <div className='flex flex-row items-center'>
                                    <div className='paragraph-medium-semi-bold mr-2'>
                                        {el?.orderedDishes}
                                        <span className='ml-2 inline-block align-middle'>
                                            {el?.type === 'veg' ? <VegIcon /> : <NonVegIcon />}
                                        </span>
                                    </div>
                                </div>

                                <span className='paragraph-medium-semi-bold min-w-[70px] sm:min-w-[34px] text-right'>
                                    {el?.quantity}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
