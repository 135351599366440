import React, { useState } from 'react';
import { ReactComponent as Arrow } from '../../Assets/arrow.svg';
import Table from './Components/Table';
import PaymentReports from './Components/PaymentReports';
const Reports = () => {
    const sidebarTabs = [
        { label: 'Infrastructure', value: 'infrastructure_credit_report' },
        { label: 'Abandoned Carts', value: 'abandoned_carts_report' },
        { label: 'Service Credit', value: 'service_credit_report' },
        { label: 'Customer Credit', value: 'customer_list_report' },
        { label: 'Customer Credit_Orders', value: 'customer_orders_list_report' },
        { label: 'Loyalty Report', value: 'loyalty_cashback_report' },
        { label: 'CashBack', value: 'cashback_report' },
        { label: 'Past Orders_List', value: 'order_rating_list_report' },
        { label: 'Failed Orders_List', value: 'failed_orders_report' },
        { label: 'Rider List', value: 'rider_list_report' },
    ];
    const [activeTab, setActiveTab] = useState(sidebarTabs[0].label);
    const handleTabClick = (tab) => {
        setActiveTab(tab.label);
    };
    const getActiveTabValue = () => {
        return sidebarTabs.find((tab) => tab.label === activeTab)?.value || '';
    };
    return (
        <div className='bg-[#fafafa]'>
            <div className={`flex flex-col h-screen w-full max-w-[1336px] mx-auto bg-white`}>
                <div className='flex h-1/2'>
                    {/* Sidebar */}
                    <div className='2xl:w-1/4 xl:-w-1/3 overflow-auto scrollbar-style p-2 cursor-pointer'>
                        <ul>
                            {sidebarTabs.map((tab) => (
                                <li
                                    key={tab?.value}
                                    className='border-b lg:text-base md:text-sm sm:text-xs text-lg mt-4 flex flex-row justify-between items-center'>
                                    <span
                                        className={`w-full text-left px-4 py-2 flex justify-between items-center hover:text-primary-500 transition-colors ${
                                            activeTab === tab.value ? 'text-primary-500 font-medium' : 'text-black'
                                        }`}
                                        onClick={() => handleTabClick(tab)}>
                                        {tab.label}
                                    </span>
                                    <Arrow className='rotate-180 m-auto w-4 h-4 fill-current text-white largeTablet:hidden' />
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* Screen to render */}
                    <div className='scrollbar-style w-full xl:p-2 lg:p-1 overflow-auto'>
                        <div className='p-4'>
                            <PaymentReports
                                tabName={activeTab}
                                reportType={getActiveTabValue()}
                            />
                        </div>
                    </div>
                </div>
                <div className='h-1/2 w-full overflow-auto p-4'>
                    <span className='text-2xl font-bold p-4'>Recently Generated Reports</span>
                    <Table />
                </div>
            </div>
        </div>
    );
};

export default Reports;
