import React from 'react';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { ReactComponent as VegIcon } from '../../../../Assets/vegetable-icon.svg';
import { RadioButton } from '../../../../Components/FormControl/FormControls';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function CustomizeVariantPopUp(props) {
    const { handleClickClose, setShowPopup } = props;

    const pizzaInformation = [
        { name: 'Personal giant size (22.5 cm)', price: '₹0.00/-', stock: 1 },
        { name: 'Regular size (Serves 1, 17.7 cm)', price: '₹10.00/-', stock: 2 },
        { name: 'Medium size (Serves 2, 24.5 cm)', price: '₹100.00/-', stock: 1 },
        { name: 'Large size (Serves 4, 33 cm)', price: '₹120.00/-', stock: 0 },
        { name: 'The giant size (Serves 8, 45.7 cm)', price: '₹150.00/-', stock: 3 },
        { name: 'The monster size (Serves 12, 61 cm)', price: '₹200.00/-', stock: 0 },
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex justify-center items-center overflow-auto p-4'>
                <div className='max-w-[613px] w-full rounded-xl bg-shades-50 p-6 md:p-4 m-auto'>
                    <div className='flex flex-row justify-between items-center mb-6 md:mb-4'>
                        <div className='flex flex-col'>
                            {!isMobileScreen ? (
                                <span className='paragraph-large-medium'>Customize as per customer's taste</span>
                            ) : (
                                <span className='paragraph-large-medium'>Customize</span>
                            )}
                            {!isMobileScreen && (
                                <div className='flex flex-row items-center'>
                                    <span className='paragraph-medium-italic text-neutral-500'>
                                        Double Cheese Margherita
                                    </span>
                                    <div className='h-1 w-1 rounded-full bg-neutral-500 mx-3' />
                                    <span className='paragraph-medium-italic text-neutral-500'>
                                        ₹559.00 - ₹1,559.00
                                    </span>
                                </div>
                            )}
                        </div>
                        <div
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </div>
                    </div>

                    {!isMobileScreen && (
                        <>
                            <span className='paragraph-large-medium mr-2'>Select pizza base size</span>
                            <span className='paragraph-medium-italic text-neutral-500'>(Select any 1)</span>
                        </>
                    )}

                    <div className='mb-14 md:mb-[18px]'>
                        {pizzaInformation.map((el, index) => (
                            <div
                                className='flex flex-row justify-between items-center mt-4 md:block md:mt-[18px]'
                                key={index}>
                                <div className='flex flex-row items-center label-text-format'>
                                    <RadioButton
                                        label={el.name}
                                        paddingL='6 md:pl-2'
                                        optionId={index}
                                        labelStyle='paragraph-medium-regular md:block md:overflow-hidden'
                                        disabled={el.stock === 0}
                                    />
                                    <div className='ml-2'>
                                        <VegIcon />
                                    </div>
                                </div>
                                <div className='paragraph-small-regular md:pl-8 md:pt-2.5'>{el.price}</div>
                            </div>
                        ))}
                    </div>

                    <div className='flex flex-row items-center justify-between'>
                        <span className='paragraph-large-medium'>Step 1/2</span>
                        <div
                            className='min-w-[118px] cursor-pointer'
                            onClick={() => setShowPopup('customizeAddon')}>
                            <LargePrimaryButton label='Continue' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
