import React, { useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../Assets/search.svg';
import { ReactComponent as Filter } from '../../../Assets/filter.svg';
import { ReactComponent as SelectIcon } from '../../../Assets/select.svg';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as AddIcon } from '../../../Assets/add.svg';
import { ReactComponent as ExportIcon } from '../../../Assets/export.svg';
import { useWindowSize } from '@uidotdev/usehooks';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';
import APIV3 from '../../../api/axios/APIV3';
import { useSelector } from 'react-redux';

const Header = (props) => {
    const {
        activeTab,
        isShowBulkSelectButton,
        handleClickBulkButton,
        handleClickAddCustomer,
        setSearchedCustomer,
        isDisable,
        setCheckedItems,
        handleConfirmDeletePopup,
        setFilters,
        filters,
        searchedCustomer,
        setPaginationState,
    } = props;

    const pageWidths = useWindowSize().width;

    const [showFilters, setShowFilters] = useState(false);

    const [showPrimaryFilters, setShowPrimaryFilters] = useState(false);

    const [showSecondaryFilters, setShowSecondaryFilters] = useState(false);

    const [isExportDataDisable, setIsExportDataDisable] = useState(false);

    const [selectedIntialFilter, setSelectedIntialFilter] = useState({ label: 'All', value: null });

    const handleClickFilter = () => {
        setShowFilters(!showFilters);
        setShowPrimaryFilters(false);
        setShowSecondaryFilters(false);
    };

    const initialFilterSelection = [
        { label: 'All', value: null },
        { label: 'Revenue Generated', value: 'life_time_value' },
        { label: 'Last order', value: 'last_order' },
    ];

    const secondarySelectionOne = [
        { label: 'Highest first', value: 'desc' },
        { label: 'Lowest first', value: 'asc' },
    ];

    const secondarySelectionTwo = [
        { label: 'Latest first', value: 'desc' },
        { label: 'Earliest first', value: 'asc' },
    ];

    const handleSelection = (selection) => {
        if (selection.value == filters.primaryfilter.value) {
            return;
        }
        setSelectedIntialFilter(selection);
        if (selection?.value === null) {
            setShowPrimaryFilters(false);
            setShowSecondaryFilters(false);
        } else if (selection?.value === 'life_time_value') {
            setShowPrimaryFilters(true);
            setShowSecondaryFilters(false);
        } else if (selection?.value === 'last_order') {
            setShowPrimaryFilters(false);
            setShowSecondaryFilters(true);
        }
        setFilters((prevState) => ({
            ...prevState,
            primaryfilter: selection,
        }));
        setFilters((prevState) => ({
            ...prevState,
            secondaryFilter: '',
        }));
    };

    const handleSelectionSecondary = (selection) => {
        setFilters((prevState) => ({
            ...prevState,
            secondaryFilter: selection,
        }));
        setPaginationState((prevState) => ({ ...prevState, selectedPage: 1 }));
    };

    let data;

    // const handleClickExportData = async () => {
    //     data = {
    //         type: 'customer_list_report',
    //         restaurant_id: selectedRestaurantId,
    //     };

    //     if (filters.primaryfilter) {
    //         data.filter = { column: filters.primaryfilter };
    //     }

    //     if (filters.secondaryFilter) {
    //         data.filter.direction = filters.secondaryFilter;
    //     }

    //     if (searchedCustomer) {
    //         data.search = searchedCustomer;
    //     }

    //     try {
    //         setIsExportDataDisable(true);
    //         const response = await APIV3.post(`export-report`, data);

    //         if (response.success === true) {
    //             setIsExportDataDisable(false);
    //         } else {
    //             setIsExportDataDisable(false);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    return (
        <>
            <div className='flex flex-row justify-between border-b border-neutral-300 pb-4 lg:mb-[7px] md:block items-center'>
                <div className='flex flex-row items-center md:block'>
                    <div className='min-w-[375px] w-full mr-2 lg:min-w-[298px] md:max-w-full md:mr-0 mobile:min-w-0'>
                        <DefaultInputField
                            placeholder={'Search customers'}
                            placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                            enteredValue={setSearchedCustomer}
                        />
                    </div>

                    <div className='w-full ml-2 xl:ml-0 md:mt-4'>
                        {!isShowBulkSelectButton ? (
                            <div
                                className={`max-w-[156px] md:max-w-full w-full cursor-pointer`}
                                onClick={() => {
                                    handleClickBulkButton();
                                    setCheckedItems([]);
                                }}>
                                <LargePrimaryButton
                                    isDefault={false}
                                    label='Bulk select'
                                    leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                                    leftIconClick={<SelectIcon stroke='#C4BEED' />}
                                />
                            </div>
                        ) : (
                            <div className='flex flex-row items-center'>
                                <div
                                    className='max-w-[125px] md:max-w-full w-1/2 mr-4 xl:mr-2 cursor-pointer'
                                    onClick={handleClickBulkButton}>
                                    <LargeTertiaryButton
                                        isDefault={false}
                                        label='Cancel'
                                    />
                                </div>
                                <div
                                    className='max-w-[192px] md:max-w-full w-1/2'
                                    onClick={handleConfirmDeletePopup}>
                                    <LargeDestructiveButton
                                        label='Delete'
                                        disabled={isDisable}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className='flex flex-row md:mt-4'>
                    <div
                        className='mx-4 md:mx-0 md:mr-2 md:w-1/2'
                        onClick={handleClickFilter}>
                        <LargePrimaryButton
                            label='Filters'
                            leftIconDefault={<Filter fill='#FFFFFF' />}
                            leftIconClick={<Filter fill='#C4BEED' />}
                        />
                    </div>
                    {/* <div
                        className='max-w-[161px] w-full md:ml-2 md:max-w-full md:w-1/2'
                        onClick={handleClickExportData}>
                        <LargePrimaryButton
                            label='Export data'
                            leftIconDefault={<ExportIcon stroke='#FFFFFF' />}
                            leftIconClick={<ExportIcon stroke='#C4BEED' />}
                            hideLabel='lg:hidden md:block'
                            isClicked={isExportDataDisable}
                        />
                    </div> */}
                </div>
            </div>
            <div className='flex flex-row justify-end sm:block'>
                {showSecondaryFilters ? (
                    <div className='flex sm:block items-center justify-end mb-4 mr-3 md:mb-4 mt-4'>
                        <div className='flex mobile:block'>
                            <div className='w-[200px] mobile:w-full mobile:mr-0 mobile:mb-3'>
                                <DropdownWithValueLabel
                                    menuItems={secondarySelectionTwo}
                                    placeholder='Select Type'
                                    selectedItem={filters?.secondaryFilter}
                                    setSelected={(selection) => handleSelectionSecondary(selection)}
                                    label='Filter options'
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
                {showPrimaryFilters ? (
                    <div className='flex sm:block items-center justify-end mb-4 mr-3 md:mb-4 mt-4'>
                        <div className='flex mobile:block'>
                            <div className='w-[200px] mobile:w-full mobile:mr-0 mobile:mb-3'>
                                <DropdownWithValueLabel
                                    menuItems={secondarySelectionOne}
                                    placeholder='Select Type'
                                    selectedItem={filters?.secondaryFilter}
                                    setSelected={(selection) => handleSelectionSecondary(selection)}
                                    label='Filter options'
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
                {showFilters ? (
                    <div className='flex sm:block items-center justify-end mb-4 md:mb-4 mt-4'>
                        <div className='flex mobile:block md:w-full'>
                            <div className='w-[200px] mobile:w-full mobile:mr-0 mobile:mb-3'>
                                <DropdownWithValueLabel
                                    menuItems={initialFilterSelection}
                                    placeholder='Select Type'
                                    selectedItem={
                                        filters?.primaryfilter?.value?.length
                                            ? filters?.primaryfilter
                                            : initialFilterSelection[0]
                                    }
                                    setSelected={(selection) => handleSelection(selection)}
                                    label='Apply filter on'
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default Header;
