import React from 'react';
import { ReactComponent as AddIcon } from '../../../../../../Assets/add.svg';
import { ReactComponent as TrashIcon } from '../../../../../../Assets/trash.svg';
import { Controller } from 'react-hook-form';
import CountDropdown from './CountDropdown';

export const CategoryLayoutTable = (props) => {
    const { fields, append, remove, control, setShowFooter, setShowTabChangeWarningPopup } = props;

    return (
        <>
            <div className='mb-6'>
                <div className='rounded-lg border-neutral-300 border'>
                    <table className='w-full break-words table-row-bg'>
                        <thead>
                            <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                <th className='px-6 min-w-[142px] min-w-unset largeTablet:pl-4 largeTablet:pr-0 paragraph-overline-small text-neutral-700 rounded-tl-lg'>
                                    ROW NUMBER
                                </th>
                                <th className='px-6 largeTablet:pl-3 largeTablet:pr-0 min-w-[237px] pageContent:min-w-[200px] pageContent:pr-0 pageContent:pl-3 pageContentSmall:px-6 pageContentSmall:min-w-[237px] min-w-unset paragraph-overline-small text-neutral-700'>
                                    CATEGORY DISPLAY COUNT
                                </th>
                                <th className='px-6 largeTablet:pl-0 largeTablet:pr-3 paragraph-overline-small text-neutral-700 min-w-[259px] pageContentSmall:min-w-min xl:min-w-[250px] min-w-unset rounded-tr-lg'>
                                    ACTIONS
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {fields?.map((el, index) => (
                                <tr
                                    className='paragraph-small-regular text-left justify-center h-[70px] border-neutral-300 border-t first:border-none radius-td'
                                    key={el.id}>
                                    <td className='px-6 largeTablet:pl-4 largeTablet:pr-0'>
                                        {index < 10 ? '0' + (index + 1) : index + 1}
                                    </td>

                                    <td className='px-6 pageContent:pr-0 pageContent:pl-3 pageContentSmall:px-6 largeTablet:pl-3 largeTablet:pr-0'>
                                        <Controller
                                            render={({ field }) => (
                                                <CountDropdown
                                                    handleChangeCount={(value) => {
                                                        field.onChange(value);
                                                        setShowFooter(true);
                                                        setShowTabChangeWarningPopup(true);
                                                    }}
                                                    selectedItem={field.value}
                                                />
                                            )}
                                            control={control}
                                            name={`appearance.category_layout.${index}.category_display_count`}
                                        />
                                    </td>

                                    <td className='px-6 largeTablet:pl-0 largeTablet:pr-3'>
                                        <ActionComponent
                                            handleClickAddRow={() => {
                                                append({ category_display_count: 1, column_rank: index + 2 });
                                            }}
                                            handleClickDeleteRow={remove}
                                            index={index}
                                            disableDeleteOption={index === 0 && fields.length === 1}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

const ActionComponent = (props) => {
    const { handleClickAddRow, handleClickDeleteRow, index, disableDeleteOption, setShowTabChangeWarningPopup } = props;

    return (
        <>
            <div className='flex flex-row items-center pageContentSmall:block xl:flex largeTablet:block'>
                <div
                    className='flex flex-row items-center mr-6 largeTablet:mr-0 largeTablet:mb-1.5 cursor-pointer'
                    onClick={handleClickAddRow}>
                    <AddIcon stroke='#6C5DD3' />
                    <span className='ml-1 text-primary-500'>Add row</span>
                </div>

                <div
                    className={`flex flex-row items-center ${
                        disableDeleteOption ? 'cursor-not-allowed' : 'cursor-pointer'
                    }`}
                    onClick={() => handleClickDeleteRow(index)}>
                    <TrashIcon stroke={disableDeleteOption ? '#fecaca' : '#EF4444'} />
                    <span className={`ml-1 ${disableDeleteOption ? 'text-destructive-200' : 'text-destructive-500'}`}>
                        Delete row
                    </span>
                </div>
            </div>
        </>
    );
};
