import React, { useEffect, useState } from 'react';
import SliderDashboard from 'react-slick';
import Card from '../../../../Components/Card/Card';
import CardDescription from '../../../../Components/Card/CardDescription';
import { ReactComponent as UserIcon } from '../../../../Assets/user.svg';
import { ReactComponent as CashBackIcon } from '../../../../Assets/loyalty-cashback.svg';
import { IsMobileScreen } from '../../../../Constants/Constants';
import APIV2 from '../../../../api/axios/APIV2';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import _ from 'lodash';
import CardLoader from '../../../../Components/ContentLoader/CardContentLoader';
import { useNavigate } from 'react-router-dom';

const CashbackCards = (props) => {
    const { selectedDates } = props;
    const navigate = useNavigate();

    const [cardVariableData, setCardVariableData] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    const settingsDashboardSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: 'dashboard-slide',
    };

    const handleClick = (value) => {
        navigate(`/customer-information/customer-order-details/${value}`);
    };

    let rewardedValue = '0.0';
    let redeemedValue = '0.0';
    const cardDetails = [
        {
            title: 'Total cashback rewarded',
            heightLightedTitle: cardVariableData ? cardVariableData?.total_rewarded_points?.toLocaleString() : 0,
            tag: (() => {
                if (cardVariableData && cardVariableData?.compare_rewarded_records) {
                    if (cardVariableData?.compare_rewarded_records?.increment) {
                        rewardedValue = cardVariableData.compare_rewarded_records.increment;
                    } else if (cardVariableData?.compare_rewarded_records?.decrement) {
                        rewardedValue = cardVariableData.compare_rewarded_records.decrement;
                    } else if (cardVariableData?.compare_rewarded_records?.neutral) {
                        rewardedValue = cardVariableData.compare_rewarded_records.neutral;
                    }
                }
                return rewardedValue;
            })(),
            description: (
                <CardDescription
                    focusContent={cardVariableData ? cardVariableData?.total_rewarded_point_by_customers : 0}
                    description='Total cashback rewarded to :focusContent: customers for selected dates'
                    focusContentColor={
                        cardVariableData && cardVariableData.compare_redeemed_records
                            ? cardVariableData.compare_redeemed_records.increment
                                ? '#3d8c82'
                                : cardVariableData.compare_redeemed_records.decrement
                            : '#991B1B'
                    }
                />
            ),
            recordsStatus:
                cardVariableData && cardVariableData?.compare_rewarded_records?.decrement
                    ? 'decrement'
                    : cardVariableData?.compare_rewarded_records?.neutral == 0
                      ? 'neutral'
                      : 'increment',
            hasAboutDots: false,
            icon: (
                <CashBackIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
        {
            title: 'Total cashback redeemed',
            heightLightedTitle: cardVariableData ? cardVariableData?.total_redeemed_points?.toLocaleString() : 0,
            tag: (() => {
                if (cardVariableData && cardVariableData?.compare_redeemed_records) {
                    if (cardVariableData?.compare_redeemed_records?.increment) {
                        redeemedValue = cardVariableData.compare_rewarded_records.increment;
                    } else if (cardVariableData?.compare_redeemed_records?.decrement) {
                        redeemedValue = cardVariableData?.compare_redeemed_records?.decrement;
                    } else if (cardVariableData?.compare_redeemed_records?.neutral) {
                        redeemedValue = cardVariableData?.compare_redeemed_records?.neutral;
                    }
                }
                return redeemedValue;
            })(),
            description: (
                <CardDescription
                    focusContent={cardVariableData ? cardVariableData?.total_redeemed_point_by_customers : 0}
                    description='Total cashback redeemed by :focusContent: customers for selected dates'
                    focusContentColor='#6C5DD3'
                />
            ),
            recordsStatus:
                cardVariableData && cardVariableData?.compare_redeemed_records?.decrement
                    ? 'decrement'
                    : cardVariableData?.compare_redeemed_records?.neutral == 0
                      ? 'neutral'
                      : 'increment',
            hasAboutDots: false,
            icon: (
                <CashBackIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
        {
            title: 'Most rewarded customer',
            heightLightedTitle: cardVariableData?.most_rewarded?.customer_name
                ? cardVariableData?.most_rewarded?.customer_name
                : 'N/A',
            description: (
                <CardDescription
                    description={`${
                        cardVariableData?.most_rewarded?.customer_name
                            ? cardVariableData?.most_rewarded?.customer_name
                            : 'N/A'
                    } redeemed a total of :focusContent: cashback points for selected dates :focusContent:`}
                    focusContent={
                        cardVariableData?.most_rewarded?.points
                            ? cardVariableData?.most_rewarded?.points?.toLocaleString()
                            : 0
                    }
                    focusContentColor='#6C5DD3'
                />
            ),
            recordsStatus: false,
            hasAboutDots: cardVariableData?.most_rewarded?.customer_name ? true : false,
            icon: (
                <UserIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            customerId: cardVariableData?.most_rewarded?.customer_id,
        },
        {
            title: 'Most redeeming customer',
            heightLightedTitle: cardVariableData?.most_redeemed?.customer_name
                ? cardVariableData?.most_redeemed?.customer_name
                : 'N/A',
            description: (
                <CardDescription
                    description={`${
                        cardVariableData?.most_redeemed?.customer_name
                            ? cardVariableData?.most_redeemed?.customer_name
                            : 'N/A'
                    } redeemed a total of :focusContent: cashback points for selected dates`}
                    focusContent={
                        cardVariableData?.most_redeemed?.points
                            ? cardVariableData?.most_redeemed?.points?.toLocaleString()
                            : 0
                    }
                    focusContentColor='#6C5DD3'
                />
            ),
            recordsStatus: false,
            hasAboutDots: cardVariableData?.most_redeemed?.customer_name ? true : false,
            icon: (
                <UserIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            customerId: cardVariableData?.most_redeemed?.customer_id,
        },
    ];

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const isMobileScreen = IsMobileScreen();

    const getParams = () => {
        if (!selectedDates.length) {
            const params = {
                filters: {
                    date_range: {
                        from_date: format(new Date(), 'yyyy-MM-dd'),
                        to_date: format(new Date(), 'yyyy-MM-dd'),
                    },
                },
            };

            return params;
        }

        const from_date = format(new Date(Math.min(...selectedDates)), 'yyyy-MM-dd');

        const to_date = format(new Date(Math.max(...selectedDates)), 'yyyy-MM-dd');

        const params = {
            filters: {
                from_date,
                to_date,
            },
        };

        return params;
    };

    const fetchCardDetails = async () => {
        try {
            const response = await APIV2.get(
                `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/reward-point-totals`,
                {
                    params: getParams(),
                }
            );

            const { massage, success, ...rest } = response;

            setCardVariableData(rest);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchCardDetails();

        // eslint-disable-next-line
    }, [selectedDates, selectedOutletId]);

    return (
        <>
            {!_.isEmpty(cardVariableData) &&
                (!isMobileScreen ? (
                    <div className='-mx-[11px] mb-6 lg:-mx-[1px] lg:mb-3'>
                        {isLoading
                            ? [...Array(4)].map((_, index) => (
                                  <div
                                      className='max-w-[303px] w-full inline-block align-top mx-2.5 mt-4 lg:w-1/2 lg:odd:pr-1 lg:even:pl-1 lg:max-w-full lg:mx-0 lg:my-1'
                                      key={index}>
                                      <CardLoader />
                                  </div>
                              ))
                            : cardDetails?.map((el, index) => (
                                  <div
                                      className='max-w-[303px] w-full inline-block align-top mx-2.5 mt-4 lg:w-1/2 lg:odd:pr-1 lg:even:pl-1 lg:max-w-full lg:mx-0 lg:my-1'
                                      key={index}>
                                      <Card
                                          {...el}
                                          cardHeight='min-h-[140px]'
                                          handleClick={handleClick}
                                      />
                                  </div>
                              ))}
                    </div>
                ) : isLoading ? (
                    <div className='flex flex-row'>
                        {[...Array(4)].map((_, index) => (
                            <div
                                className='md:mb-[5px] mr-4'
                                key={index}>
                                <CardLoader />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='md:-mx-4'>
                        <SliderDashboard {...settingsDashboardSlider}>
                            {cardDetails.map((el, index) => (
                                <div
                                    className='md:mb-[5px]'
                                    key={index}>
                                    <Card
                                        {...el}
                                        cardHeight='min-h-[140px]'
                                    />
                                </div>
                            ))}
                        </SliderDashboard>
                    </div>
                ))}
        </>
    );
};

export default CashbackCards;
