import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Layout from '../DeviceSetting/Components/Layout';
import LargeTitleSwitch from '../../Components/ToggleSwitch/LargeTitleSwitch';
import { DefaultInputField } from '../../Components/InputField/InputField';
import DropdownWithValueLabel from '../../Components/DropDown/DropdownWithValueLabel';
import { useDispatch } from 'react-redux';
import { savePOSSettings } from '../../reduxStore/reducer/posSetting';
import { useSelector } from 'react-redux';

const actionButtons = [
    { switchName: 'save', title: 'Save' },
    { switchName: 'savePrint', title: 'Save & Print' },
    { switchName: 'saveEBill', title: 'Save & e-bill' },
    { switchName: 'kot', title: 'KOT' },
    { switchName: 'kotPrint', title: 'KOT & print' },
];

const redirectOptions = [
    { label: 'Login', value: '/login' },
    { label: 'Select Restaurant', value: '/select-restaurant' },
    { label: 'Dashboard', value: '/' },
    { label: 'Table View', value: '/table-view' },
    { label: 'POS', value: '/pos' },
    { label: 'POS Settings', value: '/POSSettings' },
    { label: 'Kitchen Display System', value: '/kitchen-display-system' },
    { label: 'Customer Information', value: '/customer-information' },
    { label: 'Customer Group Details', value: '/customer-information/customer-group-details' },
    { label: 'Customer Order Details', value: '/customer-information/customer-order-details' },
    { label: 'Create Offer', value: '/offers/create-offer' },
    { label: 'Table Management', value: '/table-management' },
    { label: 'Abandoned Carts', value: '/abandoned-carts' },
    { label: 'Settings', value: '/settings/:settingType' },
    { label: 'Rider', value: '/rider' },
    { label: 'Rider Details', value: '/rider/:section/:id' },
    { label: 'Bill Payment', value: '/bill-payment' },
    { label: 'Device Setting', value: '/device-setting' },
];

const ActionSwitches = ({ control }) => {
    return (
        <div className='flex flex-wrap gap-4 pb-4 border-b border-gray-300 mt-4'>
            <span className='text-lg font-semibold'>Actions Buttons</span>
            {actionButtons.map(({ switchName, title }) => (
                <LargeTitleSwitch
                    key={switchName}
                    classNames='ml-6'
                    switchName={switchName}
                    title={title}
                    control={control}
                />
            ))}
        </div>
    );
};

const SettingSection = ({ control, switchName, title }) => (
    <div className='pb-4 border-b border-gray-300'>
        <LargeTitleSwitch
            classNames='mb-6 mt-6'
            switchName={switchName}
            title={title}
            control={control}
        />
    </div>
);

const KOTResetSection = ({ control }) => (
    <div className='pb-4 border-b border-gray-300'>
        <div className='m-4 flex items-center justify-between'>
            <span className='text-lg font-semibold'>KOT Reset Number</span>
            <div className='w-[50%]'>
                <DefaultInputField
                    control={control}
                    placeholder='Write the number you want to start your'
                    shadow='shadow-xSmall'
                />
            </div>
        </div>
    </div>
);

export default function POSSetting() {
    const dispatch = useDispatch();
    const { actionSwitches, redirectLink, posSettings } = useSelector((state) => state.outlet);
    const [selectedRedirect, setSelectedRedirect] = useState(null);
    const methods = useForm({
        defaultValues: {
            ...actionSwitches,
            redirectLink,
            kotResetNumber: posSettings?.kotResetNumber || '',
            isCustomerInfoMandatory: posSettings?.isCustomerInfoMandatory,
            billPaymentNCB: posSettings?.billPaymentNCB,
            discountPermission: posSettings?.discountPermission,
        },
    });

    const {
        control,
        setValue,
        watch,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = methods;

    const handleDiscard = () => {
        const initialRedirect = redirectOptions.find((option) => option.value === redirectLink);
        setSelectedRedirect(initialRedirect || null);
        reset();
    };

    useEffect(() => {
        const initialRedirect = redirectOptions.find((option) => option.value === redirectLink);
        setSelectedRedirect(initialRedirect || null);

        reset({
            ...actionSwitches,
            redirectLink,
            kotResetNumber: posSettings?.kotResetNumber || '',
            isCustomerInfoMandatory: posSettings?.isCustomerInfoMandatory,
            billPaymentNCB: posSettings?.billPaymentNCB,
            discountPermission: posSettings?.discountPermission,
        });
    }, [actionSwitches, redirectLink, posSettings, reset]);

    const handleSave = (data) => {
        const updatedSettings = {
            isCustomerInfoMandatory: data.isCustomerInfoMandatory,
            billPaymentNCB: data.billPaymentNCB,
            discountPermission: data.discountPermission,
            kotResetNumber: data.kotResetNumber,
            redirectLink: data.redirectLink,
            actionSwitches: {
                save: data.save,
                savePrint: data.savePrint,
                saveEBill: data.saveEBill,
                kot: data.kot,
                kotPrint: data.kotPrint,
            },
        };
        dispatch(savePOSSettings(updatedSettings));
    };

    const handleRedirect = (option) => {
        setSelectedRedirect(option);
        setValue('redirectLink', option.value, { shouldDirty: true });
    };

    return (
        <FormProvider {...methods}>
            <Layout
                handleSave={handleSubmit(handleSave)}
                handleDiscard={handleDiscard}
                isDirty={isDirty}>
                <div className='flex items-center gap-4 justify-between pb-4 border-b border-gray-300'>
                    <span className='text-lg font-semibold mb-2'>New Order button redirection</span>
                    <div className='w-[50%]'>
                        <DropdownWithValueLabel
                            placeholder='Select Page'
                            menuItems={redirectOptions}
                            setSelected={handleRedirect}
                            selectedItem={selectedRedirect}
                            onChange={handleRedirect}
                            fixedHeight='max-h-52'
                        />
                    </div>
                </div>
                <ActionSwitches
                    control={control}
                    values={watch()}
                />
                <SettingSection
                    control={control}
                    switchName='isCustomerInfoMandatory'
                    title='Is customer information mandatory'
                />
                <SettingSection
                    control={control}
                    switchName='billPaymentNCB'
                    title='Bill payment NCB'
                />
                <SettingSection
                    control={control}
                    switchName='discountPermission'
                    title='Discount permission'
                />
                <KOTResetSection control={control} />
            </Layout>
        </FormProvider>
    );
}
