import React from 'react';
import { useFormContext } from 'react-hook-form';
import { IsIpadScreen, IsMobileScreen } from '../../../../Constants/Constants';
import { useParams } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import _ from 'lodash';
import { MultipleTab } from '../../../../Components/Tabs/Tabs';

export default function LeftPanel(props) {
    const { tabs } = props;

    const {
        formState: { errors },
    } = useFormContext();

    const { choiceTab } = useParams();

    const isMobileScreen = IsMobileScreen();

    const isIpadScreen = IsIpadScreen();

    const { height: screenHeight } = useWindowSize();

    const findHasError = (tab) => {
        let errorObj = errors;

        if (_.isEmpty(errors)) return;

        if (tab.formState === 'details' && Array.isArray(errorObj?.details?.display)) {
            const displayErrors = errorObj?.details?.display?.slice(1);
            const allNull = displayErrors.every((error) => error === null);
            if (allNull) return false;
        }

        return !!errorObj[tab.formState];
    };
    return (
        <>
            {!isMobileScreen && (
                <div
                    className='flex flex-col mt-4 pr-6 mr-5 border-r min-w-[197px] border-neutral-300 overflow-auto [&::-webkit-scrollbar]:hidden lg:pr-4 lg:mr-3 lg:min-w-[190px]'
                    style={{ height: isIpadScreen ? screenHeight - 148 : screenHeight - 163 }}>
                    {tabs?.map((el, index) => {
                        const hasError = findHasError(el);

                        return (
                            <div
                                className='lg:max-w-[173px] w-full mb-4'
                                key={index}>
                                <MultipleTab
                                    hasError={hasError}
                                    label={el.item}
                                    index={index}
                                    onClick={el.onClick}
                                    isActive={choiceTab === el.link}
                                    minWidth='min-w-[173px]'
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}
