import axios from 'axios';
import {
    interceptorsRequest,
    interceptorsRequestError,
    interceptorsResponse,
    interceptorsResponseError,
} from './interceptors';
import { REACT_APP_BASE_URL_V1 } from '../../envConfig';

let APIV1 = axios.create({
    baseURL: REACT_APP_BASE_URL_V1,
    responseType: 'json',
});

APIV1.defaults.headers.post['content-type'] = 'application/json';

APIV1.defaults.headers.get['Accept'] = 'application/json';

APIV1.interceptors.request.use(
    (request) => {
        const interceptorsReq = interceptorsRequest(request);
        return interceptorsReq;
    },

    (error) => {
        const promiseError = interceptorsRequestError(error);
        return promiseError;
    }
);

APIV1.interceptors.response.use(
    (response) => {
        const interceptorsRes = interceptorsResponse(response);
        return interceptorsRes;
    },

    (error) => {
        const responseError = interceptorsResponseError(error);
        return responseError;
    }
);

export default APIV1;
