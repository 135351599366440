import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as LeftArrow } from '../../Assets/chevron-down.svg';
import { ReactComponent as Close } from '../..//Assets/close.svg';
import { DefaultInputField, InputArea } from '../../Components/InputField/InputField';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../Components/Buttons/Button';
import { IsMobileScreen } from '../../Constants/Constants';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '../../Components/Error/ErrorMessage';
import { InputField } from '../Login/Components/Components';
import PhoneInputDropdown from '../../Components/MobileNumberInputFlagDropDown/Dropdown';
import { useSelector } from 'react-redux';
import { countryCodes } from '../../Components/MobileNumberInputFlagDropDown/CountryData';
import APIV5 from '../../api/axios/APIV5';
import WaiterPopupLoader from './Components/WaiterPopupLoader';
import useOutsideClick from '../../Helper/Hooks/useOutsideClick';
import { ValidateUserPhoneNumber } from './Components/ValidateUserPhoneNumber';

export default function AddWaiter(props) {
    const isMobileScreen = IsMobileScreen();

    const {
        handleClickAddRider,
        riderDetails,
        setShowAddRiderPage,
        addOrEdit,
        isEditUser,
        uploadedImageDetails,
        setriderSaved,
        riderDetailsEdit,
        isLoadingEditRider,
    } = props;

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
        getValues,
        setError,
        clearErrors,
        trigger,
    } = useForm();

    const popupRef = useRef();
    useOutsideClick(popupRef, handleClickAddRider);

    // const outletList = useSelector((state) => state.outlet.outletList);
    const [tableSectionNames, setTableSectionNames] = useState([]);
    const [selectedSections, setSelectedSections] = useState([]);
    const [isShowMobileNumberPopup, setIsShowMobileNumberPopup] = useState(false);
    const [validatedNumber, mobileNumber, otp] = watch(['validatedNumber', 'mobileNumber', 'otp']);
    const [isValidInput, setIsValidInput] = useState(true);
    const [countryData, setCountryData] = useState(
        countryCodes.find((country) => '+' + country.code == (watch('country_code') ?? '+91'))
    );

    useEffect(() => {
        if (riderDetailsEdit && addOrEdit === 'edit') {
            setValue('validatedNumber', riderDetailsEdit?.mobile_number);
            setValue('Name', riderDetailsEdit?.first_name);
            setValue('mobile_number_id', riderDetailsEdit?.phone_detail_id);
            setValue('Email', riderDetailsEdit?.email);
        }
    }, [riderDetailsEdit, setValue, addOrEdit]);

    const addRiderCardInputField = [
        { label: 'Name', placeholder: 'Enter Name', required: true },
        { label: 'Mobile number', placeholder: 'Enter mobile number', required: false },
        { label: 'Email', placeholder: 'Enter email', required: true },
    ];

    const variable = getValues('outletSelection') ?? [];

    const handleClickItem = (item) => {
        const selectedIndex = variable?.indexOf(item.value);
        if (selectedIndex === -1) {
            setValue('outletSelection', [...variable, item.value]);
        } else {
            const newSelectedItems = [...variable];
            newSelectedItems.splice(selectedIndex, 1);
            setValue('outletSelection', newSelectedItems);
        }

        clearErrors('outletSelection');
    };

    const data = {
        first_name: watch('Name'),
        Email_Address: watch('Email'),
        phone_detail_id: watch('mobile_number_id') ?? riderDetailsEdit?.phone_detail_id,
        // phone_detail_id: 1,
        country_code: countryData?.code,
    };

    const handleEdit = async () => {
        try {
            const response = await APIV5.put(`restaurants/${32}/riders/${riderDetailsEdit?.id}`, data);
            setShowAddRiderPage(false);
            {
                response.success === true && setriderSaved((prevState) => !prevState);
            }
        } catch (error) {
            console.log('error ==>', error);
        }
    };

    const onEditSubmit = () => {
        handleSubmit(handleEdit)();
    };

    let addWaiter = {
        first_name: watch('Name'),
        Email_Address: watch('Email'),
        phone_detail_id: watch('mobile_number_id'),
        country_code: countryData?.code,
        section_ids: selectedSections,
    };

    const handleDiscard = () => {
        setShowAddRiderPage(false);
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 md:z-[9] flex overflow-auto px-[33px] py-4 md:bg-white md:relative md:py-0 md:px-1'>
                <div
                    className='max-w-[830px] min-h-[388px] rounded-xl bg-shades-50 px-8 py-6 m-auto w-full md:max-w-full md:px-0 md:py-0 md:min-h-full'
                    ref={popupRef}>
                    {isLoadingEditRider ? (
                        <WaiterPopupLoader />
                    ) : (
                        <div ref={popupRef}>
                            {isMobileScreen && (
                                <div
                                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                                    onClick={() => handleClickAddRider()}>
                                    <LeftArrow className='rotate-90' />
                                    <span className='ml-1'>Back to Waiter</span>
                                </div>
                            )}

                            <div className='flex flex-row justify-between items-center border-neutral-300'>
                                <div>
                                    {addOrEdit === 'add' ? (
                                        <>
                                            <span className='paragraph-large-medium'>Add Waiter</span>
                                            <div className='flex flex-row items-center'>
                                                <span className='paragraph-medium-italic text-neutral-500'>
                                                    Register a new waiter for your outlet
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        addOrEdit === 'edit' && (
                                            <>
                                                <span className='paragraph-large-medium'>Edit rider</span>
                                                <div className='flex flex-row items-center'>
                                                    <span className='paragraph-medium-italic text-neutral-500'>
                                                        Edit an existing rider for your outlet
                                                    </span>
                                                </div>
                                            </>
                                        )
                                    )}
                                </div>
                                <div
                                    onClick={setShowAddRiderPage}
                                    className='md:hidden cursor-pointer'>
                                    <Close />
                                </div>
                            </div>

                            <div>
                                {addRiderCardInputField.map((el, index) => {
                                    return (
                                        <>
                                            <div
                                                key={index}
                                                className='align-top inline-block w-1/2 even:pl-2 odd:pr-2 my-2'>
                                                {el.label === 'Mobile number' ? (
                                                    <div className='w-full  relative lg:w-full lg:ml-0 remove-spin-button'>
                                                        <Controller
                                                            render={() => (
                                                                <InputField
                                                                    inputValue={validatedNumber ?? ''}
                                                                    setValue={setValue}
                                                                    showMobileIcon={false}
                                                                    isMobileNumberPage={true}
                                                                    isValidInput={isValidInput}
                                                                    setIsValidInput={setIsValidInput}
                                                                    onInputClick={() => {
                                                                        setIsShowMobileNumberPopup(true);
                                                                    }}
                                                                    paddingLeft='pl-[105px]'
                                                                    mobileNumberFIeldName={'mobileNumber'}
                                                                    otpFieldName={'otp'}
                                                                />
                                                            )}
                                                            name={`validatedNumber`}
                                                            control={control}
                                                            rules={{ required: 'Mobile number is required' }}
                                                        />
                                                        <div
                                                            className={`phoneinputbox absolute ${
                                                                errors?.validatedNumber ? 'bottom-8' : 'bottom-3'
                                                            } left-4`}
                                                            onClick={() => setIsShowMobileNumberPopup(true)}>
                                                            <PhoneInputDropdown
                                                                value={countryData?.code}
                                                                disabled={true}
                                                                countryData={countryData}
                                                                setCountryData={setCountryData}
                                                            />
                                                        </div>
                                                        {errors?.validatedNumber && (
                                                            <ErrorMessage errors={errors?.validatedNumber} />
                                                        )}

                                                        {isShowMobileNumberPopup && (
                                                            <ValidateUserPhoneNumber
                                                                setIsShowMobileNumberPopup={setIsShowMobileNumberPopup}
                                                                mobileNumber={mobileNumber}
                                                                otp={otp}
                                                                isValidInput={isValidInput}
                                                                setIsValidInput={setIsValidInput}
                                                                countryData={countryData}
                                                                setCountryData={setCountryData}
                                                                methods={{
                                                                    setValue,
                                                                    setError,
                                                                    formState: { errors },
                                                                    clearErrors,
                                                                    getValues,
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                ) : (
                                                    <>
                                                        <Controller
                                                            key={index}
                                                            render={({ field }) => (
                                                                <div>
                                                                    <DefaultInputField
                                                                        label={el.label}
                                                                        placeholder={el.placeholder}
                                                                        enteredValue={field.onChange}
                                                                        value={field.value}
                                                                        onChange={() => {
                                                                            // field.onChange();
                                                                            trigger(el.label);
                                                                        }}
                                                                    />
                                                                    {!field.value && errors && errors[el.label] && (
                                                                        <ErrorMessage errors={errors[el.label]} />
                                                                    )}
                                                                </div>
                                                            )}
                                                            name={el.label}
                                                            control={control}
                                                            rules={{
                                                                required: el.required && el.label + ' is required',
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    );
                                })}
                            </div>

                            {addOrEdit === 'add' ? (
                                <div className='flex flex-row items-center md:block md:pb-20 mt-14'>
                                    <div className='w-1/2 mr-2 md:w-full'>
                                        <span className='paragraph-large-medium'>Step 1/2</span>
                                    </div>
                                    <div
                                        className='w-1/2 ml-2 md:hidden'
                                        onClick={() => setShowAddRiderPage('sectionSelect')}>
                                        <LargePrimaryButton label='Next' />
                                    </div>
                                </div>
                            ) : (
                                addOrEdit === 'edit' && (
                                    <div className='flex justify-between'>
                                        <div
                                            className='w-8/12 pr-2'
                                            onClick={onEditSubmit}>
                                            <LargePrimaryButton
                                                label='Edit rider'
                                                type='submit'
                                            />
                                        </div>
                                        <div
                                            className='w-4/12 pl-2'
                                            onClick={handleDiscard}>
                                            <LargeDestructiveButton
                                                label='Discard Rider'
                                                type='submit'
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
