import React from 'react';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../../Components/Buttons/Button';
import OtherDetails from '../../CustomerOrderDetails/Components/OtherDetails';

const OrderActions = (props) => {
    const { orderingMode, setShowPopup, orderDetail, onReady } = props;
    const hasRestaurantAcceptedOrder = orderDetail?.status?.restaurant_accepted_at;
    const hasRestaurantReadyOrder = orderDetail?.status?.restaurant_ready_at;
    const onChnageStatus = () => {
        if (!hasRestaurantAcceptedOrder && !orderDetail?.status?.restaurant_ready_at) {
            setShowPopup('acceptOrder');
        } else {
            onReady();
        }
    };
    return (
        <>
            {orderingMode !== 'Dine-In order' ? (
                <>
                    <div className='flex flex-row mb-2'>
                        <div
                            className={`w-${
                                hasRestaurantAcceptedOrder && hasRestaurantReadyOrder ? 'full' : '1/2'
                            } mr-[7px] cursor-pointer`}
                            onClick={() => setShowPopup('cancelOrder')}>
                            <LargeDestructiveButton
                                label='Cancel order'
                                isDefault={false}
                            />
                        </div>
                        {(!orderDetail?.status?.restaurant_accepted_at ||
                            !orderDetail?.status?.restaurant_ready_at) && (
                            <div
                                className='w-1/2 ml-[7px]'
                                onClick={onChnageStatus}>
                                <LargePrimaryButton
                                    label={
                                        orderDetail?.status?.restaurant_accepted_at &&
                                        !orderDetail?.status?.restaurant_ready_at
                                            ? 'Ready Order'
                                            : 'Accept order'
                                    }
                                    isDefault={false}
                                />
                            </div>
                        )}
                    </div>

                    {orderingMode === 'delivery' &&
                        !(
                            orderDetail.rider ||
                            orderDetail.elt_order ||
                            orderDetail.dunzo_order ||
                            orderDetail.wefast_order ||
                            orderDetail.scout_order ||
                            orderDetail.shadowfax_order ||
                            orderDetail.porter_order ||
                            orderDetail.pidge_order
                        ) && (
                            <div
                                className='w-full'
                                onClick={() => setShowPopup('riderAssignment')}>
                                <LargeTertiaryButton
                                    label='Rider assignment'
                                    isDefault={false}
                                />
                            </div>
                        )}
                </>
            ) : (
                <div className='gap-2 items-center text-center justify-center'>
                    <div
                        className='w-full mb-3'
                        onClick={() => setShowPopup('readyOrder')}>
                        <LargePrimaryButton
                            label='Ready order'
                            isDefault={false}
                        />
                    </div>

                    <span
                        className='text-primary-500 paragraph-medium-regular cursor-pointer text-center hover:underline underline-offset-2'
                        onClick={() => setShowPopup('cancelOrder')}>
                        Cancel order
                    </span>
                </div>
            )}
        </>
    );
};

export default OrderActions;
