import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import APIV5 from '../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';

const formatCurrency = (value) => {
    if (value === null || value === undefined) return '₹0.00';
    return `₹${parseFloat(value)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

export default function TotalSales(props) {
    const { outletId, selectedDates } = props;
    const [salesTotalDetails, setSalesTotalDetails] = useState([]);
    const formatDate = (date) => format(date, 'yyyy-MM-dd');
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const defaultParams = () => {
        const [start_date, end_date] = selectedDates.sort((a, b) => a - b);
        return {
            start_date: formatDate(start_date),
            end_date: formatDate(end_date),
        };
    };
    const TotalSalesDetails = async () => {
        const defaultParamsData = defaultParams();
        try {
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/total-sale-details`, {
                params: {
                    ...defaultParamsData,
                    outlet_id: [outletId],
                },
            });
            setSalesTotalDetails(response);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        TotalSalesDetails();
    }, [selectedDates, outletId]);

    const salesCardContent = [
        { label: 'My amount', value: salesTotalDetails?.total_sales_details?.my_amount },
        { label: 'Discount', value: salesTotalDetails?.total_sales_details?.discount },
        { label: 'Delivery charge', value: salesTotalDetails?.total_sales_details?.delivery_charge },
        { label: 'Container charge', value: salesTotalDetails?.total_sales_details?.packaging_charge },
        { label: 'Service charge', value: salesTotalDetails?.total_sales_details?.convenience_charge },
        { label: 'Tax', value: salesTotalDetails?.total_sales_details?.tax },
        { label: 'Waived off', value: salesTotalDetails?.total_sales_details?.waived_off },
        { label: 'Round off', value: salesTotalDetails?.total_sales_details?.round_off },
    ];

    const totalSales = salesTotalDetails?.total_sales_details?.total_sales || 0;
    const onlinePercentage = salesTotalDetails?.total_sales_details?.online_sale_in_percentage || 0;
    const offlinePercentage = salesTotalDetails?.total_sales_details?.offline_sale_in_percentage || 0;

    const chartOptions = {
        plotOptions: {
            pie: {
                donut: {
                    size: '62%',
                },
            },
        },
        labels: ['Online Sales', 'Offline Sales'],
        legend: {
            show: false,
        },

        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
                fontSize: 16,
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
            },
            formatter: function (val) {
                return parseInt(val) + '%';
            },
        },
        colors: ['#FFBB3C', '#74C2B9'],
        stroke: {
            colors: 'transparent',
        },
    };
    return (
        <>
            <div className='flex flex-row justify-between items-center md:block'>
                <div className='w-full'>
                    <div className='w-[238px] mx-[5.5px] mb-[26px] flex items-center justify-center md:mx-auto'>
                        <ReactApexChart
                            className='m-auto'
                            type='donut'
                            width={280}
                            height={280}
                            series={[onlinePercentage, offlinePercentage]}
                            options={chartOptions}
                        />
                    </div>
                    <div className='flex flex-row justify-between md:justify-center w-full max-w-[251px] md:max-w-full'>
                        <div className='flex flex-row items-center'>
                            <div className='h-[11px] w-[11px] rounded-full bg-tertiary-600 mr-2' />
                            <span className='paragraph-medium-regular'>Online Sales</span>
                        </div>

                        <div className='flex flex-row items-center md:ml-6'>
                            <div className='h-[11px] w-[11px] rounded-full bg-secondary-600 mr-2' />
                            <span className='paragraph-medium-regular'>Offline Sales</span>
                        </div>
                    </div>
                </div>
                <div className='max-w-[315px] w-full md:mt-10 md:max-w-full'>
                    <div className='border-b border-neutral-300 mb-4'>
                        {salesCardContent.map((item, index) => (
                            <div
                                key={index}
                                className='flex flex-row justify-between mb-4'>
                                <span className='paragraph-medium-regular'>{item?.label}</span>
                                <span className='paragraph-medium-regular'>{formatCurrency(item?.value)}</span>
                            </div>
                        ))}
                    </div>
                    <div className='flex flex-row justify-between max-w-[315px] md:max-w-full'>
                        <span className='paragraph-medium-semi-bold'>Total Sales</span>
                        <span className='paragraph-medium-semi-bold'>{formatCurrency(totalSales)}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
