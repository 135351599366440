import React from 'react';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../../Components/Buttons/Button';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { useSelector } from 'react-redux';

const buttonConfig = {
    save: {
        component: LargeTertiaryButton,
        props: {
            label: 'Save',
            buttonStyle: 'pageContent:px-1',
            btnStyle: 'pageContent:h-10',
            hideLabel: 'pageContent:text-sm',
            isDefault: false,
        },
        wrapperClass: 'w-full',
        onClick: (setShowPopup) => () => setShowPopup('addPayment'),
    },
    savePrint: {
        component: LargeTertiaryButton,
        props: {
            label: 'Save & print',
            buttonStyle: 'pageContent:px-1',
            btnStyle: 'pageContent:h-10',
            hideLabel: 'pageContent:text-sm',
            isDefault: false,
            nameMargin: '4xl:mx-0',
        },
        wrapperClass: 'w-full',
        onClick: (setShowPopup) => () => setShowPopup('addPayment'),
    },
    saveEBill: {
        component: LargeTertiaryButton,
        props: {
            label: 'Save & e-bill',
            buttonStyle: 'pageContent:px-1',
            btnStyle: 'pageContent:h-10',
            hideLabel: 'pageContent:text-sm',
            isDefault: false,
            nameMargin: '4xl:mx-0',
        },
        wrapperClass: 'w-full',
        onClick: (setShowPopup) => () => setShowPopup('addPayment'),
    },
    kot: {
        component: LargePrimaryButton,
        props: {
            label: 'KOT',
            isDefault: false,
            buttonStyle: 'pageContent:h-10',
            hideLabel: 'pageContent:text-sm',
        },
        wrapperClass: 'w-full cursor-pointer',
        onClick: (setShowPopup) => () => setShowPopup('fireDishes'),
    },
    kotPrint: {
        component: LargePrimaryButton,
        props: {
            label: 'KOT & print',
            buttonStyle: 'pageContent:h-10',
            hideLabel: 'pageContent:text-sm',
            isDefault: false,
            nameMargin: '4xl:mx-0',
        },
        wrapperClass: 'w-full',
        onClick: (setShowPopup) => () => setShowPopup('fireDishes'),
    },
};

export default function OrdersButtonDesktop(props) {
    const { setShowPopup } = props;

    const configuration = ['save', 'savePrint', 'saveEBill', 'kot', 'kotPrint'];
    const actionSwitches = useSelector((state) => state.outlet.actionSwitches);
    const isMobileScreen = IsMobileScreen();
    if (isMobileScreen) return;

    const renderButton = (key) => {
        const { component: ButtonComponent, props: buttonProps, wrapperClass, onClick } = buttonConfig[key];
        const isDisabled = !actionSwitches[key];

        if (isDisabled) {
            return null;
        }
        return (
            <div
                className={wrapperClass}
                key={key}
                onClick={onClick ? onClick(setShowPopup) : undefined}>
                <ButtonComponent {...buttonProps} />
            </div>
        );
    };

    const firstRow = ['save', 'savePrint', 'saveEBill'];
    const secondRow = ['kot', 'kotPrint'];

    return (
        <div>
            <div className='flex flex-row mb-2 gap-1.5'>
                {firstRow.map((key) => {
                    if (!configuration.includes(key)) {
                        return null;
                    }

                    return renderButton(key);
                })}
            </div>

            <div className='flex flex-row pageContent:pb-2 gap-1.5'>
                {secondRow.map((key) => {
                    if (!configuration.includes(key)) {
                        return null;
                    }

                    return renderButton(key);
                })}
            </div>
        </div>
    );
}
