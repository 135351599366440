import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Tag from '../../../../../Components/Tag/Tag';
import DropdownWithValueLabel from '../../../../../Components/DropDown/DropdownWithValueLabel';
import { useParams } from 'react-router-dom';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';

export default function OrderingModeSection() {
    const { id } = useParams();

    const {
        watch,
        setValue,
        getValues,
        control,
        formState: { errors },
        trigger,
    } = useFormContext();

    const orderingMode = useSelector((state) => state.outlet.orderingModes);

    const [orderingModesMenuItem, setOrderingModesMenuItem] = useState([]);
    const [initialOrderingModes, setInitialOrderingModes] = useState([]);

    useEffect(() => {
        const filteredOrderingModeMenuItems = [
            { label: 'All', value: 'all' },
            ...orderingMode.map((item) => ({
                label: item.display_name,
                value: item,
            })),
        ];

        setOrderingModesMenuItem(filteredOrderingModeMenuItems);
    }, [orderingMode]);

    useEffect(() => {
        setInitialOrderingModes(watch('ordering_modes'));
    }, [watch('ordering_modes')]);

    const handelSelectionOrderingMode = async (onChange, selection) => {
        if (selection.value === 'all') {
            const allModes = orderingMode.map((item) => ({
                label: item.display_name,
                value: item,
            }));
            onChange(allModes);
            setValue('ordering_modes', allModes);
            setOrderingModesMenuItem([{ label: 'All', value: 'all' }]);
            return;
        }

        const newOrderingMode = watch('ordering_modes')?.length ? [...watch('ordering_modes'), selection] : [selection];

        onChange(newOrderingMode);
        setValue('ordering_modes', newOrderingMode);

        const filteredOrderingModeMenuItems = [
            { label: 'All', value: 'all' },
            ...orderingMode
                .filter((item) => !newOrderingMode.some((el) => el.value.id === item.id))
                .map((item) => ({
                    label: item.display_name,
                    value: item,
                })),
        ];

        setOrderingModesMenuItem(filteredOrderingModeMenuItems);
    };

    const handleClickClose = async (mode) => {
        if (!!id) {
            const oldMode = getValues('delete_ordering_modes') ?? [];
            const matchingMode = initialOrderingModes.find((initialMode) => initialMode?.value?.id === mode?.value?.id);

            if (matchingMode) {
                setValue('delete_ordering_modes', [...oldMode, matchingMode?.value?.id]);
            }
        }

        const newOrderingMode = watch('ordering_modes').filter((el) => el?.value?.id !== mode?.value?.id);

        setValue('ordering_modes', newOrderingMode);
        trigger('ordering_modes'); // Trigger validation after removing a tag

        if (newOrderingMode.length === 0) {
            const allOptions = [
                { label: 'All', value: 'all' },
                ...orderingMode.map((item) => ({
                    label: item.display_name,
                    value: item,
                })),
            ];
            setOrderingModesMenuItem(allOptions);
        } else {
            setOrderingModesMenuItem((prevState) => [
                { label: 'All', value: 'all' },
                ...prevState.filter((item) => item.value !== 'all'),
                mode,
            ]);
        }

        const deletedOrderingMode = watch('delete_ordering_modes') || [];
        setValue('delete_ordering_modes', [...deletedOrderingMode, mode?.value?.id]);
    };

    const hasSelectedMode = watch('ordering_modes')?.length > 0;

    return (
        <>
            <div className='mb-6'>
                <TitleDescription
                    title='Ordering mode'
                    description='Select ordering mode to apply this offer.'
                />

                <div className='w-full max-w-[312px] md:max-w-full mt-4'>
                    <Controller
                        render={({ field }) => (
                            <DropdownWithValueLabel
                                menuItems={orderingModesMenuItem}
                                placeholder={
                                    hasSelectedMode ? 'Select additional ordering mode' : 'Select ordering mode'
                                }
                                shadow='shadow-xSmall'
                                boxHeight='h-[48px]'
                                hasFixedPlaceholder
                                setSelected={(selection) => handelSelectionOrderingMode(field.onChange, selection)}
                                zIndex='z-[1]'
                                additionalBelowSpace={120}
                                selectedItem={getValues('ordering_modes')}
                                error={errors.ordering_modes ? true : false}
                            />
                        )}
                        control={control}
                        name='ordering_modes'
                        rules={{
                            required: 'Please select Ordering mode',
                            validate: (value) => {
                                return (
                                    (Array.isArray(value) && value.length > 0) ||
                                    'At least one ordering mode is required'
                                );
                            },
                        }}
                    />

                    {errors.ordering_modes && <ErrorMessage errors={errors.ordering_modes} />}
                </div>

                <div className='align-top mt-1'>
                    {watch('ordering_modes')?.map((el, index) => (
                        <div
                            className='mr-2 inline-block'
                            key={index}>
                            <Tag
                                tag={el.label}
                                onClose={() => handleClickClose(el)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
