import { useWindowSize } from '@uidotdev/usehooks';
import React from 'react';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../Components/Buttons/Button';
import { useFormContext } from 'react-hook-form';

export default function Layout(props) {
    const { children, handleSave, handleDiscard } = props;

    const {
        handleSubmit,
        formState: { isDirty },
    } = useFormContext();

    const screenSize = useWindowSize();

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className='px-8 pb-5 lg:px-4 lg:pb-0 pt-4 w-full max-w-[1336px] mx-auto bg-white relative'
                    style={{ height: screenSize.height - 50 }}>
                    <div className='max-w-[636px] md:max-w-full'>{children}</div>

                    {isDirty && (
                        <div className='absolute z-[49] md:z-[8] group-[.undock-left-panel]:z-[8] md:fixed md:justify-center md:border-none md:py-0 md:pb-1 md:pt-2 md:shadow-dropShadow left-0 right-0 bottom-0 flex px-8 lg:px-4 flex-row justify-end w-full max-w-[1336px] mx-auto bg-white py-6 border-t border-neutral-300 lg:py-4'>
                            <div
                                className='min-w-[196px] mr-5 md:w-1/2 md:mr-[7.5px] md:min-w-0'
                                onClick={handleDiscard}>
                                <LargeDestructiveButton label='Discard' />
                            </div>

                            <div
                                className='min-w-[196px] md:w-1/2 md:ml-[7.5px] md:min-w-0'
                                onClick={handleSubmit(handleSave)}>
                                <LargePrimaryButton label='Save' />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
