import React from 'react';
import GridBlockPlus from './GridBlockPlus';
import Table from './Table';
import { useWindowSize } from '@uidotdev/usehooks';
import TableLoader from './TableLoader';
import { useParams } from 'react-router-dom';
import { handleShowMore } from '../HelperFunction/ApiFunctions';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useSelector } from 'react-redux';

const Tables = (props) => {
    const {
        isEditMode,
        sectionList,
        setPopupStatus,
        setValue,
        loaderStatus,
        setloaderStatus,
        tableList,
        setTableList,
        setShowTableContent,
        popupStatus,
    } = props;
    const { section } = useParams();
    const screenWidth = useWindowSize().width;
    let selectedSection = sectionList.find((sections) => sections.internal_name == section);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const allFalse = Object.values(popupStatus).every((value) => value === false);
    const gridBlock = (tables) => {
        //this function is used to return number of grids as per screen size
        return screenWidth < 767 ? 6 - tables : screenWidth < 1354 ? 8 - tables : 12 - tables;
    };
    const isMobileScreen = IsMobileScreen();

    const handlePopupStatus = ({ model, value }) => {
        //this function is used to open edit table popup and set seleted table data for editing

        const requestedDataForEdit = {
            internal_name: value?.internal_name,
            capacity: value?.capacity,
            outlet_section: formateSubSection({
                id: selectedSection?.id,
                dataForMap: [selectedSection],
            }),
            outlet_sub_section: formateSubSection({
                id: value?.outlet_section_id,
                dataForMap: selectedSection?.sub_section,
            }),
            deeplink: value?.deeplink,
            tableId: value.id,
            tableCode: value?.code,
        };
        isMobileScreen && setShowTableContent(false);
        Object.entries(requestedDataForEdit).forEach(([name, value]) => setValue(name, value));
        setPopupStatus((prevStatus) => ({
            ...Object.fromEntries(Object.keys(prevStatus).map((kay) => [kay, false])),
            [model]: true,
        }));
    };

    const formateSubSection = ({ id, dataForMap }) => {
        //this function is used to formate subsection data as per needed
        const item = dataForMap?.find((item) => item?.id == id);
        return { label: item?.internal_name, value: id };
    };

    return (
        <>
            <div className='flex flex-col md:mb-1'>
                <span className='paragraph-large-medium pb-4 md:pb-2'>{section != ':section' ? section : ''}</span>
            </div>
            {tableList.length > 0 || (loaderStatus.isLoading && allFalse) ? (
                tableList?.map((section, index) => {
                    return (
                        <div
                            className='relative mb-8'
                            key={index}>
                            {loaderStatus.isLoading && allFalse ? (
                                <TableLoader />
                            ) : (
                                <>
                                    <div className='flex flex-row items-center'>
                                        <span className='paragraph-large-medium mobile:paragraph-small-regular z-[7]'>
                                            {section?.subSection?.internal_name}
                                        </span>
                                        <span className='paragraph-small-small mobile:paragraph-small-regular ml-2'>
                                            {'Showing'} {section?.table?.length} of {section?.totalTable} results
                                        </span>
                                        {section?.nextPageUrl && section?.lastPage > Number(section?.currentPage) && (
                                            <div
                                                onClick={() =>
                                                    handleShowMore({
                                                        section,
                                                        index,
                                                        setloaderStatus,
                                                        setTableList,
                                                        selectedSection,
                                                        selectedOutletId,
                                                    })
                                                }
                                                className='min-w-[24px] cursor-pointer w-24'>
                                                <span className='paragraph-small mobile:paragraph-small-regular ml-2 text-primary-600 hover:underline underline-offset-3'>
                                                    {'Show More'}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className='grid grid-cols-6 pageContentSmall:grid-cols-4 relative z-[7] md:grid-cols-2 md:-mx-2 text-center'>
                                        {section.table.length < 1 && !loaderStatus.isLoading && (
                                            <div className='mb-8'>
                                                <Table
                                                    page='tableManagement'
                                                    key={index}
                                                />
                                            </div>
                                        )}
                                        {section.table.map((item, key) => (
                                            <div
                                                key={key}
                                                className='relative'>
                                                <Table
                                                    page='tableManagement'
                                                    edit={isEditMode}
                                                    tableAllocation={item.current_session ? 'seated' : 'empty'}
                                                    tableName={item.internal_name}
                                                    maxCapacity={item.capacity}
                                                    onEditPress={() =>
                                                        handlePopupStatus({ model: 'editTable', value: item })
                                                    }
                                                />
                                                {isEditMode && (
                                                    <div
                                                        className='grid grid-cols-6 pageContentSmall:grid-cols-4 absolute top-0 w-full h-full z-[5] md:grid-cols-2'
                                                        key={key}>
                                                        <GridBlockPlus
                                                            key={key}
                                                            edit={isEditMode}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        {isEditMode && section.table.length != 0
                                            ? [...Array(gridBlock(section.table.length))].map((item, index) => {
                                                  return (
                                                      <div
                                                          className='grid grid-cols-6 pageContentSmall:grid-cols-4 relative top-0 w-full h-full z-[5] md:grid-cols-3'
                                                          key={index}>
                                                          <GridBlockPlus
                                                              key={index}
                                                              edit={isEditMode}
                                                          />
                                                      </div>
                                                  );
                                              })
                                            : null}
                                    </div>
                                </>
                            )}

                            <div className='absolute left-0 top-0 h-full w-6 z-[6] -ml-2.5 bg-white' />
                        </div>
                    );
                })
            ) : (
                <Table
                    page='tableManagement'
                    edit={false}
                    tableAllocation={'empty'}
                    maxCapacity={0}
                />
            )}
        </>
    );
};
export default Tables;
