import React, { useState } from 'react';
import { ReactComponent as VegIcon } from '../../../Assets/vegetable-icon.svg';
import { ReactComponent as NonVegIcon } from '../../../Assets/non-veg.svg';
import { ReactComponent as DropDownIcon } from '../../../Assets/chevron-down.svg';

export const DishItems = (props) => {
    const { quantity } = props.el;
    const [clickCount, setClickCount] = useState(0);
    const [showWithItems, setShowWithItems] = useState(false);
    const [showAddonItems, setShowAddonIcon] = useState(false);
    const [isGreen, setIsGreen] = useState(props.el.updatedQuantity && true);

    const handleClickItem = () => {
        if (isGreen) {
            setIsGreen(false);
            props.handleUpdateOrder(true);
            return;
        }

        if (clickCount < quantity) {
            const newClickCount = clickCount + 1;
            setClickCount(newClickCount);
            const isFullyStruckThrough = newClickCount >= quantity;
            props.handleUpdateOrder(true, isFullyStruckThrough);
        }
    };

    const getStruckThroughQuantity = () => {
        return clickCount > 0 ? ('0' + clickCount).slice(-2) : '';
    };
    const isFullyStruckThrough = clickCount >= quantity;

    return (
        <>
            <div
                className='flex flex-row justify-between items-start cursor-pointer'
                onClick={handleClickItem}>
                <div className='flex flex-row items-start w-full'>
                    <div
                        className={`paragraph-medium-semi-bold ${isGreen && 'text-tertiary-800'} ${
                            isFullyStruckThrough ? 'text-red-500 line-through' : ''
                        }`}>
                        {props?.el?.items_translation?.title[1]}
                        <span className='ml-2 inline-block align-middle'>
                            {props?.el?.items?.food_type === 'vegetarian' ? (
                                <VegIcon
                                    height={16}
                                    width={16}
                                />
                            ) : (
                                <NonVegIcon
                                    height={16}
                                    width={16}
                                />
                            )}
                        </span>
                    </div>
                </div>

                <div className='flex flex-row items-baseline gap-1'>
                    {clickCount > 0 && (
                        <div className='paragraph-x-small-strikethrough text-red-500'>{getStruckThroughQuantity()}</div>
                    )}
                    <div className='max-w-[90px] sm:max-w-[65px] text-right'>
                        <div className='paragraph-medium-semi-bold'>{('0' + quantity).slice(-2)}</div>
                    </div>
                </div>
            </div>

            <div className='pl-[21px] ml-2 border-l border-neutral-300 mt-1'>
                {props.el.customization && (
                    <div>
                        <div className='flex flex-row items-center'>
                            <div className='paragraph-small-medium title-sap relative'>With</div>

                            <div
                                className='cursor-pointer'
                                onClick={() => setShowWithItems((prevState) => !prevState)}>
                                <DropDownIcon
                                    height={20}
                                    className={`${showWithItems && 'rotate-180'}`}
                                />
                            </div>
                        </div>

                        <div
                            className={`flex flex-col paragraph-small-italic text-neutral-500 mb-1 ${
                                !showWithItems && 'hidden'
                            }`}>
                            {props.el.customization.map((customizationEl, index) => (
                                <div
                                    className='mt-1'
                                    key={index}>
                                    {customizationEl.displayName}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {props.el.addons && (
                    <div className='w-full max-w-[199px]'>
                        <div className='flex flex-row items-center'>
                            <div className='paragraph-small-medium title-sap relative'>Note</div>
                            <div
                                className='cursor-pointer'
                                onClick={() => setShowAddonIcon((prevState) => !prevState)}>
                                <DropDownIcon
                                    height={20}
                                    className={`${showAddonItems && 'rotate-180'}`}
                                />
                            </div>
                        </div>
                        <p
                            className={`mt-1 flex flex-col paragraph-small-italic text-neutral-500 ${
                                !showAddonItems && clickCount > 0 && 'hidden'
                            }`}>
                            {props.el.addons}
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};
