import React, { useState } from 'react';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { ReactComponent as ExportIcon } from '../../../../Assets/export.svg';
import CalenderField from '../../../../Components/Calender/CalenderField';

import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import APIV3 from '../../../../api/axios/APIV3';

const Header = (props) => {
    const { selectedDates, setSelectedDates, setIsLoading, updatePaginationState, orderDetails } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [isDisable, setIsDisable] = useState(false);

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    let data;

    const handleClickExportData = async () => {
        setIsDisable(true);
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);

        data = {
            type: 'cashback_report',
            start_date: formatDate(from_date),
            end_date: formatDate(to_date),
            restaurant_id: selectedRestaurantId,
        };

        try {
            const response = await APIV3.post(`export-report`, data);

            if (response.success === true) {
                setIsDisable(false);
            } else {
                setIsDisable(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className='flex flex-row justify-between border-b border-neutral-300 pb-4 lg:mb-3 md:block md:mb-4'>
                <div className='flex flex-row'>
                    <CalenderField
                        setSelectedDates={setSelectedDates}
                        selectedDates={selectedDates}
                    />
                </div>

                {/* <div className='flex flex-row md:justify-between md:mt-4'>
                    <div
                        className='md:w-1/2 md:ml-[8.5px] mobile:ml-1'
                        onClick={handleClickExportData}>
                        <LargePrimaryButton
                            label='Export data'
                            leftIconDefault={<ExportIcon stroke='#FFFFFF' />}
                            leftIconClick={<ExportIcon stroke='#C4BEED' />}
                            hideLabel='lg:hidden md:block'
                            isClicked={isDisable}
                        />
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default Header;
