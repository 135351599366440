import React from 'react';
import { ReactComponent as CloseIcon } from '../../Assets/close.svg';

export default function Tag(props) {
    const { tag, onClose, disabled } = props;

    return (
        <>
            <div className='border border-neutral-300 bg-neutral-50 px-2 rounded flex flex-row items-center w-fit mt-1'>
                <span className={`py-0.5 paragraph-x-small-regular mr-1  ${disabled && 'text-neutral-400'}`}>
                    {tag}
                </span>

                <CloseIcon
                    width={12}
                    height={12}
                    onClick={() => !disabled && onClose()}
                    className={!disabled ? 'cursor-pointer' : 'cursor-not-allowed'}
                />
            </div>
        </>
    );
}
