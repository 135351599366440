import APIV1 from '../../../api/axios/APIV1';
import APIV5 from '../../../api/axios/APIV5';
import store from '../../../reduxStore/index';

const selectedRestaurantId = store.getState().auth.selectedRestaurantId;
const selectedOutletId = store.getState().outlet.selectedOutletId;

export const fetchWaiterOrders = async ({
    startDate,
    endDate,
    selectedOrderingMode,
    selectedRestaurantId,
    selectedStatus,
    perPage,
    page,
}) => {
    const requestedData = {
        per_page: perPage,
        status: selectedStatus,
        start_date: startDate,
        end_date: endDate,
        // page: page,
        ordering_mode: selectedOrderingMode,
    };

    try {
        const response = await APIV1.get(`/restaurants/${selectedRestaurantId}/waiters/orders`, {
            params: requestedData,
        });
        if (response.status < 200 || response.status >= 300) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.orders || [];
    } catch (error) {
        console.error('Error fetching waiter orders:', error);
        return [];
    }
};

export const fetchOrderDetail = async (orderId) => {
    try {
        const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/orders/${orderId}`);
        return response ? response?.order : {};
    } catch (error) {
        console.error('Error fetching order details:', error);
        return {};
    }
};
