import React from 'react';
import { ReactComponent as VegIcon } from '../../../../../Assets/vegetable-icon.svg';
import { ReactComponent as NonVegIcon } from '../../../../../Assets/non-veg.svg';

export default function GroupCard(props) {
    const { name, image, isVeg } = props;

    return (
        <>
            <div className='border border-neutral-300 rounded-md px-4 py-2'>
                <div className='flex flex-row items-start gap-2'>
                    <img
                        src={image}
                        alt=''
                        className='rounded-lg w-[78px] h-[78px]'
                    />
                    <div className='flex flex-row gap-1'>
                        <span className='heading-h6-medium-desktop w-[170px] line-clamp-2'>{name}</span>
                        {isVeg ? (
                            <VegIcon
                                height={14}
                                width={14}
                                className='mt-2'
                            />
                        ) : (
                            <NonVegIcon
                                height={14}
                                width={14}
                                className='mt-2'
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
