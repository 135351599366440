import React, { useEffect, useState } from 'react';
import Tag from '../../../../../Components/Tag/Tag';
import PaytmGatewayDetails from './Components/PaytmGatewayDetails';
import Layout from '../Components/Layout';
import { useFormContext } from 'react-hook-form';
import DropdownWithValueLabel from '../../../../../Components/DropDown/DropdownWithValueLabel';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';

export default function PaymentSetting(props) {
    const { fetchSetting, handleDiscardForm } = props;

    const {
        handleSubmit,
        formState: { isDirty, isValid },
        watch,
        setValue,
        reset,
    } = useFormContext();

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updatePaymentSetting = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=payment_settings`, { ...params });
            if (response.success) {
                reset();
                setShowFooter(false);
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const formatData = (data) => {
        const payment_settings = {};

        selectedpaymentType.forEach((item) => {
            if (item.value === 'stripe') {
                payment_settings.stripe = {
                    STRIPE_KEY: data.stripe.stripe_key,
                    STRIPE_SECRET: data.stripe.stripe_secret,
                    STRIPE_WEBHOOK: data.stripe.stripe_webhook,
                    STRIPE_WEBHOOK_SECRET: data.stripe.stripe_webhook_secret,
                };
            } else if (item.value === 'paytm') {
                payment_settings.paytm = {
                    PAYTM_MERCHANT_KEY: data.paytm.paytm_merchant_key,
                    PAYTM_MERCHANT_ID: data.paytm.paytm_merchant_id,
                    PAYTM_INDUSTRY_TYPE_ID: data.paytm.paytm_industry_type_id,
                    PAYTM_WEBSITE: data.paytm.paytm_website,
                    PAYTM_BASE_API_URL: data.paytm.paytm_base_api_url,
                    PAYTM_CHILD_MERCHANT_ID: data.paytm.paytm_child_merchant_id,
                };
            }
        });

        return { payment_settings };
    };

    const onSubmit = (data) => {
        updatePaymentSetting(formatData(data.payment_settings));
    };

    const relatedField = {
        paytm: [
            {
                label: '(Merchant key)',
                placeholder: 'Enter merchant key',
                id: 'payment_settings.paytm.paytm_merchant_key',
                isRequired: true,
            },
            {
                label: '(Merchant ID)',
                placeholder: 'Enter merchant ID',
                id: 'payment_settings.paytm.paytm_merchant_id',
                isRequired: true,
            },
            {
                label: '(Child merchant ID)',
                placeholder: 'Enter child merchant ID',
                id: 'payment_settings.paytm.paytm_child_merchant_id',
                isRequired: true,
            },
            {
                label: '(Industry type ID)',
                placeholder: 'Enter industry type ID',
                id: 'payment_settings.paytm.paytm_industry_type_id',
                isRequired: true,
            },
            {
                label: '(Website)',
                placeholder: 'Enter website',
                id: 'payment_settings.paytm.paytm_website',
                isRequired: true,
            },
            {
                label: '(Base API URL)',
                placeholder: 'Enter base API URL',
                id: 'payment_settings.paytm.paytm_base_api_url',
                isRequired: true,
            },
        ],
        stripe: [
            {
                label: '(Stripe key)',
                placeholder: 'Please enter stripe key',
                id: 'payment_settings.stripe.stripe_key',
                isRequired: true,
            },
            {
                label: '(Stripe secret)',
                placeholder: 'Please enter stripe secret',
                id: 'payment_settings.stripe.stripe_secret',
                isRequired: true,
            },
            {
                label: '(Stripe webhook)',
                placeholder: 'Please enter stripe webhook',
                id: 'payment_settings.stripe.stripe_webhook',
                isRequired: true,
            },
            {
                label: '(Stripe webhook secret)',
                placeholder: 'Please enter webhook secret',
                id: 'payment_settings.stripe.stripe_webhook_secret',
                isRequired: true,
            },
        ],
    };

    const [selectedpaymentType, setSelectedpaymentType] = useState([]);

    const initialPaymentType = [
        { label: 'Paytm', value: 'paytm' },
        { label: 'Stripe', value: 'stripe' },
    ];

    const [paymentType, setPaymentType] = useState(initialPaymentType);

    const handleItem = (selection) => {
        setPaymentType((prevState) => prevState.filter((el) => el.value !== selection.value));
        setSelectedpaymentType((prevState) => [...prevState, selection]);
    };

    const removeTag = (tag) => {
        setPaymentType((prevState) => [...prevState, tag]);
        setSelectedpaymentType((prevState) => prevState.filter((el) => el.value !== tag.value));
        setShowFooter(true);
    };

    const onReset = () => {
        setPaymentType(initialPaymentType);
        setSelectedpaymentType([]);

        if (watch('defaultValue.payment_settings')?.hasOwnProperty('paytm')) {
            handleItem(initialPaymentType[0]);
        }

        if (watch('defaultValue.payment_settings')?.hasOwnProperty('stripe')) {
            handleItem(initialPaymentType[1]);
        }
    };

    useEffect(() => {
        if (!watch('payment_settings') || !!selectedpaymentType.length) return;

        initialPaymentType.forEach((item) => {
            if (watch('payment_settings').hasOwnProperty(item.value)) {
                handleItem(item);
            }
        });

        // eslint-disable-next-line
    }, [watch('payment_settings')]);

    // console.log('payment_settings ==>', watch('payment_settings'));

    // console.log('payment_settings ==>', watch('payment_settings'));

    const [showFooter, setShowFooter] = useState(false);

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            showFooter={isDirty || showFooter}
            handleClickCancel={() => {
                handleDiscardForm();
                setShowFooter(false);
                onReset();
            }}>
            <div className='max-w-[636px] w-full md:max-w-full md:pb-20'>
                <div className='pb-6'>
                    <TitleDescription
                        title='Select payment gateways'
                        description='Select your payment gateway from the list. You can set up multiple payment providers if you have accounts available with them.'
                    />

                    <div className='w-full max-w-[312px] md:max-w-full mt-4'>
                        <DropdownWithValueLabel
                            label='(Select payment providers)'
                            placeholder='Select multiple providers'
                            labelStyle='paragraph-medium-italic text-neutral-500'
                            shadow='shadow-smallDropDownShadow'
                            menuItems={paymentType}
                            setSelected={(selection) => {
                                handleItem(selection);
                                setShowFooter(true);
                            }}
                            hasFixedPlaceholder
                            labelPaddingB='pb-2'
                        />
                    </div>

                    <div className='align-top'>
                        {selectedpaymentType?.map((el, index) => (
                            <div
                                className='mr-2 inline-block'
                                key={index}>
                                <Tag
                                    tag={el.label}
                                    onClose={() => removeTag(el)}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                {selectedpaymentType.map((el, index) => {
                    return (
                        <div
                            className='pt-6 pb-6 first:mt-0 border-t border-neutral-300'
                            key={index}>
                            <PaytmGatewayDetails
                                mainKey={el.value}
                                title={`${el.label} gateway`}
                                tagLine={`Enter your ${el.label} payment gateway API details.`}
                                relatedField={relatedField[el.value]}
                            />
                        </div>
                    );
                })}
            </div>
        </Layout>
    );
}
