import React from 'react';
import ChoiceCard from '../../../Cards/ChoiceCard';
import { useSelector } from 'react-redux';

export default function CardSection(props) {
    const { selectedAddon } = props;

    const selectedLanguageId = useSelector((state) => state.langauge.languageId);

    return (
        <>
            <div className='flex flex-col gap-2'>
                {selectedAddon?.choices?.map((el, index) => (
                    <ChoiceCard
                        isVeg={el.food_type === 'vegetarian'}
                        name={el.translations.name[selectedLanguageId]}
                        image={el.image_url?.image_url}
                    />
                ))}
            </div>
        </>
    );
}
