import { Menu, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { ReactComponent as DropdownIcon } from '../../../../../Assets/chevron-down.svg';

export default function DetailsDropDown(props) {
    const { handleChangeDropDown, menuItem, fixedHeight, openFromBottom } = props;

    const [selectedItem, setSelectedItem] = useState(props.selectedItem ?? menuItem?.[0]);

    const handleClickItem = (item) => {
        handleChangeDropDown(item);
        setSelectedItem(item);
    };

    return (
        <div className='dropdown-icon w-fit'>
            <Menu
                as='div'
                className='relative inline-block text-left'>
                <div>
                    <Menu.Button className='remove-shadow paragraph-medium-regular inline-flex items-center text-primary-500 w-full justify-center rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                        {selectedItem?.label}
                        <span className='-mr-1.5'>
                            <DropdownIcon
                                className='drop-down-icon-rotate'
                                height={16}
                                fill='#6C5DD3'
                            />
                        </span>
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'>
                    <Menu.Items
                        className={`absolute shadow-medium right-0 mt-2.5 p-4 w-full min-w-[164px] origin-top-right rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none paragraph-small-medium ${
                            fixedHeight && fixedHeight + ' overflow-auto scrollbar-style'
                        } ${openFromBottom && 'bottom-full mb-2'}`}>
                        {menuItem?.map((el, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`cursor-pointer ${index === 0 ? 'pt-0' : 'pt-4'}`}
                                    onClick={() => handleClickItem(el)}>
                                    <Menu.Item>
                                        <span className='hover:text-primary-500'>{el?.label}</span>
                                    </Menu.Item>
                                </div>
                            );
                        })}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}
