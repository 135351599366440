import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SmallPrimaryButton } from '../../../../../../Components/Buttons/Button';
import { ReactComponent as AddIcon } from '../../../../../../Assets/add.svg';
import CardSection from './Components/CardSection.js';

export default function ChoiceSection(props) {
    const { selectedAddon } = props;

    const navigate = useNavigate();

    return (
        <>
            <div className='flex flex-col'>
                <div className='flex flex-row justify-between w-full items-center md:pb-2 pb-4'>
                    <span className='paragraph-large-medium text-[#000000]'>Addon Choice</span>
                    <div
                        className='max-w-[131px] cursor-pointer'
                        onClick={() => navigate('/menu/addon/choice/basic-details')}>
                        <SmallPrimaryButton
                            leftIconClick={
                                <AddIcon
                                    stroke='#C4BEED'
                                    height={208}
                                    width={20}
                                />
                            }
                            leftIconDefault={
                                <AddIcon
                                    stroke='#FFFFFF'
                                    height={20}
                                    width={20}
                                />
                            }
                            label='Add choice'
                        />
                    </div>
                </div>

                <CardSection selectedAddon={selectedAddon} />
            </div>
        </>
    );
}
