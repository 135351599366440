import React, { useEffect, useState } from 'react';
import PaginationWithNumber from '../../Components/Pagination/PaginationWithNumber';
import OrderDetailsPopup from '../../Components/CustomerOrderDetails/OrderDetailsPopup';
import SelectOfferPage from '../../Components/SelectOffer/SelectOfferPage';
import { useNavigate, useParams } from 'react-router-dom';
import { IsMobileScreen } from '../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import HeaderOrderDetails from './Components/HeaderOrderDetails';
import OrderDetailsTop from './Components/OrderDetailsTop';
import { TableOrderDetails } from './Components/TableList';
import APIV5 from '../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';

export default function CustomerOrderDetails() {
    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const { id } = useParams();

    const [customerDetails, setCustomerDetails] = useState([]);

    const [isCustomerTopLoading, setIsCustomerTopLoading] = useState(false);

    const [isCustomerOrderListLoading, setIsCustomerOrderListLoading] = useState(false);

    const selectedLanguageId = useSelector((state) => state.langauge.languageId);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [offerCreated, setOfferCreated] = useState(false);

    const [ShowSendOffer, setShowSendOffer] = useState(false);

    const [filters, setFilters] = useState({ primaryfilter: '', secondaryFilter: '' });

    const screenHeight = useWindowSize().height;

    const isMobileScreen = IsMobileScreen();

    const today = new Date();

    const [selectedDates, setSelectedDates] = useState([today, today]);

    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });

    const [createOfferDetails, setCreateOfferDetails] = useState({ partial_amount: null, id: null, gross_total: null });

    const [customerOrderDetailList, setCustomerOrderDetailList] = useState([]);

    const [isPopupLoading, setIsPopupLoading] = useState(false);

    const [popupDetails, setPopupDetails] = useState({});

    const [cashbacks, setCashbacks] = useState({});

    const [popupOrderId, setPopupOrderId] = useState(null);
    const fetchOrderListForCustomers = async () => {
        try {
            setIsCustomerTopLoading(true);
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/customers/${id}`);
            setCustomerDetails(response.customer);
            setIsCustomerTopLoading(false);
        } catch (error) {
            console.log('error <====>', error);
        }
    };

    useEffect(() => {
        fetchOrderListForCustomers();
    }, [id]);

    const handleClickBillName = () => {
        setShowOrderDetails(!showOrderDetails);
    };

    const handleClickSendOffer = () => {
        setShowSendOffer(!ShowSendOffer);
    };
    const navigate = useNavigate();

    const goToCustomerInformation = () => {
        navigate('/customer-information');
    };

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const defaultParams = () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        const filterParams = {};
        if (
            (filters.primaryfilter === 'order_date' || filters.primaryfilter === 'revenue_generated') &&
            filters.secondaryFilter
        ) {
            filterParams.filter = { column: filters.primaryfilter, direction: filters.secondaryFilter };
        }

        if (filters.primaryfilter === 'order_status') {
            filterParams.order_status = filters.secondaryFilter;
        }
        if (filters.primaryfilter === 'order_type') {
            filterParams.ordering_mode_ids = filters.secondaryFilter;
        }
        if (filters.primaryfilter === 'payment_method') {
            filterParams.payment_method = filters.secondaryFilter;
        }

        return {
            ...filterParams,
            start_date: formatDate(from_date),
            end_date: formatDate(to_date),
        };
    };

    const fetchCustomerOrderDetail = async (params) => {
        try {
            setIsCustomerOrderListLoading(true);
            // const defaultParamsData = defaultParams();
            // console.log(`defaultParamsData ==>`,defaultParamsData)
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/customers/${id}/orders`, {
                params: {
                    ...params,
                    // ...defaultParamsData,
                    per_page: params.per_page ?? paginationState.resultPerPage,
                    page: params.page ?? paginationState.selectedPage,
                },
            });
            setCustomerOrderDetailList(response.customer.data);
            setIsCustomerOrderListLoading(false);
            setPaginationState((prevState) => ({
                ...prevState,
                lastPage: response.customer.last_page,
                selectedPage: response.customer.current_page,
            }));
        } catch (error) {
            console.log('error <====>', error);
        }
    };

    useEffect(() => {
        const filterParams = {};

        if (
            (filters.primaryfilter === 'order_date' || filters.primaryfilter === 'revenue_generated') &&
            filters.secondaryFilter
        ) {
            filterParams.filter = { column: filters.primaryfilter, direction: filters.secondaryFilter };
        }

        if (filters.primaryfilter === 'order_status') {
            filterParams.order_status = filters.secondaryFilter;
        }
        if (filters.primaryfilter === 'order_type') {
            filterParams.ordering_mode_ids = filters.secondaryFilter;
        }
        if (filters.primaryfilter === 'payment_method') {
            filterParams.payment_method = filters.secondaryFilter;
        }

        let params = {
            ...filterParams,
            per_page: paginationState.resultPerPage,
            page: paginationState.selectedPage,
        };
        fetchCustomerOrderDetail(params);
    }, [
        selectedDates,
        paginationState.resultPerPage,
        paginationState.selectedPage,
        filters.secondaryFilter,
        offerCreated,
    ]);

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    const fetchOrderDetailPopup = async (orderId) => {
        try {
            setIsPopupLoading(true);
            const response = await APIV5.get(`orders/${orderId}`);
            const matchedOrder = customerOrderDetailList.find((order) => order.order_id === popupOrderId);
            if (matchedOrder) {
                setCashbacks({
                    ...cashbacks,
                    cashback_earned: matchedOrder.cashback_earned,
                    cashback_redeemed: matchedOrder.cashback_redeemed,
                });
            }
            setPopupDetails(response.order_details);
            setIsPopupLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const calculateTotalQuantity = (cartItems) =>
        cartItems?.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0) || 0;

    const orderingMode = {
        'dine_in': 'Dine-In',
        'delivery': 'Delivery',
        'takeaway': 'Takeaway',
        'CASH_ON_DELIVERY': 'Cash',
        'CREDIT_CARD/DEBIT_CARD': 'Card',
        'CREDIT_CARD': 'Credit card',
        'DEBIT_CARD': 'Debit card',
        'UPI': 'UPI',
        'UPI_INTENT': 'UPI',
        'BALANCE': 'Balance',
    };

    function formatTime(time) {
        const [hours, minutes] = time.split(':');
        const hour = parseInt(hours, 10);
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 24 || 24;
        const formattedTime = `${formattedHour}:${minutes} ${period}`;

        return formattedTime;
    }

    const formattedDate = (dateString) => {
        return dateString ? format(new Date(dateString), 'dd MMM yyyy') : 'N/A';
    };

    const popupData = [
        {
            item: 'Order bill name',
            value: popupDetails && popupDetails?.order_bill_name ? popupDetails?.order_bill_name : 'NA',
        },
        {
            item: 'Order date',
            value: popupDetails && popupDetails?.order_date ? popupDetails?.order_date : 'NA',
        },
        {
            item: 'Order type',
            value: popupDetails && popupDetails?.order_type ? orderingMode[popupDetails?.order_type] : 'NA',
        },
        {
            item: 'Order taken by',
            value:
                popupDetails && popupDetails?.rider
                    ? `${popupDetails.rider.first_name} ${popupDetails.rider.last_name}`
                    : 'NA',
        },
        {
            item: "Customer's order",
            value: popupDetails && popupDetails?.customers_order ? popupDetails?.customers_order : 'NA',
        },
        {
            item: 'Order time',
            value: popupDetails && popupDetails?.order_time ? formatTime(popupDetails?.order_time) : 'NA',
        },
        {
            item: 'Payment method',
            value: popupDetails && popupDetails?.payment_method ? orderingMode[popupDetails?.payment_method] : 'NA',
        },
        {
            item: 'Dishes ordered',
            value:
                popupDetails && popupDetails?.cart?.cart_items
                    ? calculateTotalQuantity(popupDetails?.cart?.cart_items)
                    : 'NA',
        },
    ];

    const feedbacks = {
        orderFeedback: popupDetails?.order_feedback,
        riderFeedback: popupDetails?.rider_feedback,
        date: formattedDate(popupDetails?.order_date),
    };

    const otherAmount = [
        { type: 'Gross total', value: popupDetails?.gross_total?.toFixed(2) },
        {
            type: 'Other charges & taxes',
            value: (
                popupDetails?.cart?.gst_amount +
                popupDetails?.cart?.packaging_charge +
                popupDetails?.cart?.convenience_charge
            )?.toFixed(2),
        },
        {
            type: 'Delivery charge',
            value: popupDetails?.cart?.delivery_charge?.toFixed(2),
        },
    ];

    const extraCharges = [
        {
            gst_amount: popupDetails?.cart?.gst_amount,
            packaging_charge: popupDetails?.cart?.packaging_charge,
            convenience_charge: popupDetails?.cart?.convenience_charge,
        },
    ];

    const handleOfferCreate = async () => {
        try {
            const response = await APIV5.post(`orders/${createOfferDetails.id}/create-offer`, {
                discount_amount: createOfferDetails.partial_amount ?? createOfferDetails.gross_total,
            });
            setShowOrderDetails(false);
            setOfferCreated(!offerCreated);
        } catch (error) {
            console.log('Error creating offer:', error);
        }
    };

    return (
        <>
            <div className='bg-[#fafafa]'>
                {((!showOrderDetails && !ShowSendOffer) || !isMobileScreen) && (
                    <div
                        className={`px-8 pb-[30px] lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white`}
                        style={{ minHeight: screenHeight - 50 }}>
                        <OrderDetailsTop
                            goToCustomerInformation={goToCustomerInformation}
                            customerDetails={customerDetails}
                            isCustomerTopLoading={isCustomerTopLoading}
                        />
                        <HeaderOrderDetails
                            handleClickSendOffer={handleClickSendOffer}
                            setSelectedDates={setSelectedDates}
                            selectedDates={selectedDates}
                            setFilters={setFilters}
                            filters={filters}
                            setPaginationState={setPaginationState}
                        />
                        <TableOrderDetails
                            isCustomerOrderListLoading={isCustomerOrderListLoading}
                            handleClickBillName={handleClickBillName}
                            customerOrderDetailList={customerOrderDetailList}
                            fetchOrderDetailPopup={fetchOrderDetailPopup}
                            setPopupOrderId={setPopupOrderId}
                            setCreateOfferDetails={setCreateOfferDetails}
                            fetchCustomerOrderDetail={fetchCustomerOrderDetail}
                            paginationState={paginationState}
                        />

                        {!_.isEmpty(customerOrderDetailList) && (
                            <div className='pt-4'>
                                <PaginationWithNumber
                                    lastPage={paginationState.lastPage}
                                    selectedPage={paginationState.selectedPage}
                                    setSelectedPage={(value) => {
                                        handlePagination({ selectedPage: value }, 'selectedPage');
                                    }}
                                    setResultPerPage={(value) => {
                                        handlePagination({ resultPerPage: value }, 'resultPerPage');
                                    }}
                                    selectedResultPerpage={paginationState.resultPerPage}
                                    isLoading={isCustomerOrderListLoading}
                                />
                            </div>
                        )}
                    </div>
                )}
                {showOrderDetails && (
                    <OrderDetailsPopup
                        code={popupDetails?.last_four_character_order_label}
                        isPopupLoading={isPopupLoading}
                        orderedDishes={popupDetails?.cart?.cart_items}
                        isRiderFeedback={popupData[2]?.value}
                        orderDetails={popupData}
                        extraCharges={extraCharges}
                        otherAmount={otherAmount}
                        netTotal={popupDetails?.total_bill_amount}
                        feedbacks={feedbacks}
                        isPaid={popupDetails?.paid}
                        cashbacks={cashbacks}
                        popupDetails={popupDetails}
                        grossTotal={popupDetails?.gross_total}
                        selectedLangaugeId={selectedLanguageId}
                        popupOrderId={popupOrderId}
                        page='customerInformation'
                        handleClickClose={() => {
                            setShowOrderDetails(false);
                        }}
                        headerTabletScreen='Back to individual details'
                        handleClickSendOffer={handleClickSendOffer}
                        setCreateOfferDetails={setCreateOfferDetails}
                        handleOfferCreate={handleOfferCreate}
                    />
                )}
                {/* {ShowSendOffer && (
                    <SelectOfferPage
                        title='Send offer'
                        handleClickClose={handleClickSendOffer}
                        tagLine='Select and send the offer to your customer'
                    />
                )} */}
            </div>
        </>
    );
}
