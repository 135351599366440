import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IsMobileScreen } from '../../Constants/Constants';
import APIV2 from '../../api/axios/APIV2';
import { useSelector } from 'react-redux';
import AddBannerPopup from './Component/AddBannerPopup';
import EditBannerPopup from './Component/EditBannerPopup';
import _ from 'lodash';
import TableControllerButtons from './Component/TableControllerButtons';
import Header from './Component/Header';
import LinkOutletPopup from './Component/LinkOutletComponent/LinkOutletPopup';
import Table from './Component/ListComponent/Table';
import { bannerCategoryType } from './Utils/constants';
import RearrangeFlyout from './Component/RearrangeFlyout/RearrangeFlyout';
import { useWindowSize } from '@uidotdev/usehooks';

export default function Banners() {
    const { tab, bannerType } = useParams();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [bannerCatalogueList, setBannerCatalogueList] = useState({});

    const [storeBannerList, setStoreBannerList] = useState({});

    const [selectedBanners, setSelectedBanners] = useState([]);

    const [isShowAddBannerPopup, setIsShowAddBannerPopup] = useState(false);

    const [editBannerData, setEditBannerData] = useState({});

    const [linkBannerData, setLinkBannerData] = useState({});

    const [isLoading, setIsLoading] = useState(true);

    const initialPageState = {
        isRearrange: false,
        isSelectionEnable: false,
        isEditbanner: false,
    };

    const [pageState, setPageState] = useState(initialPageState);

    const initialLastPageState = {
        catalogue: { primaryBanner: 1, secondaryBanner: 1, tertiaryBanner: 1 },
        store: { primaryBanner: 1, secondaryBanner: 1, tertiaryBanner: 1 },
    };

    const [lastPage, setLastPage] = useState(initialLastPageState);

    const [resultPerPage, setResultPerPage] = useState(10);

    const [selectedPage, setSelectedPage] = useState(1);

    const isMobileScreen = IsMobileScreen();

    const catalogueListApi = async (params) => {
        setIsLoading(true);
        try {
            const response = await APIV2.get(`/restaurants/${selectedRestaurantId}/banners`, { params });

            response.success && setIsLoading(false);

            return await response.banners;
        } catch (error) {
            console.log(error);
        }
    };

    const setCatalogueList = (bannerCategory, data) => {
        setBannerCatalogueList((prevState) => {
            return { ...prevState, [bannerCategory.value]: data.data };
        });

        setLastPage((prevState) => {
            return {
                ...prevState,
                catalogue: { ...prevState.catalogue, [bannerCategory.value]: data.last_page },
            };
        });
    };

    const fetchCatalogueList = async (bannerCategory, page, result) => {
        const category = bannerCategory ?? bannerCategoryType.find((category) => category.url === bannerType);

        const params = {
            per_page: result ?? resultPerPage,
            banner_type: category.apiParam,
            page: page ?? selectedPage,
        };

        const data = await catalogueListApi(params);

        setCatalogueList(category, data);
    };

    const storeBannerListApi = async (params) => {
        setIsLoading(true);
        try {
            const response = await APIV2.get(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/banners`,
                { params }
            );

            response.success && setIsLoading(false);

            return await response.banners;
        } catch (error) {
            console.log(error);
        }
    };

    const setBannerListOfStore = (bannerCategory, data) => {
        setStoreBannerList((prevState) => {
            return { ...prevState, [bannerCategory.value]: data.data };
        });

        setLastPage((prevState) => {
            return {
                ...prevState,
                store: { ...prevState.catalogue, [bannerCategory.value]: data.last_page },
            };
        });
    };

    const fetchStoreBannerList = async (bannerCategory, page, result) => {
        const category = bannerCategory ?? bannerCategoryType.find((category) => category.url === bannerType);

        const params = {
            per_page: result ?? resultPerPage,
            banner_type: category.apiParam,
            page: page ?? selectedPage,
        };

        const data = await storeBannerListApi(params);

        setBannerListOfStore(category, data);
    };

    const fetchData = () => {
        const type = bannerCategoryType.filter((el) => el.url === bannerType)[0];

        if (tab === 'banner-catalogue') {
            fetchCatalogueList(type);
            return;
        }

        if (tab === 'store-banner') {
            fetchStoreBannerList(type);
        }
    };

    useEffect(() => {
        if (!selectedRestaurantId) {
            return;
        }

        fetchData();

        setPageState(initialPageState);

        // eslint-disable-next-line
    }, [tab, selectedRestaurantId]);

    useEffect(() => {
        if (!selectedRestaurantId) {
            return;
        }

        setLastPage(initialLastPageState);

        const type = bannerCategoryType.filter((el) => el.url === bannerType)[0];

        setStoreBannerList({});

        fetchStoreBannerList(type);

        setPageState(initialPageState);

        // eslint-disable-next-line
    }, [selectedOutletId]);

    const handleChangeResultPerPage = (resultPerPage) => {
        setResultPerPage(resultPerPage);

        const type = bannerCategoryType.find((el) => el.url === bannerType);

        tab !== 'banner-catalogue'
            ? fetchStoreBannerList(type, null, resultPerPage)
            : fetchCatalogueList(type, null, resultPerPage);
    };

    const canFetchCatalogList = () => {
        let lastPageNumber;

        if (tab === 'banner-catalogue') {
            const value = bannerCategoryType.find((el) => el.url === bannerType).value;

            lastPageNumber = lastPage.catalogue[value];
        } else {
            const value = bannerCategoryType.find((el) => el.url === bannerType).value;

            lastPageNumber = lastPage.store[value];
        }

        return lastPageNumber === selectedPage;
    };

    const [rearrangeBannerList, setRearrangeBannerList] = useState([]);

    const showMainLayout =
        !isMobileScreen ||
        (!isShowAddBannerPopup &&
            _.isEmpty(editBannerData) &&
            _.isEmpty(linkBannerData) &&
            !rearrangeBannerList?.length);

    const bannerCategory = bannerCategoryType.find((category) => category.url === bannerType)?.value;

    useEffect(() => {
        !!pageState.isRearrange && setRearrangeBannerList(storeBannerList[bannerCategory]);
    }, [pageState.isRearrange]);

    const updateSequence = () => {
        fetchStoreBannerList();
    };

    const { height: screenHeight } = useWindowSize();

    return (
        <>
            <div className='bg-[#fafafa]'>
                {showMainLayout && (
                    <div
                        className='px-8 pb-5 lg:px-4 lg:pb-0 pt-4 w-full max-w-[1336px] mx-auto bg-white'
                        style={{ minHeight: screenHeight - 50 }}>
                        <Header
                            tab={tab}
                            setIsShowAddBannerPopup={setIsShowAddBannerPopup}
                            bannerType={bannerType}
                            setPageState={setPageState}
                            bannerCatalogueList={bannerCatalogueList}
                            storeBannerList={storeBannerList}
                        />

                        <TableControllerButtons
                            tab={tab}
                            selectedBanners={selectedBanners}
                            fetchCatalogueList={fetchCatalogueList}
                            setSelectedBanners={setSelectedBanners}
                            bannerType={bannerType}
                            fetchStoreBannerList={fetchStoreBannerList}
                            bannerCatalogueList={bannerCatalogueList}
                            storeBannerList={storeBannerList}
                            setPageState={setPageState}
                            pageState={pageState}
                        />

                        <Table
                            fetchCatalogueList={fetchCatalogueList}
                            fetchStoreBannerList={fetchStoreBannerList}
                            tab={tab}
                            storeBannerList={storeBannerList}
                            setStoreBannerList={setStoreBannerList}
                            bannerCatalogueList={bannerCatalogueList}
                            setSelectedBanners={setSelectedBanners}
                            selectedBanners={selectedBanners}
                            bannerType={bannerType}
                            setEditBannerData={setEditBannerData}
                            setLinkBannerData={setLinkBannerData}
                            lastPage={lastPage}
                            resultPerPage={resultPerPage}
                            handleChangeResultPerPage={handleChangeResultPerPage}
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                            setPageState={setPageState}
                            pageState={pageState}
                            isLoading={isLoading}
                        />
                    </div>
                )}
            </div>

            {isShowAddBannerPopup && (
                <AddBannerPopup
                    bannerType={bannerType}
                    handleClickClose={() => setIsShowAddBannerPopup(false)}
                    fetchCatalogueList={fetchCatalogueList}
                    canFetchCatalogList={canFetchCatalogList()}
                />
            )}

            {!_.isEmpty(editBannerData) && (
                <EditBannerPopup
                    handleClickClose={() => setEditBannerData({})}
                    editBannerData={editBannerData}
                    fetchCatalogueList={fetchCatalogueList}
                    // ImageRenderOnTabs={tab}
                />
            )}

            {!_.isEmpty(linkBannerData) && (
                <LinkOutletPopup
                    handleClickClose={() => setLinkBannerData({})}
                    linkBannerData={linkBannerData}
                    fetchCatalogueList={fetchCatalogueList}
                    fetchStoreBannerList={fetchStoreBannerList}
                />
            )}

            {!!rearrangeBannerList?.length ? (
                <RearrangeFlyout
                    menuItem={rearrangeBannerList}
                    setMenuItem={setRearrangeBannerList}
                    selectedPage={selectedPage}
                    catalogueListApi={(params) => catalogueListApi(params)}
                    handleClickClose={() => setRearrangeBannerList([])}
                    updateSequence={updateSequence}
                />
            ) : null}
        </>
    );
}
