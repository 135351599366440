import React from 'react';
import { ReactComponent as CrossArrowIcon } from '../../Assets/cross-arrow.svg';
import { ReactComponent as RatingIcon } from '../../Assets/order-ratings.svg';
import { ReactComponent as RedirectIcon } from '../../Assets/redirect.svg';
import { AboutDots } from '../AboutDots/AboutDots';

export default function Card(props) {
    const {
        title,
        ratingCard,
        heightLightedTitle,
        tag,
        description,
        showDetailsPopup,
        icon,
        cardHeight,
        hasAboutDots,
        handleClick,
        customerId,
        recordsStatus,
    } = props;

    const getRatingColors = (rating) => {
        const fill = rating >= 4 ? '#EBF6F5' : rating >= 3 ? '#FFF5E3' : '#FEF2F2';
        const stroke = rating >= 4 ? '#3D8C82' : rating >= 3 ? '#FFA704' : '#EF4444';
        return { fill, stroke };
    };

    const { fill: ratingIconFill, stroke: ratingIconStroke } = getRatingColors(heightLightedTitle);

    const iconBG = !(title === 'Swiggy' || title === 'Zomato') && 'bg-primary-500';

    return (
        <>
            <div className={`border border-neutral-300 rounded-md px-4 py-2.5 pb-4 relative w-full ${cardHeight}`}>
                <div className='paragraph-small-semi-bold text-neutral-500 mb-3'>{title}</div>

                <div className='flex flex-row items-center mb-3'>
                    {ratingCard && (
                        <span className='mr-2'>
                            <RatingIcon
                                stroke={ratingIconStroke}
                                fill={ratingIconFill}
                            />
                        </span>
                    )}

                    <span className='heading-h6-semi-bold-desktop mr-2'>
                        {heightLightedTitle && typeof heightLightedTitle === 'number'
                            ? heightLightedTitle
                            : heightLightedTitle}
                    </span>

                    {tag && (
                        <div
                            className={`px-1 border w-fit ${
                                recordsStatus === 'increment'
                                    ? 'bg-tertiary-100 border-tertiary-800'
                                    : recordsStatus === 'neutral'
                                      ? 'bg-gray-100 border-gray-600'
                                      : 'bg-destructive-100 border-destructive-600'
                            } rounded flex flex-row items-center h-4`}>
                            <div
                                className={`leading-3 text-[10px] font-medium ${
                                    recordsStatus === 'increment'
                                        ? 'text-tertiary-800'
                                        : recordsStatus === 'neutral'
                                          ? 'text-gray-600'
                                          : 'text-destructive-600'
                                }`}>
                                {tag}%
                            </div>

                            {recordsStatus !== 'neutral' && (
                                <CrossArrowIcon
                                    stroke={
                                        recordsStatus === 'increment'
                                            ? '#3D8C82'
                                            : recordsStatus === 'neutral'
                                              ? '#808080'
                                              : '#DC2626'
                                    }
                                    height={11}
                                    width={11}
                                    className={`${recordsStatus === 'decrement' && 'rotate-90'}`}
                                />
                            )}
                        </div>
                    )}
                </div>

                <div className='flex flex-row items-center justify-between w-full'>
                    <div className='min-h-[40px]'>{description}</div>

                    {hasAboutDots && (
                        <div
                            onClick={() => handleClick(customerId)}
                            className='cursor-pointer py-2'>
                            <RedirectIcon
                                height={24}
                                width={24}
                            />
                        </div>
                    )}
                </div>

                <div className={`absolute right-4 top-3 h-8 w-8 rounded-lg justify-center flex ${iconBG}`}>
                    <span className='m-auto'>{icon}</span>
                </div>
            </div>
        </>
    );
}
