import React, { useEffect, useState } from 'react';

import CustomerInformationPopup from './Popup/CustomerInformationPopup';
import TableNumberPopup from './Popup/TableNumberPopup';
import FireDishesPopup from './Popup/FireDishesPopup';
import { IsMobileScreen } from '../../Constants/Constants';

import ConfirnOrderPopup from './Popup/ConfirnOrderPopup';
import CategoriesSection from './Sections/CategoriesSection';
import DishesSection from './Sections/DishesSection';
import OrderSummery from './Sections/OrderSummery';
import AddPaymentPopup from '../Order/Popup/AddPaymentPopup/AddPaymentPopup';
import Layout from './Components/Layout';
import MobileFooter from './Components/MobileFooter';
import MobileCategoryPopup from './Popup/MobileCategoryPopup/MobileCategoryPopup';
import CustomizeVariantPopUp from './Popup/CustomizeVariant/CustomizeVariantPopUp';
import CustomizeAddonPopUp from './Popup/CustomizeAddon/CustomizeAddonPopUp';
import OrderDetailsPopup from '../../Components/CustomerOrderDetails/OrderDetailsPopup';
import ApplyOffers from './Sections/Components/ApplyOffers';
import AddEditNotePopup from './Popup/AddEditNote/AddEditNotePopup';
import { useSelector } from 'react-redux';
import { json } from 'react-router-dom';

export default function POS(props) {
    const { handelHoldOrder } = props;

    const [showOrdersView, setShowOrdersView] = useState(false);

    const isMobileScreen = IsMobileScreen();

    const [showPopup, setShowPopup] = useState();
    const [selectedCategory, setSelectedCategory] = useState();

    const showMobileLayout = isMobileScreen && showPopup === 'addPayment';

    const categories = useSelector((state) => state.outlet.categories);
    const items = useSelector((state) => state.outlet.items);

    // Create a map to store parent categories and their subcategories
    const categoryMap = {};

    // Iterate over each category to build the structure
    categories.forEach((category) => {
        if (category.parent_id == null) {
            categoryMap[category.id] = { ...category, subcategories: [], items: [] };
        } else {
            if (categoryMap[category.parent_id]) {
                categoryMap[category.parent_id].subcategories.push({ ...category, items: [] });
            } else {
                categoryMap[category.parent_id] = { subcategories: [{ ...category, items: [] }] };
            }
        }
    });

    // Convert the map into an array of objects
    const structuredCategories = Object.values(categoryMap);

    // Function to find and assign items to categories and subcategories
    const assignItemsToCategories = (categories, items) => {
        categories.forEach((category) => {
            // Filter and assign items to the category based on category_item_links
            category.items = JSON.parse(category.category_item_links)
                .map((link) => items.find((item) => item.id === link.item_id))
                .filter(Boolean); // Remove any undefined values

            // Recursively assign items to subcategories
            if (category?.subcategories?.length > 0) {
                assignItemsToCategories(category.subcategories, items);
            }
        });
    };

    // Assign items to categories and subcategories
    assignItemsToCategories(structuredCategories, items);

    useEffect(() => {
        if (selectedCategory === undefined && structuredCategories.length > 0) {
            setSelectedCategory(structuredCategories?.[0]?.id);
        }
    }, [selectedCategory, structuredCategories]);

    return (
        <>
            {!showMobileLayout && (
                <>
                    <Layout>
                        {(!showOrdersView || !isMobileScreen) && (
                            <div className='w-full pt-4 h-full flex flex-col'>
                                <DishesSection
                                    setShowPopup={setShowPopup}
                                    categoryItems={structuredCategories.find((item) => item.id == selectedCategory)}
                                />

                                {!isMobileScreen && (
                                    <CategoriesSection
                                        structuredCategories={structuredCategories}
                                        setSelectedCategory={setSelectedCategory}
                                    />
                                )}
                            </div>
                        )}

                        {(showOrdersView || !isMobileScreen) && (
                            <OrderSummery
                                setShowOrdersView={setShowOrdersView}
                                handelHoldOrder={handelHoldOrder}
                                setShowPopup={setShowPopup}
                            />
                        )}
                    </Layout>

                    <MobileFooter setShowOrdersView={setShowOrdersView} />
                </>
            )}

            {showPopup === 'mobileCategory' && <MobileCategoryPopup handleClickClose={() => setShowPopup(null)} />}

            {showPopup === 'customizeVariant' && (
                <CustomizeVariantPopUp
                    setShowPopup={setShowPopup}
                    handleClickClose={() => setShowPopup(null)}
                />
            )}

            {showPopup === 'customizeAddon' && (
                <CustomizeAddonPopUp
                    setShowPopup={setShowPopup}
                    handleClickClose={() => setShowPopup(null)}
                />
            )}

            {showPopup === 'applyOffer' && (
                <ApplyOffers
                    handleClickClose={() => setShowPopup(null)}
                    backToPage='order summery'
                />
            )}

            {showPopup === 'customerInformation' && <OrderDetailsPopup handleClickClose={() => setShowPopup(null)} />}

            {showPopup === 'addEditNote' && <AddEditNotePopup handleClickClose={() => setShowPopup(null)} />}

            {showPopup === 'enterCustomerDetails' && (
                <CustomerInformationPopup
                    handleClickClose={() => setShowPopup(null)}
                    setShowPopup={setShowPopup}
                />
            )}

            {showPopup === 'enterTableNumber' && <TableNumberPopup handleClickClose={() => setShowPopup(null)} />}

            {showPopup === 'confirmOrder' && (
                <ConfirnOrderPopup
                    handleClickClose={() => setShowPopup(null)}
                    setShowPopup={setShowPopup}
                />
            )}

            {showPopup === 'fireDishes' && (
                <FireDishesPopup
                    handleClickClose={() => (isMobileScreen ? setShowPopup('confirmOrder') : setShowPopup())}
                />
            )}

            {showPopup === 'addPayment' && <AddPaymentPopup handleClickClose={() => setShowPopup(null)} />}
        </>
    );
}
