// export const BASE_API_URL = process.env.REACT_APP_BASE_URL;
export const REACT_APP_BASE_URLV2 = 'https://api.staging.fab.delivery/api/v2';
export const REACT_APP_BASE_URL_V2_1 = 'https://api.staging.fab.delivery/api/v2_1';
export const REACT_APP_BASE_URL_V3 = 'https://api.staging.fab.delivery/api/v3';
export const REACT_APP_BASE_URL_V4 = 'https://api.staging.fab.delivery/api/v4';
export const REACT_APP_BASE_URL_V5 = 'https://api.staging.fab.delivery/api/v5';
export const REACT_APP_BASE_URL_V1 = 'https://api.staging.fab.delivery/api/v1';

export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyB6X4yskdEEp-3VwH4uCIBtCiiYZT5PXI0',
    authDomain: 'orderloop-admin-panel-redesign.firebaseapp.com',
    projectId: 'orderloop-admin-panel-redesign',
    storageBucket: 'orderloop-admin-panel-redesign.appspot.com',
    messagingSenderId: '661413195106',
    appId: '1:661413195106:web:993037b399d09b9e45d03b',
    measurementId: 'G-61BXKNK7WZ',
};

export const vapidKey = 'BE1uKJi02Z1kQ3MII-GyUgIA8SSG57hPCoqINn0JTdzr9ev8SuDpXqXgh4vbj2qy6VVuFM4OEwY3T8gQVZ4-mU4';
