import React from 'react';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import { ReactComponent as LeftIcon } from '../../../../Assets/chevron-down.svg';
import { Link } from 'react-router-dom';

export default function Layout(props) {
    const { children } = props;

    const isMobileScreen = IsMobileScreen();

    const { height: screenHeight } = useWindowSize();

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className='px-8 lg:px-4 pt-4 w-full max-w-[1336px] mx-auto bg-white relative md:max-w-full md:pb-10'
                    style={{ minHeight: !isMobileScreen && screenHeight - 50 }}>
                    <Link
                        to='/menu'
                        className='flex flex-row items-center gap-3 cursor-pointer'>
                        <LeftIcon className='rotate-90' />
                        <span className='paragraph-large-medium text-neutral-700'>Back to menu management</span>
                    </Link>

                    {children}
                </div>
            </div>
        </>
    );
}
