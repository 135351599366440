import React from 'react';
import { useWindowSize } from '@uidotdev/usehooks';
import { useNavigate, useParams } from 'react-router-dom';
import LeftPanel from './Components/LeftPanel/LeftPanel';
import Layout from './Components/Layout/Layout';
import {
    BasicDetails,
    Clubbing,
    Configure,
    NotificationSettings,
    OfferTiming,
    OfferType,
    TermsAndConditions,
    ValidityAndApplicability,
    VisibilityAndLinking,
} from './TabComponents/index';
import ItemList from './TabComponents/ItemList/ItemList';
import { FormProvider, useForm } from 'react-hook-form';

export default function CreateOffer(props) {
    const { defaultValue } = props;

    const { section } = useParams();

    const componentMapping = {
        'offer-type': OfferType,
        'basic-details': BasicDetails,
        'configure': Configure,
        'clubbing': Clubbing,
        'visibility-linking': VisibilityAndLinking,
        'validity-applicability': ValidityAndApplicability,
        'offer-timing': OfferTiming,
        'terms-conditions': TermsAndConditions,
        'item-list': ItemList,
        'notification-settings': NotificationSettings,
    };

    const ComponentToRender = componentMapping[section];

    const pageSize = useWindowSize();

    const pageStyle = { height: pageSize.width > 767 ? pageSize.height - 165 : 'auto' };

    const methods = useForm({
        defaultValues: !!defaultValue
            ? { ...defaultValue, delete_ordering_modes: [] }
            : {
                  delete_ordering_modes: [],
                  allow_offer_clubbing: { label: 'No', value: false },
                  display_in_cart_tab: { label: 'No', value: false },
                  display_in_offers_tab: { label: 'No', value: false },
                  do_add_user: { label: 'No', value: false },
                  has_coupon_limit: { label: 'No', value: false },
                  has_usage_number: { label: 'No', value: false },
                  hasMinMaxOrderCount: { label: 'No', value: false },
                  has_offer_validity: { label: 'No', value: false },
                  is_minimum_order_count_enable: { label: 'No', value: false },
              },
    });

    const navigate = useNavigate();

    const { watch } = methods;

    if (section !== 'offer-type' && !watch('offer_type')?.value) {
        navigate('/offers/create-offer/offer-type');
        return;
    }

    return (
        <FormProvider {...methods}>
            <Layout>
                <div className='px-8 lg:px-4 flex flex-row'>
                    <LeftPanel />

                    <div
                        className='overflow-auto pb-8 md:overflow-visible px-1 md:px-0 w-full'
                        style={pageStyle}>
                        <ComponentToRender />
                    </div>
                </div>
            </Layout>
        </FormProvider>
    );
}
