import React from 'react';
import { ReactComponent as TrashIcon } from '../../Assets/trash.svg';
import { ErrorMessage } from '../Error/ErrorMessage';
import { LargeDestructiveButton } from '../Buttons/Button';
import CircleLoader from '../CircleLoader/CircleLoader';

export default function ConfirmDeletePopup(props) {
    const {
        handleClickClose,
        header,
        description,
        errorWhileDelete,
        handleDelete,
        showLoadingButton,
        isDelete,

        loaderStatus,
    } = props;

    const isClicked = loaderStatus?.isClicked;
    const isLoading = loaderStatus?.isLoading;
    const isResponseSuccess = loaderStatus?.isResponseSuccess;

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-[101] md:px-4 flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4 relative'>
                    <div className='pb-3 flex flex-row items-center'>
                        <div className='p-2 bg-destructive-200 rounded-md'>
                            <TrashIcon stroke='#EF4444' />
                        </div>
                        {header}
                    </div>

                    {description}

                    {errorWhileDelete && (
                        <div className='mb-3'>
                            <ErrorMessage errors={errorWhileDelete} />
                        </div>
                    )}

                    <div className='flex flex-row justify-between'>
                        <button
                            onClick={handleClickClose}
                            className='h-12 w-[196px] md:w-1/2 md:mr-[7.5px] paragraph-medium-medium rounded-md text-neutral-500 bg-shades-50 border border-neutral-300'>
                            Cancel
                        </button>

                        {showLoadingButton ? (
                            <div
                                className='h-12 w-[196px] md:w-1/2 md:ml-[7.5px] paragraph-medium-medium rounded-md'
                                onClick={handleDelete}>
                                <LargeDestructiveButton
                                    label={
                                        !(isClicked && isDelete) ? (
                                            'Delete section'
                                        ) : (
                                            <CircleLoader
                                                isLoading={isLoading}
                                                isResponseSuccess={isResponseSuccess}
                                            />
                                        )
                                    }
                                    isClicked={!isDelete}
                                />
                            </div>
                        ) : (
                            <button
                                className='h-12 w-[196px] md:w-1/2 md:ml-[7.5px] paragraph-medium-medium rounded-md text-shades-50 bg-destructive-500'
                                onClick={handleDelete}>
                                Delete
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
