import React, { useEffect, useState } from 'react';
import { preferenceMenuItem } from '../../../Constants/Constants';
import { useNavigate, useParams } from 'react-router-dom';
import {
    OrderingMode,
    Appearance,
    Address,
    BillSetting,
    BillPayment,
    Contacts,
    ServiceRadius,
    PreparationTime,
    DeliveryCharges,
    PaymentSetting,
    PaymentMethod,
    Currency,
    Timing,
    RiderSetting,
    Taxes,
    FoodLicense,
    OtherSetting,
    OtherCharges,
    ThirdPartyPOS,
    CodLimit,
    EnableCash,
    MOV,
} from './TabComponent';
import { FormProvider, useForm } from 'react-hook-form';
import APIV5 from '../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import Tabs from './Components/Tabs';
import { countryCodes, currencyData } from '../../../Components/MobileNumberInputFlagDropDown/CountryData';
import WarningPopup from '../Components/WarningPopup';
import StoreSettingParentComponent from './Components/StoreSettingParentComponent';
import Loader from './Components/Loader';

export default function StoreSetting() {
    const { tab } = useParams();

    const methods = useForm();

    const menuItemPreparationTiming = [
        { label: 'No rush hours', value: 'no_time_slots' },
        { label: 'Specific time for all days', value: 'same_time_slot_for_all_days' },
        { label: 'Specific time for each day', value: 'different_time_slots_for_different_days' },
    ];

    const menuItemTiming = [
        { label: 'Full time', value: 'no_time_slots' },
        { label: 'Specific time for all days', value: 'same_time_slot_for_all_days' },
        { label: 'Specific time for each day', value: 'different_time_slots_for_different_days' },
    ];

    const paymentType = [
        { label: 'Paytm', value: 'paytm' },
        { label: 'Stripe', value: 'stripe' },
    ];

    const [showLoader, setShowLoader] = useState(true);

    const {
        setValue,
        getValues,
        reset,
        watch,
        formState: { isDirty },
    } = methods;

    const componentMapping = {
        'ordering-mode': OrderingMode,
        'appearance': Appearance,
        'address': Address,
        'bill-settings': BillSetting,
        'bill-payments': BillPayment,
        'contacts': Contacts,
        'service-radius': ServiceRadius,
        'preparation-time': PreparationTime,
        'delivery-charges': DeliveryCharges,
        'payment-setting': PaymentSetting,
        'payment-method': PaymentMethod,
        'currency': Currency,
        'timing': Timing,
        'rider-setting': RiderSetting,
        'taxes': Taxes,
        'food-license': FoodLicense,
        'other-setting': OtherSetting,
        'other-charges': OtherCharges,
        'third-party-POS': ThirdPartyPOS,
        'cod-limit': CodLimit,
        'enable-cash': EnableCash,
        'mov': MOV,
    };

    const [showTabChangeWarningPopup, setShowTabChangeWarningPopup] = useState(false);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const ComponentToRender = componentMapping[tab];

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const countryName = countryCodes.map((el) => {
        return { label: el.fullName, value: el };
    });

    const formateOutletAddress = (data) => {
        const outletAddressData = data.outlet_address;

        setValue(
            'defaultValue.outlet_address.countryCode',
            countryName.find((el) => el.value.shortName === outletAddressData.iso_country_code)
        );

        setValue(
            'outlet_address.countryCode',
            countryName.find((el) => el.value.shortName === outletAddressData.iso_country_code)
        );

        Object.keys(outletAddressData.translations.city).forEach((el, index) => {
            setValue(`outlet_address.translations.city[${el}]`, outletAddressData.translations.city[el]);
            setValue(`defaultValue.outlet_address.translations.city[${el}]`, outletAddressData.translations.city[el]);
        });

        Object.keys(outletAddressData.translations.state).forEach((el, index) => {
            setValue(`outlet_address.translations.state[${el}]`, outletAddressData.translations.state[el]);
            setValue(`defaultValue.outlet_address.translations.state[${el}]`, outletAddressData.translations.state[el]);
        });

        Object.keys(outletAddressData.translations.address_line_1).forEach((el, index) => {
            setValue(
                `outlet_address.translations.address_line_1[${el}]`,
                outletAddressData.translations.address_line_1[el]
            );
            setValue(
                `defaultValue.outlet_address.translations.address_line_1[${el}]`,
                outletAddressData.translations.address_line_1[el]
            );
        });

        Object.keys(outletAddressData.translations.address_line_2).forEach((el, index) => {
            setValue(
                `outlet_address.translations.address_line_2[${el}]`,
                outletAddressData.translations.address_line_2[el]
            );
            setValue(
                `defaultValue.outlet_address.translations.address_line_2[${el}]`,
                outletAddressData.translations.address_line_2[el]
            );
        });
    };

    const formateServiceRadius = (isEnable) => {
        !isEnable
            ? setValue('service_radius.thirdPartyRiderService', preferenceMenuItem[1])
            : setValue('service_radius.thirdPartyRiderService', preferenceMenuItem[0]);

        !isEnable
            ? setValue('defaultValue.service_radius.thirdPartyRiderService', preferenceMenuItem[1])
            : setValue('defaultValue.service_radius.thirdPartyRiderService', preferenceMenuItem[0]);
    };

    const formatePreparationTiming = (data, type) => {
        if (type === 'same_time_slot_for_all_days') {
            const filteredDataForSingleDay = data.filter((el) => el.day_of_week === 1);

            setValue('preparation_time.rushHourType', menuItemPreparationTiming[1]);
            setValue('defaultValue.preparation_time.rushHourType', menuItemPreparationTiming[1]);

            const formatSchedule = filteredDataForSingleDay.map((el) => ({
                ...el,
                preparation_time_in_rush: el.outlet_rush_hour_setting.preparation_time_in_rush,
            }));

            setValue('rushHourSchedule', formatSchedule);
            setValue('defaultValue.rushHourSchedule', filteredDataForSingleDay);
            return;
        }

        if (type === 'different_time_slots_for_different_days') {
            setValue('preparation_time.rushHourType', menuItemPreparationTiming[2]);
            setValue('defaultValue.preparation_time.rushHourType', menuItemPreparationTiming[2]);

            const filteredData = [[], [], [], [], [], [], []];

            data.forEach((item) => {
                const index = item.day_of_week - 1;
                filteredData[index].push({
                    start_time: item.start_time,
                    end_time: item.end_time,
                    preparation_time_in_rush: item.outlet_rush_hour_setting.preparation_time_in_rush,
                });
            });

            setValue('rushHourSchedule', filteredData);
            setValue('defaultValue.rushHourSchedule', filteredData);
            return;
        }

        setValue('preparation_time.rushHourType', menuItemPreparationTiming[0]);
        setValue('rushHourSchedule', []);

        setValue('defaultValue.preparation_time.rushHourType', menuItemPreparationTiming[0]);
        setValue('defaultValue.rushHourSchedule', []);
    };

    const formatDifferentTimeInTimingTab = (data, orderingMode) => {
        setValue(
            'timing_0_timingType',
            menuItemTiming.find((el) => el.value === data.time_slot_setting)
        );

        setValue(
            'defaultValue.timing_0_timingType',
            menuItemTiming.find((el) => el.value === data.time_slot_setting)
        );

        if (data.time_slot_setting === menuItemTiming[1].value) {
            setValue(
                'timing_0',
                data.timeslots.filter((slot) => slot.day_of_week === 1)
            );

            setValue(
                'defaultValue.timing_0',
                data.timeslots.filter((slot) => slot.day_of_week === 1)
            );
        } else if (data.time_slot_setting === menuItemTiming[2].value) {
            const timeSlots = Array.from({ length: 7 }, () => []);

            data.timeslots.forEach((item) => {
                const index = item.day_of_week - 1;
                timeSlots[index].push({
                    start_time: item.start_time,
                    end_time: item.end_time,
                });
            });

            setValue(`timing_0`, timeSlots);

            setValue(`defaultValue.timing_0`, timeSlots);
        }

        setValue(
            'timing.timeSlots',
            orderingMode.map((el) => el)
        );

        setValue(
            'defaultValue.timing.timeSlots',
            orderingMode.map((el) => el)
        );

        orderingMode.map((el) => setValue(`timing_${el.id}_otherDetails`, el));
        orderingMode.map((el) => setValue(`defaultValue.timing_${el.id}_otherDetails`, el));

        orderingMode.forEach((el) => {
            if (el.time_slot_setting === menuItemTiming[0].value) {
                setValue(`timing_${el.id}_timingType`, menuItemTiming[0]);
                setValue(`defaultValue.timing_${el.id}_timingType`, menuItemTiming[0]);
                return;
            }

            if (el.time_slot_setting === menuItemTiming[1].value) {
                setValue(`timing_${el.id}_timingType`, menuItemTiming[1]);
                setValue(`defaultValue.timing_${el.id}_timingType`, menuItemTiming[1]);

                const singleDaySlots = el.timeslots.filter((slot) => slot.day_of_week === 1);

                setValue(`timing_${el.id}`, singleDaySlots);
                setValue(`defaultValue.timing_${el.id}`, singleDaySlots);
                return;
            }

            if (el.time_slot_setting === menuItemTiming[2].value) {
                setValue(`timing_${el.id}_timingType`, menuItemTiming[2]);
                setValue(`defaultValue.timing_${el.id}_timingType`, menuItemTiming[2]);

                const timeSlots = Array.from({ length: 7 }, () => []);

                el.timeslots.forEach((item) => {
                    const index = item.day_of_week - 1;
                    timeSlots[index].push({
                        start_time: item.start_time,
                        end_time: item.end_time,
                    });
                });

                setValue(`timing_${el.id}`, timeSlots);
                setValue(`defaultValue.timing_${el.id}`, timeSlots);

                return;
            }
        });
    };

    const decodeEntities = (s) => {
        var str,
            temp = document.createElement('p');
        temp.innerHTML = s;
        str = temp.textContent || temp.innerText;
        temp = null;
        return str;
    };

    const formatOtherCharges = (data) => {
        setValue('other_charges', data);
        setValue('defaultValue.other_charges', data);

        const firstElement = data[0];

        const keepSamePrice = data
            .map(
                (item) =>
                    item.packaging_charge === firstElement.packaging_charge &&
                    item.convenience_charge === firstElement.convenience_charge
            )
            .every((val) => val);

        setValue('otherCharges.keepSamePrice', keepSamePrice);
        setValue('defaultValue.otherCharges.keepSamePrice', keepSamePrice);

        data.forEach((el, index) => {
            el.is_convenience_charge_applicable === 0
                ? setValue(`other_charges.${index}.enableConvenienceCharge`, preferenceMenuItem[1])
                : setValue(`other_charges.${index}.enableConvenienceCharge`, preferenceMenuItem[0]);
            el.is_packaging_charge_applicable === 0
                ? setValue(`other_charges.${index}.enablePackaging`, preferenceMenuItem[1])
                : setValue(`other_charges.${index}.enablePackaging`, preferenceMenuItem[0]);
        });
    };

    const formatPaymentMethod = (data) => {
        const cardPaymentProvider = data.find(
            (el) =>
                el.payment_method === 'CREDIT_CARD/DEBIT_CARD' ||
                el.payment_method === 'DEBIT_CARD' ||
                el.payment_method === 'CREDIT_CARD'
        )?.payment_provider;

        setValue(
            'payment_methods.CREDIT_CARD/DEBIT_CARD',
            paymentType.find((el) => el.value === cardPaymentProvider)
        );

        setValue(
            'defaultValue.payment_methods.CREDIT_CARD/DEBIT_CARD',
            paymentType.find((el) => el.value === cardPaymentProvider)
        );

        const singleModeById = data.filter(
            (el) =>
                el.restaurant_ordering_mode_id === data[0].restaurant_ordering_mode_id &&
                (el.payment_provider === 'paytm' || el.payment_provider === 'stripe')
        );

        const initialMethod = [
            {
                label: '(Credit/Debit card)',
                placeholder: 'Select multiple providers',
                isRequired: true,
                id: 'payment_methods.CREDIT_CARD/DEBIT_CARD',
                method: 'CREDIT_CARD/DEBIT_CARD',
            },
            {
                label: '(Net banking)',
                placeholder: 'Select multiple providers',
                isRequired: true,
                id: 'payment_methods.NET_BANKING',
                method: 'NET_BANKING',
            },
            {
                label: '(Balance)',
                placeholder: 'Select multiple providers',
                isRequired: true,
                id: 'payment_methods.BALANCE',
                method: 'BALANCE',
            },
            {
                label: '(UPI)',
                placeholder: 'Select multiple providers',
                isRequired: true,
                id: 'payment_methods.UPI',
                method: 'UPI',
            },
            {
                label: '(UPI Intent)',
                placeholder: 'Select multiple providers',
                isRequired: true,
                id: 'payment_methods.UPI_INTENT',
                method: 'UPI_INTENT',
            },
        ];

        let reorderedMethod = [...initialMethod];

        const creditDebitIndex = initialMethod.findIndex((method) => method.method === 'CREDIT_CARD/DEBIT_CARD');

        if (
            singleModeById.some((item) => ['CREDIT_CARD', 'DEBIT_CARD'].includes(item.payment_method)) &&
            !reorderedMethod.some((item) => item.method === 'CREDIT_CARD/DEBIT_CARD')
        ) {
            reorderedMethod.splice(creditDebitIndex, 0, initialMethod[creditDebitIndex]);
        }

        setValue('payment_methods.sequence', reorderedMethod);
    };

    const formateAndSetOrderingMode = (storeOrderingMode, globalOrderingMode) => {
        const filteredStore = storeOrderingMode.filter((storeItem) =>
            globalOrderingMode.some((globalItem) => globalItem.id === storeItem.restaurant_ordering_mode_id)
        );

        setValue(
            'ordering_modes',
            filteredStore?.sort((a, b) => a.display_rank - b.display_rank)
        );
        setValue(
            'defaultValue.ordering_modes',
            filteredStore?.sort((a, b) => a.display_rank - b.display_rank)
        );
    };

    const setDefaultValue = (data, enabledOrderingMode) => {
        Object.keys(data).forEach((key) => {
            if (key === 'outlet_address') {
                formateOutletAddress(data);
                return;
            }

            if (key === 'other_charges') {
                formatOtherCharges(data[key]);
                return;
            }

            if (key === 'taxes') {
                setValue('taxes.gst_applicable.preference', preferenceMenuItem[data[key].gst_applicable === 1 ? 0 : 1]);
                setValue(
                    'defaultValue.taxes.gst_applicable.preference',
                    preferenceMenuItem[data[key].gst_applicable === 1 ? 0 : 1]
                );
                setValue('taxes.gst_number', data[key].gst_number);
                setValue('defaultValue.taxes.gst_number', data[key].gst_number);
                return;
            }

            if (key === 'preparation_time') {
                const kitchenPreparationTime = data[key]?.kitchen_preparation_times ?? [];
                const hasSamePreparationTime = kitchenPreparationTime.every(
                    (item, _, arr) => item.kitchen_preparation_time == arr[0].kitchen_preparation_time
                );
                kitchenPreparationTime.map((orderingMode, index) => {
                    setValue(
                        `preparation_time.kitchen_preparation_time_${index}`,
                        orderingMode.kitchen_preparation_time
                    );
                });
                setValue('preparation_time.kitchen_preparation_times', kitchenPreparationTime);
                setValue('preparation_time.isSamePreparationTimeForAllOrderingMode', hasSamePreparationTime);
                hasSamePreparationTime &&
                    setValue(
                        'preparation_time.kitchen_preparation_time',
                        kitchenPreparationTime[0].kitchen_preparation_time
                    );
            }
            if (key === 'currency') {
                const selectedCurrency = currencyData.find((el) => el.currencyCode === data[key].currency_code);

                setValue('currency.currency', {
                    label: `${selectedCurrency.countryname}(${selectedCurrency.currencyCode}) - ${decodeEntities(
                        selectedCurrency.symbol
                    )}`,

                    value: selectedCurrency?.currencyCode,
                });

                setValue('defaultValue.currency.currency', {
                    label: `${selectedCurrency.countryname}(${selectedCurrency.currencyCode}) - ${decodeEntities(
                        selectedCurrency.symbol
                    )}`,

                    value: selectedCurrency?.currencyCode,
                });
                return;
            }

            if (key === 'other_settings') {
                setValue(
                    'other_settings.hasAutoAcceptance',
                    preferenceMenuItem[data[key].enable_auto_accept_order === 1 ? 0 : 1]
                );
                setValue(
                    'other_settings.hasOwnRiderService',
                    preferenceMenuItem[data[key].enable_own_rider_service === 1 ? 0 : 1]
                );
                return;
            }

            if (key === 'third_party_pos') {
                setValue(
                    'third_party_pos.enablePetPoojaIntegration',
                    preferenceMenuItem[data[key].petpooja.is_pet_pooja_integration_enabled === 1 ? 0 : 1]
                );

                setValue(
                    'third_party_pos.enablePetPoojaOrder',
                    preferenceMenuItem[data[key].petpooja.is_pet_pooja_order_assignment_enabled === 1 ? 0 : 1]
                );
                setValue(
                    'defaultValue.third_party_pos.enablePetPoojaIntegration',
                    preferenceMenuItem[data[key].petpooja.is_pet_pooja_integration_enabled === 1 ? 0 : 1]
                );
                setValue(
                    'defaultValue.third_party_pos.enablePetPoojaOrder',
                    preferenceMenuItem[data[key].petpooja.is_pet_pooja_order_assignment_enabled === 1 ? 0 : 1]
                );

                setValue(
                    'third_party_pos.enableBillberryIntegration',
                    preferenceMenuItem[data[key].billberry.is_bill_berry_integration_enabled === 1 ? 0 : 1]
                );
            }

            if (key === 'timings') {
                formatDifferentTimeInTimingTab(data[key], data['ordering_modes']);
                return;
            }

            if (key === 'payment_methods') {
                formatPaymentMethod(data[key]);
                return;
            }

            if (key === 'ordering_modes') {
                formateAndSetOrderingMode(data[key], enabledOrderingMode);
                return;
            }

            if (typeof data[key] === 'object') {
                Object.keys(data[key]).forEach((subKey) => {
                    if (subKey === 'consider_third_party_rider_service_radius') {
                        formateServiceRadius(data[key][subKey]);
                    }

                    if (subKey === 'rush_hour_timings') {
                        formatePreparationTiming(data[key][subKey], data[key].rush_hour_time_slot_setting);
                        return;
                    }

                    if (subKey === 'bill_payment_convenience_charge') {
                        setValue('bill_payments.enableConvenienceCharge', data[key][subKey] !== '0.00');
                        setValue('defaultValue.bill_payments.enableConvenienceCharge', data[key][subKey] !== '0.00');
                    }

                    if (subKey === 'taxes_on_delivery_charged') {
                        setValue('delivery_charge.gstSlab', {
                            label: `${data[key][subKey]}%`,
                            value: data[key][subKey],
                        });

                        setValue('defaultValue.delivery_charge.gstSlab', {
                            label: `${data[key][subKey]}%`,
                            value: data[key][subKey],
                        });

                        data[key][subKey] !== 0
                            ? setValue('delivery_charge.hasGstSlab', true)
                            : setValue('delivery_charge.hasGstSlab', false);
                        data[key][subKey] !== 0
                            ? setValue('defaultValue.delivery_charge.hasGstSlab', true)
                            : setValue('defaultValue.delivery_charge.hasGstSlab', false);

                        return;
                    }

                    if (subKey === 'distance_fee') {
                        setValue(
                            'delivery_charge.hasDistanceFees',
                            data[key][subKey].extra_charge !== '0.00' ||
                                data[key][subKey]?.normal_delivery_radius !== '0.00'
                        );

                        setValue(
                            'defaultValue.delivery_charge.hasDistanceFees',
                            data[key][subKey].extra_charge !== '0.00' ||
                                data[key][subKey]?.normal_delivery_radius !== '0.00'
                        );
                    }

                    if (subKey === 'home_screen_layout') {
                        data[key]?.home_screen_layout.sort((a, b) => a.display_rank - b.display_rank);

                        const formattedData = data[key]?.home_screen_layout.map((item, index) => ({
                            ...item,
                            displayRank: index + 1,
                        }));

                        setValue('appearance.home_screen_layout', formattedData);

                        setValue('defaultValue.appearance.home_screen_layout', formattedData);

                        return;
                    }

                    if (subKey === 'default_category_display_count') {
                        if (!data[key].default_category_display_count) {
                            setValue('appearance.default_category_display_count', {
                                label: '02',
                                value: 2,
                            });

                            setValue('defaultValue.appearance.default_category_display_count', {
                                label: '02',
                                value: 2,
                            });
                        } else {
                            setValue('appearance.default_category_display_count', {
                                label: `0${data[key].default_category_display_count}`,
                                value: data[key].default_category_display_count,
                            });

                            setValue('defaultValue.appearance.default_category_display_count', {
                                label: `0${data[key].default_category_display_count}`,
                                value: data[key].default_category_display_count,
                            });
                        }
                        return;
                    }

                    setValue(`defaultValue.${key}.${subKey}`, data[key][subKey]);
                    setValue(`${key}.${subKey}`, data[key][subKey]);
                });
            } else {
                setValue(key, data[key]);
                setValue(`defaultValue.${key}`, data[key]);
            }
        });
        setValue('isFormLoading', false);
    };

    const filterEnabledGlobalOrderingModes = (orderingMode) => {
        return orderingMode.filter((el) => el.status);
    };

    const fetchSetting = async () => {
        setShowLoader(true);

        try {
            const response = await APIV5.get(`outlets/${selectedOutletId}`);

            const enabledOrderingMode = await filterEnabledGlobalOrderingModes(await fetchOrderingMode());

            setDefaultValue(response.outlet, enabledOrderingMode);
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false);
        }
    };

    const fetchOrderingMode = async () => {
        setShowLoader(true);

        try {
            const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/restaurant-ordering-modes`);

            // console.log('response.ordering_modes ==>', JSON.stringify(response.ordering_modes));

            return response.ordering_modes;
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        setValue('isFormLoading', true);

        fetchSetting();

        // eslint-disable-next-line
    }, []);

    const [showWarningPopup, setShowWarningPopup] = useState({ status: false });

    const navigate = useNavigate();

    const handleDiscardForm = () => {
        const defaultValue = getValues('defaultValue');

        reset();

        setValue('defaultValue', defaultValue, { shouldDirty: false });

        Object.entries(defaultValue).forEach(([key, value]) => {
            setValue(key, value, { shouldDirty: false });
        });
    };

    return showLoader ? (
        <Loader />
    ) : (
        <>
            <StoreSettingParentComponent
                defaultValues={watch()}
                fetchSetting={fetchSetting}
                isFormLoading={watch('isFormLoading')}
            />

            {/* <FormProvider {...methods}>
                <div className='flex flex-row md:block'>
                    <Tabs
                        setShowWarningPopup={setShowWarningPopup}
                        showTabChangeWarningPopup={showTabChangeWarningPopup}
                    />

                    <div className='w-full'>
                        <ComponentToRender
                            fetchSetting={fetchSetting}
                            handleDiscardForm={handleDiscardForm}
                            setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                            showTabChangeWarningPopup={showTabChangeWarningPopup}
                        />
                    </div>
                </div>
            </FormProvider>

            {showWarningPopup.status && (
                <WarningPopup
                    handleClickClose={() => {
                        setShowWarningPopup({ status: false });
                    }}
                    handleClickChange={() => {
                        reset();
                        fetchSetting();
                        navigate(showWarningPopup.route);
                        setShowWarningPopup({ status: false });
                        setShowTabChangeWarningPopup(false);
                    }}
                    isDirty={isDirty}
                    showTabChangeWarningPopup={showTabChangeWarningPopup}
                    setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                />
            )} */}
        </>
    );
}
