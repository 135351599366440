import React, { useEffect, useState } from 'react';
import { InputAreaMetaTag, InputFieldMetaTag } from './MetaTagInputs';
import ScheduleNotification from './ScheduleNotification';
import { LargeTertiaryButton } from '../../../Components/Buttons/Button';
import pizza1 from '../../../Assets/MediaLibrary/pizza1.png';
import Dropdown from '../../../Components/DropDown/Dropdown';
import { ReactComponent as CalenderIcon } from '../../../Assets/calendar.svg';
import DropdownSection from '../../../Components/DropDown/DropdownSection';
import { Controller, useFormContext } from 'react-hook-form';
import SelectImageFromLibraryPopup from '../../Banners/Component/SelectImagePopupComponent/SelectImageFromLibraryPopup';
import { ErrorMessage } from '../../../Components/Error/ErrorMessage';
import { useSelector } from 'react-redux';

export default function LangaugeViseDetails(props) {
    const {
        language,
        pageTitle,
        popupDetails,
        hasImageSizeReccomandation,
        selectedTimeType,
        index,
        disabled,
        selectedLangauge,
        details,
        selectedLangaugeId,
        setShowUploadImagePopup,
        id,
        onDataChange,
        metaTagsList,
        screensList,
        categoriesList,
        onDataChangeNotification,
        schedulePresetMenuItems,
        isEditNotificationFieldEnabled,
    } = props;

    const handleClickUploadImage = () => {
        setShowUploadImagePopup(id);
    };

    const languageList = useSelector((state) => state.langauge.availableLanguages);

    const {
        watch,
        setValue,
        formState: { errors },
        control,
    } = useFormContext();

    const [showSelectContent, setShowSelectContent] = useState(false);

    const [selectLibraryPopup, setSelectLibraryPopup] = useState(false);

    const [languageNames, setLanguageNames] = useState([]);

    const [showLibraryImagePopupLanguageId, setShowLibraryImagePopupLanguageId] = useState(null);

    const [showSameAsButton, setShowSameAsButton] = useState(true);

    const handleClickSelectImageContent = () => {
        setSelectLibraryPopup(id);
    };

    useEffect(() => {
        setShowSelectContent(false);
    }, [pageTitle]);

    const detailsLabel = {
        inputFieldLabel: `${language !== 'English' ? language + ':' : ''} Notification title`,
        inputFieldPlaceholder: `Enter notification title in ${language !== 'English' ? language : 'English'}`,
        inputAreaLabel: `${language !== 'English' ? language + ':' : ''} Description`,
        inputAreaPlaceholder: `Enter notification description in ${language !== 'English' ? language : 'English'}`,
        displayScreenDropdownLabel: `${language !== 'English' ? language + ':' : ''} Display screen`,
        displayScreenDropdownPlaceholder: `Select display screen for ${language !== 'English' ? language : 'English'}`,
        scheduleNotificationLabel: `${language !== 'English' ? language + ':' : ''} Schedule notification`,
        selectImageLabel: `${language !== 'English' ? language + ':' : ''} Select image`,
    };

    const MenuItemsScreens = screensList?.map((screen, index) => ({
        label: screen,
        value: index,
    }));

    const MenuItemsCategories = categoriesList?.map((category) => ({
        label: category.internal_name,
        value: category.category_id.toString(),
    }));

    useEffect(() => {
        if (pageTitle !== 'Edit preset') {
            const convertTo12HourFormat = (time) => {
                if (!time) return '';
                const [hours, minutes] = time.split(':');
                let hours12 = parseInt(hours, 10);
                hours12 = hours12 % 12 || 12;
                return `${hours12.toString().padStart(2, '0')}:${minutes}`;
            };

            setValue('notification_selected_time', {
                label: convertTo12HourFormat(popupDetails?.time),
                value: convertTo12HourFormat(popupDetails?.time),
            });

            const screenName = details?.screen;
            const selectedMenuItem = MenuItemsScreens?.find((item) => item.label === screenName);
            if (selectedMenuItem) {
                setValue('screen', selectedMenuItem);
            }

            setValue('notification_time_type', {
                label: popupDetails?.date,
                value: popupDetails?.date,
            });
        }

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const day = date.getDate();
            const monthNames = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ];
            const monthIndex = date.getMonth();
            const year = date.getFullYear();

            return `${day} ${monthNames[monthIndex]} ${year}`;
        };

        const formattedDate = formatDate(details.time_to_send);
        setValue('notification_selected_date', new Date(formattedDate));

        const categoryId = details?.param;
        const selectedCategory = MenuItemsCategories?.find((item) => item.value === categoryId);
        if (selectedCategory) {
            setValue('category', selectedCategory);
        }
    }, [details, setValue]);

    useEffect(() => {
        if (languageList && languageList.length > 0) {
            const names = languageList.map((lang) => lang.language_label);
            setLanguageNames(names);
        }
    }, [languageList]);

    const isFirstEnglish = languageNames.findIndex((name) => name === 'English') === index;

    const scheduleNotificationMenuItems = [
        { label: 'Instant', value: '0' },
        { label: 'Custom date & time', value: 'custom' },
    ];

    const handlePopupReopen = () => {
        watch('imageDetails')?.image_url ? handleClickUploadImage() : handleClickSelectImageContent();
    };
    const handleClickSave = (imageDataObj) => {
        setValue(`imageDetails.${id}`, imageDataObj);
    };

    const handleClickSelectSameImageAsEnglish = () => {
        const englishImageDetails = watch('imageDetails.1');
        if (englishImageDetails) {
            setValue(`imageDetails.${id}`, englishImageDetails);
        }
        setShowSameAsButton(false);
    };

    if (selectLibraryPopup) {
        return (
            <SelectImageFromLibraryPopup
                handleClickClose={() => setSelectLibraryPopup(null)}
                handleSelectImage={(imageDataObj) => handleClickSave(imageDataObj, showLibraryImagePopupLanguageId)}
            />
        );
    }

    return (
        <>
            <div className='w-full'>
                <div className='mb-3.5'>
                    <Controller
                        name={`notification_title.${id}`}
                        control={control}
                        rules={{ required: 'Please enter title' }}
                        render={({ field }) => {
                            return (
                                <InputFieldMetaTag
                                    label={detailsLabel.inputFieldLabel}
                                    menuItems={Object.keys(metaTagsList).map((key) => key)}
                                    placeholder={detailsLabel.inputFieldPlaceholder}
                                    disabled={!disabled}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            );
                        }}
                    />
                    {errors.notification_title && (
                        <ErrorMessage errors={{ message: errors.notification_title[id].message }} />
                    )}
                </div>

                <div className='mb-2.5'>
                    <Controller
                        name={`notification_description.${id}`}
                        control={control}
                        rules={{ required: 'Please enter description' }}
                        render={({ field }) => {
                            return (
                                <InputFieldMetaTag
                                    label={detailsLabel.inputAreaLabel}
                                    menuItems={Object.keys(metaTagsList).map((key) => key)}
                                    placeholder={detailsLabel.inputAreaPlaceholder}
                                    disabled={!disabled}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            );
                        }}
                    />
                    {errors.notification_description && (
                        <ErrorMessage errors={{ message: errors.notification_description[id].message }} />
                    )}
                </div>

                <div className=''>
                    <div className='mb-2 -mr-2'>
                        <span className={`paragraph-medium-medium ${!disabled && 'text-neutral-300'}`}>
                            {detailsLabel.selectImageLabel}
                        </span>
                        <span
                            className={`paragraph-medium-italic ${
                                disabled ? 'text-neutral-500' : 'text-neutral-300'
                            } ml-1 ${!hasImageSizeReccomandation && 'lg:hidden'} md:hidden`}>
                            (1080px x 1080px recommended)
                        </span>
                    </div>

                    {!showSelectContent &&
                        (watch(`imageDetails.${id}`) || watch(`imageDetails.${id}`) ? (
                            <>
                                <div className='flex items-center'>
                                    <div className='flex items-start'>
                                        <img
                                            className='w-[200px] h-[180px] rounded-md md:mr-[17px] md:min-w-[163px] mobile:min-w-0 mt-2'
                                            src={watch(`imageDetails.${id}`) && watch(`imageDetails.${id}`)}
                                            alt='UploadedImage'
                                        />
                                    </div>
                                    <div
                                        className='max-w-[197px] w-full md:max-w-full ml-4'
                                        onClick={handlePopupReopen}>
                                        <LargeTertiaryButton label='Replace image' />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className='flex flex-row paragraph-medium-regular mobile:text-sm'>
                                <button
                                    disabled={!disabled}
                                    className={`justify-center h-12 border-neutral-300 ${
                                        !disabled && 'text-neutral-300'
                                    } rounded-md border w-1/2 mr-[8.5px] mobile:mr-1`}
                                    onClick={handleClickUploadImage}>
                                    Upload an image
                                </button>
                                <button
                                    disabled={!disabled}
                                    className={`justify-center h-12 border-neutral-300 rounded-md border w-1/2 ml-[8.5px] mobile:ml-1 ${
                                        !disabled && 'text-neutral-300'
                                    }`}
                                    onClick={handleClickSelectImageContent}>
                                    Select from library
                                </button>
                            </div>
                        ))}
                    {isFirstEnglish && (
                        <>
                            {pageTitle !== 'Edit preset' && (
                                <div className='mb-3.5 mt-2'>
                                    <DropdownSection
                                        label={detailsLabel.displayScreenDropdownLabel}
                                        placeholder={detailsLabel.displayScreenDropdownPlaceholder}
                                        buttonTextColor='neutral-300'
                                        disabled={!disabled}
                                        menuItems={MenuItemsScreens}
                                        name='screen'
                                    />
                                </div>
                            )}
                            {watch('screen')?.label === 'category-detail-screen' && (
                                <div className='mb-3.5'>
                                    <DropdownSection
                                        label={detailsLabel.selectCategoryDropdownLabel}
                                        placeholder={detailsLabel.displayCategoryDropdownPlaceholder}
                                        buttonTextColor='neutral-300'
                                        disabled={!disabled}
                                        menuItems={MenuItemsCategories}
                                        name='category'
                                    />
                                </div>
                            )}

                            {pageTitle === 'Edit preset' ? (
                                <div className='w-1/2 mr-1 relative md:w-full md:mr-0 mb-4 mt-3'>
                                    <span className={`paragraph-small-medium ${!disabled && 'text-neutral-300'}`}>
                                        {'Schedule Notification'}
                                    </span>
                                    <div className='items-start mt-1'>
                                        <DropdownSection
                                            name={'timeForPresetNotification'}
                                            rules={{ required: 'Please select time' }}
                                            icon={<CalenderIcon stroke={!disabled ? '#D3D2D8' : '#131126'} />}
                                            openFromBottom={false}
                                            placeholder='Please select time'
                                            menuItems={schedulePresetMenuItems}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {isEditNotificationFieldEnabled ? (
                                        <div className='mb-4 mt-3'>
                                            <ScheduleNotification
                                                disabled={!disabled}
                                                label={detailsLabel.scheduleNotificationLabel}
                                                menuItems={scheduleNotificationMenuItems}
                                                watch={watch}
                                                timeTypeName={`notification_time_type.${id}`}
                                                control={control}
                                                selectedTimeName='notification_selected_time'
                                                selectedDateName='notification_selected_date'
                                                setValue={setValue}
                                                pageTitle={pageTitle}
                                            />
                                            {errors.notification_time_type && (
                                                <ErrorMessage
                                                    errors={{ message: errors.notification_time_type[id]?.message }}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <span className={`paragraph-medium-medium`}>
                                            Sent Time: {details.time_to_send}
                                        </span>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {showSameAsButton && index !== 0 && !showSelectContent && (
                        <div className=''>
                            <button
                                disabled={!disabled}
                                className={`justify-center h-12 border-neutral-300 rounded-md border w-1/2 max-w-[197px] lg:max-w-[148px] mt-2.5 ${
                                    !disabled && 'text-neutral-300'
                                }`}
                                onClick={handleClickSelectSameImageAsEnglish}>
                                Same as English
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
