import React, { useMemo, useState } from 'react';
import SelectImageSection from './BannerPopupComponent/SelectImageSection';
import OrderingModeSection from './BannerPopupComponent/OrderingModeSection';
import SelectDurationSection from './BannerPopupComponent/SelectDurationSection';
import TypeSelection from './BannerPopupComponent/TypeSelection';
import BannerScreenSelection from './BannerPopupComponent/ScreenSelection';
import TitleSection from './BannerPopupComponent/TitleSection';
import BottomButtonSection from './BannerPopupComponent/BottomButtonSection';
import SelectImageFromLibraryPopup from './SelectImagePopupComponent/SelectImageFromLibraryPopup';
import Header from './BannerPopupComponent/Header';
import ValiditySection from './BannerPopupComponent/ValiditySection';
import UploadImagePopup from '../../../Components/UploadImagePopup/UploadImagePopup';
import { FormProvider, useForm } from 'react-hook-form';
import { timingMenuItem } from '../../../Constants/Constants';

export default function AddBannerPopup(props) {
    const { handleClickClose, fetchCatalogueList, canFetchCatalogList, bannerType } = props;

    const [showUploadImagePopupLanguageId, setShowUploadImagePopupLanguageId] = useState(false);

    const [showLibraryImagePopupLanguageId, setShowLibraryImagePopupLanguageId] = useState(null);

    const method = useForm({
        defaultValues: {
            bannerType: { label: 'Primary banners', value: { is_primary: 1, is_secondary: 0, is_tertiary: 0 } },
            validity: {
                label: 'Always',
                value: 'always',
            },
            timingType: timingMenuItem[0],
            orderingMode: [],
        },
    });

    const { setValue, watch } = method;

    const handleSetImageData = (imageData, id) => {
        setValue(`selectedImage.${id}`, { ...imageData });
    };

    const uploadImageType = useMemo(() => {
        if (!!watch('bannerType')?.value.is_primary) {
            return { label: 'Primary banner', value: 'primary_banner' };
        } else if (!!watch('bannerType')?.value.is_secondary) {
            return { label: 'Secondary banner', value: 'secondary_banner' };
        } else {
            return { label: 'Tertiary banner', value: 'tertiary_banner' };
        }
    }, [watch('bannerType')]);

    return (
        <>
            {!showUploadImagePopupLanguageId && !showLibraryImagePopupLanguageId ? (
                <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex md:relative px-10 md:px-0 overflow-auto'>
                    <div className='max-w-[475px] w-full rounded-xl bg-shades-50 py-6 m-auto max-h-[969px] md:max-w-full md:rounded-none md:py-4 md:max-h-full'>
                        <FormProvider {...method}>
                            <Header
                                handleClickClose={handleClickClose}
                                tagLine='Create a new banner to add in the catalogue'
                                title='Add new banner'
                            />

                            <div className='pl-6 pr-[14px] md:pl-2 md:pr-0'>
                                <div className='h-[65vh] overflow-auto scrollbar-style pr-[12px] md:overflow-hidden md:h-full md:pb-16 md:pr-4 pl-2'>
                                    <TypeSelection bannerTypeCheck={bannerType} />

                                    <SelectImageSection
                                        handleClickUploadImage={setShowUploadImagePopupLanguageId}
                                        setShowLibraryImagePopupLanguageId={setShowLibraryImagePopupLanguageId}
                                    />

                                    <TitleSection />
                                    <BannerScreenSelection />

                                    <OrderingModeSection />

                                    <ValiditySection />

                                    <SelectDurationSection />
                                </div>
                            </div>

                            <BottomButtonSection
                                bannerData={{}}
                                type='add'
                                fetchCatalogueList={fetchCatalogueList}
                                handleClickClose={handleClickClose}
                                canFetchCatalogList={canFetchCatalogList}
                            />
                        </FormProvider>
                    </div>
                </div>
            ) : showUploadImagePopupLanguageId ? (
                <UploadImagePopup
                    handleClickClose={() => setShowUploadImagePopupLanguageId(null)}
                    setUploadedImageDetails={(imageData) =>
                        handleSetImageData(imageData, showUploadImagePopupLanguageId)
                    }
                    selectedType={uploadImageType}
                    backToPage='add banner'
                />
            ) : (
                showLibraryImagePopupLanguageId && (
                    <SelectImageFromLibraryPopup
                        handleClickClose={() => setShowLibraryImagePopupLanguageId(null)}
                        handleSelectImage={(imageData) =>
                            handleSetImageData(imageData, showLibraryImagePopupLanguageId)
                        }
                    />
                )
            )}
        </>
    );
}
