import React from 'react';
import riderImage from '../../../../../Assets/rider-image.png';

export default function GroupCard(props) {
    const { handleClickCard, isSelected, name, choiceNumber } = props;

    return (
        <>
            <div
                className={`border rounded-md px-4 py-2 ${
                    isSelected ? 'border-primary-500 bg-primary-50' : 'border-neutral-300'
                }`}
                onClick={handleClickCard}>
                <div className='flex flex-row items-start gap-2'>
                    <img
                        src={riderImage}
                        alt=''
                        className='rounded-lg w-[78px] h-[78px]'
                    />
                    <div className='flex flex-col gap-1'>
                        <span className='heading-h6-medium-desktop line-clamp-2'>{name}</span>
                        <span className='paragraph-small-italic text-neutral-500'>
                            Choices : {choiceNumber < 10 ? `0${choiceNumber}` : choiceNumber}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
