import React, { useEffect, useState } from 'react';
import InfrastructureCredit from './Components/InfrastructureCredit';
import ServiceCredit from './Components/ServiceCredit';
import { useParams } from 'react-router-dom';
import Layout from './Components/Layout';
import APIV5 from '../../api/axios/APIV5';
import { format, startOfMonth } from 'date-fns';
import { useSelector } from 'react-redux';

export default function Credits() {
    const { creditType } = useParams();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const today = new Date();

    const [creditList, setCreditList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isCardDetailLoading, setIsCardDetailLoading] = useState(false);
    const [selectedDates, setSelectedDates] = useState([startOfMonth(today), today]);
    const formatDate = (date) => format(date, 'yyyy-MM-dd');
    const [creditAndRecharge, setCreditAndRecharge] = useState({
        totalInfrastructureCredit: 0,
        numberOfRecharges: 0,
    });
    const [cardLable, setCardLable] = useState('this month');
    const [availableServiceCredit, setAvailableServiceCredit] = useState(0);

    useEffect(() => {
        const initialDates = [startOfMonth(today), today];
        const isInitialDates =
            formatDate(selectedDates[0]) === formatDate(initialDates[0]) &&
            formatDate(selectedDates[1]) === formatDate(initialDates[1]);

        setCardLable(isInitialDates ? 'this month' : 'selected dates');
    }, [selectedDates]);

    const fetchTransactionList = async (params) => {
        try {
            setIsLoading(true);
            const queryParams = params ?? {
                filters: { start_date: formatDate(selectedDates[0]), end_date: formatDate(selectedDates[1]) },
            };

            const response = await APIV5.get(
                `/restaurants/${selectedRestaurantId}/infrastructure/credit-transactions`,
                { params: queryParams }
            );
            if (response.success) {
                setCreditList(response?.transactions?.data);
            }
            setIsLoading(false);

            return response;
        } catch (error) {
            console.log(error);
        }
    };

    const fetchServiceCreditList = async (params) => {
        try {
            setIsLoading(true);
            const queryParams = params ?? {
                filters: { start_date: format(new Date(), 'yyyy-MM-dd'), end_date: format(new Date(), 'yyyy-MM-dd') },
            };

            const response = await APIV5.get(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/services/credit-transactions`,
                { params: queryParams }
            );
            if (response.success) {
                setCreditList(response?.transactions?.data);
            }
            setIsLoading(false);

            return response;
        } catch (error) {
            console.log(error);
        }
    };
    const filters = () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        const filters = {
            filters: { start_date: formatDate(from_date), end_date: formatDate(to_date) },
        };
        return filters;
    };

    const fetchInfrastructureCardDetails = async () => {
        try {
            setIsCardDetailLoading(true);

            const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/total-infrastructure-credit`, {
                params: filters(),
            });
            setCreditAndRecharge({
                totalInfrastructureCredit: response.total_infrastructure_credit,
                numberOfRecharges: response.number_of_recharges,
            });
            setIsCardDetailLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };
    const fetchServiceCardDetails = async () => {
        try {
            setIsCardDetailLoading(true);

            const response = await APIV5.get(
                `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/total-service-credit`,
                {
                    params: filters(),
                }
            );
            setAvailableServiceCredit(response.total_service_credit);
            setIsCardDetailLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };
    return (
        <Layout
            fetchTransactionList={fetchTransactionList}
            fetchServiceCreditList={fetchServiceCreditList}
            fetchInfrastructureCardDetails={fetchInfrastructureCardDetails}
            fetchServiceCardDetails={fetchServiceCardDetails}
            setSelectedDates={setSelectedDates}
            creditType={creditType}>
            {creditType === 'infrastructure' ? (
                <InfrastructureCredit
                    fetchTransactionList={fetchTransactionList}
                    creditType={creditType}
                    isInfrastructureListLoading={isLoading}
                    creditList={creditList}
                    setCreditList={setCreditList}
                    fetchInfrastructureCardDetails={fetchInfrastructureCardDetails}
                    isCardDetailLoading={isCardDetailLoading}
                    setIsCardDetailLoading={setIsCardDetailLoading}
                    creditAndRecharge={creditAndRecharge}
                    setCreditAndRecharge={setCreditAndRecharge}
                    selectedDates={selectedDates}
                    setSelectedDates={setSelectedDates}
                    cardLable={cardLable}
                />
            ) : (
                <ServiceCredit
                    creditList={creditList}
                    setCreditList={setCreditList}
                    fetchServiceCreditList={fetchServiceCreditList}
                    creditType={creditType}
                    isServiceListLoading={isLoading}
                    selectedDates={selectedDates}
                    setSelectedDates={setSelectedDates}
                    availableServiceCredit={availableServiceCredit}
                    setAvailableServiceCredit={setAvailableServiceCredit}
                    fetchServiceCardDetails={fetchServiceCardDetails}
                />
            )}
        </Layout>
    );
}
