import React, { useMemo, useState } from 'react';
import CalenderField from '../../../Components/Calender/CalenderField';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { ReactComponent as Settings } from '../../../Assets/settings.svg';
import { useWindowSize } from '@uidotdev/usehooks';

const DownArrow = ({ isReportGenerated, reportData, selectedDates, setSelectedDates }) => {
    const { width: screenWidth } = useWindowSize();
    const [selectedOrderingMode, setSelectedOrderingMode] = useState('Success'); // Set 'Success' as default
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('All');

    const orderingModes = useMemo(
        () => [
            { display_name: 'Pending', tab: '/Pending' },
            { display_name: 'Failed', tab: '/Failed' },
            { display_name: 'InProgress', tab: '/InProgress' },
            { display_name: 'Completed', tab: '/Completed' },
            { display_name: 'Canceled', tab: '/Canceled' },
        ],
        []
    );

    const orderingModeMenuItems = useMemo(
        () => [
            { item: 'Success', value: '/Success', onClick: () => setSelectedOrderingMode('Success') },
            ...orderingModes.map((item) => ({
                item: item.display_name,
                value: item.tab,
                onClick: () => setSelectedOrderingMode(item.tab),
            })),
        ],
        [orderingModes]
    );
    const paymentStatusMenuItems = useMemo(
        () => [
            { item: 'All', value: 'All', onClick: () => setSelectedPaymentStatus('All') },
            { item: 'Cash', value: 'Cash', onClick: () => setSelectedPaymentStatus('Cash') },
            { item: 'Card', value: 'Card', onClick: () => setSelectedPaymentStatus('Card') },
            { item: 'Due Payment', value: 'DuePayment', onClick: () => setSelectedPaymentStatus('DuePayment') },
            { item: 'Other', value: 'Other', onClick: () => setSelectedPaymentStatus('Other') },
            { item: 'Wallet', value: 'Wallet', onClick: () => setSelectedPaymentStatus('Wallet') },
            { item: 'Online', value: 'Online', onClick: () => setSelectedPaymentStatus('Online') },
        ],
        []
    );
    const dropDownProps = {
        menuItems: orderingModeMenuItems,
        fixedLabel: screenWidth > 1023 ? '' : 'O.M.',
        menuButtonStyle: 'border-neutral-300',
        badgeTextStyle: 'bg-neutral-200',
        dropDownIconFill: '#131126',
        boxStyle: 'border-neutral-300',
        textColor: 'text-neutral-900',
    };

    const paymentStatusDropDownProps = {
        menuItems: paymentStatusMenuItems,
        fixedLabel: screenWidth > 1023 ? '' : 'P.S.',
        menuButtonStyle: 'border-neutral-300',
        badgeTextStyle: 'bg-neutral-200',
        dropDownIconFill: '#131126',
        boxStyle: 'border-neutral-300',
        textColor: 'text-neutral-900',
    };

    // const downloadReport = () => {
    //     if (!isReportGenerated || !reportData) {
    //         alert('Report is not generated yet!');
    //         return;
    //     }

    //     const fileName = 'payment_report.json';
    //     const json = JSON.stringify(reportData, null, 2);
    //     const blob = new Blob([json], { type: 'application/json' });
    //     const href = URL.createObjectURL(blob);

    //     const link = document.createElement('a');
    //     link.href = href;
    //     link.download = fileName;
    //     document.body.appendChild(link);
    //     link.click();

    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(href);
    // };

    return (
        <div className='flex flex-row xl:flex-col xl:ml-0 gap-3 items-center'>
            <div className='w-full'>
                <span className='text-lg ml-2 lg:ml-0'>DURATION OF COLLECTIONS</span>
                <div className='lg:-ml-4'>
                    <CalenderField
                        setSelectedDates={setSelectedDates}
                        selectedDates={selectedDates}
                    />
                </div>
            </div>

            <div className='w-full'>
                <span className='text-lg ml-1'>PAYMENT STATUS</span>
                <div className=''>
                    <DropDownTabs {...paymentStatusDropDownProps} />
                </div>
            </div>

            <div className='w-full'>
                <span className='text-lg ml-1'>VENDOR</span>
                <DropDownTabs {...dropDownProps} />
            </div>

            {/* SELECT DOWNLOADS Button */}
            <div
                className='flex items-center w-full border-2 xl:p-1 xl:text-sm cursor-pointer border-gray-400 xl:mt-6 p-2 lg:mt-2 mt-7 rounded-lg'
                // onClick={downloadReport}
            >
                <Settings className='-mx-auto' />
                <span className='text-base ml-5'>SELECT DOWNLOADS</span>
            </div>
        </div>
    );
};

export default DownArrow;
