import React from 'react';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import InputSection from '../../../../../../Components/InputField/InputSection';
import _ from 'lodash';
import { getNestedProperty } from '../../../../AddDish/TabComponents/DishPricing/Components/PriceInputForm';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function PricingForSingleOrderingMode(props) {
    const { priceId, display_name } = props;

    const {
        formState: { errors },
    } = useFormContext();

    const handleChangeInput = (params) => {};

    const getError = (name) => !_.isEmpty(errors) && getNestedProperty(errors, name);

    return (
        <>
            <div className='border-b border-neutral-300 pb-8 mb-8 last:border-b-0'>
                <TitleDescription
                    className='mb-4'
                    title={`Choice ${display_name} price`}
                    description='Set the choice price. Current price defines the amount that customer needs to pay whereas compare price defines the price that was earlier.'
                />

                <div className='flex flex-row items-start lg:block'>
                    <div className='mr-1.5 w-1/2 lg:w-full lg:mb-2 lg:max-w-[312px] md:max-w-full lg:mr-0'>
                        <InputSection
                            inputType='number'
                            label='(Current price)'
                            labelStyle='paragraph-medium-italic text-neutral-500'
                            placeholder='Enter current price in rupees'
                            shadow='shadow-small-drop-down-shadow'
                            name={`${priceId}.price`}
                            handleInputChange={(value) => handleChangeInput(value, 'price')}
                            rules={{ required: 'Please enter price' }}
                        />

                        {!_.isEmpty(getError(`${priceId}.price`)) && (
                            <ErrorMessage errors={getError(`${priceId}.price`)} />
                        )}
                    </div>

                    <div className='ml-1.5 w-1/2 lg:w-full lg:max-w-[312px] md:max-w-full lg:ml-0'>
                        <InputSection
                            label='(Compare/strike-through price)'
                            labelStyle='paragraph-medium-italic text-neutral-500'
                            placeholder='Enter compare price in rupees'
                            shadow='shadow-small-drop-down-shadow'
                            name={`${priceId}.compare_price`}
                            inputType='number'
                            handleInputChange={(value) => handleChangeInput(value, 'compare_price')}
                            rules={{ required: 'Please enter compare price' }}
                        />
                        {!_.isEmpty(getError(`${priceId}.compare_price`)) && (
                            <ErrorMessage errors={getError(`${priceId}.compare_price`)} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
