import React, { useEffect, useState } from 'react';
import APIV5 from '../../../api/axios/APIV5';
import { format } from 'date-fns';

const Table = () => {
    const [isReportCardLoading, setIsReportCardLoading] = useState(false);
    const [reportDetails, setReportDetails] = useState([]);

    const fetchReportDetails = async () => {
        try {
            setIsReportCardLoading(true);
            const response = await APIV5.get(`/report-downloads?per_page=2`);
            setReportDetails(response?.requests?.data || []);
            setIsReportCardLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
            setIsReportCardLoading(false);
        }
    };

    const formatReportType = (type) => {
        return type
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    useEffect(() => {
        fetchReportDetails();
    }, []);

    return (
        <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border mt-8'>
            <table className='w-full break-words'>
                <thead>
                    <tr className='bg-neutral-50 uppercase shadow-innerShadow paragraph-overline-small text-neutral-700 pl-6 h-11'>
                        <th className='min-w-[100px] pl-6 text-left'>REPORT ID</th>
                        <th className='min-w-[200px] pl-6 text-left'>REPORT NAME</th>
                        <th className='min-w-[200px] pl-6 text-left'>REPORT TYPE</th>
                        <th className='min-w-[150px] pl-6 text-left'>START DATE</th>
                        <th className='min-w-[150px] pl-6 text-left'>REQUESTED AT</th>
                        <th className='min-w-[150px] pl-6 text-left'>GENERATED AT</th>
                    </tr>
                </thead>

                <tbody>
                    {reportDetails.length > 0 ? (
                        reportDetails.map((report) => (
                            <tr
                                key={report.id}
                                className='even:bg-neutral-50 border-t first:border-none border-neutral-300 paragraph-small-regular h-[70px] justify-center'>
                                <td className='pl-6'>{report.id}</td>
                                <td className='pl-6 cursor-pointer text-primary-500 hover:underline'>{report.name}</td>
                                <td className='pl-6'>{formatReportType(report.type)}</td>
                                <td className='pl-6'>{report.start_date}</td>
                                <td className='pl-6'>{report.requested_at}</td>
                                <td className='pl-6'>{report.generated_at || 'Pending'}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td
                                colSpan={7}
                                className='text-center py-4'>
                                No reports available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
