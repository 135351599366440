import React from 'react';
import Header from './Header';
import AllCards from './AllCards';
import RatingGuide from '../../../Components/RatingGuide/RatingGuide';
import OrderRatingDetails from './OrderRatingDetails';
import PaginationNumber from '../../../Components/Pagination/PaginationWithNumber';
import _ from 'lodash';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function RatingComponent(props) {
    const {
        setAppliedOrderFilter,
        setAppliedRiderFilter,
        setSelectedDates,
        selectedDates,
        toggleCustomerReviewPopup,
        toggleCustomerOfferPopup,
        orderRatingDetails,
        setDeleteReviewId,
        fetchOrderRatingDetail,
        isLoading,
        paginationState,
        paginationChanged,
        setCreateOfferDetails,
        fetchOfferDetails,
        setOfferid,
        handlePagination,
        appliedOrderFilter,
        deleted,
        appliedRiderFilter,
    } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <Header
                setAppliedOrderFilter={setAppliedOrderFilter}
                setAppliedRiderFilter={setAppliedRiderFilter}
                setSelectedDates={setSelectedDates}
                selectedDates={selectedDates}
                appliedOrderFilter={appliedOrderFilter}
                appliedRiderFilter={appliedRiderFilter}
            />

            <AllCards
                selectedDates={selectedDates}
                deleted={deleted}
            />

            <div className='flex justify-end mb-6 md:w-full'>
                <RatingGuide />
            </div>

            <OrderRatingDetails
                toggleCustomerReviewPopup={toggleCustomerReviewPopup}
                toggleCustomerOfferPopup={toggleCustomerOfferPopup}
                orderRatingDetails={orderRatingDetails}
                setDeleteReviewId={setDeleteReviewId}
                fetchOrderRatingDetail={fetchOrderRatingDetail}
                isLoading={isLoading}
                paginationState={paginationState}
                paginationChanged={paginationChanged}
                setCreateOfferDetails={setCreateOfferDetails}
                fetchOfferDetails={fetchOfferDetails}
                setOfferid={setOfferid}
            />

            {!_.isEmpty(orderRatingDetails) && (
                <div className='pt-4'>
                    <PaginationNumber
                        lastPage={paginationState.lastPage}
                        selectedPage={paginationState.selectedPage}
                        setSelectedPage={(value) => {
                            handlePagination({ selectedPage: value }, 'selectedPage');
                        }}
                        setResultPerPage={(value) => {
                            handlePagination({ resultPerPage: value }, 'resultPerPage');
                        }}
                        selectedResultPerpage={paginationState.resultPerPage}
                    />
                </div>
            )}
        </>
    );
}
