import React from 'react';
import ContentLoader from 'react-content-loader';

const WaiterPopupLoader = () => {
    return (
        <ContentLoader
            width='852px'
            height='1000px'
            className=' w-fit rounded-md'>
            {/* header */}
            <rect
                x='6'
                y='5'
                rx='4'
                ry='4'
                width='200'
                height='35'
            />
            <rect
                x='730'
                y='5'
                rx='4'
                ry='4'
                width='32'
                height='32'
            />
            <rect
                x='6'
                y='50'
                rx='4'
                ry='4'
                width='300'
                height='20'
            />
            {/* second part */}
            <rect
                x='6'
                y='100'
                rx='4'
                ry='4'
                width='400'
                height='25'
            />
            <rect
                x='6'
                y='140'
                rx='4'
                ry='4'
                width='200'
                height='40'
            />
            {/* first rider line */}
            <rect
                x='6'
                y='205'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='230'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />
            <rect
                x='400'
                y='205'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='400'
                y='230'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />
            {/* second rider line */}
            <rect
                x='6'
                y='305'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='330'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />
            <rect
                x='400'
                y='305'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='400'
                y='330'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />
            {/* third rider line */}
            <rect
                x='6'
                y='405'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='430'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />
            <rect
                x='400'
                y='405'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='400'
                y='430'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />
            {/* fourth rider line */}
            <rect
                x='6'
                y='505'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='530'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />
            <rect
                x='400'
                y='505'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='400'
                y='530'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />
            {/* fifth rider line */}
            <rect
                x='6'
                y='605'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='630'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />
            {/* select rider outlet  */}
            <rect
                x='6'
                y='705'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='730'
                rx='4'
                ry='4'
                width='750'
                height='40'
            />
            {/* rider address */}
            <rect
                x='6'
                y='805'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='830'
                rx='4'
                ry='4'
                width='750'
                height='90'
            />
            {/* button */}
            <rect
                x='6'
                y='945'
                rx='4'
                ry='4'
                width='750'
                height='40'
            />
        </ContentLoader>
    );
};

export default WaiterPopupLoader;
