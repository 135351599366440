import React, { useState, useEffect, useMemo } from 'react';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { ReactComponent as VegIcon } from '../../../../Assets/vegetable-icon.svg';
import { CheckBox } from '../../../../Components/FormControl/FormControls';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function CustomizeAddonPopUp(props) {
    const { handleClickClose, setShowPopup } = props;
    const toppingVeg = useMemo(() => [
        {
            id: 1,
            name: 'Jalapenos',
            price: '₹10.00/-',
            checked: false,
            stock: 1,
        },
        { id: 2, name: 'Pineapples', price: '₹20.00/-', checked: false, stock: 0 },
        { id: 3, name: 'Fresh tomatoes', price: '₹20.00/-', checked: false, stock: 0 },
        { id: 4, name: 'Onion', price: '₹10.00/-', checked: false, stock: 1 },
        { id: 5, name: 'Capsicums', price: '₹20.00/-', checked: false, stock: 1 },
    ]);
    const [checkedAddon, setCheckedAddon] = useState(toppingVeg.length === 1 ? [toppingVeg[0]] : []);

    const isMobileScreen = IsMobileScreen();

    const handleCheckBoxClick = (isChecked, el) => {
        if (isChecked) {
            setCheckedAddon([...checkedAddon, el]); // Add the entire data object
        } else {
            const updatedData = checkedAddon.filter((item) => item.id !== el.id); // Filter by id to remove the item
            setCheckedAddon(updatedData);
        }
    };

    const handleAddDishClick = () => {
        setShowPopup(null);
    };
    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex justify-center items-center overflow-auto p-4'>
                <div className='max-w-[613px] w-full rounded-xl bg-shades-50 p-6 md:p-4 m-auto'>
                    <div className='flex flex-row justify-between items-center mb-6 md:mb-4'>
                        <div className='flex flex-col'>
                            {!isMobileScreen ? (
                                <span className='paragraph-large-medium'>Customize as per customer's taste</span>
                            ) : (
                                <span className='paragraph-large-medium'>Customize</span>
                            )}
                            {!isMobileScreen && (
                                <div className='flex flex-row items-center'>
                                    <span className='paragraph-medium-italic text-neutral-500'>
                                        Double Cheese Margherita
                                    </span>
                                    <div className='h-1 w-1 rounded-full bg-neutral-500 mx-3' />
                                    <span className='paragraph-medium-italic text-neutral-500'>
                                        ₹559.00 - ₹1,559.00
                                    </span>
                                </div>
                            )}
                        </div>
                        <div
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </div>
                    </div>
                    <div className='border border-neutral-300 rounded-lg shadow-smallDropDownShadow flex flex-row items-center justify-between py-[14px] px-4 mb-4 h-[52px] md:block md:h-auto md:p-2'>
                        <div className='flex flex-row items-center'>
                            <span className='paragraph-medium-regular mr-2'>Personal giant size (22.5 cm)</span>
                            <VegIcon />
                        </div>
                        <span
                            className='text-primary-500 font-normal text-sm leading-4 mt-0.5 border-b border-primary-500 cursor-pointer'
                            onClick={() => setShowPopup('customizeVariant')}>
                            Change
                        </span>
                    </div>
                    <div className=''>
                        <span className='paragraph-large-medium mr-2'>Toppings-veg (giant slices)</span>
                        <span className='paragraph-medium-italic text-neutral-500'>
                            Atlist select 3 ({checkedAddon?.length}/5)
                        </span>
                    </div>
                    <div className='mb-9 md:mb-[18px]'>
                        {toppingVeg.map((el, index) => (
                            <div
                                className='pt-4 flex flex-row justify-between'
                                key={index}>
                                <div className='flex flex-row items-center label-text-format pr-4'>
                                    <CheckBox
                                        label={el.name}
                                        optionId={el.name + index}
                                        isChecked={checkedAddon.some((item) => item.id === el.id)}
                                        paddingL='6 md:pl-2'
                                        labelStyle='paragraph-medium-regular md:block md:overflow-hidden'
                                        item={el}
                                        onChange={handleCheckBoxClick}
                                        disabled={el.stock === 0}
                                    />
                                    <span>
                                        <VegIcon className='ml-2' />
                                    </span>
                                </div>
                                <span className='paragraph-small-regular '>{el.price}</span>
                            </div>
                        ))}
                    </div>
                    {checkedAddon.length > 0 && checkedAddon.length < 3 && toppingVeg.length > 1 && (
                        <div className='bg-black text-white p-2 rounded-lg mb-4 '>
                            <p>You need to select at least 3 items</p>
                        </div>
                    )}
                    <div className='flex flex-row items-center justify-between'>
                        <span className='paragraph-large-medium'>Step 2/2</span>
                        <div
                            className=''
                            onClick={() => handleAddDishClick()}>
                            <LargePrimaryButton
                                label='Add dish'
                                disabled={checkedAddon.length < 3 && toppingVeg.length > 1}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
