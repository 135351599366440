import React, { useEffect, useState } from 'react';
import { useWindowSize } from '@uidotdev/usehooks';
import { useSelector } from 'react-redux';

import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { fetchDishList, getFoodType, rearrangeDish } from '../../../../../functions';
import { Dishes } from '../../../Components/Card';
import { SortableItem } from '../../../Components/SortableItem';
import ListLoader from '../../../Components/ListLoader';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../../../../../Components/Buttons/Button';

export default function SubCategoryDishList(props) {
    const { selectedCategory, handleClickClose, isSelectedCategorySaneAsRendering, setIsOperationLoading } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [isLoading, setIsLoading] = useState(true);

    const [subCategoryDishList, setSubCategoryDishList] = useState([]);

    const getAllSubCategory = async (category) => {
        let dishList = [];

        for (let index = 0; index < Math.ceil(category.total_items / 10); index++) {
            const dish = await fetchDishList(
                selectedRestaurantId,
                { page: index + 1, per_page: 10 },
                category.category_id
            );

            dishList.push(...dish.data);
        }

        setSubCategoryDishList((prevState) => [
            ...prevState,
            { category: { name: category.internal_name, id: category.category_id }, dish: dishList },
        ]);
    };

    useEffect(() => {
        setIsLoading(true);

        setSubCategoryDishList([]);

        const getAllSubCategoryPromises = selectedCategory?.value.sub_categories.map((el) => getAllSubCategory(el));

        Promise.all(getAllSubCategoryPromises)
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error while fetching subcategories:', error);
            });
    }, []);

    const screenSize = useWindowSize();

    const reArrangeCategoryWise = async (dishList) => {
        isSelectedCategorySaneAsRendering && setIsOperationLoading(true);

        const payload = { items: dishList?.map((el) => ({ item_id: el.item_id })) };
        try {
            const response = await rearrangeDish(payload);
            return response.success;
        } catch (error) {
            console.log('error : ', error);
            return false;
        }
    };

    const updateReArrange = async () => {
        const rearrangedSubCategory = subCategoryDishList?.filter((el) => !!el.hasRearrange);

        const promises = rearrangedSubCategory.map((el) => reArrangeCategoryWise(el.dish));

        const results = await Promise.all(promises);

        const allSuccessful = results.every((result) => result === true);

        if (allSuccessful) {
            isSelectedCategorySaneAsRendering && setIsOperationLoading(false);

            handleClickClose();
        }
    };

    return (
        <>
            {isLoading ? (
                <ListLoader className='min-w-[410px] h-[104px]' />
            ) : (
                <div className=''>
                    <div
                        id='parent'
                        className='scrollbar-style -mr-8 pr-8 overflow-auto -ml-6 pl-6 pageContentSmall:-mr-4'
                        style={{ height: screenSize.height - 340 }}>
                        {subCategoryDishList?.map((el, index) => {
                            return (
                                <div key={index}>
                                    <SingleCategoryDishList
                                        setSubCategoryDishList={setSubCategoryDishList}
                                        index={index}
                                        details={el}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className='flex flex-row items-center gap-2 absolute w-full max-w-[442px] pt-2 pb-4 -ml-8 pl-8 bg-white bottom-0'>
                        <div
                            className='w-1/2'
                            onClick={handleClickClose}>
                            <LargeTertiaryButton label='Cancel' />
                        </div>
                        <div
                            className='w-1/2'
                            onClick={updateReArrange}>
                            <LargePrimaryButton label='Save' />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

const SingleCategoryDishList = (props) => {
    const { details, setSubCategoryDishList, index } = props;

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!over) {
            return;
        }

        const oldIndex = details.dish.findIndex((item) => item.item_id === active.id);
        const newIndex = details.dish.findIndex((item) => item.item_id === over.id);

        if (oldIndex !== newIndex) {
            const updatedDishes = arrayMove(details.dish, oldIndex, newIndex);

            setSubCategoryDishList((prevState) => {
                const updatedState = [...prevState];

                if (index !== -1) {
                    updatedState[index] = {
                        ...updatedState[index],
                        hasRearrange: true,
                        dish: updatedDishes,
                    };
                }
                return updatedState;
            });
        }
    };

    return (
        <>
            <h5 className='text-neutral-700 paragraph-large-italic pt-4'>{details.category.name}</h5>

            {!details?.dish.length && (
                <span className='paragraph-small-italic text-neutral-500'>No dishes available</span>
            )}

            <DndContext onDragEnd={handleDragEnd}>
                <SortableContext items={details?.dish.map((item) => item.item_id)}>
                    {details?.dish.map((dish, index) => (
                        <SortableItem
                            key={dish.item_id}
                            id={dish.item_id}>
                            <DraggableItem
                                key={dish.item_id}
                                index={index}
                                item={dish}
                            />
                        </SortableItem>
                    ))}
                </SortableContext>
            </DndContext>
        </>
    );
};

const DraggableItem = (props) => {
    const { index, item } = props;

    return (
        <>
            <div className='mt-4 min-w-[411px] lg:min-w-0 md:pl-2.5'>
                <Dishes
                    handleClickEdit={() => {}}
                    isEdit={false}
                    title={item.internal_name}
                    image={item.image_url}
                    variants={item.variants_count}
                    isActive={false}
                    isRearrange={true}
                    type={getFoodType(item)}
                    index={index}
                    showUnlink={!!item.is_mapped_item}
                    handleUnlinkItem={() => {}}
                    isSelectionActive={false}
                />
            </div>
        </>
    );
};
