import React from 'react';
import Header from './Header';
import { useWindowSize } from '@uidotdev/usehooks';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function Layout(props) {
    const {
        children,
        showList,
        setShowUploadPopup,
        fetchMedia,
        setAppliedFilter,
        appliedFilter,
        setPaginationState,
        bannerType,
        fetchImageSubCategory,
        typeSubCategory,
        setTypeSubCategory,
    } = props;

    const pageSize = useWindowSize();

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='bg-[#fafafa]'>
                {showList && (
                    <div
                        className='px-8 pb-12 lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'
                        style={{ minHeight: !isMobileScreen ? pageSize.height - 50 : 0 }}>
                        <Header
                            setAppliedFilter={setAppliedFilter}
                            setShowUploadPopup={setShowUploadPopup}
                            fetchMedia={fetchMedia}
                            bannerType={bannerType}
                            setPaginationState={setPaginationState}
                            appliedFilter={appliedFilter}
                            fetchImageSubCategory={fetchImageSubCategory}
                            typeSubCategory={typeSubCategory}
                            setTypeSubCategory={setTypeSubCategory}
                        />

                        {children}
                    </div>
                )}
            </div>
        </>
    );
}
