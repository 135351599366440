import React, { useState } from 'react';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';
import { Controller } from 'react-hook-form';
import Layout from './TablePopupComponent/Layout';
import QRCodeSection from './TablePopupComponent/QRCodeSection';
import { ErrorMessage } from '../../../Components/Error/ErrorMessage';
import { deleteTable, editTable } from '../HelperFunction/ApiFunctions';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

export default function EditTablePopup(props) {
    const {
        control,
        watch,
        handleClickClose,
        handleSubmit,
        errors,
        sectionList,
        loaderStatus,
        setloaderStatus,
        setTotalTable,
        setTableList,
        errorOfApi,
        seterrorOfApi,
        isDirty,
        setValue,
        getValues,
    } = props;
    const { section } = useParams();
    const sections = sectionList.map((item) => ({ label: item.internal_name, value: item.id }));
    const selectedTableId = watch('tableId');
    const [isDelete, setisDelete] = useState(false);
    const tableLinks = [
        {
            header: 'Table',
            deeplink: watch('tableCode') ?? '',
        },
        {
            header: 'Deeplink',
            deeplink: watch('deeplink') ?? '',
        },
    ];
    const [isLinkShare, setIsLinkShare] = useState(tableLinks.map(() => false));

    const subSections =
        watch('outlet_section') &&
        sectionList
            ?.filter((items) => items?.id == watch('outlet_section')?.value)[0]
            ?.sub_section?.map((item) => ({ label: item?.internal_name, value: item?.id }));

    const onSubmit = (table) => {
        //this function is call editTable function to edit seleted table
        editTable({
            tableData: table,
            setloaderStatus: setloaderStatus,
            handleClickClose: handleClickClose,
            selectedTableId: selectedTableId,
            setisDelete: setisDelete,
            section,
            sectionList,
            setTotalTable,
            setTableList,
            seterrorOfApi,
        });
    };
    const onDelete = () => {
        //this function is call deleteTable function to delete seleted table
        deleteTable({
            setloaderStatus: setloaderStatus,
            handleClickClose: handleClickClose,
            selectedTableId: selectedTableId,
            setisDelete: setisDelete,
            section,
            sectionList,
            setTotalTable,
            setTableList,
            seterrorOfApi,
        });
    };
    const onSelectSection = (onChange, value) => {
        const section = getValues('outlet_section');
        onChange(value);
        if (section.value != value.value) {
            setValue('outlet_sub_section', null);
        }
    };
    return (
        <Layout
            handleClickClose={handleClickClose}
            isEditTable={true}
            loaderStatus={loaderStatus}
            editTable={handleSubmit(onSubmit)}
            isDelete={isDelete}
            errorOfApi={errorOfApi}
            deleteTable={onDelete}
            isDirty={isDirty}>
            <div className='flex flex-row items-start md:mb-20 md:block'>
                <div className='max-w-[435px] w-full pr-6 border-r border-neutral-300 lg:max-w-[335px] md:max-w-full md:pr-0 md:border-b md:border-r-0 md:pb-4 md:mb-4'>
                    <div className='mb-4'>
                        <Controller
                            render={({ field }) => (
                                <DefaultInputField
                                    label='Table name'
                                    placeholder='Enter new table name'
                                    enteredValue={field.onChange}
                                    value={watch('internal_name')}
                                    disabled={loaderStatus.isLoading}
                                />
                            )}
                            name='internal_name'
                            control={control}
                            rules={{ required: 'Internal name require' }}
                        />
                        {errors?.['internal_name'] && <ErrorMessage errors={errors?.['internal_name']} />}
                    </div>

                    <div className='mb-4 relative'>
                        <Controller
                            render={({ field }) => (
                                <DropdownWithValueLabel
                                    label='Select section'
                                    menuItems={sections}
                                    placeholder='Select section'
                                    setSelected={(value) => onSelectSection(field.onChange, value)}
                                    selectedItem={watch('outlet_section')}
                                    disabled={loaderStatus.isLoading}
                                />
                            )}
                            name='outlet_section'
                            control={control}
                            rules={{ required: 'Section require' }}
                        />
                        {errors?.['outlet_section'] && <ErrorMessage errors={errors?.['outlet_section']} />}
                    </div>
                    <div className='mb-4 relative'>
                        <Controller
                            render={({ field }) => (
                                <DropdownWithValueLabel
                                    label='Select sub-section'
                                    menuItems={subSections ?? []}
                                    placeholder='Select sub-section'
                                    setSelected={field.onChange}
                                    selectedItem={watch('outlet_sub_section')}
                                    disabled={!watch('outlet_section') || loaderStatus.isLoading}
                                />
                            )}
                            name='outlet_sub_section'
                            control={control}
                            rules={{ required: 'Sub section require' }}
                        />
                        {errors?.['outlet_sub_section'] && <ErrorMessage errors={errors?.['outlet_sub_section']} />}
                    </div>

                    <div className='mb-4'>
                        <Controller
                            render={({ field }) => (
                                <DefaultInputField
                                    label='Table capacity'
                                    placeholder='Enter table capacity'
                                    enteredValue={field.onChange}
                                    value={watch('capacity')}
                                    inputType={'number'}
                                    disabled={loaderStatus.isLoading}
                                />
                            )}
                            name='capacity'
                            control={control}
                            rules={{ required: 'Table capacity require' }}
                        />
                        {errors?.['capacity'] && <ErrorMessage errors={errors?.['capacity']} />}
                    </div>

                    <div className='mb-4'>
                        <Controller
                            render={({ field }) => (
                                <DefaultInputField
                                    label='Customer URL'
                                    placeholder='Enter deeplink URL'
                                    enteredValue={field.onChange}
                                    value={watch('deeplink')}
                                    disabled={loaderStatus.isLoading}
                                />
                            )}
                            name='deeplink'
                            control={control}
                            rules={{
                                required: 'Custom URL required',
                                pattern: {
                                    value: /^(ftp|http|https):\/\/[^ "]+$/,
                                    message: 'Invalid URL',
                                },
                            }}
                        />
                        {errors?.['deeplink'] && <ErrorMessage errors={errors?.['deeplink']} />}
                    </div>

                    {/* <ScheduleNotification label='Disable table' /> */}
                </div>

                <div className='flex flex-col ml-6 md:ml-0'>
                    {tableLinks.map((item, index) => {
                        return (
                            item?.deeplink && (
                                <div
                                    className='mb-6'
                                    key={index}>
                                    <QRCodeSection
                                        header={item.header}
                                        deeplink={item.deeplink}
                                        isLinkShare={isLinkShare}
                                        index={index}
                                        setIsLinkShare={setIsLinkShare}
                                    />
                                </div>
                            )
                        );
                    })}
                </div>
            </div>
        </Layout>
    );
}
