import React, { useCallback, useEffect, useRef, useState } from 'react';
import { fetchDishList, rearrangeDish } from '../../../../../functions';
import { useSelector } from 'react-redux';
import { useWindowSize } from '@uidotdev/usehooks';
import ListLoader from '../../../Components/ListLoader';
import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { SortableItem } from '../../../Components/SortableItem';
import { DraggableDishItem } from '../../../Components/DraggableComponents';
import HasMoreCard from '../../../Components/HasMoreCard';
import SubCategoryDishList from './SubCategoryDishList';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../../../../../Components/Buttons/Button';

export default function DishSection(props) {
    const { selectedCategory, handleClickClose, selectedCategoryDish, setIsOperationLoading } = props;

    const containerRef = useRef(null);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [isLoading, setIsLoading] = useState(true);

    const initialPageState = { page: 1, per_page: 10, has_more_pages: null };

    const [paginationState, setPaginationState] = useState(initialPageState);

    const [dishList, setDishList] = useState([]);

    const screenSize = useWindowSize();

    const setDishData = (page, dishData) => {
        page === 1 ? setDishList(dishData) : setDishList((prevState) => [...prevState, ...dishData]);
    };

    const fetchDish = async (params) => {
        setIsLoading(true);

        try {
            const dish = await fetchDishList(selectedRestaurantId, params, selectedCategory.value?.category_id);

            setIsLoading(false);

            setPaginationState((prevState) => ({
                ...prevState,
                has_more_pages: dish?.has_more_pages,
                last_page: dish?.last_page,
            }));

            setDishData(params.page, dish?.data);

            setIsLoading(false);
        } catch (error) {
            console.log('error : ', error);
        }
    };

    const handleScroll = useCallback(() => {
        const container = containerRef.current;

        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;

            if (scrollTop + clientHeight === scrollHeight && paginationState.last_page !== paginationState.page) {
                fetchDish({ ...paginationState, page: paginationState.page + 1 });

                setPaginationState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
            }
        }
        // eslint-disable-next-line
    }, [paginationState]);

    const handleDragEnd = (event) => {
        const { active, over } = event;

        const oldIndex = dishList.findIndex((item) => item.item_id === active.id);

        const newIndex = dishList.findIndex((item) => item.item_id === over.id);

        if (oldIndex !== newIndex) {
            setDishList((items) => arrayMove(items, oldIndex, newIndex));
        }
    };

    const pageState = { edit: false, reArrange: true, selection: false };

    useEffect(() => {
        fetchDish(initialPageState);

        // eslint-disable-next-line
    }, [selectedCategory.value?.category_id]);

    const isSelectedCategorySaneAsRendering =
        selectedCategoryDish?.category?.category_id === selectedCategory?.value?.category_id;

    const reArrangeDishWithoutSubCategory = async () => {
        isSelectedCategorySaneAsRendering && setIsOperationLoading(true);

        const payload = { items: dishList?.map((el) => ({ item_id: el.item_id })) };

        try {
            const response = await rearrangeDish(payload);

            if (response.success) {
                handleClickClose();
            }

            isSelectedCategorySaneAsRendering && setIsOperationLoading(false);
        } catch (error) {
            console.log('error : ', error);

            isSelectedCategorySaneAsRendering && setIsOperationLoading(false);
        }
    };

    const updateReArrange = async () => {
        reArrangeDishWithoutSubCategory();
    };

    return (
        <>
            {!!selectedCategory.value?.sub_categories?.length ? (
                <SubCategoryDishList
                    selectedCategory={selectedCategory}
                    handleClickClose={handleClickClose}
                    isSelectedCategorySaneAsRendering={isSelectedCategorySaneAsRendering}
                    setIsOperationLoading={setIsOperationLoading}
                />
            ) : (
                <>
                    <div
                        ref={containerRef}
                        onScroll={handleScroll}
                        className='overflow-auto scrollbar-style -mr-8 pr-8 -ml-6 pl-6 pageContentSmall:-mr-4'
                        style={{ height: screenSize.height - 340 }}>
                        {isLoading && paginationState.page === 1 && <ListLoader className='min-w-[410px] h-[104px]' />}

                        {!isLoading && !dishList.length && (
                            <div
                                className='m-auto flex justify-center min-w-[411px] items-center text-xl font-bold '
                                style={{ height: screenSize.height - 340 }}>
                                No dishes are available
                            </div>
                        )}

                        <DndContext onDragEnd={handleDragEnd}>
                            <SortableContext items={dishList.map((item) => item.item_id)}>
                                {dishList.map((dish, index) => (
                                    <SortableItem
                                        key={dish.item_id}
                                        id={dish.item_id}>
                                        <DraggableDishItem
                                            index={index}
                                            pageState={pageState}
                                            item={dish}
                                            key={index}
                                            handleUnlinkItem={() => {}}
                                        />
                                    </SortableItem>
                                ))}
                            </SortableContext>
                        </DndContext>

                        {isLoading && paginationState.page !== 1 && (
                            <div className='mt-6'>
                                <ListLoader className='max-w-[411px] h-[104px]' />
                            </div>
                        )}
                    </div>
                    {paginationState?.has_more_pages && <HasMoreCard />}

                    <div className='flex flex-row items-center gap-2 absolute w-full max-w-[442px] pt-2 pb-4 -ml-8 pl-8 bg-white bottom-0'>
                        <div
                            className='w-1/2'
                            onClick={handleClickClose}>
                            <LargeTertiaryButton label='Cancel' />
                        </div>
                        <div
                            className='w-1/2'
                            onClick={updateReArrange}>
                            <LargePrimaryButton label='Save' />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
