import React from 'react';
import CalenderField from '../../../Components/Calender/CalenderField';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function Layout(props) {
    const { showTotalSalesCard, children, selectedDates, setSelectedDates } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='bg-[#fafafa]'>
                {(!isMobileScreen || !showTotalSalesCard) && (
                    <div className='lg:pb-6 pt-4 w-full max-w-[1336px] mx-auto bg-white pb-10 md:pb-4 relative'>
                        <div className='px-8 lg:px-4'>
                            <div className='flex flex-row md:block justify-between pb-4 border-b border-b-neutral-300 mb-6 md:mb-4'>
                                <CalenderField
                                    label='25 Sept 2022 - 09 Oct 2022'
                                    buttonStyle='md:w-full'
                                    selectedDates={selectedDates}
                                    setSelectedDates={setSelectedDates}
                                />
                            </div>
                        </div>

                        {children}
                    </div>
                )}
            </div>
        </>
    );
}
