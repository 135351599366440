import { useSelector } from 'react-redux';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { userHasPermission } from '../../Helper/auth';

export default function PrivateRoute() {
    const user = useSelector((state) => state.auth.user);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const permissions = useSelector((state) => state.permission.permission);

    const location = useLocation();

    const splitRoute = `/${location.pathname.split('/')[1]}`;

    if (!user) {
        return <Navigate to='/login' />;
    }

    if (!selectedRestaurantId) {
        return <Navigate to='/select-restaurant' />;
    }

    // if (permissions) {
    //     if (!userHasPermission(permi`ssions, splitRoute, 'read')) {
    //         return <Navigate to='/error' />;
    //     }
    return <Outlet />;
    // }
}
