import React, { useEffect, useState } from 'react';
import DropdownPagination from '../../../../../../../Components/DropDown/DropdownPagination';
import { fetchCategoryList } from '../../../../../functions';
import { useSelector } from 'react-redux';

export default function CategorySelection(props) {
    const { selectedCategory, setSelectedCategory } = props;

    const [categoryMenuItems, setCategoryMenuItems] = useState([]);

    const initialPaginationState = { page: 1, per_page: 10, has_more_pages: null };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [paginationState, setPaginationState] = useState(initialPaginationState);

    const fetchCategory = async (params) => {
        try {
            const category = await fetchCategoryList(selectedRestaurantId, params);

            setPaginationState((prevState) => ({
                ...prevState,
                has_more_pages: category?.has_more_pages,
                last_page: category?.last_page,
            }));

            if (params.page === 1) {
                setCategoryMenuItems(category?.data?.map((el) => ({ label: el.internal_name, value: el })));
            } else {
                setCategoryMenuItems((prevState) => [
                    ...prevState,
                    ...category?.data?.map((el) => ({ label: el.internal_name, value: el })),
                ]);
            }

            return true;
        } catch (error) {
            console.log('error : ', error);
        }
    };

    useEffect(() => {
        fetchCategory(initialPaginationState);
    }, []);

    return (
        <>
            <DropdownPagination
                label='(Select category)'
                labelStyle='paragraph-medium-italic text-neutral-500'
                placeholder='Enter city in english'
                shadow='shadow-smallDropDownShadow'
                menuItems={categoryMenuItems}
                fixedHeight='h-[200px]'
                fetchMenuItems={fetchCategory}
                setPaginationState={setPaginationState}
                paginationState={paginationState}
                selectedItem={selectedCategory}
                setSelected={(selection) => setSelectedCategory(selection)}
            />
        </>
    );
}
