import React from 'react';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as Export } from '../../../Assets/export.svg';
import { ReactComponent as LinkIcon } from '../../../Assets/link.svg';
import { ReactComponent as SelectIcon } from '../../../Assets/select.svg';
import CalenderField from '../../../Components/Calender/CalenderField';
import { useWindowSize } from '@uidotdev/usehooks';

const HeaderGroupDetails = (props) => {
    const { handleClickLinkOffers } = props;

    const screenWidth = useWindowSize().width;

    return (
        <>
            <div className='flex flex-row justify-between md:block'>
                <div className='flex flex-row md:justify-between'>
                    <div className='min-w-[280px] md:min-w-0 w-full '>
                        <CalenderField
                            label='25 Sept 2022 - 09 Oct 2022'
                            buttonStyle='md:px-0 md:pl-3 md:w-full'
                        />
                    </div>
                    <div className='max-w-[156px] w-full md:max-w-[64px] ml-4 lg:ml-2 md:ml-2'>
                        <LargePrimaryButton
                            label='Bulk select'
                            hideLabel='md:hidden'
                            leftIconDefault={<SelectIcon stroke='#ffffff' />}
                            leftIconClick={<SelectIcon stroke='#C4BEED' />}
                        />
                    </div>
                </div>
                <div className='flex flex-row md:justify-between md:my-4'>
                    <div
                        className='md:w-1/2 md:pl-[3.5px] lg:mx-2 mx-4 md:mx-0 md:mr-2 mobile:mr-1 cursor-pointer'
                        onClick={handleClickLinkOffers}>
                        <LargePrimaryButton
                            isDefault={false}
                            label='Link offers'
                            leftIconDefault={screenWidth > 357 && <LinkIcon stroke='#FFFFFF' />}
                            leftIconClick={screenWidth > 357 && <LinkIcon stroke='#C4BEED' />}
                            badgeText={4}
                            hideLabel='lg:hidden md:block'
                        />
                    </div>
                    {/* <div className='md:w-1/2 md:mx-0 md:ml-2 mobile:ml-1'>
                        <LargePrimaryButton
                            label='Export data'
                            leftIconDefault={<Export stroke='#FFFFFF' />}
                            leftIconClick={<Export stroke='#C4BEED' />}
                            hideLabel='lg:hidden md:block'
                        />
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default HeaderGroupDetails;
