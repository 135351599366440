import React, { useEffect, useState } from 'react';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';
import { bannerType } from './constants';
import DropdownWithValueLabel from '../../../../Components/DropDown/DropdownWithValueLabel';

export default function TypeSelection({ bannerTypeCheck }) {
    const selectedType = bannerType.find((type) => type?.url == bannerTypeCheck) || bannerType[0];
    const [selectedBannerType, setSelectedBannerType] = useState(selectedType);

    useEffect(() => {
        const matchedType = bannerType.find((type) => type.url == bannerTypeCheck);
        if (matchedType) {
            setSelectedBannerType(matchedType);
        }
    }, [bannerTypeCheck]);
    return (
        <DropdownWithValueLabel
            label='Banner type'
            boxHeight='h-[44px]'
            name='bannerType'
            className='mb-[13px] relative'
            menuItems={bannerType}
            maxWidth='max-w-full'
            selectedItem={selectedBannerType}
        />
    );
}
