import React from 'react';
import { IsIpadScreen, IsMobileScreen } from '../../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import LeftPanel from './LeftPanel.js';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as LeftArrow } from '../../../../Assets/chevron-down.svg';
import { DropDownTabs } from '../../../../Components/DropDown/DropDownTabs';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../Components/Buttons/Button';

export default function Layout(props) {
    const { children } = props;

    const { choiceId, choiceTab } = useParams();

    const isMobileScreen = IsMobileScreen();

    const isIpadScreen = IsIpadScreen();

    const { height: screenHeight } = useWindowSize();

    const navigate = useNavigate();

    const isEditMode = !!choiceId;

    const onSave = () => {};

    const tabs = [
        {
            item: 'Basic details',
            onClick: () =>
                navigate(`${isEditMode ? `/menu/addon/choice/${choiceId}` : `/menu/addon/choice`}/basic-details`),
            link: 'basic-details',
            formState: 'details',
        },
        {
            item: 'Expose choice',
            onClick: () =>
                navigate(`${isEditMode ? `/menu/addon/choice/${choiceId}` : `/menu/addon/choice`}/expose-choice`),
            link: 'expose-choice',
            formState: 'exposeChoice',
        },
        {
            item: 'Pricing',
            onClick: () => navigate(`${isEditMode ? `/menu/addon/choice/${choiceId}` : `/menu/addon/choice`}/pricing`),
            link: 'pricing',
            formState: 'pricing',
        },
    ];

    const { handleSubmit } = useFormContext();

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className='px-8 lg:px-4 w-full max-w-[1336px] mx-auto bg-white relative md:max-w-full'
                    style={{ height: isIpadScreen ? screenHeight - 132 : screenHeight - 147 }}>
                    {isMobileScreen && (
                        <div className=''>
                            <div
                                className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer md:px-1 md:pt-4'
                                onClick={() => navigate('/menu')}>
                                <LeftArrow className='rotate-90' />
                                <span className='ml-1'>Back to catalogue</span>
                            </div>

                            <div className='block mb-4 pb-4 border-b border-neutral-300 mx-1'>
                                <DropDownTabs menuItems={tabs} />
                            </div>
                        </div>
                    )}

                    <div className='flex flex-row md:block'>
                        <LeftPanel tabs={tabs} />
                        <div className='w-full pl-1 md:pr-1 md:mt-0 md:pb-20'>{children}</div>
                    </div>
                </div>

                <div className='sticky z-[49] md:z-[8] group-[.undock-left-panel]:z-[8] md:fixed md:justify-center md:border-none md:py-0 md:pb-1 md:pt-2 md:shadow-dropShadow left-0 right-0 bottom-0 flex px-8 lg:px-4 flex-row justify-end w-full max-w-[1336px] mx-auto bg-white py-6 border-t border-neutral-300 lg:py-4'>
                    <div
                        className='min-w-[196px] mr-5 md:w-1/2 md:mr-[7.5px] md:min-w-0'
                        onClick={() => navigate('/menu')}>
                        <LargeDestructiveButton label='Discard' />
                    </div>

                    <div
                        className='min-w-[196px] md:w-1/2 md:ml-[7.5px] md:min-w-0'
                        onClick={handleSubmit(onSave)}>
                        <LargePrimaryButton
                            label={choiceTab === 'up-sell-current-dish' ? 'Save' : 'Next'}
                            isDefault={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
