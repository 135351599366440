import React from 'react';
import { useFormContext } from 'react-hook-form';
import DishCategorySelection from './Components/DishCategorySelection';
import FreeItemSection from './FreeItemSection.js';
import BuyXGetYSection from './BuyXGetYSection.js';
import AddonAndMaximumDiscount from './Components/AddonAndMaximumDiscount';
import TitleDescription from '../../../../Components/TitleDescription/TitleDescription';
import InputSection from '../../../../Components/InputField/InputSection';
import { preferenceMenuItem } from '../../../../Constants/Constants';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';

export default function Configure() {
    const { watch } = useFormContext();

    const implementationPresetMenuItems = [
        { label: 'Applicable on all categories', value: 'all_categories' },
        { label: 'Applicable on specific categories', value: 'specific_categories' },
        { label: 'Applicable on specific items', value: 'specific_items' },
    ];

    const isMinimumOrderCountEnabled = watch('is_minimum_order_count_enable')?.value;

    return (
        <>
            <div className='max-w-[636px] md:max-w-full lg:mb-5 md:mb-12 lg:max-w-[459px] w-full'>
                {watch('offer_type')?.value === 'flat_discount' && (
                    <div className='mb-6'>
                        <InputSection
                            title='Discount amount'
                            description='Please enter discount amount in rupees for this offer.'
                            placeholder='Enter coupon code'
                            name='discount_amount'
                            inputType='number'
                            rules={{ required: 'Please enter discount amount' }}
                        />
                    </div>
                )}

                {(watch('offer_type')?.value === 'percentage_discount' ||
                    watch('offer_type')?.value === 'combo_item_price_deal' ||
                    watch('offer_type')?.value === 'menu_discount') && (
                    <InputSection
                        className='mb-6'
                        title='Percentage discount'
                        description='Please enter the percentage amount to this offer.'
                        placeholder='Please enter percentage'
                        name='percentage'
                        inputType='number'
                        enteringLimit={100}
                        rules={{ required: 'Please enter percentage' }}
                    />
                )}

                {watch('offer_type')?.value === 'percentage_discount' && <AddonAndMaximumDiscount />}

                {watch('offer_type')?.value === 'combo_item_price_deal' && (
                    <div className='mb-6'>
                        <InputSection
                            title='Combo index number'
                            description='Please enter the combo index number to this offer.'
                            placeholder='Please enter combo index number'
                            name='combo_item_price_deal'
                            inputType='number'
                            rules={{ required: 'Please enter combo index number' }}
                        />
                    </div>
                )}

                {watch('offer_type')?.value === 'free_items' && <FreeItemSection />}

                {watch('offer_type')?.value === 'buy_x_get_y' && <BuyXGetYSection />}

                <InputSection
                    title='Offer cooldown period'
                    description='Enter the time in minutes, after which a customer can use this offer again.'
                    placeholder='Enter time in minutes'
                    name='repeat_usage_time'
                    inputType='number'
                    className='mb-6'
                />

                <TitleDescription
                    className='mb-4'
                    title='Minimum cart value'
                    description='Select whether you would like to keep a minimum cart value to apply this offer.'
                />

                <div className='flex flex-row justify-between w-full mb-6 lg:block gap-3 md:gap-0'>
                    <DropdownSection
                        menuItems={preferenceMenuItem}
                        labelPaddingB='pb-2'
                        label='(Select preference)'
                        placeholder='Select your preference'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        name='is_minimum_order_count_enable'
                        className='lg:mb-4'
                        rules={{ required: 'Please select preference' }}
                        clearErrorName={['minimum_cart_amount_value']}
                        zIndex='z-10'
                        additionalBelowSpace={100}
                    />

                    {isMinimumOrderCountEnabled && (
                        <InputSection
                            label='(Enter amount in rupees)'
                            placeholder='Enter minimum cart value in rupees'
                            labelStyle={`paragraph-medium-italic ${
                                !watch('is_minimum_order_count_enable')?.value ? 'text-neutral-300' : 'text-neutral-500'
                            }`}
                            disabled={!watch('is_minimum_order_count_enable')?.value}
                            inputType='number'
                            name='minimum_cart_amount_value'
                            boxHeight='12'
                            rules={
                                isMinimumOrderCountEnabled
                                    ? { required: 'Please enter minimum cart value' }
                                    : { required: false }
                            }
                        />
                    )}
                </div>

                <DropdownSection
                    className='mb-4'
                    title='Offer implementation'
                    description='Select categories and dishes on which this offer would be applicable.'
                    label='(Select presets)'
                    labelPaddingB='pb-2'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    name='implementation_preset'
                    placeholder='Select any category'
                    menuItems={implementationPresetMenuItems}
                    rules={{ required: 'Please select preset' }}
                    zIndex='z-[1]'
                    additionalBelowSpace={120}
                />

                {watch('implementation_preset')?.value !== implementationPresetMenuItems[0]?.value &&
                    !!watch('implementation_preset')?.value && (
                        <div className='max-w-[392px] w-full mb-2 relative md:max-w-full'>
                            <label className='paragraph-medium-italic text-neutral-500'>
                                (Search
                                {watch('implementation_preset')?.value === implementationPresetMenuItems[1]?.value
                                    ? ' categories'
                                    : ' dishes'}
                                )
                            </label>
                        </div>
                    )}
                <DishCategorySelection implementationPresetMenuItems={implementationPresetMenuItems} />
            </div>
        </>
    );
}
