import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import AddImageSection from './AddImageSection';
import AddDetails from './AddDetails';
import FileDetails from './FileDetails';
import SaveButtonContainer from './SaveButtonContainer';
import EditImageContainer from './EditImageContainer';
import { uploadImage } from './functions';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import APIV5 from '../../api/axios/APIV5';
import { useLocation } from 'react-router-dom';

export default function ImageWithForm(props) {
    const {
        showEditImage,
        handleClickClose,
        setShowEditImage,
        setUploadedImageDetails,
        fetchData,
        imageDetails,
        isEditSection,
        selectedType,
        aspectRatio,
        isRiderPage,
        fetchImageSubCategory,
        typeSubCategory,
        setTypeSubCategory,
        setUserDetail,
    } = props;
    const url = useLocation().pathname;

    const [isShowSaveLoader, setIsShowSaveLoader] = useState(false);

    const [menuTypes, setMenuTypes] = useState([]);

    const formatDefaultValue = () => {
        const removedSnackCase = imageDetails.image_type.split('_').join(' ');

        const image_category = {
            label: removedSnackCase.charAt(0).toUpperCase() + removedSnackCase.slice(1),
            value: imageDetails.image_type,
        };

        return {
            uploaded_on: imageDetails.uploaded_on,
            uploader: imageDetails.uploaded_by,
            image_category,
            imageSize: (imageDetails.file_size / 1024).toFixed(2),
            originalDimensions: {
                width: imageDetails.original_image_width,
                height: imageDetails.original_image_height,
            },
            croppedDimensions: {
                width: imageDetails.cropped_image_width,
                height: imageDetails.cropped_image_height,
            },
            alternative_title: imageDetails.alternative_title,
            image_title: imageDetails.image_title,
            imageType: imageDetails.file_type ? imageDetails.file_type : 'riders',
            imageUrlToDisplay: imageDetails.image_url,
            image_tags: imageDetails.image_tags.map((el) => ({ label: el.name, value: el.id })),
            originalImage: { image_url: imageDetails.original_image_url },
            is_dirty: false,
            editingImage: { image_url: imageDetails.image_url },
        };
    };

    const rations = {
        primary_banner: 1.74825174825,
        secondary_banner: 2.70157938487,
        tertiary_banner: 2.70157938487,
        notifications: 2,
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const fetchTypeMenuItems = async () => {
        try {
            const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/image-types`);
            const type = response.image_types.map((el) => {
                const removedSnackCase = el.split('_').join(' ');
                return { label: removedSnackCase.charAt(0).toUpperCase() + removedSnackCase.slice(1), value: el };
            });

            setMenuTypes(type);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        !url.includes('banners') && fetchTypeMenuItems();
        fetchImageSubCategory && fetchImageSubCategory();
    }, []);

    const handleClickSave = (data) => {
        if (!data?.editingImage?.image_url) {
            setError('selectedImage', { message: 'Please select an image' });
            return;
        }

        const formattedImageDetails = {
            image_title: data?.image_title,
            alternative_title: data?.alternative_title,
            image_type: data?.image_category?.value ? data?.image_category?.value : 'riders',
            tag_ids: data?.selectedCategory?.map((el) => el?.value),
            original_image: data?.originalImage,
            is_dirty: data?.is_dirty,
        };

        setIsShowSaveLoader(true);

        uploadImage(
            selectedRestaurantId,
            setUploadedImageDetails,
            handleClickClose,
            fetchData,
            formattedImageDetails,
            isEditSection,
            imageDetails.id,
            data.is_dirty ? data.editingImage.image_url : data.originalImage.image_url,
            setIsShowSaveLoader,
            'filename'
        );
    };
    const defaultOptions = _.isEmpty(imageDetails) ? {} : formatDefaultValue();

    const method = useForm({
        defaultValues: {
            ...defaultOptions,
            ...(selectedType
                ? {
                      image_category: selectedType,
                      isDisableCategory: true,
                      aspectRatio: aspectRatio ?? rations?.[selectedType?.value],
                  }
                : {}),
        },
    });
    const { watch, handleSubmit, setError } = method;
    const [subCategoryMenuItem, setSubCategoryMenuItem] = useState(
        typeSubCategory?.filter((item) => watch('image_tags')?.some((el) => item.label.includes(el.label)))
    );

    return (
        <>
            <FormProvider {...method}>
                {!showEditImage ? (
                    <div className='flex flex-row items-start lg:block'>
                        <AddImageSection
                            setShowEditImage={setShowEditImage}
                            menuTypes={menuTypes}
                            isRiderPage={isRiderPage}
                        />

                        <div className='w-full max-w-[375px] lg:ml-0 lg:flex-row lg:max-w-full lg:items-start lg:flex md:block ml-6'>
                            <AddDetails
                                typeSubCategory={typeSubCategory}
                                fetchImageSubCategory={fetchImageSubCategory}
                                subCategoryMenuItem={subCategoryMenuItem}
                                setSubCategoryMenuItem={setSubCategoryMenuItem}
                                isRiderPage={isRiderPage}
                                menuTypes={menuTypes}
                                setMenuTypes={setMenuTypes}
                            />

                            <div className='w-full lg:mt-6 mt-6'>
                                <FileDetails
                                    imageDetails={imageDetails}
                                    setUserDetail={setUserDetail}
                                />

                                <SaveButtonContainer
                                    handleClickSave={handleSubmit(handleClickSave)}
                                    handleClickClose={handleClickClose}
                                    isShowSaveLoader={isShowSaveLoader}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='md:h-[80vh]'>
                        <EditImageContainer handleClickClose={() => setShowEditImage(false)} />
                    </div>
                )}
            </FormProvider>
        </>
    );
}
