import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import Tag from '../../../Components/Tag/Tag';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';

export default function Selectlangauge(props) {
    const { handleChangeLangauge, notificationDetailsEnable, pageTitle, details, isEditNotificationFieldEnabled } =
        props;

    const [selectLangs, setSelectLangs] = useState([]);

    const {
        watch,
        setValue,
        formState: { isDirty, errors },
        setError,
        clearErrors,
    } = useFormContext();

    const languageList = useSelector((state) => state.langauge.availableLanguages);

    const selectedLanguages = watch('selectedLangauges') || [];

    useEffect(() => {
        if (notificationDetailsEnable === false) {
            setValue('selectedLangauges', []);
        }
    }, [notificationDetailsEnable, setValue]);
    useEffect(() => {
        if (errors.removeLanguageError) {
            const timeoutId = setTimeout(() => {
                clearErrors('removeLanguageError');
            }, 5000);

            return () => clearTimeout(timeoutId);
        }
    }, [errors.removeLanguageError, clearErrors]);

    // useEffect(() => {
    //     if (pageTitle === 'Notification details' && selectedLanguages.length === 0) {
    //         const englishLanguage = languageList.find((lang) => lang.language_label === 'English');
    //         if (englishLanguage) {
    //             setValue('selectedLangauges', [englishLanguage]);
    //         }
    //     }
    // }, [pageTitle, languageList, selectedLanguages.length, setValue]);

    const handleSelectionLanguage = (language) => {
        console.log('handleSelectionLanguagelanguage <====>', language);
        setValue('selectedLangauges', [...selectedLanguages, language.value]);
        clearErrors('removeLanguageError');
    };

    const removeLanguage = (tag) => {
        if (selectedLanguages.length > 1) {
            const updatedList = selectedLanguages.filter((el) => el.id !== tag.id);
            setValue('selectedLangauges', updatedList);
        } else {
            setError('removeLanguageError', { message: 'At least one language is required' });
        }
    };

    const languageOptions = languageList.map((language) => ({
        label: language.language_label,
        value: language,
    }));

    const availableLanguageOptions = languageOptions.filter(
        (item) => !selectedLanguages.find((selectedItem) => selectedItem.id === item.value.id)
    );

    return (
        <>
            <DropdownWithValueLabel
                label='(Select languages)'
                placeholder='Select languages'
                labelPaddingB='pb-2'
                labelStyle='paragraph-medium-italic text-neutral-500'
                shadow='shadow-smallDropDownShadow'
                className='mb-1'
                disabled={!isEditNotificationFieldEnabled}
                // menuItems={
                //     languageOptions.filter(
                //         (item) => !watch('langauges')?.find((selectedItem) => selectedItem.id === item.value.id)
                //     ) ?? []
                // }

                // this is the new changes
                menuItems={languageOptions.filter(
                    (item) =>
                        !(watch('selectedLangauges') || []).find((selectedItem) => selectedItem.id === item.value.id)
                )}
                hasFixedPlaceholder
                setSelected={handleSelectionLanguage}
            />
            {selectedLanguages.map((el, index) => (
                <div
                    className='mr-2 inline-block'
                    key={index}>
                    <Tag
                        tag={el?.language_label}
                        onClose={() => removeLanguage(el)}
                        disabled={!isEditNotificationFieldEnabled}
                    />
                </div>
            ))}
        </>
    );
}
