import React from 'react';

export default function OrderDetailCard(props) {
    const { icon, truncate, textColor, title, statusIcon, onClick } = props;

    return (
        <>
            <div
                className='w-full rounded h-[56px] border border-neutral-300 relative justify-center text-center mx-auto pt-1 lg:px-1'
                onClick={onClick}>
                {icon ?? <div className='paragraph-medium-regular text-secondary-800 h-6'>-</div>}
                <div
                    className={`paragraph-medium-medium pageContent:text-sm ${
                        truncate && 'truncate w-[100px]'
                    } mx-auto ${textColor}`}>
                    {title}
                </div>
                <div className='absolute top-1 right-1'>{statusIcon}</div>
            </div>
        </>
    );
}
