import React from 'react';

const PlatformSalesDetails = ({ platformSalesData, color }) => {
    return (
        <>
            {platformSalesData?.platform_stats?.map((el, index) => (
                <PlatformSalesInfo
                    platForm={el?.platform_name}
                    sales={el?.sales}
                    // color={color}
                    key={index}
                />
            ))}
        </>
    );
};

export default PlatformSalesDetails;

const PlatformSalesInfo = (props) => {
    const { color, platForm, sales } = props;
    return (
        <div className='flex justify-between items-center paragraph-medium-regular pb-6 last:pb-0'>
            <div className='flex items-center'>
                <div className={`w-[11px] h-[11px] rounded-full mr-2 ${color}`} />
                <div>{platForm}</div>
            </div>
            <div>{sales}</div>
        </div>
    );
};
