import React from 'react';
import FillAddonChoiceDetails from './Components/FillAddonChoiceDetails';

export default function CreateAddonChoice() {
    return (
        <>
            <FillAddonChoiceDetails />
        </>
    );
}
