import React, { useState, useEffect } from 'react';
import { ReactComponent as MaximizeIcon } from '../../../Assets/maximize.svg';
import { ReactComponent as FilterIcon } from '../../../Assets/filter.svg';
import { ReactComponent as OrderIcon } from '../../../Assets/order.svg';

import { Tab } from '../../../Components/Tabs/Tabs';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import FilterDropDown from './FilterDropDown';
import { IsMobileScreen } from '../../../Constants/Constants';
import OrderCard from './OrderCard';

export default function Header(props) {
    const { handleClickMaximize, handleClickDishesSummery, activeTab, setActiveTab, tabs } = props;

    const [showFilters, setShowFilters] = useState(false);

    const handleClickFilter = () => {
        setShowFilters(!showFilters);
    };

    const isMobileScreen = IsMobileScreen();

    // useEffect(() => {
    //     // Reset selectedCard when switching tabs
    //     if (activeTab !== 2) {
    //         props.setSelectedCard(null);
    //     }
    // }, [activeTab, props]);

    return (
        <>
            <div className='px-8 pt-4 lg:px-4'>
                <div className='mb-4 md:hidden flex'>
                    <span
                        className='cursor-pointer -mb-1 mt-1 ml-auto'
                        onClick={handleClickMaximize}>
                        <MaximizeIcon />
                    </span>
                </div>

                <div className='pb-6 border-b border-neutral-300 lg:pb-4'>
                    <div className='flex flex-row justify-between items-start lg:block'>
                        {!isMobileScreen ? (
                            <div className='flex flex-row items-center md:hidden'>
                                {tabs.map((el, index) => (
                                    <div
                                        className='mr-4 xl:mr-2'
                                        onClick={() => setActiveTab(el.value)}
                                        key={index}>
                                        <Tab
                                            label={el.item}
                                            badgeText={el.badgeText}
                                            isActive={activeTab === el.value}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className='hidden md:block w-full'>
                                <DropDownTabs
                                    shadow='shadow-xSmall'
                                    menuItems={tabs}
                                    tabStyle='paragraph-small-regular'
                                    itemStyle='paragraph-small-regular'
                                    boxStyle='border-neutral-300'
                                    dropDownIconFill='#131126'
                                    textColor='text-neutral-900'
                                />
                            </div>
                        )}

                        <div className='flex items-center flex-row-reverse lg:block'>
                            <div className='flex flex-row lg:mt-4'>
                                <div
                                    className='lg:w-1/2 cursor-pointer'
                                    onClick={handleClickFilter}>
                                    <LargePrimaryButton
                                        label='Filters'
                                        leftIconDefault={<FilterIcon fill='#FFFFFF' />}
                                        leftIconClick={<FilterIcon fill='#C4BEED' />}
                                        hideLabel='xl:hidden lg:block mobile:hidden'
                                    />
                                </div>

                                <div
                                    className='ml-4 xl:ml-2 lg:w-1/2 cursor-pointer'
                                    onClick={handleClickDishesSummery}>
                                    <LargePrimaryButton
                                        label='Dishes summary'
                                        leftIconDefault={<OrderIcon stroke='#FFFFFF' />}
                                        leftIconClick={<OrderIcon stroke='#C4BEED' />}
                                        hideLabel='xl:hidden lg:block mobile:hidden'
                                    />
                                </div>
                            </div>

                            {showFilters && (
                                <div
                                    className={`min-w-[213px] pageContentSmall:min-w-[140px] w-full mr-4 xl:mr-2 relative lg:mr-0 lg:mt-4`}>
                                    <FilterDropDown />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Conditional rendering based on the active tab */}
                {/* {activeTab === 2 && Array.isArray(selectedCard) && (
                    <div className='mt-4 flex flex-wrap'>
                        {selectedCard.map((order, index) => (
                            <div
                                className='w-[329px] 2xlBig:w-[326px] mx-2.5 inline-block align-top 2xl:mx-0 2xl:w-1/3 pageContentSmall:w-1/2 2xl:min-w-0 2xl:px-2.5 lg:px-1 pt-6 lg:pt-4 md:w-full md:px-0 lg:min-w-0'
                                key={index}>
                                <OrderCard
                                    KOT={index + 1}
                                    {...order}
                                />
                            </div>
                        ))}
                    </div>
                )} */}
            </div>
        </>
    );
}
