import React from 'react';

import { ReactComponent as SuccessTickIcon } from '../../../../Assets/success-tick.svg';
import { ReactComponent as InfoIcon } from '../../../../Assets/reimbursement.svg';
import { ReactComponent as CancelledIcon } from '../../../../Assets/cancel.svg';
import { ReactComponent as DelieryIcon } from '../../../../Assets/riders.svg';
import { ReactComponent as DininIcon } from '../../../../Assets/dine-in.svg';
import { ReactComponent as TakeAwayIcon } from '../../../../Assets/orders.svg';
import { ReactComponent as TableIcon } from '../../../../Assets/LiveOrderTableIcon.svg';
import { IsMobileScreen } from '../../../../Constants/Constants';

export const OrderStatus = ({ status }) => {
    const isMobileScreen = IsMobileScreen();

    const iconHeight = isMobileScreen ? 18 : 20;

    const iconWidth = isMobileScreen ? 18 : 20;

    const statusIconController = (value) => {
        return value === 'Delivered' || value === 'Refund success' ? (
            <SuccessTickIcon
                height={iconHeight}
                width={iconWidth}
            />
        ) : value === 'Refund initiated' ? (
            <InfoIcon
                height={iconHeight}
                width={iconWidth}
            />
        ) : (
            <CancelledIcon
                height={iconHeight}
                width={iconWidth}
            />
        );
    };

    return (
        <>
            {status.map((statusEl, statusIndex) => {
                const statusIcon = statusIconController(statusEl);
                return (
                    <div
                        className='flex flex-row items-center py-0.5'
                        key={statusIndex}>
                        {statusIcon}
                        <span className='ml-1'>{statusEl}</span>
                    </div>
                );
            })}
        </>
    );
};

export const ModeCustomerName = (props) => {
    const { orderingMode, customerName, tableName } = props;
    const modeIcon =
        orderingMode === 'delivery' ? (
            <DelieryIcon stroke='#FFFFFF' />
        ) : orderingMode === 'dine_in' ? (
            <DininIcon
                stroke='#FFFFFF'
                fill='#6C5DD3'
            />
        ) : (
            <TakeAwayIcon stroke='#FFFFFF' />
        );

    const iconBG =
        orderingMode === 'delivery'
            ? 'bg-tertiary-800'
            : orderingMode === 'dine_in'
              ? 'bg-primary-500'
              : 'bg-secondary-700';

    return (
        <>
            <div className='flex flex-row items-center'>
                <div className={`flex min-w-[40px] h-10 rounded-lg ${iconBG}`}>
                    <span className='m-auto'>{modeIcon}</span>
                </div>
                <div className='flex flex-col ml-2'>
                    <span>{customerName}</span>
                    {tableName && (
                        <div className='flex flex-row'>
                            <TableIcon fill={'black'} />
                            <span className='ml-1'>{tableName}</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
