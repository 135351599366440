import React from 'react';

export default function CategoryCard(props) {
    const translations = JSON.parse(props?.translations);
    const linkedItem = JSON.parse(props?.category_item_links);
    return (
        <>
            <div className='border border-neutral-300 rounded-md px-3 py-2.5 min-w-[196px] flex flex-row bg-white bg:hover-bg-[#F8F7FD]'>
                <img
                    src={props?.image_url}
                    alt=''
                    className='w-[72px] h-[72px] pageContent:w-16 pageContent:h-16 rounded-md mr-2'
                />
                <div className='flex flex-col py-0.5'>
                    <h6 className='heading-h6-medium-desktop mb-5 pageContent:text-base'>{translations?.title?.[1]}</h6>
                    <span className='paragraph-small-italic text-neutral-500 pageContent:text-xs'>
                        {linkedItem?.length} dishes
                    </span>
                </div>
            </div>
        </>
    );
}
