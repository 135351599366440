import React from 'react';
import DishCard from '../Components/DishCard';
import { ReactComponent as SearchIcon } from '../../../Assets/search.svg';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import pizza1 from '../../../Assets/MediaLibrary/pizza1.png';
import pizza2 from '../../../Assets/MediaLibrary/pizza2.png';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';
import { useSelector } from 'react-redux';

export default function DishesSection(props) {
    const { setShowPopup, categoryItems } = props;

    const { height: screenHeight, width: screenWidth } = useWindowSize();
    const items = useSelector((state) => state.outlet.categoryWithItems);

    const isMobileScreen = IsMobileScreen();

    const determineHeight = () => {
        let height;

        switch (true) {
            case screenWidth < 768:
                height = 'auto';
                break;

            case screenWidth < 1441:
                height = screenHeight - 375;
                break;

            case screenWidth > 2600:
                height = screenHeight - 465;
                break;

            default:
                height = screenHeight - 380;
                break;
        }
        return height;
    };

    return (
        <>
            <div className='pl-6 md:px-4'>
                <div className='flex flex-row justify-between items-start pr-7 lg:pr-5 md:pr-0 md:block pb-4'>
                    {!isMobileScreen && (
                        <div className='flex flex-col lg:pr-2'>
                            <span className='paragraph-large-semi-bold mb-4'>Dishes</span>
                        </div>
                    )}

                    <div className='w-full max-w-[375px] md:max-w-full'>
                        <DefaultInputField
                            placeholder='Search category or dish'
                            placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                        />
                    </div>
                </div>

                {isMobileScreen && (
                    <div className='border-t border-neutral-300 mt-4 pt-4'>
                        <button
                            onClick={() => setShowPopup('mobileCategory')}
                            className='flex flex-row justify-between m-auto w-full paragraph-medium-regular rounded-md focus:outline-none focus:ring-0 font-normal leading-5 appearance-none px-4 py-[14px] border border-primary-500 bg-primary-50 h-12 items-center'>
                            <span className='paragraph-medium-regular text-primary-500 truncate'>
                                Categories - All (102 dishes)
                            </span>
                            <span className='min-w-[24px] ml-2'>
                                <DownArrow
                                    className='drop-down-icon-rotate'
                                    fill={'#6C5DD3'}
                                />
                            </span>
                        </button>
                    </div>
                )}

                <div className=''>
                    {isMobileScreen && (
                        <div className='pt-4 pb-1'>
                            <span className='paragraph-medium-semi-bold'>Pizza</span>
                        </div>
                    )}

                    <div
                        className='-ml-2.5 overflow-auto scrollbar-style mr-0.5 md:m-0 md:pb-24'
                        style={{ height: determineHeight() }}>
                        {categoryItems?.subcategories?.length
                            ? categoryItems?.subcategories.map((item, index) => {
                                  return (
                                      <React.Fragment key={index}>
                                          <div className='paragraph-medium-italic text-neutral-500 ml-2.5'>
                                              {JSON.parse(item.translations).title[1]}
                                          </div>
                                          {item?.items?.map((el, id) => (
                                              <div
                                                  className='inline-block align-top mx-2.5 mt-4 cursor-pointer md:block md:mx-0 md:mt-2 last:mb-2'
                                                  key={id}>
                                                  <DishCard
                                                      item={el}
                                                      setShowPopup={setShowPopup}
                                                  />
                                              </div>
                                          ))}
                                      </React.Fragment>
                                  );
                              })
                            : categoryItems?.items?.length &&
                              categoryItems?.items.map((el, id) => {
                                  return (
                                      <div
                                          className='inline-block align-top mx-2.5 mt-4 cursor-pointer md:block md:mx-0 md:mt-2 last:mb-2'
                                          key={id}>
                                          <DishCard
                                              item={el}
                                              setShowPopup={setShowPopup}
                                          />
                                      </div>
                                  );
                              })}
                        {/* {items[selectedCategory] &&
                            items[selectedCategory]?.items.map((item, id) => (
                                <div
                                    className='inline-block align-top mx-2.5 mt-4 cursor-pointer md:block md:mx-0 md:mt-2 last:mb-2'
                                    key={id}>
                                    <DishCard
                                        item={item}
                                        setShowPopup={setShowPopup}
                                    />
                                </div>
                            ))} */}
                    </div>
                </div>
            </div>
        </>
    );
}
