export function toSentenceCase(str) {
    if (!str) return ''; // Handle empty or undefined strings
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function toSentenceCaseWithSpecialCharacter(text) {
    if (!text) return text;
    text = text.trim().toLowerCase().replace(/_/g, ' ');
    return text.charAt(0).toUpperCase() + text.slice(1);
}
