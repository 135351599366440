import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from '@uidotdev/usehooks';
import { useSelector } from 'react-redux';
import ListLoader from '../../../Components/ListLoader';
import { Dishes } from '../../../Components/Card';
import { fetchDishList, getFoodType } from '../../../../../functions';

export default function SubCategoryDishList(props) {
    const {
        setSelectedCategoryDish,
        selectedCategoryDish,
        handleClickEditCategoryDish,
        onSelectDish,
        selectedDish,
        allCategoryDish,
        setAllCategoryDish,
    } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [isLoading, setIsLoading] = useState(true);

    const [subCategoryDishList, setSubCategoryDishList] = useState([]);

    const checkDishExist = (category) => {
        const hasDish = allCategoryDish
            .find((el) => el.category_id === category.parent_id)
            ?.sub_categories?.[0]?.hasOwnProperty('dish');

        if (hasDish) {
            const dishList = allCategoryDish
                .find((el) => el.category_id === category.parent_id)
                ?.sub_categories?.find((el) => el.category_id === category.category_id)?.dish;

            setSubCategoryDishList((prevState) => [
                ...prevState,
                { category: { name: category.internal_name, id: category.category_id }, dish: dishList },
            ]);
        }

        return hasDish;
    };

    const getAllSubCategory = async (category) => {
        let dishList = [];

        const hasDish = checkDishExist(category);

        if (hasDish) {
            return;
        }

        for (let index = 0; index < Math.ceil(category.total_items / 10); index++) {
            const dish = await fetchDishList(
                selectedRestaurantId,
                { page: index + 1, per_page: 10 },
                category.category_id
            );

            dishList.push(...dish.data);
        }

        setAllCategoryDish((prevState) =>
            prevState.map((el) => {
                if (el.category_id === category.parent_id) {
                    return {
                        ...el,
                        sub_categories: el.sub_categories.map((subCategoryEl) => {
                            if (subCategoryEl.category_id === category.category_id) {
                                return { ...subCategoryEl, dish: dishList };
                            } else {
                                return subCategoryEl;
                            }
                        }),
                    };
                }

                return el;
            })
        );

        setSubCategoryDishList((prevState) => [
            ...prevState,
            { category: { name: category.internal_name, id: category.category_id }, dish: dishList },
        ]);
    };

    useEffect(() => {
        if (!!selectedCategoryDish?.subCategory?.length) {
            setIsLoading(true);

            setSubCategoryDishList([]);

            const getAllSubCategoryPromises = selectedCategoryDish?.subCategory.map((el) => getAllSubCategory(el));

            Promise.all(getAllSubCategoryPromises)
                .then(() => {
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Error while fetching subcategories:', error);
                });
        }
    }, [selectedCategoryDish.category.category_id]);

    const screenSize = useWindowSize();

    const subCategoryDishListRef = useRef([]);

    useEffect(() => {
        const targetIndex = subCategoryDishList.findIndex(
            (item) => item.category.id === selectedCategoryDish.subCategoryId
        );

        if (targetIndex !== -1 && subCategoryDishListRef.current[targetIndex]) {
            subCategoryDishListRef.current[targetIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [selectedCategoryDish.subCategoryId]);

    const handleScroll = (event) => {
        const scrollPosition = event.target.scrollTop;
        const categoryIndex = findCategoryIndexByScrollPosition(scrollPosition);
        if (categoryIndex !== -1) {
            setSelectedCategoryDish((prevState) => ({
                ...prevState,
                subCategoryId: subCategoryDishList[categoryIndex].category.id,
            }));
        }
    };

    const findCategoryIndexByScrollPosition = (scrollPosition) => {
        let cumulativeHeight = 0;
        for (let i = 0; i < subCategoryDishListRef.current.length; i++) {
            const categoryHeight = subCategoryDishListRef.current[i].offsetHeight;
            cumulativeHeight += categoryHeight;
            if (cumulativeHeight > scrollPosition) {
                return i;
            }
        }
        return -1;
    };

    return (
        <>
            {isLoading ? (
                <div
                    className='overflow-auto scrollbar-style pr-8'
                    style={{ height: screenSize.height - 355 }}>
                    <ListLoader className='min-w-[273px] h-[104px] mt-2' />
                </div>
            ) : (
                <div
                    onScroll={handleScroll}
                    className='overflow-auto scrollbar-style pr-8'
                    style={{ height: screenSize.height - 355 }}>
                    {subCategoryDishList?.map((el, index) => {
                        return (
                            <div
                                key={index}
                                ref={(ref) => (subCategoryDishListRef.current[index] = ref)}>
                                <SingleCategoryDishList
                                    details={el}
                                    handleClickEditCategoryDish={handleClickEditCategoryDish}
                                    setSelectedCategoryDish={setSelectedCategoryDish}
                                    selectedCategoryDish={selectedCategoryDish}
                                    onSelectDish={onSelectDish}
                                    selectedDish={selectedDish}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}

const SingleCategoryDishList = (props) => {
    const {
        details,
        handleClickEditCategoryDish,
        setSelectedCategoryDish,
        selectedCategoryDish,
        onSelectDish,
        selectedDish,
    } = props;

    return (
        <>
            <h5 className='text-neutral-700 paragraph-large-italic pt-4'>{details.category.name}</h5>

            {!details?.dish.length && (
                <span className='paragraph-small-italic text-neutral-500'>No dishes available</span>
            )}

            {details?.dish.map((el, index) => {
                return (
                    <div
                        className={`mt-4 min-w-[273px] lg:min-w-0 `}
                        key={index}>
                        <Dishes
                            handleClickEdit={handleClickEditCategoryDish}
                            isEdit={false}
                            title={el.internal_name}
                            image={el.image_url}
                            variants={el.variants_count}
                            handleClickDish={() =>
                                setSelectedCategoryDish((prevState) => ({ ...prevState, dish: el.item_id }))
                            }
                            isActive={selectedCategoryDish.dish === el.item_id}
                            isRearrange={false}
                            type={getFoodType(el)}
                            index={index}
                            isSelectionActive={true}
                            onSelectDish={() => onSelectDish(el)}
                            isChecked={selectedDish.includes(el.item_id)}
                        />
                    </div>
                );
            })}
        </>
    );
};
