import React from 'react';
import { ReactComponent as CrossArrowIcon } from '../../../Assets/cross-arrow.svg';
import { ReactComponent as InformationIcon } from '../../../Assets/reimbursement.svg';
import { AboutDot } from './CardComponent';

export default function Card(props) {
    const { title, number, percentage, positive, description, information, showDetailsPopup, icon, showAbout } = props;

    // Function to check if the percentage is negative
    const isNegative = (value) => {
        return value < 0;
    };

    return (
        <div className='border border-neutral-300 rounded-md px-4 py-3 pb-2.5 relative w-full h-full flex flex-col justify-between'>
            <div>
                <div className='paragraph-small-semi-bold text-neutral-500 mb-3'>{title}</div>

                <div className='flex flex-row items-center mb-3'>
                    <span className='heading-h6-semi-bold-desktop mr-2'>{number}</span>

                    {percentage && (
                        <div
                            className={`px-1 border w-fit rounded flex flex-row items-center h-4 ${
                                isNegative(percentage)
                                    ? 'bg-red-100 border-red-600'
                                    : positive
                                      ? 'bg-tertiary-100 border-tertiary-800'
                                      : 'bg-destructive-100 border-destructive-600'
                            }`}>
                            <div
                                className={`${
                                    isNegative(percentage) ? 'text-red-600' : 'text-tertiary-800'
                                } leading-3 text-[10px] font-medium`}>
                                {Math.abs(percentage)}%
                            </div>

                            <CrossArrowIcon
                                stroke={isNegative(percentage) ? '#DC2626' : positive ? '#3D8C82' : '#DC2626'}
                                height={11}
                                width={11}
                                className={`${isNegative(percentage) && 'rotate-90'}`}
                            />
                        </div>
                    )}
                </div>
                <div className='min-h-[40px]'>{description}</div>
            </div>

            <div className='flex flex-row justify-between mt-3 items-center'>
                <div className='flex flex-row items-start'>
                    <span className='mr-1'>
                        <InformationIcon
                            fill='#6C5DD3'
                            height={14}
                            width={14}
                            className='rotate-180 mt-[3px]'
                        />
                    </span>

                    <span className='paragraph-x-small-regular text-neutral-500'>{information}</span>
                </div>
                {showAbout && (
                    <span
                        onClick={showDetailsPopup}
                        className='cursor-pointer'>
                        <AboutDot />
                    </span>
                )}
            </div>

            <div
                className={`absolute right-4 top-3 h-8 w-8 rounded-lg justify-center flex ${
                    !(title === 'Swiggy' || title === 'Zomato') && 'bg-primary-500'
                }`}>
                <span className='m-auto'>{icon}</span>
            </div>
        </div>
    );
}
