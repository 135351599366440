import React from 'react';
import { ReactComponent as Moped } from '../../../Assets/moped.svg';
import { SmallTertiaryButton } from '../../Buttons/Button';
import { riderCancel } from '../../../Screen/Order/Helper/functions';

export default function AssignedOrder(orderDetail) {
    const { setCancelRiders, setShowPopup, handleClickClose, fetchData } = orderDetail;
    const pidgeService = orderDetail?.orderDetail?.pidge_order;
    let deliveryService = null;
    const orderDetails = orderDetail?.orderDetail;
    const orderId = orderDetails?.order_id;

    let riderDetails = {};

    if (orderDetails?.pidge_order) {
        riderDetails = orderDetails?.pidge_order;
        deliveryService = 'pidge';
    } else if (orderDetails?.porter_order) {
        riderDetails = orderDetails?.porter_order;
        deliveryService = 'porter';
    } else if (orderDetails?.shadowfax_order) {
        riderDetails = orderDetails?.shadowfax_order;
        deliveryService = 'shadowfax';
    } else if (orderDetails?.wefast_order) {
        riderDetails = orderDetails?.wefast_order;
        deliveryService = 'wefast';
    } else if (orderDetails?.dunzo_order) {
        riderDetails = orderDetails?.dunzo_order;
        deliveryService = 'dunzo';
    } else if (orderDetails?.elt_order) {
        riderDetails = orderDetails?.elt_order;
    } else {
        riderDetails = { ...orderDetails?.rider, ...orderDetails?.rider_order, order_id: orderDetails.order_id };
    }

    const handleClickCancleRider = async () => {
        // setCancelRiders(true);
        try {
            const response = await riderCancel(deliveryService, orderId, handleClickClose);

            if (response.success) {
                await fetchData();
            }
            // window.location.reload();
        } catch (error) {
            console.error('Failed to assign rider:', error);
        }
    };
    if (riderDetails) {
        return (
            <div className='px-2 bg-cyan-200'>
                <p className='flex flex-row text-2xl '>
                    <Moped
                        height={30}
                        width={30}
                    />{' '}
                    <span className='mx-4 font-bold'>Rider Information</span>
                </p>
                <p className='font-bold'>
                    Order Id :<span>{riderDetails?.order_id}</span>
                </p>
                {orderDetails?.pidge_order && (
                    <p className='font-bold'>
                        Rider service name : <span>{riderDetails?.network_name}</span>
                    </p>
                )}
                <p className='font-bold'>
                    Estimated amount :{' '}
                    <span>{`₹${riderDetails?.estimated_price ?? riderDetails?.rider_restaurant_cod_settlement}`}</span>
                </p>
                {!riderDetails?.rider_name ||
                !riderDetails?.first_name ||
                !riderDetails?.last_name ||
                !riderDetails?.middle_name
                    ? `Waiting for ${
                          riderDetails?.network_name ??
                          `${riderDetails?.first_name} ${riderDetails?.middle_name} ${riderDetails?.last_name}`
                      } to assign rider`
                    : ''}
                <hr />
                <div
                    onClick={handleClickCancleRider}
                    className='mt-12 pb-3 mb-2 md:fixed md:bottom-0 md:w-full md:pr-4 md:pb-2 md:pt-4 md:shadow-dropShadow md:-ml-4 md:pl-4'>
                    <SmallTertiaryButton label='Cancel rider' />
                </div>
            </div>
        );
    }
    return null;
}
