import React from 'react';
import { IsMobileScreen } from '../../../../../../Constants/Constants';
import { ReactComponent as CloseIcon } from '../../../../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../../../Assets/chevron-down.svg';
import { RadioButton } from '../../../../../../Components/FormControl/FormControls';
import { LargeDestructiveButton } from '../../../../../../Components/Buttons/Button';

export default function CancelOrderPopup(props) {
    const { handleClickClose, backToPage, orderCancleFrom, setOrderCancleFrom, onConfirmCancel } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative px-4'>
                <div className='max-w-[400px] w-full rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:px-0 md:py-4 m-auto md:max-w-full'>
                    {isMobileScreen && (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium pl-1'>Back to {backToPage}</span>
                        </div>
                    )}

                    <div className='flex flex-row justify-between items-center mb-6'>
                        <div className='flex flex-col'>
                            <span className='paragraph-large-medium'>Cancel order</span>
                            <span className='paragraph-medium-italic text-neutral-500'>
                                Are you sure to cancel this order ?
                            </span>
                        </div>

                        {!isMobileScreen && (
                            <div
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </div>
                        )}
                    </div>

                    <div className='mb-12'>
                        <span className='paragraph-medium-medium'>Who is canceling this order</span>
                        <div className='mt-3'>
                            <RadioButton
                                marginB='2'
                                label='Restaurant'
                                optionId='restaurant'
                                value='restaurant'
                                checked={orderCancleFrom == 'restaurant'}
                                handleOptionChange={() => setOrderCancleFrom('restaurant')}
                            />
                            <RadioButton
                                marginB='2'
                                label='Customer'
                                optionId='Customer'
                                value='customer'
                                checked={orderCancleFrom == 'customer'}
                                handleOptionChange={() => setOrderCancleFrom('customer')}
                            />
                        </div>
                    </div>

                    <div className='md:px-4 md:pt-[13px] md:pb-1 md:fixed md:bottom-0 md:left-0 md:right-0 md:bg-white md:shadow-dropShadow'>
                        <div className='flex flex-row items-center'>
                            <button
                                className='paragraph-medium-medium w-1/2 h-12 border rounded-md border-neutral-300 mr-2'
                                onClick={handleClickClose}>
                                Cancel
                            </button>

                            <div
                                className='w-1/2 ml-2'
                                onClick={onConfirmCancel}>
                                <LargeDestructiveButton label='Confirm' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
