import React, { useState } from 'react';
import Tag from '../../../../../Components/Tag/Tag';
import Layout from '../../../Components/Layout';
import { useFormContext } from 'react-hook-form';
import APIV5 from '../../../../../api/axios/APIV5';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import DropdownWithValueLabel from '../../../../../Components/DropDown/DropdownWithValueLabel';
import { useSelector } from 'react-redux';
import { DropdownBoxLoader } from '../../../../../Components/ContentLoader/DropdownLoader';
import { useDispatch } from 'react-redux';
import * as langaugeTypes from '../../../../../reduxStore/types/langauge';
import Loader from './Components/Loader';

export default function Languages(props) {
    const { fetchSettings, handleDiscardForm, fetchlanguageList } = props;

    const {
        handleSubmit,
        watch,
        setValue,
        formState: { isDirty },
    } = useFormContext();

    const dispatch = useDispatch();

    const [showFooter, setShowFooter] = useState(false);

    const [isLoading] = useState(false);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const formateData = (data) => {
        return {
            language_ids: data.map((el) => el.id),
        };
    };

    const setLangauge = (language) => {
        dispatch({
            type: langaugeTypes.SET_AVAILABLE_LANGUAGES,
            payload: {
                availableLanguages: language,
            },
        });
    };

    const onSubmit = (data) => {
        updatelanguageSetting(formateData(data.language_settings.selectedLanguage));
        setLangauge(data.language_settings.selectedLanguage);
    };

    const handleSelectionLanguage = (language) => {
        const selectedLanguageList = watch('language_settings.selectedLanguage')?.length
            ? [...watch('language_settings.selectedLanguage'), language.value]
            : [language.value];

        setShowFooter(true);

        setValue('language_settings.selectedLanguage', selectedLanguageList);
    };

    const removeLanguage = (tag) => {
        const updatedList = watch('language_settings.selectedLanguage')?.filter((el) => el.id !== tag.id);

        setShowFooter(true);

        setValue('language_settings.selectedLanguage', updatedList);
    };

    const updatelanguageSetting = async (params) => {
        try {
            const response = await APIV5.put(`restaurants/${selectedRestaurantId}?section=language_settings`, {
                ...params,
            });

            if (response.success) {
                setValue('isLoadingForm', true);
                setShowFooter(false);
                fetchlanguageList();
                fetchSettings();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return watch('isLoadingForm') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                handleDiscardForm();
                setShowFooter(false);
            }}
            showFooter={showFooter || isDirty}>
            <div className='mb-4'>
                <TitleDescription
                    className='mb-2'
                    title='Application languages'
                    description='Select the languages you want your mobile application to be in. '
                />

                <div className='w-full max-w-[312px] md:max-w-full mb-1'>
                    {isLoading ? (
                        <DropdownBoxLoader hasLabel />
                    ) : (
                        <DropdownWithValueLabel
                            label='(Select languages)'
                            placeholder='Select languages'
                            labelPaddingB='pb-2'
                            labelStyle='paragraph-medium-italic text-neutral-500'
                            shadow='shadow-smallDropDownShadow'
                            className='mb-1'
                            menuItems={
                                watch('language_settings.languageList')?.filter(
                                    (item) =>
                                        !watch('language_settings.selectedLanguage')?.find(
                                            (selectedItem) => selectedItem.id === item.value.id
                                        )
                                ) ?? []
                            }
                            hasFixedPlaceholder
                            setSelected={handleSelectionLanguage}
                        />
                    )}

                    {watch('language_settings.selectedLanguage')?.map((el, index) => (
                        <div
                            className='mr-2 inline-block'
                            key={index}>
                            <Tag
                                tag={el.language_label}
                                onClose={() => removeLanguage(el)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className='paragraph-medium-italic text-neutral-500 max-w-[636px]'>
                <p>
                    <span className='text-neutral-900'>NOTE:</span>You will have to add the menu and other details in
                    all the languages you select.
                </p>
            </div>
        </Layout>
    );
}
