import React, { useEffect, useState } from 'react';
import APIV2 from '../../../../api/axios/APIV2';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';
import { useFormContext, useWatch } from 'react-hook-form';
import SelectCategorySection from '../../../CreateOffer/TabComponents/Configure/Components/SelectCategorySection';

export default function ScreenSelection() {
    const [mobileScreenList, setMobileScreenList] = useState([]);

    const { control, getValues, setValue } = useFormContext();
    const selectedScreen = useWatch({
        control,
        name: 'screen',
    });

    useEffect(() => {
        fetchMobileScreens();

        // eslint-disable-next-line
    }, []);

    const formatMenuItemStringToLabelValue = (items) => {
        const formattedScreenMenuItem = items.map((el) => {
            const removedSnackCase = el.split('-').join(' ');

            const menuItem = {
                label: removedSnackCase.charAt(0).toUpperCase() + removedSnackCase.slice(1),
                value: el,
            };

            if (el === getValues('selectedScreen')) {
                setValue('screen', menuItem);
            }

            return menuItem;
        });

        setMobileScreenList(formattedScreenMenuItem);
    };

    const fetchMobileScreens = async () => {
        try {
            const response = await APIV2.get('/mobile/screens-list');

            formatMenuItemStringToLabelValue(response.screens);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <DropdownSection
                className='mb-[13px] relative'
                fixedHeight='h-[160px]'
                label='Banner screen'
                boxHeight='h-[44px]'
                name='screen'
                menuItems={mobileScreenList}
                maxWidth='max-w-full'
                placeholder='Select target screen'
            />

            {selectedScreen?.value === 'category-detail-screen' && (
                <div className='max-w-[392px] w-full mb-2 relative md:max-w-full'>
                    <label className='paragraph-medium-bold text-black'>Banners Categories</label>
                    <SelectCategorySection />
                </div>
            )}
        </>
    );
}
