import React, { useEffect, useState } from 'react';
import { ReactComponent as OrderRatingIcon } from '../../../Assets/order-ratings.svg';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';
import { ReactComponent as TimerIcon } from '../../../Assets/timer.svg';

export default function ListViewRiderDetail(props) {
    const {
        billName,
        orderId,
        orderDate,
        orderAmount,
        deliveryDistance,
        deliveryCost,
        riderRating,
        deliveryTime,
        paymentMode,
        handleClickTotalSalesCard,
        handleOrderPopup,
        cache,
        onResize,
    } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    useEffect(() => {
        cache?.clearAll();
        onResize && onResize();
    }, [isShowDetails, onResize]);

    return (
        <>
            <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md mb-4'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    <div>
                        <h3 className='paragraph-overline-small text-neutral-700'>BILL NAME:</h3>
                        <span className='paragraph-small-regular'>{billName}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        {!isShowDetails && (
                            <div className='flex flex-row items-center mr-1'>
                                <OrderRatingIcon
                                    height={20}
                                    width={20}
                                    className='mr-1'
                                    fill={riderRating > 3 ? '#EBF6F5' : riderRating === 3 ? '#FFF5E3' : '#FEF2F2'}
                                    stroke={riderRating > 3 ? '#3D8C82' : riderRating === 3 ? '#FFA704' : '#EF4444'}
                                />
                                <span className='paragraph-small-regular'>{riderRating}</span>
                            </div>
                        )}
                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>
                {isShowDetails && (
                    <div className='pt-2'>
                        <div className='pt-2 flex flex-row'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER DATE:</span>
                            <span className='paragraph-small-regular ml-1'>{orderDate}</span>
                        </div>
                        <div className='pt-2 flex flex-row'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER AMOUNT:</span>
                            <span className='paragraph-small-regular ml-1'>₹{orderAmount}/-</span>
                        </div>
                        <div className='pt-2 flex flex-row'>
                            <span className='paragraph-overline-small text-neutral-700'>DELIVERY DISTANCE:</span>
                            <span className='paragraph-small-regular ml-1'>{deliveryDistance} kms</span>
                        </div>
                        <div className='pt-2 flex flex-row'>
                            <span className='paragraph-overline-small text-neutral-700'>DELIVERY COST:</span>
                            <span className='paragraph-small-regular ml-1'>{deliveryCost}/-</span>
                        </div>
                        <div className='pt-2 flex flex-row'>
                            <div className='flex flex-row'>
                                <span className='paragraph-overline-small text-neutral-700'>RIDER RATING:</span>
                                <span className='paragraph-small-regular ml-1'>
                                    <div className='flex flex-row items-center'>
                                        <OrderRatingIcon
                                            height={20}
                                            width={20}
                                            fill={
                                                riderRating > 3 ? '#EBF6F5' : riderRating === 3 ? '#FFF5E3' : '#FEF2F2'
                                            }
                                            stroke={
                                                riderRating > 3 ? '#3D8C82' : riderRating === 3 ? '#FFA704' : '#EF4444'
                                            }
                                        />
                                        <span className='paragraph-small-regular ml-1'>{riderRating}</span>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className='pt-2 flex flex-row'>
                            <span className='paragraph-overline-small text-neutral-700'>PAYMENT MODE:</span>
                            <div className='paragraph-small-regular ml-1 flex flex-row items-center'>
                                <TimerIcon className='h-5 w-5 mr-1.5' />
                                {paymentMode}
                            </div>
                        </div>
                        <div className='pt-2 flex flex-row justify-between items-center mobile:block'>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-overline-small text-neutral-700'>DELIVERY TIME:</span>
                                <div className='paragraph-small-regular ml-1 flex flex-row items-center'>
                                    <TimerIcon className='h-5 w-5 mr-1.5' />
                                    {deliveryTime}
                                </div>
                            </div>
                            <span
                                className='paragraph-small-italic text-primary-500 cursor-pointer'
                                onClick={() => {
                                    handleClickTotalSalesCard(orderId);
                                    handleOrderPopup(orderId);
                                }}>
                                View full details
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
