import React, { useCallback, useState, useEffect, useRef } from 'react';
import { ReactComponent as TimerIcon } from '../../../Assets/timer.svg';
import { ReactComponent as DineInIcons } from '../../../Assets/dine-in.svg';
import { DishItems } from './OrderCardComponent';
import { LargePrimaryButton, SmallPrimaryButton, SmallTertiaryButton } from '../../../Components/Buttons/Button';
import { format } from 'date-fns';
import isElectron from 'is-electron';
import { useDispatch } from 'react-redux';
import { SET_LIST_ORDER_MENU } from '../../../reduxStore/types/outlet';
import useOutsideClick from '../../../Helper/Hooks/useOutsideClick';

export default function OrderCard(props) {
    const {
        id,
        KOT,
        ordering_mode,
        last_four_character_order_label,
        kitchen_preparation_time,
        icon,
        onDoubleClick,
        order_status,
        activeTab,
    } = props;
    const dispatch = useDispatch();
    const items = JSON.parse(props?.cart);
    const cartItems = items.cart_items;
    const totalQuantity = cartItems.reduce((acc, current) => acc + Number(current.quantity), 0);

    const [isUpdated, setIsUpdated] = useState(!props.isUpdated);
    const [clickedItems, setClickedItems] = useState({});
    const [showReminder, setShowReminder] = useState(false);

    const handleClickOutside = () => {
        setShowReminder(false);
    };

    const reminderRef = useRef(null);
    useOutsideClick(reminderRef, handleClickOutside);

    const handleUpdateOrder = (value, itemId, isFullyStruckThrough) => {
        setIsUpdated(value);
        setClickedItems((prev) => ({ ...prev, [itemId]: isFullyStruckThrough }));
    };

    const handleHeaderDoubleClick = useCallback(() => {
        const allItemsClicked = cartItems.every((item) => clickedItems[item.id]);
        const anyItemNotFullyStruckThrough = Object.values(clickedItems).some((value) => !value);
        if (!allItemsClicked || anyItemNotFullyStruckThrough) {
            setShowReminder(true);
        } else {
            onDoubleClick();
        }
    }, [cartItems, clickedItems, onDoubleClick]);

    const completeOrder = async () => {
        const currentDate = new Date();
        const formattedDate = format(currentDate, 'yyyy-MM-dd HH:mm:ss');

        let orderStatus = typeof order_status === 'string' ? JSON.parse(order_status) : order_status;
        orderStatus = JSON.stringify({ ...orderStatus, restaurant_ready_at: formattedDate });

        if (isElectron()) {
            window.electron.ipcRenderer.send('updateOrderStatus', { orderId: id, orderStatus });

            window.electron.ipcRenderer.on('updateOrderStatusSuccess', (data) => {
                if (data.success) {
                    dispatch({
                        type: SET_LIST_ORDER_MENU,
                        payload: data.updatedOrderStatus,
                    });
                }
            });
        }
    };

    const restoreOrder = async () => {
        let orderStatus = typeof order_status === 'string' ? JSON.parse(order_status) : order_status;

        orderStatus = JSON.stringify({ ...orderStatus, restaurant_ready_at: null });

        if (isElectron()) {
            window.electron.ipcRenderer.send('updateOrderStatus', { orderId: id, orderStatus });

            window.electron.ipcRenderer.on('updateOrderStatusSuccess', (data) => {
                if (data.success) {
                    dispatch({
                        type: SET_LIST_ORDER_MENU,

                        payload: data.updatedOrderStatus,
                    });
                }
            });
        }
    };

    const handleOkayClick = () => {
        setShowReminder(false);
        if (activeTab === 'completed') {
            restoreOrder();
        } else {
            completeOrder();
        }
    };

    return (
        <>
            <div className={`w-full border border-neutral-300 rounded-xl p-4`}>
                <div
                    className='flex flex-row justify-between items-center mb-4 pb-4 border-b border-neutral-300'
                    onDoubleClick={handleHeaderDoubleClick}>
                    <div className='flex flex-row'>
                        {ordering_mode === 'Dine-In order' ? (
                            <>
                                <div className='p-2 bg-primary-500 rounded-lg'>
                                    <DineInIcons
                                        height={32}
                                        width={32}
                                        stroke='#FAFAFA'
                                    />
                                </div>
                            </>
                        ) : (
                            icon
                        )}
                        <div className=''>
                            <div className='ml-2.5 paragraph-medium-semi-bold'>{ordering_mode}</div>
                            <div className='ml-2 paragraph-medium-italic text-neutral-500'>
                                Order {last_four_character_order_label}
                            </div>
                        </div>
                    </div>
                    <div className='paragraph-medium-semi-bold justify-end flex flex-col text-right'>
                        <div className=''>KOT</div>
                        <div className=''>{KOT < 10 ? '#0' + KOT : '#' + KOT}</div>
                    </div>
                </div>

                {!isUpdated && (
                    <div className='mb-4 pb-4 border-b border-neutral-300 text-center'>
                        <span className='paragraph-overline-large text-tertiary-800'>ORDERED DISHES UPDATED!</span>
                    </div>
                )}

                <div className='flex flex-row justify-between mb-4'>
                    <div className='text-base leading-4 border-b border-neutral-900'>Ordered dishes</div>
                    <div className='text-base leading-4 border-b border-neutral-900'>Quantity</div>
                </div>

                <div className='border-b border-neutral-300 mb-4'>
                    {cartItems.map((item, index) => (
                        <div
                            className='mb-4'
                            key={index}>
                            <DishItems
                                el={item}
                                handleUpdateOrder={(value, isFullyStruckThrough) =>
                                    handleUpdateOrder(value, item.id, isFullyStruckThrough)
                                }
                            />
                        </div>
                    ))}
                </div>

                <div className='flex flex-row justify-between paragraph-medium-semi-bold mb-4 pb-4 border-b border-neutral-300'>
                    <span>Total dishes quantity</span>
                    <span>{totalQuantity}</span>
                </div>

                <div className='flex flex-row justify-between paragraph-medium-semi-bold border-neutral-300 text-tertiary-800'>
                    <span>Time elapsed</span>
                    <div className='flex flex-row items-center'>
                        <TimerIcon
                            height={24}
                            width={24}
                            stroke='#3D8C82'
                        />
                        <span className='ml-2'>{kitchen_preparation_time} mins</span>
                    </div>
                </div>

                {showReminder && (
                    <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex p-4 md:p-0 md:absolute md:z-[9] overflow-auto md:overflow-visible'>
                        <div className='max-w-[830px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:mt-0 md:w-full md:p-4 lg:max-w-[439px] md:max-w-full md:rounded-none'>
                            <div
                                ref={reminderRef}
                                className='bg-white text-black p-4 rounded-lg'>
                                <p className='text-2xl font-semibold'>Reminder</p>
                                <p className='text-xl'>
                                    Please check the remaining items before completing the order. Are you sure you want
                                    to complete this card...
                                </p>
                                <div className='flex flex-row justify-center gap-4 mt-4'>
                                    <div
                                        className='lg:w-1/2 cursor-pointer'
                                        onClick={() => setShowReminder(false)}>
                                        <LargePrimaryButton
                                            label='Cancel'
                                            hideLabel='xl:hidden lg:block mobile:hidden'
                                        />
                                    </div>
                                    <div
                                        className='lg:w-1/2 cursor-pointer'
                                        onClick={handleOkayClick}>
                                        <LargePrimaryButton
                                            label='Okay'
                                            hideLabel='xl:hidden lg:block mobile:hidden'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
