import React, { useEffect, useState } from 'react';
import { RatingCard } from './Cards';
import Card from '../../../Components/Card/Card';
import CardDescription from '../../../Components/Card/CardDescription';
import { ReactComponent as ReviewIcon } from '../../../Assets/review.svg';
import { ReactComponent as OrderIcon } from '../../../Assets/order.svg';
import { ReactComponent as RiderIcon } from '../../../Assets/riders.svg';
import SliderDashboard from 'react-slick';
import { IsMobileScreen } from '../../../Constants/Constants';
import { format } from 'date-fns';
import APIV5 from '../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import CardContentLoader from '../../../Components/ContentLoader/CardContentLoader';

export default function AllCards(props) {
    const { selectedDates, deleted } = props;
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [cardDetails, setCardDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getParams = () => {
        if (!selectedDates.length) {
            const params = {
                filters: {
                    date_range: {
                        from_date: format(new Date(), 'yyyy-MM-dd'),
                        to_date: format(new Date(), 'yyyy-MM-dd'),
                    },
                },
            };

            return params;
        }

        const from_date = format(new Date(Math.min(...selectedDates)), 'yyyy-MM-dd');
        const to_date = format(new Date(Math.max(...selectedDates)), 'yyyy-MM-dd');

        const params = {
            start_date: from_date,
            end_date: to_date,
        };

        return params;
    };

    const fetchCardDetails = async () => {
        try {
            const response = await APIV5(`outlets/${selectedOutletId}/order-rating-totals`, {
                params: getParams(),
            });
            setCardDetails(response);
            setIsLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchCardDetails();
    }, [selectedDates, deleted, selectedOutletId]);

    const cardContent = [
        {
            title: 'Total reviews',
            heightLightedTitle: cardDetails?.total_reviews?.total_reviews || '0',
            tag:
                cardDetails?.total_reviews?.percentage_increment ||
                cardDetails?.total_reviews?.percentage_decrement ||
                '0',
            percentage:
                cardDetails?.total_reviews?.percentage_increment || cardDetails?.total_reviews?.percentage_decrement,
            positive: cardDetails?.total_reviews && cardDetails?.total_reviews?.percentage_increment,
            description: <CardDescription description='Growth in new reviews recorded for selected dates' />,
            icon: <ReviewIcon stroke='#FFFFFF' />,
        },
        {
            ratingCard: true,
            title: 'Average order rating',
            heightLightedTitle: parseFloat(cardDetails?.average_order_rating || '0').toFixed(1),
            description: (
                <CardDescription
                    description={`Order rating received was :focusContent: by ${
                        cardDetails?.order_rating_count || '0'
                    } customers for selected dates`}
                    focusContent={
                        cardDetails && cardDetails.average_order_rating >= 4
                            ? 'good'
                            : cardDetails.average_order_rating >= 3
                              ? 'average'
                              : 'bad'
                    }
                    focusContentColor={
                        cardDetails && cardDetails.average_order_rating >= 4
                            ? '#3D8C82'
                            : cardDetails.average_order_rating >= 3
                              ? '#FFA704'
                              : '#EF4444'
                    }
                />
            ),
            icon: <OrderIcon stroke='#FFFFFF' />,
        },
        {
            ratingCard: true,
            title: 'Average rider rating',
            heightLightedTitle: parseFloat(cardDetails?.average_rider_rating || '0').toFixed(1),
            description: (
                <CardDescription
                    description={`Rider rating received was :focusContent: by ${
                        cardDetails?.rider_rating_count || '0'
                    } customers for selected dates`}
                    focusContent={
                        cardDetails && cardDetails.average_rider_rating >= 4
                            ? 'good'
                            : cardDetails.average_rider_rating >= 3
                              ? 'average'
                              : 'bad'
                    }
                    focusContentColor={
                        cardDetails && cardDetails.average_rider_rating >= 4
                            ? '#3D8C82'
                            : cardDetails.average_rider_rating >= 3
                              ? '#FFA704'
                              : '#EF4444'
                    }
                />
            ),
            icon: <RiderIcon stroke='#FFFFFF' />,
        },
    ];

    const settingsDashboardSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: 'dashboard-slide',
    };

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {isLoading ? (
                !isMobileScreen ? (
                    <div className='-mx-[11px] mb-6 lg:-mx-[1px] lg:mb-3'>
                        {[...Array(4)].map((_, index) => (
                            <div
                                className='max-w-[303px] w-full inline-block align-top mx-2.5 mt-4 lg:w-1/2 lg:odd:pr-1 lg:even:pl-1 lg:max-w-full lg:mx-0 lg:my-1 md:mt-4'
                                key={index}>
                                <CardContentLoader />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='flex flex-row'>
                        {[...Array(4)].map((_, index) => (
                            <div
                                className='md:mb-[5px] mr-4 md:mt-4'
                                key={index}>
                                <CardContentLoader />
                            </div>
                        ))}
                    </div>
                )
            ) : (
                !_.isEmpty(cardDetails) &&
                (!isMobileScreen ? (
                    <div className='-mx-[11px] mb-6 lg:-mx-[1px] lg:mb-3'>
                        {cardContent?.map((el, index) => (
                            <div
                                className='max-w-[303px] w-full inline-block mx-2.5 lg:max-w-full lg:w-1/2 lg:mx-0 lg:mt-2 lg:odd:pr-1 lg:even:pl-1 mt-4'
                                key={index}>
                                <Card {...el} />
                            </div>
                        ))}
                        <div className='inline-block align-top max-w-[303px] w-full mx-2.5 lg:max-w-full lg:w-1/2 lg:mx-0 lg:mt-2 lg:pl-1 mt-4'>
                            <RatingCard ratingBasedOnStar={cardDetails?.rating_based_on_star} />
                        </div>
                    </div>
                ) : (
                    <div className='md:-mx-4'>
                        <SliderDashboard {...settingsDashboardSlider}>
                            {cardContent.map((el, index) => (
                                <div
                                    className='mt-4 inline-block align-top max-w-[303px] w-full'
                                    key={index}>
                                    <Card {...el} />
                                </div>
                            ))}
                            <div className='inline-block align-top max-w-[303px] w-full mt-4 mb-2'>
                                <RatingCard ratingBasedOnStar={cardDetails?.rating_based_on_star} />
                            </div>
                        </SliderDashboard>
                    </div>
                ))
            )}
        </>
    );
}
