import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import APIV5 from '../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';

export default function OnlineSales(props) {
    const { outletId, selectedDates, popup } = props;
    const [onlineSalesData, setOnlineSalesData] = useState({
        details: [],
        total_amount: 0,
    });
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const defaultParams = () => {
        const [start_date, end_date] = selectedDates.sort((a, b) => a - b);
        return {
            start_date: formatDate(start_date),
            end_date: formatDate(end_date),
        };
    };

    const fetchOnlineSalesDetails = async () => {
        const defaultParamsData = defaultParams();
        try {
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/online-sale-details`, {
                params: {
                    ...defaultParamsData,
                    outlet_id: [outletId],
                },
            });

            if (response.success && response.online_sale_details) {
                setOnlineSalesData({
                    details: response.online_sale_details,
                    total_amount: response.total_online_sales,
                });
            }
        } catch (error) {
            console.error('Error fetching cash collection details:', error);
        }
    };

    useEffect(() => {
        fetchOnlineSalesDetails();
    }, [selectedDates, outletId]);

    const labels = onlineSalesData.details.map((item) => item.ordering_mode_name);
    const series = onlineSalesData.details.map((item) => item.online_net_total);
    const percentages = onlineSalesData.details.map((item) => item.percentage);

    const chartOptions = {
        plotOptions: {
            pie: {
                donut: {
                    size: '62%',
                },
            },
        },
        labels: labels,
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
                fontSize: 16,
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
            },
            formatter: function (val, opts) {
                return percentages[opts.seriesIndex].toFixed(1) + '%';
            },
        },
        colors: ['#FFBB3C', '#74C2B9', '#FF6361', '#58508D', '#BC5090', '#FFA600'],
        stroke: {
            colors: 'transparent',
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return '₹ ' + value.toFixed(2);
                },
            },
        },
    };

    return (
        <>
            <div className='flex flex-row justify-between items-center md:block'>
                <div className='w-full'>
                    <div className='w-[238px] mx-[5.5px] mb-[26px] flex items-center justify-center md:mx-auto'>
                        <ReactApexChart
                            className='m-auto'
                            type='donut'
                            width={280}
                            height={280}
                            series={series}
                            options={chartOptions}
                        />
                    </div>
                    <div className='flex flex-wrap justify-center w-full max-w-[251px] md:max-w-full'>
                        {labels.map((label, index) => (
                            <div
                                key={index}
                                className='flex flex-row items-center m-2'>
                                <div
                                    className='h-[11px] w-[11px] rounded-full mr-2'
                                    style={{ backgroundColor: chartOptions.colors[index % chartOptions.colors.length] }}
                                />
                                <span className='paragraph-medium-regular'>{label}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='max-w-[315px] w-full md:mt-10 md:max-w-full'>
                    <div className='border-b border-neutral-300 mb-4'>
                        {onlineSalesData.details.map((item, index) => (
                            <div
                                key={index}
                                className='flex flex-row justify-between mb-4'>
                                <span className='paragraph-medium-regular'>{item.ordering_mode_name}</span>
                                <span className='paragraph-medium-regular'>{item.online_net_total}</span>
                            </div>
                        ))}
                    </div>
                    <div className='flex flex-row justify-between max-w-[315px] md:max-w-full'>
                        <span className='paragraph-medium-semi-bold'>
                            Total {popup.replace(/([A-Z])/g, ' $1').toLowerCase()}
                        </span>
                        <span className='paragraph-medium-semi-bold'>{onlineSalesData.total_amount}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
