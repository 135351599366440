import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputFieldMetaTag } from '../../Components/MetaTagInputs';
import DropdownSection from '../../../CreateOffer/TabComponents/CommonComponents/DropdownSection';
import ScheduleNotification from './ScheduleNotification';
import { LargeTertiaryButton } from '../../../../Components/Buttons/Button';
import SelectImageFromLibraryPopup from '../../../Banners/Component/SelectImagePopupComponent/SelectImageFromLibraryPopup';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';
import { useSelector } from 'react-redux';

export default function LanguageViseDetails(props) {
    const {
        id,
        metaTagsList,
        screensList,
        categoriesList,
        setShowUploadImagePopup,
        selectedLangauge,
        index,
        language,
        selectedType,
        setButtonTitle,
    } = props;

    const languageList = useSelector((state) => state.langauge.availableLanguages);

    const {
        setValue,
        reset,
        watch,
        getValues,
        control,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useFormContext();

    const [showSelectContent, setShowSelectContent] = useState(false);
    const [showSameAsButton, setShowSameAsButton] = useState(true);
    const [selectLibraryPopup, setSelectLibraryPopup] = useState(false);
    const [languageNames, setLanguageNames] = useState([]);
    const [showLibraryImagePopupLanguageId, setShowLibraryImagePopupLanguageId] = useState(null);

    const MenuItemsCategories = categoriesList?.map((category) => ({
        label: category.internal_name,
        value: category.category_id.toString(),
    }));

    const scheduleNotificationMenuItems = [
        { label: 'Instant', value: '0' },
        { label: 'Custom date & time', value: 'custom' },
    ];

    const detailsLabel = {
        inputFieldLabel: `${language !== 'English' ? language + ':' : ''} Notification title`,
        inputFieldPlaceholder: `Enter notification title in ${language !== 'English' ? language : 'English'}`,
        inputAreaLabel: `${language !== 'English' ? language + ':' : ''} Description`,
        inputAreaPlaceholder: `Enter notification description in ${language !== 'English' ? language : 'English'}`,
        displayScreenDropdownLabel: `${language !== 'English' ? language + ':' : ''} Display screen`,
        selectCategoryDropdownLabel: `${language !== 'English' ? language + ':' : ''} Select category screen `,
        displayScreenDropdownPlaceholder: `Select display screen for ${language !== 'English' ? language : 'English'}`,
        displayCategoryDropdownPlaceholder: `Select display category for ${
            language !== 'English' ? language : 'English'
        }`,
        scheduleNotificationLabel: `${language !== 'English' ? language + ':' : ''} Schedule notification`,
        selectImageLabel: `${language !== 'English' ? language + ':' : ''} Select image`,
    };

    const MenuItemsScreens = screensList?.map((screen, index) => ({
        label: screen,
        value: index,
    }));

    const handleClickUploadImage = () => {
        setShowUploadImagePopup(id);
    };

    const handleClickSelectImageContent = () => {
        setSelectLibraryPopup(id);
    };

    const handlePopupReopen = () => {
        watch('imageDetails')?.image_url ? handleClickUploadImage() : handleClickSelectImageContent();
    };
    const handleClickSave = (imageDataObj) => {
        setValue(`imageDetails.${id}`, imageDataObj);
    };

    useEffect(() => {
        if (languageList && languageList.length > 0) {
            const names = languageList.map((lang) => lang.language_label);
            setLanguageNames(names);
        }
    }, [languageList]);

    const isFirstEnglish = languageNames.findIndex((name) => name === 'English') === index;

    const handleClickSelectSameImageAsEnglish = () => {
        const englishImageDetails = watch('imageDetails.1');
        if (englishImageDetails) {
            setValue(`imageDetails.${id}`, englishImageDetails);
        }
        setShowSameAsButton(false);
    };

    useEffect(() => {
        setButtonTitle(watch('notification_time_type'));
    }, [watch('notification_time_type')]);

    if (selectLibraryPopup) {
        return (
            <SelectImageFromLibraryPopup
                handleClickClose={() => setSelectLibraryPopup(null)}
                handleSelectImage={(imageDataObj) => handleClickSave(imageDataObj, showLibraryImagePopupLanguageId)}
            />
        );
    }

    return (
        <>
            <div className='w-full'>
                <div className='mb-3.5'>
                    <Controller
                        name={`notification_title.${id}`}
                        control={control}
                        rules={{ required: 'Please enter title' }}
                        render={({ field }) => {
                            return (
                                <InputFieldMetaTag
                                    label={detailsLabel.inputFieldLabel}
                                    menuItems={Object.keys(metaTagsList).map((key) => key)}
                                    placeholder={detailsLabel.inputFieldPlaceholder}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            );
                        }}
                    />
                    {errors.notification_title && (
                        <ErrorMessage errors={{ message: errors.notification_title[id].message }} />
                    )}
                </div>

                <div className='mb-2.5'>
                    <Controller
                        name={`notification_description.${id}`}
                        control={control}
                        rules={{ required: 'Please enter description' }}
                        render={({ field }) => {
                            return (
                                <InputFieldMetaTag
                                    label={detailsLabel.inputAreaLabel}
                                    menuItems={Object.keys(metaTagsList).map((key) => key)}
                                    placeholder={detailsLabel.inputAreaPlaceholder}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            );
                        }}
                    />
                    {errors.notification_description && (
                        <ErrorMessage errors={{ message: errors.notification_description[id].message }} />
                    )}
                </div>

                <div className=''>
                    <div className='mb-2 -mr-2'>
                        <span className={`paragraph-medium-medium ${'text-neutral-500'}`}>
                            {detailsLabel.selectImageLabel}
                        </span>
                        <span className={`paragraph-medium-italic ${'text-neutral-500'} ml-1`}>
                            (1080px x 1080px recommended)
                        </span>
                    </div>

                    {!showSelectContent &&
                        (watch(`imageDetails.${id}`)?.image_url || watch(`imageDetails.${id}`)?.image ? (
                            <>
                                <div className='flex items-center'>
                                    <div className='flex items-start'>
                                        <img
                                            className='w-[200px] h-[180px] rounded-md md:mr-[17px] md:min-w-[163px] mobile:min-w-0 mt-2'
                                            src={
                                                watch(`imageDetails.${id}`)?.image
                                                    ? watch(`imageDetails.${id}`)?.image
                                                    : watch(`imageDetails.${id}`)?.image_url
                                            }
                                            alt='UploadedImage'
                                        />
                                    </div>
                                    <div
                                        className='max-w-[197px] w-full md:max-w-full ml-4'
                                        onClick={handlePopupReopen}>
                                        <LargeTertiaryButton label='Replace image' />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className='flex flex-row paragraph-medium-regular mobile:text-sm'>
                                <button
                                    className={`justify-center h-12 border-neutral-300  rounded-md border w-1/2 mr-[8.5px] mobile:mr-1`}
                                    onClick={handleClickUploadImage}>
                                    Upload an image
                                </button>
                                <button
                                    className={`justify-center h-12 border-neutral-300 rounded-md border w-1/2 ml-[8.5px] mobile:ml-1 `}
                                    onClick={handleClickSelectImageContent}>
                                    Select from library
                                </button>
                            </div>
                        ))}
                    {isFirstEnglish && (
                        <>
                            {' '}
                            <div className='mb-3.5 mt-2'>
                                <DropdownSection
                                    label={detailsLabel.displayScreenDropdownLabel}
                                    placeholder={detailsLabel.displayScreenDropdownPlaceholder}
                                    buttonTextColor='neutral-300'
                                    menuItems={MenuItemsScreens}
                                    name='screen'
                                    rules={{ required: 'Please select screen' }}
                                />
                            </div>
                            {watch('screen')?.label === 'category-detail-screen' && (
                                <div className='mb-3.5 '>
                                    <DropdownSection
                                        label={detailsLabel.selectCategoryDropdownLabel}
                                        placeholder={detailsLabel.displayCategoryDropdownPlaceholder}
                                        buttonTextColor='neutral-300'
                                        menuItems={MenuItemsCategories}
                                        name='category'
                                        rules={{ required: 'Please select category' }}
                                    />
                                </div>
                            )}
                            <div className='mb-4'>
                                <ScheduleNotification
                                    label={detailsLabel.scheduleNotificationLabel}
                                    menuItems={scheduleNotificationMenuItems}
                                    watch={watch}
                                    timeTypeName='notification_time_type'
                                    control={control}
                                    selectedTimeName='notification_selected_time'
                                    selectedDateName='notification_selected_date'
                                    setValue={setValue}
                                    pageTitle={'pageTitle'}
                                    errors={errors}
                                    id={id}
                                />
                            </div>
                        </>
                    )}
                    {showSameAsButton && index !== 0 && !showSelectContent && (
                        <div className=''>
                            <button
                                className={`justify-center h-12 border-neutral-300 rounded-md border w-1/2 max-w-[197px] lg:max-w-[148px] mt-2.5 text-neutral-500`}
                                onClick={handleClickSelectSameImageAsEnglish}>
                                Same as English
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
