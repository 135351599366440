import React from 'react';
import Table from '../../TableManagement/Components/Table';
import { useWindowSize } from '@uidotdev/usehooks';
import { IsMobileScreen } from '../../../Constants/Constants';

const AvailableTables = (props) => {
    const { setShowPopup, showReservationDetails } = props;

    const { height: screenHeight } = useWindowSize();

    const tableData = [
        {
            sectionName: 'Ground Floor',
            subsections: [
                {
                    subsectionName: 'AC',
                    tables: Array.from({ length: 3 }).map((_, index) => ({
                        tableName: `T${index + 1}`,
                        capacity: 6,
                        price: index < 2 ? '₹2,325.00/-' : null,
                        time: index < 2 ? '15 mins 16 secs' : null,
                        allocation: index < 2 ? 'seated' : null,
                    })),
                },
                {
                    subsectionName: 'Non-AC',
                    tables: Array.from({ length: 4 }).map((_, index) => ({
                        tableName: `T1${index + 1}`,
                        capacity: 6,
                        allocation: index === 3 ? 'disabled' : null,
                    })),
                },
            ],
        },
        {
            sectionName: 'First Floor',
            subsections: [
                {
                    subsectionName: 'AC',
                    tables: Array.from({ length: 10 }).map((_, index) => ({
                        tableName: `T${index + 1}`,
                        capacity: 6,
                        price: index < 2 ? '₹2,325.00/-' : '₹1,343.00/-',
                        time: index < 2 ? '15 mins 16 secs' : '05 mins 16 secs',
                        allocation: index < 2 ? 'seated' : null,
                    })),
                },
            ],
        },
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <div
            style={{ height: isMobileScreen ? 'auto' : screenHeight }}
            className={`overflow-auto scrollbar-style pr-2.5 pb-16 md:pb-0 ${
                showReservationDetails ? '-mr-5' : '-mr-8'
            }`}>
            {tableData.map((section, sectionIndex) => (
                <div
                    key={sectionIndex}
                    className='mb-4'>
                    <div className='mb-4 paragraph-large-medium'>{section.sectionName}</div>
                    {section.subsections.map((subsection, subIndex) => (
                        <div
                            key={subIndex}
                            className='mt-2'>
                            <div className='paragraph-large-italic text-neutral-500'>{subsection.subsectionName}</div>
                            <div className='-mx-2.5 sm:text-center'>
                                {subsection.tables.map((table, tableIndex) => (
                                    <Table
                                        key={tableIndex}
                                        tableName={table.tableName}
                                        maxCapacity={table.capacity}
                                        time={table.time}
                                        price={table.price}
                                        tableAllocation={table.allocation}
                                        cursorPointer='cursor-pointer'
                                        handleClickTable={() => setShowPopup('enterTableCustomerDetails')}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default AvailableTables;
