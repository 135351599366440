import React, { useEffect, useRef, useState } from 'react';
import { CheckBox } from '../../../Components/FormControl/FormControls';
import APIV5 from '../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import { ReactComponent as Close } from '../../../Assets/close.svg';
import { useForm } from 'react-hook-form';
import useOutsideClick from '../../../Helper/Hooks/useOutsideClick';
import { IsMobileScreen } from '../../../Constants/Constants';
import { ReactComponent as LeftArrow } from '../../../Assets/chevron-down.svg';
import WaiterPopupLoader from './WaiterPopupLoader';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../Components/Buttons/Button';

const SecondPopup = (props) => {
    const { addOrEdit, setShowAddRiderPage, handleClickAddRider, isLoadingEditRider, setriderSaved, riderDetailsEdit } =
        props;
    const {
        handleSubmit,
        //  control,
        //  formState: { errors },
        //  setValue,
        //  watch,
        //  getValues,
        //  setError,
        //  clearErrors,
        //  trigger,
    } = useForm();
    const [sections, setSections] = useState([]);
    const [tableSectionNames, setTableSectionNames] = useState([]);
    const [selectedSections, setSelectedSections] = useState([]);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    useEffect(() => {
        const fetchSectionNames = async () => {
            try {
                const response = await APIV5.get(`outlets/${selectedOutletId}/outlet-sections/section-list`);
                if (response.sections) {
                    // Initialize sections with isSelected property for both main sections and sub-sections
                    const sectionsWithSelection = response.sections.map((section) => ({
                        ...section,
                        isSelected: false,
                        sub_section:
                            section.sub_section?.map((subSection) => ({
                                ...subSection,
                                isSelected: false,
                            })) || [],
                    }));
                    setSections(sectionsWithSelection);
                }
            } catch (error) {
                console.error('Error fetching section names:', error);
            }
        };

        fetchSectionNames();
    }, [selectedOutletId]);
    // This is the fetch all the tables
    // useEffect(() => {
    //     const fetchTableNames = async () => {
    //         try {
    //             const response = await APIV5.get(
    //                 `outlets/${selectedOutletId}/outlet-sections/3/table-list?per_page=1&per_page=10`
    //             );
    //             if (response.sections) {
    //                 console.log(`responsetable ==>`, response);
    //                 // Initialize sections with isSelected property
    //                 // const sectionsWithSelection = response.sections.map((section) => ({
    //                 //     ...section,
    //                 //     isSelected: false,
    //                 // }));
    //                 // setTableSectionNames(sectionsWithSelection);
    //             } else {
    //                 console.log('Data not found');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching section names:', error);
    //         }
    //     };

    //     fetchTableNames();
    // }, [selectedOutletId]);

    const handleSectionChange = (sectionId, checked, isSubSection = false, parentId = null) => {
        setSections((prevSections) => {
            return prevSections.map((section) => {
                if (isSubSection) {
                    // Handle sub-section selection
                    if (section.id === parentId) {
                        return {
                            ...section,
                            sub_section: section.sub_section.map((subSection) =>
                                subSection.id === sectionId ? { ...subSection, isSelected: checked } : subSection
                            ),
                        };
                    }
                } else {
                    // Handle main section selection
                    if (section.id === sectionId) {
                        // When main section is selected/deselected, update all its sub-sections
                        return {
                            ...section,
                            isSelected: checked,
                            sub_section:
                                section.sub_section?.map((subSection) => ({
                                    ...subSection,
                                    isSelected: checked,
                                })) || [],
                        };
                    }
                }
                return section;
            });
        });

        setSelectedSections((prevSelected) => {
            if (checked) {
                if (isSubSection) {
                    return [...prevSelected, sectionId];
                } else {
                    // When selecting main section, add both main and all sub-section IDs
                    const section = sections.find((s) => s.id === sectionId);
                    const subSectionIds = section.sub_section?.map((sub) => sub.id) || [];
                    return [...prevSelected, sectionId, ...subSectionIds];
                }
            } else {
                if (isSubSection) {
                    return prevSelected.filter((id) => id !== sectionId);
                } else {
                    // When deselecting main section, remove both main and all sub-section IDs
                    const section = sections.find((s) => s.id === sectionId);
                    const subSectionIds = section.sub_section?.map((sub) => sub.id) || [];
                    return prevSelected.filter((id) => id !== sectionId && !subSectionIds.includes(id));
                }
            }
        });
    };
    // ----------------------------------------------------------------

    let addWaiter = {
        section_ids: selectedSections,
    };
    const handleAddRider = async () => {
        try {
            const response = await APIV5.post(`restaurants/${32}/waiters`, addWaiter);
            setShowAddRiderPage(false);
            if (response.success === true) {
                setriderSaved((prevState) => !prevState);
                setShowAddRiderPage(false);
            }
        } catch (error) {
            console.error('Error adding rider:', error);
        }
    };
    const onAddSubmit = () => {
        // if (!getValues('imageDetails')) {
        //     setError('imageDetails', { message: 'Please select Image' });
        // } else {
        //     clearErrors('imageDetails');
        // }

        // if (!getValues('outletSelection')?.length) {
        //     setError('outletSelection', { message: 'Please select at least one outlet' });
        // } else {
        //     clearErrors('outletSelection');
        // }

        handleSubmit(handleAddRider)();
    };
    // const handleEdit = async () => {
    //     try {
    //         const response = await APIV5.put(`restaurants/${32}/riders/${riderDetailsEdit?.id}`, data);
    //         setShowAddRiderPage(false);
    //         {
    //             response.success === true && setriderSaved((prevState) => !prevState);
    //         }
    //     } catch (error) {
    //         console.log('error ==>', error);
    //     }
    // };
    //   const onEditSubmit = () => {
    //       handleSubmit(handleEdit)();
    //   };

    const popupRef = useRef();
    useOutsideClick(popupRef, handleClickAddRider);
    return (
        <div className='fixed bg-black bg-opacity-50 inset-0 z-50 md:z-[9] flex overflow-auto px-[33px] py-4 md:bg-white md:relative md:py-0 md:px-1'>
            <div
                className='max-w-[830px] min-h-[588px] rounded-xl bg-shades-50 px-8 py-6 m-auto w-full md:max-w-full md:px-0 md:py-0 md:min-h-full'
                ref={popupRef}>
                {isLoadingEditRider ? (
                    <WaiterPopupLoader />
                ) : (
                    <div ref={popupRef}>
                        {IsMobileScreen && (
                            <div
                                className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                                onClick={() => handleClickAddRider()}>
                                <LeftArrow className='rotate-90' />
                                <span className='ml-1'>Back to Waiter</span>
                            </div>
                        )}
                        <div className='flex flex-row justify-between items-center border-neutral-300'>
                            <div>
                                {addOrEdit === 'add' ? (
                                    <>
                                        <span className='paragraph-large-medium'>Select Section</span>
                                        <div className='flex flex-row items-center'>
                                            <span className='paragraph-medium-italic text-neutral-500'>
                                                Select the section for the waiter
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    addOrEdit === 'edit' && (
                                        <>
                                            <span className='paragraph-large-medium'>Edit rider</span>
                                            <div className='flex flex-row items-center'>
                                                <span className='paragraph-medium-italic text-neutral-500'>
                                                    Edit an existing rider for your outlet
                                                </span>
                                            </div>
                                        </>
                                    )
                                )}
                            </div>
                            <div
                                onClick={setShowAddRiderPage}
                                className='md:hidden cursor-pointer'>
                                <Close />
                            </div>
                        </div>

                        <div className='p-1'>
                            {sections.map((section) => (
                                <div
                                    key={section.id}
                                    className='mb-4'>
                                    {/* Main section checkbox */}
                                    <div className='p-3'>
                                        <CheckBox
                                            label={section.internal_name}
                                            optionId={`section-${section.id}`}
                                            paddingL='6 md:pl-2'
                                            labelStyle='paragraph-medium-regular md:block md:overflow-hidden font-medium'
                                            isChecked={section.isSelected}
                                            setIsChecked={(checked) => handleSectionChange(section.id, checked)}
                                        />
                                    </div>

                                    {/* Sub-section checkboxes */}
                                    {section.sub_section && section.sub_section.length > 0 && (
                                        <div className='ml-8 '>
                                            {section.sub_section.map((subSection) => (
                                                <div
                                                    key={subSection.id}
                                                    className='p-2'>
                                                    <CheckBox
                                                        label={subSection.internal_name}
                                                        optionId={`sub-section-${subSection.id}`}
                                                        paddingL='6 md:pl-2'
                                                        labelStyle='paragraph-medium-regular md:block md:overflow-hidden'
                                                        isChecked={subSection.isSelected}
                                                        setIsChecked={(checked) =>
                                                            handleSectionChange(
                                                                subSection.id,
                                                                checked,
                                                                true,
                                                                section.id
                                                            )
                                                        }
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>

                        {addOrEdit === 'add' ? (
                            <div className='flex flex-row items-center md:block md:pb-20 mt-14'>
                                <div className='w-1/2 mr-2 md:w-full'>
                                    <span className='paragraph-large-medium'>Step 2/2</span>
                                </div>
                                <div
                                    className='w-1/2 ml-2 md:hidden'
                                    onClick={
                                        // onAddSubmit
                                        setShowAddRiderPage
                                    }>
                                    <LargePrimaryButton label='Next' />
                                </div>
                            </div>
                        ) : (
                            addOrEdit === 'edit' && (
                                <div className='flex justify-between'>
                                    <div
                                        className='w-8/12 pr-2'
                                        // onClick={onEditSubmit}
                                    >
                                        <LargePrimaryButton
                                            label='Edit rider'
                                            type='submit'
                                        />
                                    </div>
                                    <div
                                        className='w-4/12 pl-2'
                                        // onClick={handleDiscard}
                                    >
                                        <LargeDestructiveButton
                                            label='Discard Rider'
                                            type='submit'
                                        />
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SecondPopup;
