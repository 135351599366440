import React from 'react';

export default function Card(props) {
    const { highLightedPart, description, title, header, icon } = props;

    const heighLightedPart = highLightedPart;

    const index = description.indexOf(heighLightedPart);

    return (
        <div className='relative border border-neutral-300 rounded-md px-4 pt-3 w-full h-full'>
            <div className='paragraph-small-semi-bold text-neutral-500'>{title}</div>
            <h6 className='heading-h6-semi-bold-desktop py-3'>{header}</h6>
            <p className='paragraph-x-small-regular text-neutral-500 pb-3 lg:max-w-[256px] md:max-w-full'>
                {description.substring(0, index)}
                <span className='paragraph-x-small-medium text-destructive-500'>
                    {description.substring(index, index + highLightedPart.length)}
                </span>
                {description.substring(index + highLightedPart.length)}
            </p>
            <div className='absolute top-3 right-4'>
                <div className='p-1 bg-primary-500 rounded-lg'>{icon}</div>
            </div>
        </div>
    );
}
