import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SmallPrimaryButton } from '../../../../../../Components/Buttons/Button';
import { ReactComponent as AddIcon } from '../../../../../../Assets/add.svg';
import CardSection from './Components/CardSection';

export default function GroupSection(props) {
    const { selectedAddon, setSelectedAddon } = props;

    const navigate = useNavigate();

    return (
        <>
            <div className='flex flex-col'>
                <div className='flex flex-row justify-between w-full items-center md:pb-2 pb-4'>
                    <span className='paragraph-large-medium text-[#000000]'>Addon group</span>
                    <div
                        className='max-w-[131px] cursor-pointer'
                        onClick={() => navigate('/menu/addon/basic-details')}>
                        <SmallPrimaryButton
                            leftIconClick={
                                <AddIcon
                                    stroke='#C4BEED'
                                    height={208}
                                    width={20}
                                />
                            }
                            leftIconDefault={
                                <AddIcon
                                    stroke='#FFFFFF'
                                    height={20}
                                    width={20}
                                />
                            }
                            label='Add addon'
                        />
                    </div>
                </div>

                <CardSection
                    selectedAddon={selectedAddon}
                    setSelectedAddon={setSelectedAddon}
                />
            </div>
        </>
    );
}
