import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as SwiggyIcon } from '../../Assets/swiggy.svg';
import { ReactComponent as ZomatoIcon } from '../../Assets/zomato.svg';
import OrderCard from './Components/OrderCard';
import Header from './Components/Header';
import { IsIpadScreen, IsMobileScreen } from '../../Constants/Constants';
import DishesSummery from './Components/DishesSummery';
import { useDispatch } from 'react-redux';
import { TOGGLE_FULL_SCREEN } from '../../reduxStore/types/fullScreen';
import { SET_LIST_ORDER_MENU } from '../../reduxStore/types/outlet';
import { useSelector } from 'react-redux';
import isElectron from 'is-electron';
import { format } from 'date-fns';

export default function KitchenDisplaySystem() {
    const dispatch = useDispatch();

    const orders = useSelector((state) => state.outlet.listOrder);
    const statusCheck = orders.map((order) => JSON.parse(order?.order_status));
    const [showDishesSummery, setShowDishesSummery] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [activeTab, setActiveTab] = useState('active');

    const dishesSummary = useMemo(() => {
        const summary = {};
        orders.forEach((order) => {
            const parsedCart = JSON.parse(order.cart);
            const cartItems = parsedCart.cart_items;
            cartItems.forEach((item) => {
                const itemName = item.items_translation.title['1']; // Assuming '1' is the language key you want
                if (summary[itemName]) {
                    summary[itemName].quantity += item.quantity;
                } else {
                    summary[itemName] = {
                        quantity: item.quantity,
                        type: item.items.food_type === 'vegetarian' ? 'veg' : 'non-veg',
                    };
                }
            });
        });
        return Object.entries(summary).map(([name, details]) => ({
            orderedDishes: name,
            quantity: details.quantity,
            type: details.type,
        }));
    }, [orders]);

    const handleClickMaximize = () => {
        dispatch({
            type: TOGGLE_FULL_SCREEN,
            payload: !showFullScreen,
        });
        setShowFullScreen(!showFullScreen);
    };

    const isMobileScreen = IsMobileScreen();

    const isIpadScreen = IsIpadScreen();
    const filterOrdersByStatus = (orders) => {
        const activeOrders = [];
        const completedOrders = [];

        orders.forEach((order) => {
            try {
                const orderStatus = JSON.parse(order.order_status);
                if (!orderStatus.restaurant_ready_at) {
                    activeOrders.push(order);
                } else {
                    completedOrders.push(order);
                }
            } catch (error) {
                console.error('Error parsing order_status', error);
            }
        });

        return { active: activeOrders, completed: completedOrders };
    };
    let filteredOrders = filterOrdersByStatus(orders);

    useEffect(() => {
        filteredOrders = filterOrdersByStatus(orders);
    }, [orders]);

    const handleDoubleClickHeader = async (order) => {
        const currentDate = new Date();
        const formattedDate = format(currentDate, 'yyyy-MM-dd HH:mm:ss');

        let orderStatus = typeof order.order_status == 'string' ? JSON.parse(order.order_status) : order.order_status;
        orderStatus = JSON.stringify({ ...orderStatus, restaurant_ready_at: formattedDate });
        if (isElectron()) {
            window.electron.ipcRenderer.send('updateOrderStatus', { orderId: order.id, orderStatus });

            // Listen for the ‘menu-data’ response from the main process
            window.electron.ipcRenderer.on('updateOrderStatusSuccess', (data) => {
                if (data.success) {
                    dispatch({
                        type: SET_LIST_ORDER_MENU,
                        payload: data.updatedOrderStatus,
                    });
                }
            });
        }
        // const updatedOrders = orders.map((o) => (o.id === order.id ? { ...o, status: 'completed' } : o));
        // setOrders(updatedOrders);
        // setSelectedCard({ ...order, status: 'completed' });
    };

    const tabs = [
        { item: 'Active', badgeText: filteredOrders?.active?.length, value: 'active' },
        {
            item: 'Completed',
            badgeText: filteredOrders?.completed?.length,
            value: 'completed',
        },
    ];
    useEffect(() => {
        if (isElectron()) {
            window.electron.ipcRenderer.send('getlist');
            // Listen for the ‘menu-data’ response from the main process
            window.electron.ipcRenderer.on('list-order-data', (data) => {
                dispatch({
                    type: SET_LIST_ORDER_MENU,
                    payload: data,
                });
            });
        }
    }, []);

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div className={`w-full ${!showFullScreen ? 'max-w-[1440px]' : 'max-w-[1920px]'} mx-auto bg-white`}>
                    {(!showDishesSummery || !isMobileScreen) && (
                        <Header
                            handleClickDishesSummery={() => setShowDishesSummery((prevState) => !prevState)}
                            handleClickMaximize={handleClickMaximize}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            tabs={tabs}
                        />
                    )}

                    <div
                        className={`flex flex-row min-h-screen pl-[22px] lg:pl-3 md:pl-4 md:pr-4 lg:pb-4 md:block ${
                            !showDishesSummery && 'pr-[22px] lg:pr-3'
                        }`}>
                        {(!showDishesSummery || !isMobileScreen) && (
                            <div className={`md:contents pb-6 ${showDishesSummery ? 'lg:w-1/2' : ''}`}>
                                {filteredOrders[activeTab]
                                    // .filter((item) => item.status == activeTab)
                                    ?.map((order, index) => (
                                        <div
                                            className={`w-[329px] 2xlBig:w-[326px] mx-2.5 inline-block align-top 2xl:mx-0 2xl:w-[329px] pageContentSmall:w-[329px] 2xl:min-w-0 2xl:px-2.5 lg:px-1 pt-6 lg:pt-4 md:w-full md:px-0 lg:min-w-0 ${
                                                showDishesSummery ? 'lg:w-full' : 'lg:w-[329px]'
                                            }`}
                                            key={index}>
                                            <OrderCard
                                                KOT={index + 1}
                                                {...order}
                                                onDoubleClick={() => handleDoubleClickHeader(order)}
                                                activeTab={activeTab}
                                            />
                                        </div>
                                    ))}
                            </div>
                        )}

                        {showDishesSummery && (
                            <div className='sticky lg:w-1/2 md:w-full border-l border-neutral-300 ml-1 3xl:ml-2 md:border-none md:ml-0'>
                                <div
                                    className={`min-w-[362px] pr-[18px] md:border-0 md:ml-0 md:pr-0 md:min-w-0 lg:min-w-0 sticky lg:w-full ${
                                        showFullScreen ? (isIpadScreen ? 'top-6' : 'top-3') : 'top-[60px]'
                                    }`}>
                                    <DishesSummery
                                        handleClickBack={() => setShowDishesSummery((prevState) => !prevState)}
                                        showFullScreen={showFullScreen}
                                        dishesSummary={dishesSummary}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
