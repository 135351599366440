import React from 'react';
import FillAddonGroupDetails from './Component/FillAddonGroupDetails';

export default function CreateAddonGroup() {
    return (
        <>
            <FillAddonGroupDetails />
        </>
    );
}
