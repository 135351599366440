import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SmallPrimaryButton } from '../../../../../Components/Buttons/Button';
import { ReactComponent as AddIcon } from '../../../../../Assets/add.svg';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import ListLoader from '../Components/ListLoader';
import HasMoreCard from '../Components/HasMoreCard';
import SubCategoryCard from '../Components/SubCategoryCard';
import CategoryCardSection from '../Components/CategoryCardSection';
import { useCategoryContext } from '../Context/CategoryContext';

export default function CategorySection(props) {
    const {
        pageState,
        setSelectedCategoryDish,
        selectedCategoryDish,
        setAllCategoryDish,
        isOperationLoading,
        setIsOperationLoading,
        setSelectedCategoryId,
        selectedCategoryId,
        setClickedCategory,
    } = props;

    const { categoryList, fetchCategory, paginationState, isLoading, setPaginationState, setUpdateFunction } =
        useCategoryContext();

    const navigate = useNavigate();

    const goToAddCategory = () => {
        navigate('/menu/add-category/basic-details');
    };

    const goToEditCategory = (category) => {
        navigate(`/menu/edit-category/${category.category_id}/basic-details`, { state: { category } });
    };

    const screenSize = useWindowSize();

    const initialPaginationState = { page: 1, per_page: 10, has_more_pages: null };

    const updateFunctionForCategory = (params, category) => {
        params.page === 1 &&
            setSelectedCategoryDish((prevState) => ({
                ...prevState,
                category: category?.data?.[0],
                subCategory: category?.data[0]?.sub_categories,
                subCategoryId: category?.data?.[0]?.sub_categories?.[0]?.category_id,
            }));
        setClickedCategory({ id: category?.data?.[0]?.category_id, type: 'category' });
        setIsOperationLoading(false);
    };

    useEffect(() => {
        fetchCategory(initialPaginationState, updateFunctionForCategory);

        setUpdateFunction(() => updateFunctionForCategory);
    }, []);

    const containerRef = useRef(null);

    const handleScroll = useCallback(() => {
        const container = containerRef.current;

        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;

            if (scrollTop + clientHeight === scrollHeight && paginationState.last_page !== paginationState.page) {
                fetchCategory({ ...paginationState, page: paginationState.page + 1 }, updateFunctionForCategory);

                setPaginationState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
            }
        }
    }, [paginationState]);

    const [showSubCategory, setShowSubCategory] = useState(null);

    useEffect(() => {
        if (isOperationLoading) {
            setAllCategoryDish([]);
            fetchCategory(initialPaginationState, updateFunctionForCategory);
        }
    }, [isOperationLoading]);

    const handleCategorySelection = (isChecked, category) => {
        let selectedCategoryState = isChecked
            ? [...selectedCategoryId, category.category_id]
            : selectedCategoryId.filter((el) => el !== category.category_id);

        if (category.sub_categories.length && !isChecked) {
            const subCategoryIds = category.sub_categories.map((el) => el.category_id);
            selectedCategoryState = selectedCategoryState.filter((el) => !subCategoryIds.includes(el));
        }

        setSelectedCategoryId(selectedCategoryState);
    };

    const handleClickCategoryCard = (category) => {
        setSelectedCategoryDish((prevState) => ({
            ...prevState,
            category: category,
            subCategory: category.sub_categories,
            ...(category.sub_categories.length ? {} : { subCategoryId: null }),
        }));
        !category.sub_categories.length && setShowSubCategory(null);
        setClickedCategory({ id: category.category_id, type: 'category' });
    };

    useEffect(() => {
        if (isOperationLoading) {
            setAllCategoryDish([]);
            fetchCategory(initialPaginationState, updateFunctionForCategory);
        }
    }, [isOperationLoading]);

    return (
        <>
            <div className='flex flex-row justify-between w-full items-center md:pb-2 pb-4 max-w-[411px]'>
                <span className='paragraph-large-medium text-[#000000]'>Categories</span>
                <div
                    className='max-w-[131px] cursor-pointer'
                    onClick={() => goToAddCategory()}>
                    <SmallPrimaryButton
                        leftIconClick={
                            <AddIcon
                                stroke='#C4BEED'
                                height={208}
                                width={20}
                            />
                        }
                        leftIconDefault={
                            <AddIcon
                                stroke='#FFFFFF'
                                height={20}
                                width={20}
                            />
                        }
                        label='Add category'
                    />
                </div>
            </div>

            {isLoading && paginationState?.page === 1 && <ListLoader className='max-w-[411px] h-[104px]' />}

            {!isLoading && !categoryList?.length && (
                <div
                    className='m-auto flex justify-center min-w-[411px] items-center text-xl font-bold '
                    style={{ height: screenSize.height - 300 }}>
                    No dishes are available
                </div>
            )}

            <div
                ref={containerRef}
                onScroll={handleScroll}
                className='overflow-auto scrollbar-style -mr-8 pr-8 -ml-10 pl-10'
                style={{ height: screenSize.height - 300 }}>
                {categoryList?.map((category) => (
                    <div key={category.category_id}>
                        <CategoryCardSection
                            pageState={{ ...pageState, reArrange: false }}
                            category={category}
                            selectedCategoryDish={selectedCategoryDish}
                            handleClickCategoryCard={handleClickCategoryCard}
                            goToEditCategory={goToEditCategory}
                            setShowSubCategory={setShowSubCategory}
                            showSubCategory={showSubCategory}
                            handleCategorySelection={handleCategorySelection}
                            selectedCategoryId={selectedCategoryId}
                            setSelectedCategoryDish={setSelectedCategoryDish}
                            setSelectedCategoryId={setSelectedCategoryId}
                            setClickedCategory={setClickedCategory}
                        />
                        {!!category.sub_categories.length && showSubCategory === category.category_id && (
                            <SubCategoryCard
                                pageState={pageState}
                                selectedCategoryDish={selectedCategoryDish}
                                setSelectedCategoryDish={setSelectedCategoryDish}
                                subCategory={category?.sub_categories}
                                handleCategorySelection={handleCategorySelection}
                                setSelectedCategoryId={setSelectedCategoryId}
                                selectedCategoryId={selectedCategoryId}
                                setClickedCategory={setClickedCategory}
                                goToEditCategory={goToEditCategory}
                                category={category}
                            />
                        )}
                    </div>
                ))}

                {paginationState?.has_more_pages && <HasMoreCard />}

                {isLoading && <ListLoader className='max-w-[303px] h-[104px]' />}
            </div>
        </>
    );
}
