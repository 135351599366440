import React, { useState } from 'react';
import Layout from './Component/Layout';
import CategoryDishTab from './Component/CategoryDishTab';
import CategoryRearrange from './Component/CategoryRearrange';
import DishesRearrange from './Component/DishesRearrange';

export default function ReArrangeCategoryDishFlyout(props) {
    const { handleClickClose, selectedCategoryDish, setIsOperationLoading } = props;

    const [activeTab, setActiveTab] = useState('category');

    const componentToMap = { category: CategoryRearrange, dish: DishesRearrange };

    const ComponentToRender = componentToMap[activeTab];

    return (
        <>
            <Layout handleClickClose={handleClickClose}>
                <CategoryDishTab
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />

                <ComponentToRender
                    setIsOperationLoading={setIsOperationLoading}
                    handleClickClose={handleClickClose}
                    selectedCategoryDish={selectedCategoryDish}
                />
            </Layout>
        </>
    );
}
