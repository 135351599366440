import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { ReactComponent as DownArrowIcon } from '../../Assets/chevron-down.svg';
import { ReactComponent as AddIcon } from '../../Assets/add.svg';
import EditDelete from './EditDelete';
import APIV5 from '../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import { ErrorMessage } from '../Error/ErrorMessage';

export default function SubCategoryDropDown(props) {
    const { menuItems, fetchImageSubCategory, setSubCategoryMenuItem, subCategoryMenuItem, disabled, fixedHeight } =
        props;

    const [isCreateNewCategory, setIsCreateNewCategory] = useState(true);

    const [newCategoryName, setNewCategoryName] = useState('');

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const handleSpace = (e) => {
        if (e.keyCode === 32) {
            e.preventDefault();
            setNewCategoryName(newCategoryName + ' ');
        }
    };

    const [error, setError] = useState('');

    const addNewSubCategory = async () => {
        try {
            const response = await APIV5.post(`/restaurants/${selectedRestaurantId}/tags/create`, {
                name: newCategoryName,
            });

            if (response.success) {
                fetchImageSubCategory();
                setNewCategoryName('');
                setIsCreateNewCategory(true);
            }
        } catch (error) {
            setError(error.response.data.errors.name);
        }
    };

    const handleAddNewSubCategory = () => {
        if (newCategoryName.length > 0) {
            addNewSubCategory();
        } else {
            setError('Please enter sub-category name');
        }
    };

    const handleSelection = (selection) => {
        setSubCategoryMenuItem((prevState) => [...prevState, selection]);
    };

    const [filteredMenuItems, setFilteredMenuItems] = useState();

    const [errorRename, setErrorRename] = useState(null);

    useEffect(() => {
        setFilteredMenuItems(
            menuItems?.filter((item) => {
                return !subCategoryMenuItem.some((subItem) => subItem.label === item.label);
            })
        );
    }, [menuItems, subCategoryMenuItem]);

    const [toBeEditedValue, setToBeEditedValue] = useState();

    const handleClickEdit = (tagId) => {
        setToBeEditedValue({ tagId, newLabel: '' });
    };

    const handleClickDelete = async (tagId) => {
        try {
            const response = await APIV5.delete(`/restaurants/${selectedRestaurantId}/tags/${tagId}`);

            if (response.success) {
                setToBeEditedValue({});
                fetchImageSubCategory();
            }
        } catch (error) {
            console.log('error ==>', error);
        }
    };

    const handleRename = async () => {
        try {
            const response = await APIV5.put(
                `restaurants/${selectedRestaurantId}/tags/${toBeEditedValue.tagId}?name=${toBeEditedValue.newLabel}`
            );

            if (response.success) {
                setToBeEditedValue({});
                fetchImageSubCategory();
            }
        } catch (error) {
            console.log('error ==>', error);
            setErrorRename({ message: error?.response?.data?.errors?.name });
        }
    };

    return (
        <>
            <div className='flex mb-1 justify-between items-center'>
                <label className={`paragraph-small-medium ${disabled && 'text-neutral-300'}`}>
                    Select sub-category
                </label>
            </div>

            <div className='relative '>
                <Menu
                    as='div'
                    onTransitionEnd={() => {
                        setToBeEditedValue({});
                        setErrorRename();
                    }}>
                    <div className='dropdown-icon'>
                        <Menu.Button
                            disabled={disabled}
                            className='shadow-Xsmall w-full justify-center mobile:max-w-full rounded-md outline-none border h-12 appearance-none px-4 border-neutral-300 paragraph-small-regular'>
                            <div className='flex flex-row justify-between items-center'>
                                <div className={`text-neutral-300 flex`}>Select image sub-category</div>
                                <DownArrowIcon
                                    height={24}
                                    width={24}
                                    className='drop-down-icon-rotate'
                                    fill={disabled ? '#D3D2D8' : '#131126'}
                                />
                            </div>
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items
                            className={`absolute left-0 ${fixedHeight && fixedHeight + ' overflow-auto scrollbar-style'}  right-0 mt-2 px-4 py-2 border paragraph-small-regular rounded-md shadow-medium bg-shades-50 font-normal z-50 lg:bottom-full lg:mb-2 md:bottom-auto`}>
                            <div className='w-full pt-2 mb-2'>
                                {isCreateNewCategory ? (
                                    <span
                                        className='text-primary-500 cursor-pointer'
                                        onClick={() => setIsCreateNewCategory(!isCreateNewCategory)}>
                                        + Create new sub-category
                                    </span>
                                ) : (
                                    <>
                                        <div className='flex flex-row justify-between'>
                                            <input
                                                value={newCategoryName}
                                                onKeyDown={handleSpace}
                                                onChange={(event) => {
                                                    setNewCategoryName(event.target.value);
                                                    setError('');
                                                }}
                                                placeholder='Enter new category name'
                                                className='placeholder:paragraph-small-regular placeholder:text-neutral-300 outline-none w-full'
                                            />
                                            <span
                                                className='bg-primary-500 rounded-full cursor-pointer'
                                                onClick={handleAddNewSubCategory}>
                                                <AddIcon stroke='#FFFFFF' />
                                            </span>
                                        </div>
                                        {error && <div className='pt-2 text-destructive-400 text-xs'>* {error}</div>}
                                    </>
                                )}
                            </div>
                            {filteredMenuItems?.map((el, index) => {
                                return (
                                    <div
                                        className='w-full  pt-2 mb-2 items-center group flex flex-row justify-between'
                                        key={index}>
                                        {toBeEditedValue?.tagId !== el.value ? (
                                            <>
                                                <div
                                                    className='w-full cursor-pointer'
                                                    onClick={() => handleSelection(el)}>
                                                    <Menu.Item>
                                                        <span className=''>{el.label}</span>
                                                    </Menu.Item>
                                                </div>
                                                <div className='hidden group-hover:block'>
                                                    <EditDelete
                                                        handleClickEdit={() => handleClickEdit(el.value)}
                                                        handleClickDelete={() => handleClickDelete(el.value)}
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className='flex flex-col w-full'>
                                                    <div className='flex flex-row justify-between w-full'>
                                                        <input
                                                            type='text'
                                                            value={toBeEditedValue.newLabel}
                                                            onChange={(e) => {
                                                                setToBeEditedValue((prevState) => ({
                                                                    ...prevState,
                                                                    newLabel: e.target.value,
                                                                }));
                                                                setErrorRename();
                                                            }}
                                                            placeholder='Enter new subcategory to edit'
                                                            className='placeholder:paragraph-small-regular placeholder:text-neutral-300 outline-none w-full border-b border-neutral-300 mr-5'
                                                        />
                                                        <span
                                                            className='cursor-pointer text-primary-500 hover:underline underline-offset-2'
                                                            onClick={handleRename}>
                                                            Save
                                                        </span>
                                                    </div>

                                                    <div className=''>
                                                        {errorRename && <ErrorMessage errors={errorRename} />}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
}
