import React, { useEffect, useState } from 'react';
import Table from './Table';
import { ReactComponent as CreditIcon } from '../../../Assets/credits.svg';
import { CreditInformationCard, ReferenceCard } from './Card';
import { IsIpadScreen, IsMobileScreen } from '../../../Constants/Constants';
import CalenderExportData from './CalenderExportData';
import { format } from 'date-fns';
import PaginationWithNumber from '../../../Components/Pagination/PaginationWithNumber';
import APIV5 from '../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import TableLoader from '../../../Components/ContentLoader/TableLoader';
import CardContentLoader from '../../../Components/ContentLoader/CardContentLoader';
import { ListBlankComponentView } from '../../../Components/BlankComponent/ListBlankComponentView';

export default function ServiceCredit(props) {
    const {
        fetchServiceCreditList,
        creditType,
        isServiceListLoading,
        creditList,
        setCreditList,
        selectedDates,
        setSelectedDates,
        availableServiceCredit,
        fetchServiceCardDetails,
    } = props;
    const today = new Date();

    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [isCardDetailLoading, setIsCardDetailLoading] = useState(false);

    const isIpadScreen = IsIpadScreen();

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const creditsCardContent = {
        title: 'Available service credits',
        number: availableServiceCredit,
        description: 'These credits are not real-time',
        icon: (
            <CreditIcon
                height={24}
                width={24}
                stroke='#ffffff'
            />
        ),
    };

    useEffect(() => {
        fetchServiceCardDetails();
    }, [selectedDates, selectedOutletId]);

    const defaultParams = () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        return {
            filters: {
                start_date: formatDate(from_date),
                end_date: formatDate(to_date),
            },
        };
    };

    const setData = async (params) => {
        try {
            const defaultParamsData = defaultParams();

            const response = await fetchServiceCreditList({
                ...defaultParamsData,
                per_page: paginationState.resultPerPage,
                page: paginationState?.selectedPage,
            });
            setCreditList(response.transactions.data);
            setPaginationState((prevState) => {
                return {
                    ...prevState,
                    lastPage: response.transactions.last_page,
                    selectedPage: response.transactions.current_page,
                };
            });
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        const params = {
            page: paginationState.selectedPage,
            per_page: paginationState.resultPerPage,
        };
        setData(params);
        // eslint-disable-next-line
    }, [selectedDates, paginationState.selectedPage, paginationState.resultPerPage, selectedOutletId]);

    const isMobileScreen = IsMobileScreen();

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    return (
        <>
            <CalenderExportData
                setSelectedDates={setSelectedDates}
                selectedDates={selectedDates}
                creditType={creditType}
            />

            <div
                className={`w-full max-w-[303px] mr-5 align-top mb-9 lg:w-1/2 lg:max-w-full lg:pr-1 lg:mb-7 md:w-full md:mb-3 md:my-1 md:pr-0 md:block md:pt-1 ${
                    isCardDetailLoading ? '-mx-[11px] lg:-mx-[1px]' : ''
                }`}>
                {isCardDetailLoading ? (
                    <CardContentLoader />
                ) : (
                    <CreditInformationCard
                        {...creditsCardContent}
                        minHeight='min-h-[116px]'
                    />
                )}
            </div>

            {!isIpadScreen ? (
                isServiceListLoading ? (
                    <table>
                        <tbody>
                            <TableLoader
                                hasSwitch={false}
                                columns={6}
                            />
                        </tbody>
                    </table>
                ) : (
                    <Table tableContent={creditList} />
                )
            ) : (
                <div>
                    {creditList && creditList.length > 0 ? (
                        creditList?.map((el, index) => (
                            <div
                                className='w-1/2 inline-block odd:pr-1 even:pl-1 lg:my-2 md:w-full md:odd:pr-0 md:even:pl-0 md:my-1'
                                key={index}>
                                <ReferenceCard
                                    referenceId={el?.reference_id ?? '---'}
                                    type={el?.type ?? '---'}
                                    service={el?.third_party_api_name ?? '---'}
                                    amount={el?.amount ?? '---'}
                                    rechargeDate={el?.date ?? '---'}
                                    status={el?.status ?? '---'}
                                />
                            </div>
                        ))
                    ) : (
                        <ListBlankComponentView previewText={'No Content Available'} />
                    )}
                </div>
            )}

            {!isMobileScreen && (
                <div className='pt-4'>
                    <PaginationWithNumber
                        lastPage={paginationState.lastPage}
                        selectedPage={paginationState.selectedPage}
                        setSelectedPage={(value) => {
                            handlePagination({ selectedPage: value }, 'selectedPage');
                        }}
                        setResultPerPage={(value) => handlePagination({ resultPerPage: value }, 'resultPerPage')}
                        selectedResultPerpage={paginationState.resultPerPage}
                        isLoading={isServiceListLoading}
                    />
                </div>
            )}
        </>
    );
}
