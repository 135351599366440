import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from './Layout.js';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import BasicDetails from '../TabComponents/BasicDetails.js';
import { preferenceMenuItem } from '../../../../Constants/Constants';
import ExposeDish from '../../AddDish/TabComponents/ExposeDish.js';
import Pricing from '../TabComponents/Pricing/Pricing.js';

export default function FillAddonChoiceDetails(props) {
    const { defaultDishFormState } = props;

    const { choiceTab } = useParams();

    const componentToMap = {
        'basic-details': BasicDetails,
        'expose-choice': ExposeDish,
        'pricing': Pricing,
    };

    const ComponentToRender = componentToMap?.[choiceTab];

    const getDefaultFormValue = () => {
        return _.isEmpty(defaultDishFormState)
            ? {
                  details: { show_agmark: preferenceMenuItem[0] },
                  config: {
                      minimumSelectionPreference: preferenceMenuItem[1],
                      maximumSelectionPreference: preferenceMenuItem[1],
                  },
              }
            : defaultDishFormState;
    };

    const methods = useForm({
        defaultValues: getDefaultFormValue(),
    });

    const dishProps = {
        title: 'Expose choice',
        description:
            'Select the ordering modes in which you would like the choice to appear to the in-house workers and the customers.',
    };

    return (
        <>
            <FormProvider {...methods}>
                <Layout>
                    {ComponentToRender ? (
                        <ComponentToRender {...(choiceTab === 'expose-choice' && dishProps)} />
                    ) : (
                        <div>Invalid Tab</div>
                    )}
                </Layout>
            </FormProvider>
        </>
    );
}
