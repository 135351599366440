import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../api/axios/APIV5';
import { MediumPrimaryButton } from '../../../../Components/Buttons/Button';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import Loader from './Loader';
import { useParams } from 'react-router-dom';

export default function SelectImageFromLibraryPopup(props) {
    const { handleClickClose, handleSelectImage } = props;
    const { bannerType } = useParams();
    const restaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [libraryImage, setLibraryImage] = useState([]);

    let banner =
        bannerType == 'secondary-banner'
            ? 'secondary_banner'
            : bannerType == 'primary-banner'
              ? 'primary_banner'
              : 'tertiary_banner';

    const [appliedFilter, setAppliedFilter] = useState({ image_type: banner ?? '', tag_id: null });

    const [selectedPage, setSelectedPage] = useState(1);

    const [lastPage, setLastPage] = useState(null);

    const [resultPerPage, setResultPerPage] = useState(10);

    const [isLoading, setIsLoading] = useState(true);

    const getImageList = async () => {
        try {
            const res = await APIV5.get(`/restaurants/${restaurantId}/images`, {
                params: { per_page: resultPerPage, page: selectedPage, image_type: appliedFilter?.image_type },
            });

            if (res.success) {
                setLibraryImage([...res.images.data]);
                setLastPage(res.images.last_page);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getImageList();
        // eslint-disable-next-line
    }, [selectedPage, resultPerPage]);

    const onSelectImage = (imageData) => {
        handleSelectImage({ id: imageData.id, image_url: imageData.image_url });
        handleClickClose();
    };

    return (
        <>
            <Layout handleClickClose={handleClickClose}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className='-mx-2.5 lg:-mx-1 md:-mx-[3.5px] md:text-center'>
                            {libraryImage.map((el, index) => (
                                <div
                                    key={index}
                                    className='inline-block mb-10 align-top mx-4 lg:mb-4 lg:w-[153px] lg:mx-1 lg:h-[268px] md:w-[168px] md:h-[276px] md:mx-2 md:my-1 mobile:w-full mobile:h-full'>
                                    <img
                                        src={el.image_url}
                                        alt=''
                                        className='h-[180px] w-[180px] lg:h-[144px] lg:w-[144px] md:w-[152px] md:h-[152px] mobile:mx-auto'
                                    />
                                    <div className='w-full text-center paragraph-medium-medium mb-1'>
                                        {'el.image_title'}
                                    </div>

                                    <div
                                        className=''
                                        onClick={() => onSelectImage(el)}>
                                        <MediumPrimaryButton
                                            label='Select Image'
                                            isDefault={false}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        <PaginationWithNumber
                            lastPage={lastPage}
                            setSelectedPage={setSelectedPage}
                            selectedPage={selectedPage}
                            setResultPerPage={(resPerPage) => setResultPerPage(resPerPage)}
                            selectedResultPerpage={resultPerPage}
                        />
                    </>
                )}
            </Layout>
        </>
    );
}
