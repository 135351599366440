import React from 'react';
import { filterCategorySubCategoryDishId, getFoodType } from '../../../../../functions';
import { Categories } from '../../../Components/Card';

export default function SubCategoryCard(props) {
    const {
        subCategory,
        selectedCategoryDish,
        setSelectedCategoryDish,
        handleClickCategory,
        selectedDish,
        allCategoryDish,
        setSelectedDish,
    } = props;

    const getAllDishIds = (category) => {
        const filteredId = filterCategorySubCategoryDishId(allCategoryDish);

        const currentSubCategory = filteredId
            .find((el) => el.categoryId === selectedCategoryDish?.category?.category_id)
            ?.subCategory?.find((el) => el.id === category?.category_id);

        return currentSubCategory?.dish?.map((dish) => dish.id);
    };

    const getSelectionState = (category) => {
        const dishIds = getAllDishIds(category);

        const isFullyChecked = !!dishIds?.length && dishIds.every((el) => selectedDish.includes(el));

        const isIndeterminateSelected = !isFullyChecked && dishIds.some((el) => selectedDish.includes(el));

        return { isFullyChecked, isIndeterminateSelected };
    };

    const onSelectCategory = (category, isSelected) => {
        const dishIds = getAllDishIds(category);

        if (isSelected) {
            setSelectedDish((prevState) => [...prevState, ...dishIds]);
        } else {
            setSelectedDish((prevState) => prevState.filter((el) => !dishIds.includes(el)));
        }
    };

    return (
        <>
            {subCategory?.map((el, index) => {
                const { isFullyChecked, isIndeterminateSelected } = getSelectionState(el);

                return (
                    <div
                        className={`mt-4 -mr-4 ml-4 w-full max-w-[273px] md:max-w-full md:mt-2 relative`}
                        key={index}>
                        <Categories
                            img={el.image_url}
                            title={el.internal_name}
                            totalItems={el.total_items}
                            type={getFoodType(el)}
                            subCategoryDetails={el.subCategoryDetails}
                            isActive={el.category_id === selectedCategoryDish?.subCategoryId}
                            handleClickCategory={() => {
                                setSelectedCategoryDish?.((prevState) => ({
                                    ...prevState,
                                    subCategoryId: el.category_id,
                                }));
                                handleClickCategory?.(el);
                            }}
                            handleClickEdit={() => {}}
                            isFromSelection={true}
                            minWidth={'min-w-[273px]'}
                            onChange={(isSelected) => onSelectCategory(el, isSelected)}
                            isChecked={isFullyChecked}
                            isIndeterminateSelected={isIndeterminateSelected}
                        />
                    </div>
                );
            })}
        </>
    );
}
