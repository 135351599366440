import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';
import DropdownWithCheckbox from '../../../../Components/DropDown/DropdownWithCheckbox';
import Tag from '../../../../Components/Tag/Tag';

export default function OrderingModeSection(props) {
    const {
        watch,
        setValue,
        formState: { errors },
        clearErrors,
    } = useFormContext();

    const orderingModes = useSelector((state) => state.outlet.orderingModes);
    const [localOrderingMode, setLocalOrderingMode] = useState([]);
    const [selectedModes, setSelectedModes] = useState([]);

    useEffect(() => {
        if (orderingModes && orderingModes.length > 0) {
            const orderingMode = watch('orderingMode');

            if (!!orderingMode?.length) {
                setLocalOrderingMode(orderingMode);
                setSelectedModes(orderingMode.filter((mode) => mode.isSelected));
                return;
            }

            const formattedOrderingMode = orderingModes.map((el) => ({
                label: el.display_name,
                value: el,
                isSelected: false,
            }));

            setValue('orderingMode', formattedOrderingMode);
            setLocalOrderingMode(formattedOrderingMode);
        }
    }, [orderingModes, setValue]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'orderingMode') {
                setLocalOrderingMode(value.orderingMode);
                setSelectedModes(value.orderingMode.filter((mode) => mode.isSelected));
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    const handleSelectionMode = (isSelected, menuItem) => {
        let orderingMode = watch('orderingMode');

        orderingMode = orderingMode.map((el) => {
            if (el?.value?.id === menuItem?.value?.id) {
                return { ...el, isSelected };
            }

            return el;
        });

        setValue('orderingMode', orderingMode);
        clearErrors('orderingMode');
        setSelectedModes(orderingMode.filter((mode) => mode.isSelected));
    };

    const handleRemoveMode = (modeToRemove) => {
        let orderingMode = watch('orderingMode');

        orderingMode = orderingMode.map((el) => {
            if (el?.value?.id === modeToRemove?.value?.id) {
                return { ...el, isSelected: false };
            }
            return el;
        });

        setValue('orderingMode', orderingMode);
        setSelectedModes(orderingMode.filter((mode) => mode.isSelected));
    };

    return (
        <div className='mb-6'>
            <div className='mb-[13px] relative'>
                {localOrderingMode && localOrderingMode.length > 0 && (
                    <DropdownWithCheckbox
                        fixedHeight='h-[160px]'
                        label='Banner mode'
                        boxHeight='h-[44px]'
                        placeholder='Select banner mode'
                        menuItems={localOrderingMode}
                        handleChangeCheck={(isSelected, menuItem) => handleSelectionMode(isSelected, menuItem)}
                    />
                )}

                {errors?.orderingMode && <ErrorMessage errors={errors?.orderingMode} />}
            </div>
            <div className='align-top mt-1'>
                {selectedModes.map((mode, index) => (
                    <div
                        className='mr-2 inline-block'
                        key={mode?.value?.id || index}>
                        <Tag
                            tag={mode.label}
                            onClose={() => handleRemoveMode(mode)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
