import React from 'react';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';

export const Filters = (props) => {
    const { setFilters, setPaginationState, isMobileScreen, setAbandonedCartsList } = props;

    const orderingMode = [
        { label: 'All', value: null },
        { label: 'Delivery', value: 'delivery' },
        { label: 'Dine In', value: 'dine_in' },
        { label: 'Takeaway', value: 'takeaway' },
    ];
    const customerAppearances = [
        { label: 'All', value: null },
        { label: 'Old', value: 'old' },
        { label: 'New', value: 'new' },
    ];

    const handleSelection = (selection) => {
        setFilters((prevState) => ({
            ...prevState,
            orderingMode: selection,
        }));
        isMobileScreen &&
            setAbandonedCartsList([]) &&
            setPaginationState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });
    };

    const handleRatingSelection = (selection) => {
        setFilters((prevState) => ({
            ...prevState,
            customerType: selection,
        }));
        isMobileScreen &&
            setAbandonedCartsList([]) &&
            setPaginationState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });
    };

    return (
        <section className='flex md:block items-center justify-end mb-6 md:mb-4 mt-4'>
            <div className='flex sm:block'>
                <div className='w-[200px] md:w-1/2 sm:w-full mr-2 sm:mr-0 sm:mb-3 md:mr-1'>
                    <DropdownWithValueLabel
                        menuItems={orderingMode}
                        label='Ordering Type'
                        placeholder='All'
                        selectedItemColor='text-neutral-900'
                        setSelected={(selection) => handleSelection(selection.value)}
                    />
                </div>
                <div className='w-[200px] md:w-1/2 sm:w-full ml-2 sm:ml-0 md:ml-1'>
                    <DropdownWithValueLabel
                        menuItems={customerAppearances}
                        label='Customer Status'
                        placeholder='All'
                        selectedItemColor='text-neutral-900'
                        setSelected={(selection) => handleRatingSelection(selection.value)}
                    />
                </div>
            </div>
        </section>
    );
};
