import React, { useEffect, useState } from 'react';
import { ReactComponent as TrashIcon } from '../../../Assets/trash.svg';

export default function AddButton(props) {
    const { initialValue = 0, handleValueChange, customStyle, disabled } = props;

    const [currentValue, setCurrentValue] = useState(initialValue);

    useEffect(() => {
        setCurrentValue(initialValue);
    }, [initialValue]);

    const updateValue = (operation) => {
        const newValue = operation === 'increment' ? currentValue + 1 : currentValue - 1;
        handleValueChange ? handleValueChange(newValue) : setCurrentValue(newValue);
    };

    const handleClick = () => {
        if (currentValue === 0) {
            const newValue = 1;
            handleValueChange ? handleValueChange(newValue) : setCurrentValue(newValue);
        }
    };

    const buttonMinWidth = currentValue === 0 ? 'min-w-[80px]' : 'min-w-[104px] sm:min-w-[96px]';

    return (
        <button
            className={`paragraph-small-regular p-[1px] h-[30px] w-full rounded-md text-center cursor-pointer ${buttonMinWidth} ${customStyle} ${
                disabled ? 'bg-primary-300' : 'bg-primary-500'
            }`}
            onClick={handleClick}
            disabled={disabled}>
            <div className='flex flex-row items-center'>
                {currentValue !== 0 && (
                    <span
                        className={`font-normal cursor-pointer text-2xl leading-5 ${
                            currentValue === 1 ? 'px-[7px] py-1.5' : 'px-[9.5px] py-1'
                        } text-[#6C5DD3] bg-white rounded-md`}
                        onClick={() => updateValue('decrement')}>
                        {currentValue === 1 ? (
                            <TrashIcon
                                width={16}
                                height={16}
                            />
                        ) : (
                            '-'
                        )}
                    </span>
                )}

                <span className='text-shades-50 mx-auto paragraph-medium-regular md:paragraph-small-regular md:text-shades-50 pageContent:text-sm'>
                    {currentValue !== 0 ? currentValue : 'Add'}
                </span>

                {currentValue !== 0 && (
                    <span
                        className='font-normal text-2xl leading-5 px-[7px] py-1 text-[#6C5DD3] bg-white rounded-md cursor-pointer'
                        onClick={() => updateValue('increment')}>
                        +
                    </span>
                )}
            </div>
        </button>
    );
}
