import React, { useState, useEffect } from 'react';
import { ReactComponent as TimerIcon } from '../../../Assets/timer.svg';
import { differenceInMinutes, differenceInSeconds, parseISO } from 'date-fns';

export const OrderAgeTime = ({ orderDetail }) => {
    const { restaurant_accepted_at: targetTime, details, restaurant_ready_at } = orderDetail;
    const { kitchen_preparation_time } = details;
    const [timeDifference, setTimeDifference] = useState({ minutes: '00', seconds: '00' });
    useEffect(() => {
        if (!restaurant_ready_at) {
            const interval = setInterval(() => {
                const parsedTime = parseISO(targetTime.replace(' ', 'T'));
                const now = new Date();

                const minutes = differenceInMinutes(now, parsedTime).toString().padStart(2, '0');
                const seconds = (differenceInSeconds(now, parsedTime) % 60).toString().padStart(2, '0'); // Get remaining seconds and pad to two digits

                setTimeDifference({ minutes, seconds });
            }, 1000); // Update every second

            return () => clearInterval(interval); // Clean up on component unmount
        } else {
            // If ready_at has a value, stop the timer and keep showing the current time difference
            const parsedTime = parseISO(targetTime.replace(' ', 'T'));
            const now = new Date();

            const minutes = differenceInMinutes(now, parsedTime).toString().padStart(2, '0');
            const seconds = (differenceInSeconds(now, parsedTime) % 60).toString().padStart(2, '0'); // Pad seconds

            setTimeDifference({ minutes, seconds });
        }
    }, [targetTime, restaurant_ready_at]);

    // Convert both timeDifference and kitchen_preparation_time to total seconds
    const totalTimeInSeconds = Math.abs(timeDifference.minutes) * 60 + Math.abs(timeDifference.seconds);
    const preparationTimeInSeconds = kitchen_preparation_time * 60;

    // Check if total time difference in seconds exceeds preparation time in seconds
    const isExceedingPreparationTime = totalTimeInSeconds > preparationTimeInSeconds;
    return (
        <div
            className={`mt-4 pt-4 border-t flex flex-row justify-between paragraph-medium-semi-bold border-neutral-300 ${
                isExceedingPreparationTime ? 'text-[#F15B5B]' : 'text-primary-500'
            }`}>
            <span>Time elapsed</span>

            <div className='flex flex-row items-center'>
                {isExceedingPreparationTime && (
                    <>
                        <span className='ping-animation absolute h-6 w-6 rounded-full bg-[#F15B5B]' />
                        <div className='w-[20px] h-[20px] rounded-full bg-white absolute animate-none ml-0.5' />
                    </>
                )}
                <TimerIcon
                    height={24}
                    width={24}
                    stroke={isExceedingPreparationTime ? '#F15B5B' : '#6c5dd3'}
                    className='z-50'
                />

                <span className='ml-2'>
                    {/* {timeElapsed[0].mins} mins {timeElapsed[1].seconds} secs */}
                    {timeDifference.minutes} mins {timeDifference.seconds} secs
                </span>
            </div>
        </div>
    );
};
