import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { format, isValid, parseISO } from 'date-fns';
import DatePicker from 'react-datepicker';
import { ReactComponent as CalenderIcon } from '../../../../../Assets/calendar.svg';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import DropdownSection from '../../../../../Components/DropDown/DropdownSection';

export default function DatePickerSection() {
    const { control, setValue, watch } = useFormContext();

    const initialDate = { startTime: new Date(), endTime: new Date() };

    const [selectedDate, setSelectedDate] = useState(initialDate);

    useEffect(() => {
        const fromDate = watch('validate_from');
        const toDate = watch('validate_to');

        if (fromDate && toDate && isValid(parseISO(fromDate)) && isValid(parseISO(toDate))) {
            setSelectedDate({
                startTime: parseISO(fromDate),
                endTime: parseISO(toDate),
            });
        }
    }, [watch('validate_from'), watch('validate_to')]);

    const handleDropdownSelection = useCallback(
        (selectedOption) => {
            if (selectedOption !== undefined) {
                setValue('has_offer_validity', selectedOption);
            }
        },
        [setValue]
    );
    const handleDateSelection = useCallback(
        (fieldOnChange, date, timeSection) => {
            const formattedDate = format(date, 'yyyy-MM-dd');

            if (timeSection === 'startTime' && selectedDate.endTime < date) {
                setSelectedDate({ startTime: date, endTime: date });
                setValue('validate_from', formattedDate);
                setValue('validate_to', formattedDate);
                return;
            }

            setSelectedDate((prevState) => ({
                ...prevState,
                [timeSection]: date,
            }));

            setValue('validate_to', formattedDate);
            fieldOnChange(formattedDate);
        },
        [selectedDate, setValue]
    );

    const hasOfferValidity = watch('has_offer_validity');
    const prevHasOfferValidityRef = useRef();

    const handleSelectionOfferValidity = useCallback(
        (selectedOption) => {
            if (selectedOption && selectedOption?.value === false) {
                const currentDate = format(new Date(), 'yyyy-MM-dd');
                setValue('validate_from', currentDate);
                setValue('validate_to', currentDate);
                setSelectedDate(initialDate);
            }
        },
        [setValue, initialDate]
    );

    useEffect(() => {
        if (hasOfferValidity !== prevHasOfferValidityRef.current) {
            if (hasOfferValidity && hasOfferValidity.value === false) {
                handleSelectionOfferValidity(hasOfferValidity);
            }
            prevHasOfferValidityRef.current = hasOfferValidity;
        }
        watch('has_offer_validity')?.value === false && handleSelectionOfferValidity();

        //eslint-disable-next-line
    }, [hasOfferValidity, handleSelectionOfferValidity]);

    const today = new Date();
    return (
        <>
            <div>
                <DropdownSection
                    title='Offer validity'
                    description='Select whether to run the offer for limited period?'
                    name='has_offer_validity'
                    placeholder='Select your preference'
                    className='mb-6'
                    menuItems={preferenceMenuItem}
                    rules={{ required: 'Please select preference' }}
                    handleSelection={handleDropdownSelection}
                    zIndex='z-[1]'
                    additionalBelowSpace={120}
                />
            </div>

            {watch('has_offer_validity')?.value && (
                <div className='flex flex-row items-center md:mb-16'>
                    <div className='mr-2.5 max-w-[196px] w-full'>
                        <Controller
                            render={({ field }) => (
                                <DatePicker
                                    selected={selectedDate.startTime}
                                    onChange={(value) => handleDateSelection(field.onChange, value, 'startTime')}
                                    timeIntervals={15}
                                    timeCaption='time'
                                    dateFormat='dd MMM yyyy'
                                    showIcon
                                    icon={
                                        <CalenderIcon
                                            className={`${!watch('has_offer_validity')?.value && 'stroke-[#d3d2d8]'}`}
                                        />
                                    }
                                    showPopperArrow={false}
                                    minDate={today}
                                    disabled={!watch('has_offer_validity')?.value}
                                />
                            )}
                            name='validate_from'
                            control={control}
                        />
                    </div>

                    <div className='ml-2.5 max-w-[196px] w-full'>
                        <Controller
                            render={({ field }) => (
                                <DatePicker
                                    selected={selectedDate.endTime}
                                    onChange={(value) => handleDateSelection(field.onChange, value, 'endTime')}
                                    timeIntervals={15}
                                    timeCaption='time'
                                    dateFormat='dd MMM yyyy'
                                    showIcon
                                    icon={
                                        <CalenderIcon
                                            className={`${!watch('has_offer_validity')?.value && 'stroke-[#d3d2d8]'}`}
                                        />
                                    }
                                    popperPlacement='bottom-end'
                                    minDate={selectedDate.startTime}
                                    disabled={!watch('has_offer_validity')?.value}
                                    showPopperArrow={false}
                                    className={!watch('has_offer_validity')?.value && 'text-neutral-300 cursor-none'}
                                />
                            )}
                            name='validate_to'
                            control={control}
                        />
                    </div>
                </div>
            )}
        </>
    );
}
