import React, { createContext, useContext, useState } from 'react';
import { fetchCategoryList } from '../../../functions';
import { useSelector } from 'react-redux';

const CategoryContext = createContext();

export const useCategoryContext = () => useContext(CategoryContext);

export const CategoryProvider = ({ children }) => {
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const [categoryList, setCategoryList] = useState([]);
    const [paginationState, setPaginationState] = useState({ page: 1, per_page: 10, has_more_pages: null });
    const [isLoading, setIsLoading] = useState(true);
    const [categoryForRearrange, setCategoryForRearrange] = useState([]);

    const [updateFunction, setUpdateFunction] = useState(() => () => {});

    const fetchCategory = async (params, callBack) => {
        try {
            const category = await fetchCategoryList(selectedRestaurantId, params);

            setPaginationState((prevState) => ({
                ...prevState,
                has_more_pages: category?.has_more_pages,
                last_page: category?.last_page,
            }));

            if (params.page === 1) {
                setCategoryList(category?.data);
                setCategoryForRearrange(category?.data);
            } else {
                setCategoryList((prevState) => [...prevState, ...category?.data]);
                setCategoryForRearrange((prevState) => [...prevState, ...category?.data]);
            }

            callBack?.(params, category);

            setIsLoading(false);
        } catch (error) {
            console.log('error : ', error);
        }
    };

    return (
        <CategoryContext.Provider
            value={{
                categoryList,
                fetchCategory,
                paginationState,
                isLoading,
                setPaginationState,
                setCategoryList,
                categoryForRearrange,
                setCategoryForRearrange,
                updateFunction,
                setUpdateFunction,
            }}>
            {children}
        </CategoryContext.Provider>
    );
};
