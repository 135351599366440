import React from 'react';
import { ReactComponent as NotificationIcon } from '../../../Assets/notifications.svg';
import { ReactComponent as AddIcon } from '../../../Assets/add.svg';
import { MultipleTab } from '../../../Components/Tabs/Tabs';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { startOfMonth } from 'date-fns';

const Header = (props) => {
    const { setShowPopup, setSelectedDates } = props;
    const today = new Date();

    const { creditType } = useParams();

    const tabs = [
        { item: 'Infrastructure credits', onClick: () => handleTabChange('infrastructure'), link: 'infrastructure' },
        { item: 'Service credits', onClick: () => handleTabChange('service'), link: 'service' },
    ];

    const isMobileScreen = IsMobileScreen();

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const handleTabChange = (tab) => {
        if (pathname !== `/credits/${tab}`) {
            setSelectedDates([startOfMonth(today), today]);
            navigate(`/credits/${tab}`);
        }
    };

    return (
        <>
            <div className='flex flex-row justify-between mb-4 pb-4 border-b border-neutral-300 md:border-0 md:pb-0'>
                {!isMobileScreen ? (
                    <div className='flex flex-row items-center'>
                        {tabs.map((el, index) => (
                            <div
                                className='mr-4 lg:mr-2'
                                key={index}>
                                <MultipleTab
                                    label={el.item}
                                    isActive={el.link === creditType}
                                    maxWidth='max-w-[195px]'
                                    onClick={el.onClick}
                                    index={index}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='w-full md:min-w-[199px] mobile:min-w-[172px] mr-2'>
                        <DropDownTabs
                            menuItems={tabs}
                            tabStyle='md:paragraph-small-medium md:text-primary-500 md:truncate md:block md:text-left'
                            itemStyle='paragraph-small-medium'
                        />
                    </div>
                )}
                <div className='flex flex-row'>
                    <div
                        className='w-[138px] md:w-[64px] mobile:w-[50px] mx-4 lg:mx-0 lg:mr-2 cursor-pointer'
                        onClick={() => setShowPopup({ addCredit: false, setAlert: true })}>
                        <LargePrimaryButton
                            isDefault={false}
                            leftIconDefault={<NotificationIcon stroke='#ffffff' />}
                            leftIconClick={<NotificationIcon stroke='#C4BEED' />}
                            label='Set alert'
                            hideLabel='md:hidden'
                        />
                    </div>
                    <div
                        className='w-[161px] md:w-[64px] mobile:w-[50px] cursor-pointer'
                        onClick={() => setShowPopup({ addCredit: true, setAlert: false })}>
                        <LargePrimaryButton
                            isDefault={false}
                            leftIconDefault={<AddIcon stroke='#ffffff' />}
                            leftIconClick={<AddIcon stroke='#C4BEED' />}
                            fontsWeight={true}
                            label='Add credits'
                            hideLabel='md:hidden'
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
