import React from 'react';
import { SingleLoader } from '../../../../Components/ContentLoader/DropdownLoader';

export default function ListLoader() {
    return (
        <div className='space-y-2'>
            <SingleLoader className='h-[96px]' />
            <SingleLoader className='h-[96px]' />
            <SingleLoader className='h-[96px]' />
            <SingleLoader className='h-[96px]' />
            <SingleLoader className='h-[96px]' />
            <SingleLoader className='h-[96px]' />
        </div>
    );
}
