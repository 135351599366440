import React from 'react';
import ToggleSwitch from '../../../Components/ToggleSwitch/ToggleSwitch';
import ListViewSelectRestaurant from '../Components/ListViewSelectRestaurant';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function TableSection(props) {
    const { outlets, handleRestaurantSelection, setStatusPopupData } = props;

    const isMobileScreen = IsMobileScreen();

    const handelClickStatus = (data) => {
        if (data.data.restaurant_ordering_mode_id === undefined) return;
        setStatusPopupData(data);
    };

    const uniqueOrderingModes = Array.from(
        new Set(outlets.flatMap((outlet) => outlet.ordering_modes.map((mode) => mode.type)))
    );

    return (
        <>
            {!isMobileScreen ? (
                <div className='mt-6'>
                    <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                        <table className='w-full break-words table-row-bg'>
                            <thead>
                                <tr className='bg-neutral-50 text-left paragraph-overline-small text-neutral-700'>
                                    <th className='shadow-innerShadow px-6 min-w-[90px] min-width-unset sticky left-0 z-10 bg-neutral-50 h-11 justify-center'>
                                        STATUS
                                    </th>
                                    <th className='shadow-innerShadow px-6 min-w-[300px] min-width-unset sticky left-[101px] z-10 bg-neutral-50'>
                                        OUTLET NAME
                                    </th>
                                    <th className='shadow-innerShadow px-6 min-w-[99px] min-width-unset'>STATE</th>
                                    <th className='shadow-innerShadow px-6 min-w-[93px] min-width-unset'>CITY</th>
                                    <th className='shadow-innerShadow px-6 min-w-[344px] min-width-unset'>ADDRESS</th>
                                    {uniqueOrderingModes.map((mode, index) => (
                                        <th
                                            key={index}
                                            className='shadow-innerShadow px-6 min-w-[100px] min-width-unset'>
                                            {mode.toUpperCase()}
                                        </th>
                                    ))}
                                    <th className='shadow-innerShadow px-6 min-w-[100px] min-width-unset'>ZOMATO</th>
                                </tr>
                            </thead>

                            <tbody className='paragraph-small-regular'>
                                {outlets?.map((el, outletIndex) => (
                                    <tr
                                        key={outletIndex}
                                        className='first:border-none border-t even:bg-neutral-50 border-neutral-300 paragraph-small-regular h-[70px] justify-center'>
                                        <td
                                            className='px-6 py-[23px] sticky left-0 z-10 first:border-none bg-neutral-50 border-t border-neutral-300'
                                            onClick={() =>
                                                setStatusPopupData({
                                                    showPopup: true,
                                                    data: {
                                                        restaurant_id: el.restaurant_id,
                                                        is_outlet_open: el.is_open,
                                                        outlet_id: el.id,
                                                    },
                                                    statusOf: 'outlet',
                                                })
                                            }>
                                            <ToggleSwitch
                                                cursorType='cursor-default'
                                                isEnable={el.is_open}
                                            />
                                        </td>
                                        <td
                                            className='px-6 left-[101px] z-10 sticky cursor-pointer bg-neutral-50'
                                            onClick={() => handleRestaurantSelection(el)}>
                                            {el.legal_name}
                                        </td>
                                        <td className='px-6'>{el.state}</td>
                                        <td className='px-6'>{el.city}</td>
                                        <td className='px-6'>{el.address}</td>
                                        {uniqueOrderingModes.map((mode, modeIndex) => {
                                            const currentMode = el.ordering_modes.find((m) => m.type === mode);

                                            return (
                                                <td
                                                    key={modeIndex}
                                                    className={`pl-6 text-sm font-normal ${
                                                        !!currentMode && 'cursor-pointer'
                                                    } ${
                                                        currentMode
                                                            ? currentMode.is_available
                                                                ? 'text-success-800'
                                                                : 'text-destructive-500'
                                                            : '-'
                                                    }`}
                                                    onClick={() =>
                                                        handelClickStatus({
                                                            showPopup: true,
                                                            data: {
                                                                restaurant_id: el.restaurant_id,
                                                                outlet_id: el.id,
                                                                restaurant_ordering_mode_id: currentMode
                                                                    ? currentMode.restaurant_ordering_mode_id
                                                                    : null,
                                                                is_orderingmode_active: !!currentMode
                                                                    ? currentMode.is_available
                                                                    : null,
                                                            },
                                                            statusOf: 'ordringMode',
                                                        })
                                                    }>
                                                    {currentMode
                                                        ? currentMode.is_available
                                                            ? 'Active'
                                                            : 'Inactive'
                                                        : '-'}
                                                </td>
                                            );
                                        })}
                                        <td
                                            className='px-6 text-destructive-500 cursor-pointer'
                                            onClick={() =>
                                                setStatusPopupData({
                                                    showPopup: true,
                                                    data: {
                                                        restaurant_id: 12,
                                                        outlet_id: 12,
                                                        isActive: true,
                                                    },
                                                    statusOf: 'zomato',
                                                })
                                            }>
                                            Inactive
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className='mt-2'>
                    {outlets?.map((el, index) => {
                        return (
                            <div
                                key={index}
                                className='py-1'>
                                <ListViewSelectRestaurant
                                    handleRestaurantSelection={() => handleRestaurantSelection(el)}
                                    setStatusPopupData={setStatusPopupData}
                                    {...el}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}
