import * as types from '../types/outlet';

const initialState = {
    outletList: [],
    selectedOutletId: localStorage.getItem('selectedOutletId')
        ? JSON.parse(localStorage.getItem('selectedOutletId'))
        : null,
    selectedOutlet: null,
    orderingModes: [],
    outletOrderingModes: [],
    categories: [],
    items: [],
    categoryWithItems: [],
    listOrder: [],
    redirectLink: null,
    actionSwitches: {
        save: true,
        savePrint: true,
        saveEBill: true,
        kot: true,
        kotPrint: true,
    },
    posSettings: {
        isCustomerInfoMandatory: true,
        billPaymentNCB: true,
        discountPermission: true,
        kotResetNumber: '',
    },
};
// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_OUTLET_LIST:
            return {
                ...state,
                outletList: action.payload.outletList,
            };
        case types.SET_SELECTED_OUTLET_ID:
            return {
                ...state,
                selectedOutletId: action.payload.selectedOutletId,
            };
        case types.SET_SELECTED_OUTLET:
            return {
                ...state,
                selectedOutlet: action.payload.selectedOutlet,
            };
        case types.SET_ORDERING_MODES:
            return {
                ...state,
                orderingModes: action.payload,
            };
        case types.SET_OUTLET_ORDERING_MODES:
            return {
                ...state,
                outletOrderingModes: action.payload,
            };
        case types.SET_OUTLET_MENU:
            return {
                ...state,
                categories: action.payload.categories,
                items: action.payload.items,
            };
        case types.SET_CATEGORY_WITH_ITEMS:
            return {
                ...state,
                categoryWithItems: action.payload,
            };
        case types.SET_LIST_ORDER_MENU:
            return {
                ...state,
                listOrder: action.payload,
            };
        default:
            return state;
    }
};
