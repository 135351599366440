import React from 'react';
import { useFormContext } from 'react-hook-form';
import LargeTitleSwitch from '../../../../../Components/ToggleSwitch/LargeTitleSwitch';
import PricingForSingleOrderingMode from './Component/PricingForSingleOrderingMode';
import DropdownSection from '../../../../../Components/DropDown/DropdownSection';
import { gstSlab } from '../../../../../Constants/Constants';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';

export default function DishPricing() {
    const { watch, control } = useFormContext();

    const orderingModes = watch('exposeDish');

    if (!orderingModes?.length) {
        return (
            <span className='paragraph-normal-normal'>
                Please select at least one ordering mode to apply timing to it
            </span>
        );
    }

    return (
        <>
            <div className='mt-4 max-w-[636px] w-full'>
                <TitleDescription
                    className='mb-4'
                    title='Select gst slab for this choice'
                    description='Select a GST slab(if applicable).'
                />
                <div className='max-w-[312px] md:max-w-full mb-8'>
                    <DropdownSection
                        boxHeight='h-[52px]'
                        labelpaddingB='pb-2'
                        label='(GST)'
                        placeholder='Select a GST slab'
                        shadow='shadow-small-drop-down-shadow'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        menuItems={gstSlab}
                        name='gstSlab'
                    />
                </div>
                <LargeTitleSwitch
                    classNames='mb-6'
                    control={control}
                    title='Enable same pricing for all ordering modes'
                    description='Enabling the same pricing for all ordering modes will set the pricing same across the selected exposed dish modes.'
                    switchName='pricing.samePricing'
                />
                {watch('pricing.samePricing') ? (
                    <PricingForSingleOrderingMode
                        priceId='pricing.samePrice'
                        display_name={null}
                    />
                ) : (
                    orderingModes?.map((el, index) => {
                        return (
                            <PricingForSingleOrderingMode
                                key={index}
                                {...el}
                                priceId={`pricing.${el.id}`}
                            />
                        );
                    })
                )}
            </div>
        </>
    );
}
