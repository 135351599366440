import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import { useFormContext } from 'react-hook-form';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { tabs } from '../Constants';
import { MultipleTab } from '../../../../Components/Tabs/Tabs';
import { components, offerTypesActiveTab } from '../../Helper/constant';

const LeftPanel = () => {
    const { id, section } = useParams();
    const navigate = useNavigate();
    const { height: pageHeight } = useWindowSize();
    const isMobileScreen = IsMobileScreen();
    const {
        watch,
        formState: { errors },
    } = useFormContext();

    const enabledTabs = useMemo(() => {
        return offerTypesActiveTab?.[watch('offer_type')?.value] || [];
    }, [watch('offer_type')]);

    const handleNavigation = (route) => {
        const path = id ? `/offers/edit/${id}/${route}` : `/offers/create-offer/${route}`;
        navigate(path);
    };

    const checkError = (tab) => {
        const component = components.find((comp) => comp.tab === tab.value);

        if (!component) return false;

        return component.fields.some((field) => errors[field.name]);
    };
    const offerType = watch('offer_type')?.value;

    if (isMobileScreen) return null;

    return (
        <div
            className='flex flex-col pr-6 border-r border-neutral-300 mr-5 lg:pr-4 lg:mr-3 overflow-auto [&::-webkit-scrollbar]:hidden min-w-[198px]'
            style={{ height: pageHeight - 165 }}>
            {tabs.map((el, index) => {
                const isActive = el.value === section;

                if (
                    offerType === 'coupled_offer' ||
                    (offerType === 'buy_one_get_one_free' && el.value === 'basic-details')
                ) {
                    el.hideCouponCode = true;
                } else {
                    el.hideCouponCode = false;
                }

                const isEnable = el.value === 'offer-type' || enabledTabs.includes(el.value);

                const hasError = isEnable ? checkError(el) : false;

                if (!isEnable) {
                    return null;
                }

                return (
                    <div
                        className='mb-4 lg:mb-2'
                        key={index}>
                        <MultipleTab
                            minWidth='min-w-[173px]'
                            label={el.label}
                            onClick={() => handleNavigation(el.value)}
                            disabled={!isEnable}
                            isActive={isActive}
                            hasError={hasError}
                            hideCouponCode={el.hideCouponCode}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default LeftPanel;
